import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

export const QUERY = "rs_consultation";

export function useListAvailableConsultation() {
	const result = useQuery([QUERY], () => axios.get(`consultation/list`).then(res => res.data));

	return result;
}

export const fetcConsultationsById = (id, filter) => {
	const query = qs.stringify(filter);

	return axios.get(`consultation/by_hospital/${id}?${query}`).then(res => res.data);
};

export function useListConsultationsById(id, filter) {
	return useQuery({
		queryKey: id && [QUERY, id, filter],
		queryFn: key => fetcConsultationsById(id, filter),
		refetchOnMount: false,
		enabled: !!id,
	});
}
