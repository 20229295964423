import {
	LayananPemeriksaanPenunjangAddPage,
	LayananPemeriksaanPenunjangDetailPage,
	LayananPemeriksaanPenunjangEditPage,
	LayananPemeriksaanPenunjangListPage,
} from "pages/AdminHospital/LayananPemeriksaanPenunjang";
import {MedicineAddPage, MedicineDetailPage, MedicineEditPage, MedicineListPage} from "pages/SubAdminDivision/Medicine";
import {
	LayananPoliklinikTindakanAddPage,
	LayananPoliklinikTindakanDetailPage,
	LayananPoliklinikTindakanEditPage,
	LayananPoliklinikTindakanListPage,
} from "pages/SubAdminDivision/LayananPoliklinikTindakan";

export const subAdminDivisiRoutes = [
	{
		name: "admin-hospital-service-checkup-support-list",
		path: "/hospital/service-checkup-support/list",
		exact: true,
		isPrivate: true,
		component: LayananPemeriksaanPenunjangListPage,
	},
	{
		name: "admin-hospital-service-checkup-support-add",
		path: "/hospital/service-checkup-support/add",
		exact: true,
		isPrivate: true,
		component: LayananPemeriksaanPenunjangAddPage,
	},
	{
		name: "admin-hospital-service-checkup-support-detail",
		path: "/hospital/service-checkup-support/show/:id",
		exact: true,
		isPrivate: true,
		component: LayananPemeriksaanPenunjangDetailPage,
	},
	{
		name: "admin-hospital-service-checkup-support-edit",
		path: "/hospital/service-checkup-support/edit/:id",
		exact: true,
		isPrivate: true,
		component: LayananPemeriksaanPenunjangEditPage,
	},
	{
		name: "sub-admin-division-medicine-list",
		path: "/sub-admin-divisi/medicine/list",
		exact: true,
		isPrivate: true,
		component: MedicineListPage,
	},
	{
		name: "sub-admin-division-medicine-tambah",
		path: "/sub-admin-divisi/medicine/tambah",
		exact: true,
		isPrivate: true,
		component: MedicineAddPage,
	},
	{
		name: "sub-admin-division-medicine-detail",
		path: "/sub-admin-divisi/medicine/show/:id",
		exact: true,
		isPrivate: true,
		component: MedicineDetailPage,
	},
	{
		name: "sub-admin-division-medicine-edit",
		path: "/sub-admin-divisi/medicine/edit/:id",
		exact: true,
		isPrivate: true,
		component: MedicineEditPage,
	},
	{
		name: "layanan-poliklinik-tindakan",
		path: "/sub-admin-divisi/layanan-poliklinik-tindakan/list",
		exact: true,
		isPrivate: true,
		component: LayananPoliklinikTindakanListPage,
	},
	{
		name: "layanan-poliklinik-tindakan-tambah",
		path: "/sub-admin-divisi/layanan-poliklinik-tindakan/tambah",
		exact: true,
		isPrivate: true,
		component: LayananPoliklinikTindakanAddPage,
	},
	{
		name: "admin-layanan-poliklinik-tindakan-edit",
		path: "/sub-admin-divisi/layanan-poliklinik-tindakan/edit/:id",
		exact: true,
		isPrivate: true,
		component: LayananPoliklinikTindakanEditPage,
	},
	{
		name: "admin-layanan-poliklinik-tindakan-detail",
		path: "/sub-admin-divisi/layanan-poliklinik-tindakan/show/:id",
		exact: true,
		isPrivate: true,
		component: LayananPoliklinikTindakanDetailPage,
	},
];
