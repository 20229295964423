/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {useEffect, useState, useRef} from "react";
import clsx from "clsx";
import {useHistory} from "react-router-dom";
import {Formik} from "formik";
import * as Yup from "yup";
import {useSelector} from "react-redux";
import {DashboardLayout} from "components/Layout";
import {SubmitButton} from "components/Button";
import {useAddLayananPoliklinikTindakan} from "hooks/AdminHospital/LayananPoliklinikTindakan";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";
import {useListPoliklinikById} from "hooks/AdminHospital/Poliklinik";
import {useListUnitById} from "hooks/AdminHospital/Unit";
import {useListDivisionById} from "hooks/AdminHospital/Division";
import {useListActionById} from "hooks/AdminHospital/Action";
import {useListActionCategoryById} from "hooks/AdminHospital/ActionCategory";
import {BasicDropdown} from "components/Dropdown";
import {convertToCurency} from "utils/converter";

const FormSchemaValidation = Yup.object().shape({
	hospitalId: Yup.number().required("Rumah sakit wajib di isi"),
	divisionId: Yup.number()
		.nullable(true)
		.transform((_, val) => (val === Number(val) ? val : null)),
	unitId: Yup.number().required("Unit wajib di isi"),
	poliklinikId: Yup.number().required("Poliklinik wajib di isi"),
	actionCategoryId: Yup.number().required("Kategori tindakan wajib di isi"),
	actionId: Yup.number().required("Tindakan wajib di isi"),
	buyPrice: Yup.number().required("Harga beli wajib di isi"),
	sellPrice: Yup.number().required("Harga jual wajib di isi"),
	statusProduct: Yup.number().required("Status produk wajib di isi"),
	stock: Yup.number().required(),
	status: Yup.boolean().nullable(),
});
const FormInitialValues = {
	hospitalId: null,
	divisionId: null,
	unitId: null,
	poliklinikId: null,
	actionCategoryId: null,
	actionId: null,
	buyPrice: null,
	buyPriceStr: null,
	sellPrice: null,
	sellPriceStr: null,
	statusProduct: 1,
	stock: null,
	status: null,
};

export function LayananPoliklinikTindakanAddPage() {
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);
	const {showToast} = useToast();
	const history = useHistory();
	const formikRef = useRef();
	const {mutate, isLoading} = useAddLayananPoliklinikTindakan();
	const {data: polikliniks} = useListPoliklinikById({hospitalId: selectedHospital.Hospital.id, isActive: true});
	const [poliklinikOptions, setPoliklinikOptions] = useState([]);
	const {data: units} = useListUnitById({hospitalId: selectedHospital.Hospital.id, isActive: true});
	const [unitOptions, setUnitOptions] = useState([]);
	const {data: divisions} = useListDivisionById({hospitalId: selectedHospital.Hospital.id, isActive: true});
	const [divisionOptions, setDivisionOptions] = useState([]);
	const {data: actionCategories} = useListActionCategoryById({
		hospitalId: selectedHospital.Hospital.id,
		isActive: true,
	});
	const [actionCategoryOptions, setActionCategoryOptions] = useState([]);
	const {data: actions} = useListActionById({hospitalId: selectedHospital.Hospital.id, isActive: true});
	const [actionOptions, setActionOptions] = useState([]);
	const statusProductOptions = [
		{
			value: 1,
			label: "Habis Pakai",
		},
		{
			value: 2,
			label: "Tidak Habis Pakai",
		},
	];
	const handleSubmit = formData => {
		if (!formData.divisionId) {
			formData.divisionId = null;
		}
		mutate(formData, {
			onSuccess: () => {
				showToast("success", "Data berhasil ditambahkan", 3000);
				history.push("/hospital/layanan-poliklinik-tindakan/list");
			},
			onError: res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
			},
		});
	};

	useEffect(() => {
		if (polikliniks?.success) {
			const dataPolyclinic = polikliniks?.data?.rows?.map(polyclinic => ({
				value: polyclinic?.id + "",
				label: `${polyclinic?.name}`,
			}));

			setPoliklinikOptions([...dataPolyclinic]);
			formikRef?.current?.setFieldValue(`poliklinikId`, dataPolyclinic[0]?.value);
		}
	}, [polikliniks]);

	useEffect(() => {
		if (units?.success) {
			const dataUnit = units?.data?.rows?.map(unit => ({
				value: unit?.id + "",
				label: `${unit?.name}`,
			}));

			dataUnit.unshift({value: null, label: ""});
			const poliklinikIdx = dataUnit.findIndex(o => o.label === "Poliklinik");

			setUnitOptions([...dataUnit]);
			if (poliklinikIdx) {
				formikRef?.current?.setFieldValue(`unitId`, dataUnit[poliklinikIdx]?.value);
			}
		}
	}, [units]);

	useEffect(() => {
		if (divisions?.success) {
			const dataDivision = divisions?.data?.rows?.map(division => ({
				value: division?.id + "",
				label: `${division?.name}`,
			}));

			dataDivision.unshift({value: null, label: ""});
			dataDivision.unshift({value: null, label: "None"});

			setDivisionOptions([...dataDivision]);
			formikRef?.current?.setFieldValue(`divisionId`, dataDivision[0]?.value);
		}
	}, [divisions]);

	useEffect(() => {
		if (actionCategories?.success) {
			const dataActionCategory = actionCategories?.data?.rows?.map(actionCategory => ({
				value: actionCategory?.id + "",
				label: `${actionCategory?.title}`,
			}));

			dataActionCategory.unshift({value: null, label: ""});

			setActionCategoryOptions([...dataActionCategory]);
		}
	}, [actionCategories]);

	const getActionOptions = actionCategoryId => {
		if (actions?.success) {
			const filteredAction = actions?.data?.rows?.filter(
				action => action.actionCategoryId === Number(actionCategoryId),
			);
			const dataAction = filteredAction.map(action => ({
				value: action?.id + "",
				label: `${action?.title}`,
			}));

			dataAction.unshift({value: null, label: ""});

			setActionOptions([...dataAction]);
		}
	};

	useEffect(() => {
		formikRef?.current?.setFieldValue(`hospitalId`, selectedHospital.Hospital.id);
	}, [selectedHospital]);

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, values, errors, touched, setFieldValue}) => (
					<div class="box-white setting-content h-100 no-border p24">
						<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
							<div class="ttl-20">Tambah Layanan Poliklinik Tindakan Baru</div>
						</div>

						<hr />
						<div class="text-bold mb-4">
							<a
								class="text-dark"
								style={{cursor: "pointer"}}
								onClick={() => history.push("/hospital/layanan-poliklinik-tindakan/list")}>
								<span class="icon-ico-back me-2" />
							</a>
							Daftar List Layanan Poliklinik Tindakan
						</div>

						<div class="row gx-2 mb-5">
							<div class="col-sm-12 col-12">
								<div class="setting-item col-sm-6 col-6">
									<label class="setting-label required">Unit</label>
									<BasicDropdown
										className={clsx(
											"form-control",
											errors?.unitId && touched?.unitId && "form-control-error",
										)}
										data={unitOptions}
										disabled={true}
										name="unitId"
										value={values?.unitId}
										onSelect={fieldValue => {
											setFieldValue("unitId", fieldValue);
										}}
									/>
									{errors.unitId && touched?.unitId && (
										<p className="form-error-item-message">{errors.unitId}</p>
									)}
								</div>
							</div>
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Poliklinik</label>
									<BasicDropdown
										className={clsx(
											"form-control",
											errors?.poliklinikId && touched?.poliklinikId && "form-control-error",
										)}
										data={poliklinikOptions}
										name="poliklinikId"
										value={values?.poliklinikId}
										onSelect={fieldValue => {
											setFieldValue("poliklinikId", fieldValue);
										}}
									/>
									{errors.poliklinikId && touched?.poliklinikId && (
										<p className="form-error-item-message">{errors.poliklinikId}</p>
									)}
								</div>
							</div>
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label">Divisi</label>
									<BasicDropdown
										className={clsx(
											"form-control",
											errors?.divisionId && touched?.divisionId && "form-control-error",
										)}
										data={divisionOptions}
										name="divisionId"
										value={values?.divisionId}
										onSelect={fieldValue => {
											setFieldValue("divisionId", fieldValue);
										}}
									/>
									{errors.divisionId && touched?.divisionId && (
										<p className="form-error-item-message">{errors.divisionId}</p>
									)}
								</div>
							</div>
						</div>
						<div class="row gx-2 mb-5">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Kategori Tindakan</label>
									<BasicDropdown
										className={clsx(
											"form-control",
											errors?.actionCategoryId &&
												touched?.actionCategoryId &&
												"form-control-error",
										)}
										data={actionCategoryOptions}
										name="actionCategoryId"
										value={values?.actionCategoryId}
										onSelect={fieldValue => {
											setFieldValue("actionCategoryId", fieldValue);
											setFieldValue("actionId", null);
											getActionOptions(fieldValue);
										}}
									/>
									{errors.actionCategoryId && touched?.actionCategoryId && (
										<p className="form-error-item-message">{errors.actionCategoryId}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">Tindakan</label>
									<BasicDropdown
										className={clsx(
											"form-control",
											errors?.actionId && touched?.actionId && "form-control-error",
										)}
										data={actionOptions}
										name="actionId"
										value={values?.actionId}
										onSelect={fieldValue => {
											setFieldValue("actionId", fieldValue);
										}}
									/>
									{errors.actionId && touched?.actionId && (
										<p className="form-error-item-message">{errors.actionId}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">Harga Beli</label>
									<input
										class={clsx(
											"form-control",
											errors?.buyPrice && touched?.buyPrice && "form-control-error",
										)}
										disabled={isLoading}
										name="buyPrice"
										placeholder=""
										type="text"
										value={values?.buyPriceStr}
										onBlur={handleBlur}
										onChange={e => {
											let value = e.target.value.replace(/\D/g, "");

											setFieldValue(`buyPrice`, value);

											value = convertToCurency(value);

											setFieldValue(`buyPriceStr`, value);
										}}
									/>
									{errors.buyPrice && touched?.buyPrice && (
										<p className="form-error-item-message">{errors.buyPrice}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">Harga Jual</label>
									<input
										class={clsx(
											"form-control",
											errors?.sellPrice && touched?.sellPrice && "form-control-error",
										)}
										disabled={isLoading}
										name="sellPrice"
										placeholder=""
										type="text"
										value={values?.sellPriceStr}
										onBlur={handleBlur}
										onChange={e => {
											let value = e.target.value.replace(/\D/g, "");

											setFieldValue(`sellPrice`, value);

											value = convertToCurency(value);

											setFieldValue(`sellPriceStr`, value);
										}}
									/>
									{errors.sellPrice && touched?.sellPrice && (
										<p className="form-error-item-message">{errors.sellPrice}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">Status Produk</label>
									<BasicDropdown
										className={clsx(
											"form-control",
											errors?.statusProduct && touched?.statusProduct && "form-control-error",
										)}
										data={statusProductOptions}
										name="statusProduct"
										value={values?.statusProduct}
										onSelect={fieldValue => {
											setFieldValue("statusProduct", fieldValue);
										}}
									/>
									{errors.statusProduct && touched?.statusProduct && (
										<p className="form-error-item-message">{errors.statusProduct}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">Stok</label>
									<input
										class={clsx(
											"form-control",
											errors?.stock && touched?.stock && "form-control-error",
										)}
										disabled={isLoading}
										name="stock"
										placeholder=""
										type="text"
										value={values?.stock}
										onBlur={handleBlur}
										onChange={e => {
											let value = e.target.value.replace(/\D/g, "");

											setFieldValue(`stock`, value);
										}}
									/>
									{errors.stock && touched?.stock && (
										<p className="form-error-item-message">{errors.stock}</p>
									)}
								</div>
							</div>
						</div>
						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!FormSchemaValidation.isValidSync(values)}
								loading={isLoading}
								text="Simpan"
								onClick={handleSubmit}
							/>
						</div>
					</div>
				)}
			</Formik>
		</DashboardLayout>
	);
}
