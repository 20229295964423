import {SubmitButton} from "./SubmitButton";
export function ButtonAddTable({onClick = () => {}, text = "Tambah"}) {
	return (
		<div class="min-w-20 btn-add-container">
			<div class="row gx-2 align-items-center justify-content-end">
				<SubmitButton text={text} onClick={onClick} />
			</div>
		</div>
	);
}
