/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-key */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {useState, useRef, useEffect, useCallback} from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import clsx from "clsx";
import {Formik, Field, FieldArray} from "formik";
import * as Yup from "yup";
import {DashboardLayout} from "components/Layout";
import {ModalContainer} from "components/Modal";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";
import {BasicDropdown} from "components/Dropdown";
import {TimePicker} from "components/TimePicker";
import {useHospitalsOptions} from "hooks/SuperAdmin/Hospital";
import {useKonsultasiOptions} from "hooks/SuperAdmin/Konsultasi";
import {useAddScheduleWork} from "hooks/Doctor/ScheduleWork";
import {convertToCurency} from "utils/converter";

const FormSchemaValidation = Yup.object().shape({
	type: Yup.string().required("Tipe konsultasi wajib di isi"),
	hospitalId: Yup.string(),
	// consultationId: Yup.string().nullable().required("Konsultasi wajib di isi"),
	startDate: Yup.string().nullable().required("Tanggal mulai wajib di isi"),
	endDate: Yup.string()
		.required("Tanggal selesai wajib di isi")
		.test("lessThanStartDate", "Tanggal selesai tidak boleh mendahului tanggal mulai", function (value) {
			const startDate = new Date(this.parent.startDate);
			const endDate = new Date(value);

			if (endDate < startDate) return false;

			return true;
		}),
	times: Yup.array().of(
		Yup.object().shape({
			startTime: Yup.string().required("Jam mulai wajib di isi"),
			endTime: Yup.string()
				.required("Jam selesai wajib di isi")
				.test("lessThanStartTime", "Jam selesai tidak boleh mendahului jam mulai", function (value) {
					const startTimeHour = parseInt(this.parent.startTime?.split(":")[0]);
					const startTimeMinute = parseInt(this.parent.startTime?.split(":")[1]);
					const endTimeHour = parseInt(value?.split(":")[0]);
					const endTimeMinute = parseInt(value?.split(":")[1]);

					if (endTimeHour < startTimeHour) return false;
					if (endTimeHour === startTimeHour && endTimeMinute < startTimeMinute) return false;

					return true;
				}),
		}),
	),
	prices: Yup.array().of(
		Yup.object().shape({
			price: Yup.string().required("Harga wajib di isi"),
		}),
	),
});
const FormInitialValues = {
	userId: "",
	type: "1",
	hospitalId: "",
	consultationId: null,
	startDate: "",
	endDate: "",
	times: [{startTime: "", endTime: ""}],
	prices: [{price: 0}],
};

export function ScheduleSpecialWorkAddPage() {
	const history = useHistory();
	const userProfile = useSelector(state => state.AuthReducer.profile);
	const {id} = userProfile;
	const [disabledHours, setDisabledHours] = useState([]);
	const [disabledHourMinutes, setDisabledHourMinutes] = useState([]);
	const [priceToEdit, setPriceToEdit] = useState("");
	const [strPriceToEdit, setStrPriceToEdit] = useState("");
	const [priceNumber, setPriceNumber] = useState("");
	const [strPriceNumber, setStrPriceNumber] = useState("");
	const {showToast} = useToast();
	const [availableHospital, setAvailableHospital] = useState([]);
	const {mutate, isLoading} = useAddScheduleWork();
	const [locationOptions, setLocationOptions] = useState([]);
	const [consultationOptions, setConsultationOptions] = useState([]);
	const formikRef = useRef();

	const {hospitalOptions: locations, isLoading: isLoadingLocationOptions} = useHospitalsOptions();
	const {data: consultations, isLoading: isLoadingConsultationOptions} = useKonsultasiOptions();
	const handleSubmit = formData => {
		if (formData.type === "1") formData.hospitalId = undefined;
		mutate(formData, {
			onSuccess: () => {
				showToast("success", "Data berhasil ditambahkan", 3000);
				history.push("/doctor/schedule/list");
			},
			onError: res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
			},
		});
	};

	useEffect(() => {
		formikRef?.current?.setFieldValue("userId", parseInt(userProfile.id) || null);
		let idHospitals = [];

		userProfile?.staff?.rsMany.forEach(item => {
			if (item.hospitalId && item.roleId == 2) idHospitals.push(item.hospitalId);
		});

		setAvailableHospital([...idHospitals]);
	}, [userProfile]);

	useEffect(() => {
		if (locations.length) {
			const hospital = locations[0].value;
			const dataLocations = locations.filter(location => availableHospital.includes(parseInt(location.value)));

			setLocationOptions([...dataLocations]);
			formikRef?.current.setFieldValue("hospitalId", hospital);
		}
	}, [locations, availableHospital]);

	useEffect(() => {
		if (consultations?.success) {
			const dataConsultationOptions = consultations?.data?.rows?.map(consultation => ({
				value: consultation?.id + "",
				label: `${consultation?.name}`,
			}));

			setConsultationOptions([...dataConsultationOptions]);
			// formikRef?.current.setFieldValue("consultationId", dataConsultationOptions[0].value);
		}
	}, [consultations]);

	const savePrice = () => {
		formikRef?.current.setFieldValue(priceToEdit, priceNumber);
		formikRef?.current.setFieldValue(strPriceToEdit, strPriceNumber);

		setPriceNumber("");
		setPriceToEdit("");
		setStrPriceNumber("");
		setStrPriceToEdit("");
	};

	const closeModal = () => {
		setPriceNumber("");
		setPriceToEdit("");
		setStrPriceNumber("");
		setStrPriceToEdit("");
	};

	return (
		<>
			<ModalContainer visible={priceToEdit}>
				<div className="box-popup w430" id="change-price">
					<div className="d-flex justify-content-between align-items-center mb-4">
						<div className="text-bold text-dark">Ubah Harga</div>
						<div className="for-close">
							<button className="btn btn-close" type="button" onClick={closeModal} />
						</div>
					</div>

					<div className="popup-content">
						<div className="ft-12 mb-2 text-light-grey text-bold">Masukkan harga</div>
						<div className="position-relative mb-3">
							<input
								className="form-control ns"
								placeholder="Rp"
								type="text"
								value={strPriceNumber}
								onChange={e => {
									let value = e.target.value.replace(/\D/g, "");

									setPriceNumber(value);

									value = convertToCurency(value);

									setStrPriceNumber(value);
								}}
							/>
						</div>
						<button className="btn btn-orange r8 w-100" type="button" onClick={savePrice}>
							Simpan
						</button>
					</div>
				</div>
			</ModalContainer>
			<DashboardLayout backTo={"/doctor/schedule/list"} title="Buat Jadwal Spesial Masuk">
				<Formik
					initialValues={FormInitialValues}
					innerRef={formikRef}
					validationSchema={FormSchemaValidation}
					onSubmit={handleSubmit}>
					{({
						handleSubmit,
						handleBlur,
						handleChange,
						values,
						errors,
						touched,
						setFieldValue,
						setFieldTouched,
					}) => (
						<div className="ws p15 mb-3">
							<div className="row">
								<div className="col-xl-8">
									<div className="box-white ws p24 mb-3">
										<div className="row">
											<div className="col-sm-6 mb-3">
												<div className="text-dark mb-3">Tipe Konsultasi</div>
												<div className="row">
													<div className="col-6">
														<div className="form-check">
															<label>
																<Field
																	className="me-2 form-check-input"
																	name="type"
																	type="radio"
																	value="1"
																	onChange={() => {
																		setFieldValue("type", "1");
																		setFieldTouched("type", true);
																		setFieldValue("hospitalId", null);
																	}}
																/>
																Online
															</label>
														</div>
													</div>
													<div className="col-6">
														<div className="form-check">
															<label>
																<Field
																	className="me-2 form-check-input"
																	name="type"
																	type="radio"
																	value="2"
																	onChange={() => {
																		setFieldValue("type", "2");
																		setFieldTouched("type", true);
																		setFieldValue(
																			"hospitalId",
																			locationOptions?.[0]?.value,
																		);
																	}}
																/>
																Offline
															</label>
														</div>
													</div>
												</div>
											</div>
											<div className="col-6 mb-3">
												<div className={values.type === "1" ? "d-none" : ""}>
													<div className="ft-12 text-light-grey mb-2">Lokasi Praktik</div>
													<div className="form-filter position-relative">
														<BasicDropdown
															className={clsx(
																"form-control",
																errors?.hospitalId && "form-control-error",
															)}
															data={locationOptions}
															disabled={values.type === "1"}
															name={`hospitalId`}
															value={values?.hospitalId}
															onSelect={fieldValue => {
																setFieldValue(
																	`hospitalId`,
																	parseInt(fieldValue) || null,
																);
															}}
														/>

														<div className="icon-right">
															<span className="icon-ico-chev-down text-orange" />
														</div>
													</div>
													{errors?.hospitalId && touched?.hospitalId && (
														<p className="form-error-item-message">{errors?.hospitalId}</p>
													)}
												</div>
											</div>
											<div className="col-sm-6 mb-3 d-none">
												<div className="ft-12 text-light-grey mb-2">Pilih Konsultasi</div>
												<div className="form-filter position-relative">
													<BasicDropdown
														className={clsx(
															"form-control",
															errors?.consultationId && "form-control-error",
														)}
														data={consultationOptions}
														name={`consultationId`}
														value={values?.consultationId}
														onSelect={fieldValue => {
															setFieldValue(
																`consultationId`,
																parseInt(fieldValue) || null,
															);
														}}
													/>

													<div className="icon-right">
														<span className="icon-ico-chev-down text-orange" />
													</div>
												</div>
												{errors?.consultationId && touched?.consultationId && (
													<p className="form-error-item-message">{errors?.consultationId}</p>
												)}
											</div>
										</div>

										<hr />
										<div className="row">
											<div className="col-sm-6 mb-3">
												<div className="ft-12 text-light-grey mb-2">Tanggal Mulai</div>
												<div className="form-filter position-relative">
													<input
														className={clsx(
															"form-control",
															errors?.startDate &&
																touched?.startDate &&
																"form-control-error",
														)}
														name="startDate"
														placeholder=""
														type="date"
														value={values?.startDate}
														onBlur={handleBlur}
														onChange={handleChange}
													/>
													{errors.startDate && touched?.startDate && (
														<p className="form-error-item-message">{errors.startDate}</p>
													)}
												</div>
											</div>
											<div className="col-sm-6 mb-3">
												<div className="ft-12 text-light-grey mb-2">Tanggal Selesai</div>
												<div className="form-filter position-relative">
													<input
														className={clsx(
															"form-control",
															errors?.startDate &&
																touched?.endDate &&
																"form-control-error",
														)}
														min={values?.startDate}
														name="endDate"
														placeholder=""
														type="date"
														value={values?.endDate}
														onBlur={handleBlur}
														onChange={handleChange}
													/>
													{errors.endDate && touched?.endDate && (
														<p className="form-error-item-message">{errors.endDate}</p>
													)}
												</div>
											</div>
										</div>

										<div className="collapse show" id="col-2">
											<div className="inner-jadwal">
												<p className="text-dark mb-2">Harga</p>
												<FieldArray name="prices">
													{({insert, remove, push}) => (
														<>
															{values?.prices.length > 0 &&
																values?.prices.map((price, priceIndex) => (
																	<div className="box-harga">
																		<div className="price-value text-dark">
																			Rp.
																			{convertToCurency(`${price.price}`)}
																		</div>
																		<div className="change-price">
																			<button
																				className="btn ubah-price text-orange"
																				onClick={() =>
																					setPriceToEdit(
																						`prices.${priceIndex}.price`,
																					)
																				}>
																				Ubah
																			</button>
																		</div>
																		<div className="d-flex align-items-center">
																			<button
																				className={`btn ft-18 p-1 text-grey ${
																					priceIndex < 1 ? "hidden" : ""
																				}`}
																				onClick={() => {
																					if (priceIndex) remove(priceIndex);
																				}}>
																				<span className="icon-ico-delete" />
																			</button>
																			<button
																				className="btn ft-18 p-1 text-orange"
																				onClick={() => push({price: "0"})}>
																				<span className="icon-ico-plus" />
																			</button>
																		</div>
																	</div>
																))}
														</>
													)}
												</FieldArray>
												<div className="m-4" />
												<FieldArray name="times">
													{({insert, remove, push}) => (
														<>
															{values?.times.length > 0 &&
																values?.times.map((time, timeIndex) => (
																	<div className="row gx-2">
																		<div className="col-sm-5 mb-2">
																			<div className="ft-12 text-light-grey mb-2">
																				Jam Mulai
																			</div>
																			<div className="position-relative">
																				<TimePicker
																					disabled={isLoading}
																					disabledHourMunites={
																						disabledHourMinutes
																					}
																					disabledHours={disabledHours}
																					styleTopIcon={25}
																					value={
																						values?.times?.[timeIndex]
																							.startTime
																					}
																					onChange={fieldValue => {
																						setFieldTouched(
																							`times.${timeIndex}.startTime`,
																							true,
																						);
																						setFieldValue(
																							`times.${timeIndex}.startTime`,
																							fieldValue?.format("HH:mm"),
																						);
																					}}
																				/>
																				{errors?.times?.[timeIndex]
																					?.startTime &&
																					touched?.times?.[timeIndex]
																						?.startTime && (
																						<p className="form-error-item-message">
																							{
																								errors?.times?.[
																									timeIndex
																								]?.startTime
																							}
																						</p>
																					)}
																			</div>
																		</div>
																		<div className="col-sm-5 mb-2">
																			<div className="ft-12 text-light-grey mb-2">
																				Jam Selesai
																			</div>
																			<div className="position-relative">
																				<TimePicker
																					disabled={isLoading}
																					disabledHourMunites={
																						disabledHourMinutes
																					}
																					disabledHours={disabledHours}
																					styleTopIcon={25}
																					value={
																						values?.times?.[timeIndex]
																							.endTime
																					}
																					onChange={fieldValue => {
																						setFieldTouched(
																							`times.${timeIndex}.endTime`,
																							true,
																						);
																						setFieldValue(
																							`times.${timeIndex}.endTime`,
																							fieldValue?.format("HH:mm"),
																						);
																					}}
																				/>
																				{errors?.times?.[timeIndex]?.endTime &&
																					touched?.times?.[timeIndex]
																						?.endTime && (
																						<p className="form-error-item-message">
																							{
																								errors?.times?.[
																									timeIndex
																								]?.endTime
																							}
																						</p>
																					)}
																			</div>
																		</div>
																		<div className="col-sm-2 mb-2 d-flex align-items-center">
																			<div className="d-flex">
																				<button
																					className={`btn ft-18 p-1 text-grey ${
																						timeIndex < 1 ? "hidden" : ""
																					}`}
																					onClick={() => {
																						if (timeIndex)
																							remove(timeIndex);
																					}}>
																					<span className="icon-ico-delete" />
																				</button>
																				<button
																					className="btn ft-18 p-1 text-orange"
																					onClick={() =>
																						push({
																							startTime: "",
																							endTime: "",
																						})
																					}>
																					<span className="icon-ico-plus" />
																				</button>
																			</div>
																		</div>
																	</div>
																))}
														</>
													)}
												</FieldArray>
											</div>
										</div>
									</div>
								</div>

								<div className="col-xl-4">
									<div className="box-white ws p24 mb-3">
										<div className="mb-3">
											<div className="ft-12 text-light-grey mb-2">Tipe Konsultasi</div>
											{(formikRef.current?.values?.type === "1" ||
												!formikRef.current?.values?.type) && (
												<div className="box-consult online">
													<span className="icon-ico-zoom me-1" /> Online
												</div>
											)}
											{formikRef.current?.values?.type === "2" && (
												<div className="box-consult offline">
													<span className="icon-ico-hospital me-1" /> Offline
												</div>
											)}
										</div>
										<div className="mb-3">
											<div className="ft-12 text-light-grey mb-2">Lokasi Praktik</div>
											<div className="text-dark">
												{values.type === "offline"
													? locationOptions.filter(
															location => location.value == values?.hospitalId,
													  )?.[0]?.label
													: "-"}
											</div>
										</div>
										<div className="mb-3">
											<div className="ft-12 text-light-grey mb-2">Konsultasi</div>
											<div className="text-dark">
												{
													consultationOptions.filter(
														consultation => consultation.value == values?.consultationId,
													)?.[0]?.label
												}
											</div>
										</div>
									</div>

									<button
										className="btn btn-orange w-100 r8 mb-3"
										disabled={!FormSchemaValidation.isValidSync(values)}
										loading={isLoading}
										text="Save"
										onClick={handleSubmit}>
										Simpan
									</button>
								</div>
							</div>
						</div>
					)}
				</Formik>
			</DashboardLayout>
		</>
	);
}
