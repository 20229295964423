import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

export const QUERY = "appoitments";

export function useListAppoitments({
	page = 1,
	search = "",
	limit = 10,
	idHospital = null,
	idConsutation = null,
	date = null,
	...otherFilters
}) {
	const result = useQuery([QUERY, {page, search, limit, idHospital, idConsutation, date}], () =>
		axios
			.get(
				`/booking?sortBy=createdAt.DESC&${qs.stringify({
					page,
					search,
					limit,
					idHospital,
					idConsutation,
					date,
					...otherFilters,
				})}`,
			)
			.then(res => res.data),
	);

	return result;
}
