import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {Button} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";

const ReportCommentDetailPage = () => {
	const history = useHistory();
	const {id} = useParams();

	console.log(id);

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar List Report"
				title={`Detail Data Report Comment`}
				onBackButtonClick={() => history.push("/report")}>
				<div class="row gx-2">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Author Post</label>
							<p className="text-bold text-grey">Dr Dio</p>
						</div>
					</div>
				</div>
				<div class="row gx-2">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Title Post</label>
							<p className="text-bold text-grey d-flex align-items-center justify-content-between">
								Manfaat Apel bagi kesehatan tubuh
								<a class="text-orange wait-pay d-flex" style={{cursor: "pointer"}}>
									<span class="icon-ico-eye-open ft-18" />
								</a>
							</p>
						</div>
					</div>
				</div>

				<hr />

				<div class="row gx-2">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Comment By</label>
							<p className="text-bold text-grey d-flex align-items-center justify-content-between">
								Disca - 27 Juli 2023
								<a class="text-orange wait-pay d-flex" style={{cursor: "pointer"}}>
									<span class="icon-ico-eye-open ft-18" />
								</a>
							</p>
						</div>
					</div>
				</div>
				<div class="row gx-2">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Comment</label>
							<p className="text-bold text-grey d-flex align-items-center justify-content-between">
								Artikelnya Jelek
								<Button variant="outline-danger">Suspend Comment</Button>
							</p>
						</div>
					</div>
				</div>

				<hr />

				<div class="row gx-2">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Jumlah Report</label>
							<p className="text-bold text-grey">2</p>
						</div>
					</div>
				</div>
				<div class="row gx-2">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Report By & Report Date</label>
							<p className="text-bold text-grey mb-0">Disca - 27 Juli 2023</p>
							<p className="text-bold text-grey mb-0">Doni - 27 Juli 2023</p>
						</div>
					</div>
				</div>
				<div class="row gx-2">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Reason Report</label>
							<p className="text-bold text-grey mb-0">Harassment, Pornografi</p>
						</div>
					</div>
				</div>
			</FormCard>
		</DashboardLayout>
	);
};

export {ReportCommentDetailPage};
