import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

export const QUERY = "rs_unit";

export function useListAvailableUnit() {
	const result = useQuery([QUERY], () => axios.get(`unit/list`).then(res => res.data));

	return result;
}

export const fetchUnit = filter => {
	const query = qs.stringify(filter);

	return axios.get(`unit/?sortBy=id.desc&${query}`).then(res => res.data);
};

export function useListUnitById(filter) {
	return useQuery({
		queryKey: [QUERY, filter],
		queryFn: key => fetchUnit(filter),
		refetchOnMount: false,
		enabled: !!Object.keys(filter).length,
	});
}
