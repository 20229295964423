import clsx from "clsx";
import {SubmitButton} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {useFormik} from "formik";
import {
	useDetailLayananPemeriksaanPenunjang,
	useUpdateLayananPemeriksaanPenunjang,
} from "hooks/AdminHospital/LayananPemeriksaanPenunjang";
import {useListDivisi} from "hooks/SuperAdmin/Division";
import {useListKategoriPemeriksaan} from "hooks/SuperAdmin/KategoriPemeriksaan";
import {useListPemeriksaanPenunjang} from "hooks/SuperAdmin/PemeriksaanPenunjang";
import {useHospitalUnits} from "hooks/SuperAdmin/Unit";
import {useToast} from "hooks/useToast";
import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory, useRouteMatch} from "react-router-dom";
import {convertErrorMessageFormat, convertToCurency} from "utils/converter";
import {REGEX_NUMBER} from "utils/regex";
import * as Yup from "yup";

const FormSchemaValidation = Yup.object().shape({
	unitId: Yup.string().required("Unit wajib diisi"),
	divisionId: Yup.string().optional(),
	checkupCategoryId: Yup.string().required("Kategori pemeriksaan wajib diisi"),
	checkupSupportId: Yup.string().required("Pemeriksaan penunjang wajib diisi"),
	buyPrice: Yup.number()
		.required("Harga beli wajib diisi")
		.min(1, "Harga beli wajib diisi")
		.transform(v => (Number.isNaN(v) ? 0 : v)),
	sellPrice: Yup.number()
		.required("Harga jual wajib diisi")
		.min(1, "Harga jual wajib diisi")
		.transform(v => (Number.isNaN(v) ? 0 : v)),
	statusProduct: Yup.string().required("Status produk wajib diisi"),
	stock: Yup.number().required("Stok wajib diisi").min(1, "Stok wajib diisi"),
});
const FormInitialValues = {
	unitId: "",
	divisionId: "",
	checkupCategoryId: "",
	checkupSupportId: "",
	buyPrice: "",
	sellPrice: "",
	statusProduct: "",
	stock: "",
};

export function LayananPemeriksaanPenunjangEditPage() {
	const {permission: userPermission} = useSelector(state => state.AuthReducer);
	const {showToast} = useToast();
	const history = useHistory();
	const matchParams = useRouteMatch();
	const {params} = matchParams;
	const {id} = params;

	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);

	useEffect(
		() => {
			if (!["CRUD", "CRU"].includes(userPermission)) {
				history.push("/");
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[userPermission],
	);

	const {values, errors, handleBlur, handleChange, setFieldValue, setFieldTouched, handleSubmit} = useFormik({
		initialValues: FormInitialValues,
		validationSchema: FormSchemaValidation,
		onSubmit: values => {
			const payload = {
				id: parseInt(id),
				hospitalId: selectedHospital?.Hospital?.id,
				unitId: parseInt(values.unitId),
				divisionId: values.divisionId === "none" ? null : parseInt(values.divisionId),
				checkupCategoryId: parseInt(values.checkupCategoryId),
				checkupSupportId: parseInt(values.checkupSupportId),
				buyPrice,
				sellPrice,
				statusProduct: parseInt(values.statusProduct),
				stock: parseInt(values.stock),
			};

			mutate(payload, {
				onSuccess: () => {
					showToast("success", "Data berhasil diperbarui", 3000);
					history.push("/hospital/service-checkup-support/list");
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			});
		},
	});

	const {data: checkupSupportRes} = useDetailLayananPemeriksaanPenunjang(id);
	const checkupSupport = checkupSupportRes?.data;

	const hospitalUnitsQuery = useHospitalUnits({
		page: 1,
		limit: null,
		isActive: true,
		sortBy: "id.desc",
		hospitalId: selectedHospital?.Hospital?.id,
	});
	const hospitalUnitOptions = useMemo(() => {
		const arr = hospitalUnitsQuery.data?.data?.rows || [];
		// eslint-disable-next-line padding-line-between-statements
		return arr.map(e => ({label: e.name, value: e.id}));
	}, [hospitalUnitsQuery.data]);

	const divisiQuery = useListDivisi({
		page: 1,
		limit: null,
		sortBy: "id.desc",
		hospitalId: selectedHospital?.Hospital?.id,
		isActive: true,
	});
	const divisiOptions = useMemo(() => {
		const arr = divisiQuery.data?.data?.rows || [];
		// eslint-disable-next-line padding-line-between-statements
		return arr.map(e => ({label: e.name, value: e.id}));
	}, [divisiQuery.data]);

	const kategoriPemeriksaanQuery = useListKategoriPemeriksaan({
		page: 1,
		limit: 100,
		isActive: true,
		sortBy: "id.desc",
		hospitalId: selectedHospital?.Hospital?.id,
	});
	const kategoriPemeriksaanOptions = useMemo(() => {
		const arr = kategoriPemeriksaanQuery.data?.data?.rows || [];
		// eslint-disable-next-line padding-line-between-statements
		return arr.map(e => ({label: e.name, value: e.id}));
	}, [kategoriPemeriksaanQuery.data]);

	const pemeriksaanPenunjangQuery = useListPemeriksaanPenunjang(
		{
			page: 1,
			limit: null,
			isActive: true,
			sortBy: "id.desc",
			hospitalId: selectedHospital?.Hospital?.id,
			checkupCategoryId: parseInt(values.checkupCategoryId),
		},
		{
			enabled: !!values.checkupCategoryId,
		},
	);
	const pemeriksaanPenunjangOptions = useMemo(() => {
		const arr = pemeriksaanPenunjangQuery.data?.data?.rows || [];
		// eslint-disable-next-line padding-line-between-statements
		return arr.map(e => ({label: e.title, value: e.id}));
	}, [pemeriksaanPenunjangQuery.data]);

	// const {checkupSupportId} = values;

	// useEffect(
	// 	() => {
	// 		if (
	// 			pemeriksaanPenunjangOptions.length &&
	// 			pemeriksaanPenunjangOptions.some(option => (option.value === parseInt(checkupSupportId)) === false)
	// 		) {
	// 			setFieldValue("checkupSupportId", "");
	// 		}
	// 	},
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// 	[pemeriksaanPenunjangOptions, checkupSupportId],
	// );

	const [buyPrice, setBuyPrice] = useState(0);
	const [buyPriceString, setBuyPriceString] = useState("");

	const [sellPrice, setSellPrice] = useState(0);
	const [sellPriceString, setSellPriceString] = useState("");

	const {mutate, isLoading} = useUpdateLayananPemeriksaanPenunjang();

	useEffect(
		() => {
			const populateFormData = () => {
				if (!checkupSupport) return;

				const {
					unitId,
					divisionId,
					checkupCategoryId,
					checkupSupportId,
					statusProduct,
					buyPrice,
					sellPrice,
					stock,
				} = checkupSupport;

				setFieldValue("unitId", unitId);
				setFieldValue("divisionId", divisionId || "none");
				setFieldValue("checkupCategoryId", checkupCategoryId);
				setFieldValue("checkupSupportId", checkupSupportId);
				setFieldValue("statusProduct", statusProduct);

				setBuyPriceString(convertToCurency(buyPrice + ""));
				setBuyPrice(buyPrice);

				setSellPriceString(convertToCurency(sellPrice + ""));
				setSellPrice(sellPrice);

				setFieldValue("stock", stock);
			};

			populateFormData();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[checkupSupport],
	);

	useEffect(
		() => {
			setFieldValue("buyPrice", buyPrice);
			setTimeout(() => {
				setFieldTouched("buyPrice", true);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[buyPrice],
	);

	useEffect(
		() => {
			setFieldValue("sellPrice", sellPrice);
			setTimeout(() => {
				setFieldTouched("buyPrice", true);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[sellPrice],
	);

	useEffect(
		() => {
			setFieldValue("sellPrice", sellPrice);
			setTimeout(() => {
				setFieldTouched("buyPrice", true);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[sellPrice],
	);

	return (
		<DashboardLayout>
			<form onSubmit={handleSubmit}>
				<div class="box-white setting-content h-100 no-border p24">
					<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
						<div class="ttl-20">Edit Layanan Pemeriksaan Penunjang</div>
					</div>

					<hr />

					<div class="text-bold mb-4">
						<a
							class="text-dark"
							style={{cursor: "pointer"}}
							onClick={() => history.push("/hospital/service-checkup-support/list")}>
							<span class="icon-ico-back me-2" />
						</a>
						Daftar List Layanan Pemeriksaan Penunjang
					</div>

					<div class="row gx-2 mb-5">
						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Unit</label>
								<select
									className={clsx("form-control", errors?.unitId && "form-control-error")}
									disabled={isLoading}
									name="unitId"
									value={values?.unitId}
									onBlur={handleBlur}
									onChange={handleChange}>
									<option value="">Pilih Unit</option>
									{hospitalUnitOptions.map(option => (
										<option value={option.value}>{option.label}</option>
									))}
								</select>
								{errors.unitId && <p className="form-error-item-message">{errors.unitId}</p>}
							</div>
						</div>

						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Divisi</label>
								<select
									className={clsx("form-control", errors?.divisionId && "form-control-error")}
									disabled={isLoading}
									name="divisionId"
									value={values?.divisionId}
									onBlur={handleBlur}
									onChange={handleChange}>
									<option value="">Pilih Divisi</option>
									{divisiOptions.map(option => (
										<option value={option.value}>{option.label}</option>
									))}
									<option value={"none"}>None</option>
								</select>
								{errors.divisionId && <p className="form-error-item-message">{errors.divisionId}</p>}
							</div>
						</div>

						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Kategori Pemeriksaan</label>
								<select
									className={clsx("form-control", errors?.checkupCategoryId && "form-control-error")}
									disabled={isLoading}
									name="checkupCategoryId"
									value={values?.checkupCategoryId}
									onBlur={handleBlur}
									onChange={e => {
										setFieldValue("checkupCategoryId", e.target.value);
										setFieldValue("checkupSupportId", "");
									}}>
									<option value="">Pilih Kategori Pemeriksaan</option>
									{kategoriPemeriksaanOptions.map(option => (
										<option value={option.value}>{option.label}</option>
									))}
								</select>
								{errors.checkupCategoryId && (
									<p className="form-error-item-message">{errors.checkupCategoryId}</p>
								)}
							</div>
						</div>

						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Nama Pemeriksaan</label>
								<select
									className={clsx("form-control", errors?.checkupSupportId && "form-control-error")}
									disabled={isLoading || !values.checkupCategoryId}
									name="checkupSupportId"
									value={values?.checkupSupportId}
									onBlur={handleBlur}
									onChange={handleChange}>
									<option value="">Pilih Pemeriksaan</option>
									{pemeriksaanPenunjangOptions.map(option => (
										<option value={option.value}>{option.label}</option>
									))}
								</select>
								{errors.checkupSupportId && (
									<p className="form-error-item-message">{errors.checkupSupportId}</p>
								)}
							</div>
						</div>

						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Status Produk</label>
								<select
									className={clsx("form-control", errors?.statusProduct && "form-control-error")}
									disabled={isLoading}
									name="statusProduct"
									value={values?.statusProduct}
									onBlur={handleBlur}
									onChange={handleChange}>
									<option value="">Pilih Status Produk</option>
									<option value="0">Habis Pakai</option>
									<option value="1">Tidak Habis Pakai</option>
								</select>
								{errors.statusProduct && (
									<p className="form-error-item-message">{errors.statusProduct}</p>
								)}
							</div>
						</div>

						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Harga Beli</label>
								<input
									className={clsx("form-control", errors?.buyPrice && "form-control-error")}
									disabled={isLoading}
									placeholder="Rp"
									type="text"
									value={buyPriceString}
									onChange={e => {
										let value = e.target.value.replace(/\D/g, "");

										setBuyPrice(parseInt(value));

										value = convertToCurency(value);

										setBuyPriceString(value);
									}}
								/>
								{errors.buyPrice && <p className="form-error-item-message">{errors.buyPrice}</p>}
							</div>
						</div>

						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Harga Jual</label>
								<input
									className={clsx("form-control", errors?.sellPrice && "form-control-error")}
									disabled={isLoading}
									placeholder="Rp"
									type="text"
									value={sellPriceString}
									onChange={e => {
										let value = e.target.value.replace(/\D/g, "");

										setSellPrice(parseInt(value));

										value = convertToCurency(value);

										setSellPriceString(value);
									}}
								/>
								{errors.sellPrice && <p className="form-error-item-message">{errors.sellPrice}</p>}
							</div>
						</div>

						<div className="col-sm-12 col-lg-6  col-xs-12">
							<div className="setting-item">
								<label className="setting-label required">Stok</label>
								<input
									className={clsx("form-control", errors?.stock && "form-control-error")}
									disabled={isLoading}
									name="stock"
									placeholder="Contoh: 50"
									type="text"
									value={values?.stock}
									onBlur={() => {
										handleBlur();
									}}
									onChange={event => {
										const value = event.target.value;

										if (REGEX_NUMBER.test(value) || value === "") {
											setFieldValue("stock", value);
										}
									}}
								/>
								{errors.stock && <p className="form-error-item-message">{errors.stock}</p>}
							</div>
						</div>
					</div>

					<div class="text-center my-3">
						<SubmitButton
							className="btn btn-orange d-inline-block mw-250"
							disabled={!FormSchemaValidation.isValidSync(values)}
							loading={isLoading}
							text="Simpan"
						/>
					</div>
				</div>
			</form>
		</DashboardLayout>
	);
}
