export const getSubSupervisiAdminMenu = ({roleId, unitId, divisionId}) => {
	const adminLaboratoriumMenu =
		unitId === 1
			? [
					{
						id: "admin-management-file-lab",
						title: "Management File Lab",
						icon: "icon-ico-dashboard",
						path: "/file-labs",
						submenu: [],
					},
			  ]
			: [];

	const divisionMenu = divisionId
		? [
				{
					id: "admin-hospital-service-checkup-support-list",
					title: "Layanan Pemeriksaan Penunjang",
					icon: "icon-ico-dashboard",
					path: "/hospital/service-checkup-support/list",
					submenu: [],
				},
				{
					id: "admin-management-product",
					title: "Management Product",
					icon: "icon-ico-dashboard",
					path: "",
					submenu: [
						{
							id: "/sub-admin-divisi/medicine/list",
							title: "Obat",
							icon: "icon-ico-dashboard",
							path: "/sub-admin-divisi/medicine/list",
							submenu: [],
						},
						{
							id: "/sub-admin-divisi/layanan-poliklinik-tindakan/list",
							title: "Tindakan",
							icon: "icon-ico-dashboard",
							path: "/sub-admin-divisi/layanan-poliklinik-tindakan/list",
							submenu: [],
						},
					],
				},
		  ]
		: [];

	return {
		top: [
			{
				id: "admin-dashboard",
				title: "Dashboard",
				icon: "icon-ico-dashboard",
				path: "/",
				submenu: [],
			},
			...adminLaboratoriumMenu,
			...divisionMenu,
		],
		bottom: [],
	};
};
