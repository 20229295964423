import axios from "services/axios";
import {useMutation} from "react-query";

export function useForgotPassword(options = {}) {
	return useMutation(values => axios.post("/auth/forgot_password_by_email_patient", values).then(res => res.data));
}

export function useForgotPasswordByOtp(options = {}) {
	return useMutation(values => axios.post("/auth/forgot_password_by_otp", values).then(res => res.data));
}

export function useForgotPasswordByWhatsapp(options = {}) {
	return useMutation(values => axios.post("/auth/forgot_password_by_whatsapp", values).then(res => res.data));
}
