import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

export function useAddMedicineCategory(options = {}) {
	return useMutation(values => axios.post("/medicine/category/create", values).then(res => res.data));
}
export function useEditMedicineCategory(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/medicine/category/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("medicineCategory");
			queryClient.removeQueries("medicineCategory");
		},
	});
}

export function useDeleteMedicineCategory(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/medicine/category/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("medicineCategory");
			queryClient.removeQueries("medicineCategory");
		},
	});
}
