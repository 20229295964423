import {useHistory, useParams} from "react-router-dom";
import {DashboardLayout} from "components/Layout";
import {SubmitButton} from "components/Button";
import {CircleLoader} from "components/Loader";
import {FormCard} from "components/Form";
import {useDetailMedicineCategory} from "hooks/SuperAdmin/MedicineCategory";

export function MedicineCategoryDetailPage() {
	const history = useHistory();
	const {id} = useParams();
	const {data: detailMedicineCategory, isLoading: loadingFetcing} = useDetailMedicineCategory(id);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar list kategori obat" title="Detail Kategori Obat">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar List Kategori Obat"
				title={`Detail Data Kategori Obat ${detailMedicineCategory?.data?.name}`}
				onBackButtonClick={() => history.push("/kategori-obat")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Kategori Obat</label>
							<p className="text-bold text-grey">{detailMedicineCategory?.data?.name}</p>
						</div>
					</div>
				</div>

				<div class="text-center my-3">
					<SubmitButton
						className="btn btn-orange d-inline-block mw-250"
						text="Ubah Data Ini"
						onClick={() => history.push(`/kategori-obat/edit/${detailMedicineCategory?.data?.id}`)}
					/>
				</div>
			</FormCard>
		</DashboardLayout>
	);
}
