/* eslint-disable no-unused-vars */
import {useMemo, useState, useEffect} from "react";
import dayjs from "dayjs";
import axios from "axios";
import {isMobile} from "react-device-detect";
import {useHistory} from "react-router-dom";
import {BasicTable, SearchBox} from "components/Table";
import {useListFileLabByPatientId} from "hooks/AdminLaboratorium/FileLab";
import {ModalViewPDF} from "components/Modal/ModalViewPdf";
import {SubmitButton} from "components/Button";
import {DatePicker} from "components/DatePicker";

function ButtonAddNewFile({onClick}) {
	return (
		<div>
			<div class="row gx-2 align-items-center justify-content-end">
				<SubmitButton text="Upload File" onClick={onClick} />
			</div>
		</div>
	);
}

const visibilityEnum = {
	hidden: "Staff Only",
	visible: "Live",
};

export function PendaftaranPasienFileListPage({idPatient, idUser}) {
	const [filter, setFilter] = useState({
		page: 1,
		search: null,
		limit: 10,
		idPatient: null,
		startDate: null,
		endDate: null,
	});
	const {data, isLoading} = useListFileLabByPatientId(idPatient, filter);
	const [dataLimit, setDataLimit] = useState(10);
	const [searchKeyword, setSearchKeyword] = useState("");
	const [previewDocument, setPreviewDocument] = useState({
		show: false,
		fileSrc: null,
		title: undefined,
	});

	useEffect(() => {
		setFilter({
			...filter,
			idPatient,
		});
	}, [idPatient]);

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Nama File Lab",
				accessor: "name",
			},
			{
				Header: "Modify",
				accessor: "date",
				Cell: ({row}) => <>{dayjs(row?.values?.date).format("DD-MM-YYYY")}</>,
			},
			{
				Header: "Visibility",
				accessor: "visibility",
				Cell: ({row}) => <>{visibilityEnum[row?.values?.visibility]}</>,
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<>
							<a
								class="text-orange wait-pay mr5"
								data-placement="top"
								data-toggle="tooltip"
								style={{cursor: "pointer"}}
								title="View File"
								onClick={async () => {
									const res = await axios({
										method: "GET",
										url: row.original.file,
										responseType: "blob",
									});
									const blobUrl = URL.createObjectURL(res.data);

									setPreviewDocument({
										show: true,
										title: row.original.name,
										fileSrc: isMobile ? row.original.file : blobUrl,
									});
								}}>
								<span class="icon-ico-eye-open ft-18" />
							</a>
							<a
								class="text-orange wait-pay"
								data-placement="top"
								data-toggle="tooltip"
								rel="noreferrer"
								style={{cursor: "pointer"}}
								title="Donwload file"
								onClick={async () => {
									const res = await axios({
										method: "GET",
										url: row.original.file,
										responseType: "blob",
									});
									const blobUrl = URL.createObjectURL(res.data);
									const url = blobUrl;
									const a = document.createElement("a");

									a.href = url;
									a.download = row.original.name;
									document.body.appendChild(a);
									a.click();
									document.body.removeChild(a);
								}}>
								<span class="icon-ico-pdf ft-18" />
							</a>
						</>
					);
				},
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
			withPatientStatus: true,
		})) || [];

	return (
		<>
			<div class="text-bold text-dark mb-4">Data File Lab Pasien</div>
			<SearchBox
				rightElement={
					<div className="row position-relative search-hospital-container align-items-center justify-content-end">
						<div className="col-lg-6 col-sm-12">
							<ButtonAddNewFile onClick={() => history.push(`/file-labs/tambah/${idUser}`)} />
						</div>
						<div className="row mt-3 form-filter position-relative">
							<div class="col-lg-6 col-sm-12">
								<div className="form-filter position-relative">
									<DatePicker
										singleDatePicker
										onChange={selectedDate => {
											setFilter({
												...filter,
												startDate: dayjs(selectedDate, "MM/DD/YYYY").format("YYYY-MM-DD"),
											});
										}}>
										<input
											className="form-control"
											placeholder="Pilih tanggal awal"
											type="text"
											value={
												(filter.startDate &&
													dayjs(filter.startDate, "YYYY-MM-DD").format("DD MMMM YYYY")) ||
												""
											}
										/>
									</DatePicker>
									<div className="icon-right">
										<span className="icon-ico-date text-orange" />
									</div>
								</div>
							</div>
							<div class="col-lg-6 col-sm-12">
								<div className="form-filter position-relative">
									<DatePicker
										singleDatePicker
										onChange={selectedDate => {
											setFilter({
												...filter,
												endDate: dayjs(selectedDate, "MM/DD/YYYY").format("YYYY-MM-DD"),
											});
										}}>
										<input
											className="form-control"
											placeholder="Pilih tanggal akhir"
											type="text"
											value={
												(filter.endDate &&
													dayjs(filter.endDate, "YYYY-MM-DD").format("DD MMMM YYYY")) ||
												""
											}
										/>
									</DatePicker>
									<div className="icon-right">
										<span className="icon-ico-date text-orange" />
									</div>
								</div>
							</div>
						</div>
					</div>
				}
				onSearch={keyword => {
					setSearchKeyword(keyword);
					setFilter({...filter, page: 1, limit: dataLimit, search: keyword});
				}}
			/>
			<BasicTable
				columns={columns}
				currentPage={data?.data?.currentPage}
				data={dataWithIDSerial}
				limit={dataLimit}
				loading={isLoading}
				striped={true}
				totalItems={data?.data?.totalItems}
				totalPage={data?.data?.totalPages}
				onLimitChange={limitValue => {
					setDataLimit(limitValue);
					setFilter({...filter, page: 1, limit: limitValue, search: searchKeyword});
				}}
				onPageChange={selectedPage =>
					setFilter({...filter, page: selectedPage, limit: dataLimit, search: searchKeyword})
				}
			/>
			<ModalViewPDF
				fileSrc={previewDocument.fileSrc}
				title={previewDocument.title}
				visible={previewDocument.show}
				onClose={() => setPreviewDocument({show: false, fileSrc: null, undefined})}
			/>
		</>
	);
}
