export function converToLocalGender(gender) {
	switch (gender) {
		case "male":
			return "Pria";
		case "female":
			return "Wanita";
		default:
			return "Unknown Gender";
	}
}

export function convertArrayToOptionsFormat(data = [], options = {}) {
	const keyFrom = Object.keys(options);

	return data.map(dataItem => {
		let finalFormat = dataItem;

		keyFrom.forEach(keySource => {
			finalFormat[options[keySource]] =
				typeof finalFormat[keySource] === "number" ? finalFormat[keySource] + "" : finalFormat[keySource];
		});

		return finalFormat;
	});
}

export function convertErrorMessageFormat(status = null, message) {
	if (message && typeof message === "string") {
		return `${message}`;
	}

	if (message && typeof message === "object" && message.errors) {
		return `${message.errors[0]?.msg}`;
	}

	return `Opps, terjadi kesalahan silahkan coba lagi nanti`;
}

export function convertToCurency(angka) {
	let number_string = angka.replace(/[^,\d]/g, "").toString(),
		split = number_string.split(","),
		sisa = split[0].length % 3,
		rupiah = split[0].substr(0, sisa),
		ribuan = split[0].substr(sisa).match(/\d{3}/gi);

	// tambahkan titik jika yang di input sudah menjadi angka ribuan
	if (ribuan) {
		let separator = sisa ? "." : "";

		rupiah += separator + ribuan.join(".");
	}

	rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;

	return rupiah;
}
