import {BasicTable, SearchBox} from "components/Table";
import {useArticleReport} from "hooks/SuperAdmin/Article";
import {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";

const TabReportComment = () => {
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading, setFilter} = useArticleReport("comment");

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Comment",
				accessor: "key_a",
			},
			{
				Header: "Comment By",
				accessor: "key_b",
				Cell: ({row}) => {
					return (
						<div className="d-flex align-items-center gap-2">
							<div>
								{row?.values?.key_b} - {row?.original?.key_d}
							</div>
							<a class="text-orange wait-pay d-flex" style={{cursor: "pointer"}}>
								<span class="icon-ico-eye-open ft-18" />
							</a>
						</div>
					);
				},
			},
			{
				Header: "Date Comment",
				accessor: "key_c",
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: () => (
					<>
						<a
							class="text-orange wait-pay d-flex"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/report/comment/:id`)}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	return (
		<div>
			<h1 className="m-0 mb-3">Report Comment</h1>
			<SearchBox
				rightElement={null}
				onSearch={keyword => {
					setSearchKeyword(keyword);
					setFilter({page: 1, limit: dataLimit, search: keyword});
				}}
			/>
			<BasicTable
				columns={columns}
				currentPage={data?.data?.currentPage}
				data={dataWithIDSerial}
				limit={dataLimit}
				loading={isLoading}
				striped={true}
				title="Data Report Comment"
				totalItems={data?.data?.totalItems}
				totalPage={data?.data?.totalPages}
				onLimitChange={limitValue => {
					setDataLimit(limitValue);
					setFilter({page: 1, limit: limitValue, search: searchKeyword});
				}}
				onPageChange={selectedPage => setFilter({page: selectedPage, limit: dataLimit, search: searchKeyword})}
			/>
		</div>
	);
};

export {TabReportComment};
