import clsx from "clsx";
import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {setSelectedHospital} from "store/actions/hospital";
import {logout} from "store/actions/auth";
import {Link, useLocation} from "react-router-dom";
import {ModalConfirmation, ModalContainer} from "components/Modal";
import {getAppsMenu} from "config/menu";
import {checkCurrentPath} from "utils/string";
import {useLayoutContext} from "contexts/LayoutContextProvider";

function ModalHospital({visible, onClose = () => {}}) {
	const dispatch = useDispatch();
	const {profile: userProfile, roleId: currentSelectedRoleId} = useSelector(state => state.AuthReducer);

	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);
	const [selectedHospitalId, setSelectedHospitalId] = useState(selectedHospital);

	return (
		<ModalContainer visible={visible}>
			<div class="box-popup w430" id="location">
				<div class="d-flex justify-content-between align-items-center mb-4">
					<div class="text-bold text-dark">Pilih Lokasi Praktek </div>
					<div class="for-close">
						<button class="btn btn-close" type="button" onClick={onClose} />
					</div>
				</div>

				<div class="popup-content">
					{selectedHospitalId &&
						userProfile?.staff?.rsMany
							?.filter(item => item.roleId === currentSelectedRoleId)
							.map((hospital, index) => (
								<div
									key={index}
									class="loc-items mb-3"
									onClick={() => {
										setSelectedHospitalId(hospital);
									}}>
									<input
										checked={hospital.Hospital?.id === selectedHospitalId.Hospital?.id}
										name="locations"
										type="radio"
									/>
									<label class="label-loc" style={{cursor: "pointer"}}>
										<span class="icon-ico-h-marker me-2" />
										{hospital.Hospital?.name}
									</label>
								</div>
							))}
				</div>
				<button
					class="btn btn-orange r8 w-100"
					type="button"
					onClick={() => {
						dispatch(setSelectedHospital(selectedHospitalId));
						onClose();
					}}>
					Simpan
				</button>
			</div>
		</ModalContainer>
	);
}

function SelectHospital({onChange = () => {}}) {
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);

	return (
		<div class="location mb-2">
			<div class="loc-top">
				<div class="text-green ft-13">Lokasi Praktek Anda</div>
				<a class="text-orange change-loc ft-13" style={{cursor: "pointer"}} onClick={onChange}>
					Ubah
				</a>
			</div>
			<a class="link-loc change-loc" onClick={onChange}>
				<span class="icon-ico-h-marker ft-18" />
				<div class="side-desc">{selectedHospital ? selectedHospital?.Hospital?.name : "-"}</div>
			</a>
		</div>
	);
}

const menuWithSubmenuStyles = {
	background: "transparent",
	border: 0,
	outline: 0,
};

export function Sidemenu({role = "admin"}) {
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);

	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const [showHospitalsModal, setShowHospitalModal] = useState(false);

	const layoutContext = useLayoutContext();

	const selectedRoleId = useSelector(state => state.AuthReducer.roleId);
	const location = useLocation();
	const dispatch = useDispatch();
	const {pathname} = location;

	const handleLogout = () => {
		dispatch(logout());
		window.location.href = "/auth/login";
	};
	const splitLocation = pathname.split("/");

	const appsMenu = getAppsMenu(selectedRoleId, selectedHospital?.unitId, selectedHospital?.divisionId);

	return (
		<>
			<ModalHospital visible={showHospitalsModal} onClose={() => setShowHospitalModal(false)} />
			<ModalConfirmation
				approveLabel="Ya"
				description="Apakah anda yakin ingin keluar dari aplikasi?"
				rejectLabel="Batal"
				title="Konfirmasi Keluar"
				visible={showLogoutModal}
				onApprove={handleLogout}
				onClose={() => setShowLogoutModal(false)}
				onReject={() => setShowLogoutModal(false)}
			/>

			<div class="sidebar-content">
				<div class="sc-top">
					{[1, 2, 3, 4, 5, 6, 7].includes(selectedRoleId) && (
						<SelectHospital onChange={() => setShowHospitalModal(true)} />
					)}
					{appsMenu?.top?.map(menu => {
						if (menu.submenu?.length)
							return (
								<div key={menu.id}>
									<button
										className={clsx(
											"link-side ",
											splitLocation[1] === "" &&
												pathname === "/" &&
												menu.path === "/" &&
												"active",
										)}
										style={menuWithSubmenuStyles}
										onClick={() => {
											layoutContext.onSetSelectedSubmenu(menu.id);
											layoutContext.onToggleSubmenu();
										}}>
										<span class={menu.icon} />
										<div class="side-desc">
											{menu.title}
											{layoutContext.showSubmenu &&
												layoutContext.selectedShowSubmenu === menu.id && (
													<span style={{position: "absolute", right: 16, marginTop: -4}}>
														&#9650;
													</span>
												)}
											{!(
												layoutContext.showSubmenu &&
												layoutContext.selectedShowSubmenu === menu.id
											) && <span style={{position: "absolute", right: 16}}>&#9660;</span>}
										</div>
									</button>

									{layoutContext.showSubmenu && layoutContext.selectedShowSubmenu === menu.id && (
										<ul>
											{menu.submenu.map(e => {
												return (
													<Link
														key={e.id}
														className={clsx(
															"link-side ",
															splitLocation[1] === "" &&
																pathname === "/" &&
																e.path === "/" &&
																"active",
															pathname !== "/" &&
																splitLocation.includes(checkCurrentPath(e.path)) &&
																"active",

															pathname !== "/" && e.id === pathname && "active",
														)}
														to={e.path}>
														<span class={e.icon} />
														<div class="side-desc">{e.title}</div>
													</Link>
												);
											})}
										</ul>
									)}
								</div>
							);

						return (
							<Link
								key={menu.id}
								className={clsx(
									"link-side ",
									splitLocation[1] === "" && pathname === "/" && menu.path === "/" && "active",
									pathname !== "/" && splitLocation.includes(checkCurrentPath(menu.path)) && "active",
									menu.path === pathname && "active",
								)}
								to={menu.path}>
								<span class={menu.icon} />
								<div class="side-desc">{menu.title}</div>
							</Link>
						);
					})}

					{/* <a class="link-side active" href="appointment.html">
					<span class="icon-ico-appointment" />
					<div class="side-desc">Appointment</div>
				</a> */}

					{/* <div class="menu-mobile">
					<div class="link-side">
						<span class="icon-ico-comment" />
						<div class="side-desc">
							<div class="d-flex justify-content-between align-items-center">
								<a class="text-grey" href="forum.html">
									Forum
								</a>
								<div class="nav-top-dropdown">
									<a
										aria-expanded="false"
										class="btn btn-top-nav px-1 py-0"
										data-bs-toggle="dropdown"
										href="#"
										id="drop-1"
										type="button">
										<span class="icon-ico-chev-down ft-13" />
									</a>
									<div aria-labelledby="drop-1" class="dropdown-menu dropdown-menu-end">
										<a class="dropdown-item" href="forum-kategori.html">
											Ferlitilitas
										</a>
										<a class="dropdown-item" href="forum-kategori.html">
											Pola Hidup Seimbang
										</a>
										<a class="dropdown-item" href="forum-kategori.html">
											Covid-19
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="link-side">
						<span class="icon-ico-comment" />
						<div class="side-desc">
							<div class="d-flex justify-content-between align-items-center">
								<a class="text-grey" href="artikel.html">
									Artikel
								</a>
								<div class="nav-top-dropdown">
									<a
										aria-expanded="false"
										class="btn btn-top-nav px-1 py-0"
										data-bs-toggle="dropdown"
										id="drop-1"
										type="button">
										<span class="icon-ico-chev-down ft-13" />
									</a>
									<div aria-labelledby="drop-1" class="dropdown-menu dropdown-menu-end">
										<a class="dropdown-item" href="artikel-kategori.html">
											Ferlitilitas (promil)
										</a>
										<a class="dropdown-item" href="artikel-kategori.html">
											Kesehatan Umum
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<a class="link-side" href="update.html">
						<span class="icon-ico-comment" />
						<div class="side-desc">Berita Terkini</div>
					</a>
				</div> */}
				</div>

				<div class="sc-bottom">
					{/* <a class="link-side" href="schedule.html">
					<span class="icon-ico-schedule" />
					<div class="side-desc">Schedule</div>
				</a>
				<a class="link-side" href="withdraw.html">
					<span class="icon-ico-card" />
					<div class="side-desc">Withdraw</div>
				</a>
				<a class="link-side" href="report.html">
					<span class="icon-ico-report" />
					<div class="side-desc">Report</div>
				</a> */}
					{appsMenu?.bottom?.map(menu => (
						<Link
							key={menu.id}
							className={clsx(
								"link-side ",
								splitLocation[1] === "" && pathname === "/" && menu.path === "/" && "active",
								pathname !== "/" && splitLocation[1].includes(checkCurrentPath(menu.path)) && "active",
								menu.path === pathname && "active",
							)}
							to={menu.path}>
							<span class={menu.icon} />
							<div class="side-desc">{menu.title}</div>
						</Link>
					))}
					<a class="link-side" href="#" onClick={() => setShowLogoutModal(true)}>
						<span class="icon-ico-logout" />
						<div class="side-desc">Logout</div>
					</a>
				</div>
			</div>
		</>
	);
}
