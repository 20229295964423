/* eslint-disable no-unused-vars */
import {ButtonAddTable} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {BasicTable, SearchBox} from "components/Table";
import {useListHospitalMedicine} from "hooks/SubAdminDivision/Medicine";
import {useMemo, useState} from "react";
import {isMobile} from "react-device-detect";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {convertToCurency} from "utils/converter";
import {capitalizeFirstLetter} from "utils/string";

export function MedicineListPage() {
	const {permission: userPermission} = useSelector(state => state.AuthReducer);
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);
	const [dataLimit, setDataLimit] = useState(10);
	const [searchKeyword, setSearchKeyword] = useState("");
	const {isLoading, data: medicines, filter, filterMedicines} = useListHospitalMedicine(selectedHospital.Hospital.id);

	const history = useHistory();

	const getPermission = (...permission) => {
		return permission.includes(userPermission);
	};

	const actionColumnRenderer = row => {
		return (
			<>
				<a
					className="text-orange wait-pay"
					style={{cursor: "pointer"}}
					onClick={() => history.push(`/sub-admin-divisi/medicine/show/${row?.values?.id}`)}>
					<span className="icon-ico-eye-open ft-18" />
				</a>
				{getPermission("CRUD", "CRU") && (
					<a
						className="text-orange wait-pay"
						style={{cursor: "pointer"}}
						onClick={() => history.push(`/sub-admin-divisi/medicine/edit/${row?.values?.id}`)}>
						<span className="icon-ico-pencil ft-18" />
					</a>
				)}
			</>
		);
	};

	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Kategori Obat",
				accessor: "medicine.category.name",
			},
			{
				Header: "Nama Obat",
				accessor: "medicine.name",
			},
			{
				Header: "Satuan Obat",
				accessor: "medicine.unit.name",
			},
			{
				Header: "Harga Beli",
				accessor: "buyPrice",
				Cell: ({row}) => (
					<>{row?.original?.buyPrice ? convertToCurency(row?.original?.buyPrice?.toString()) : "-"}</>
				),
			},
			{
				Header: "Harga Jual",
				accessor: "sellPrice",
				Cell: ({row}) => (
					<>{row?.original?.sellPrice ? convertToCurency(row?.original?.sellPrice?.toString()) : "-"}</>
				),
			},
			{
				Header: "Stock",
				accessor: "stock",
			},
			{
				Header: "Status",
				accessor: "statusApproval",
				Cell: ({row}) => (
					<>
						{row?.original?.statusApproval
							? capitalizeFirstLetter(row?.original?.statusApproval)
							: "Waiting"}
					</>
				),
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => {
					return actionColumnRenderer(row);
				},
			},
		],
		[],
	);

	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Obat",
				accessor: "medicine.name",
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => <div className="col-3">{actionColumnRenderer(row)}</div>,
			},
		],
		[],
	);

	const dataWithIDSerial =
		medicines?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (medicines?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	return (
		<DashboardLayout>
			<>
				<div class="text-bold text-dark mb-4">Data Layanan Obat/Apotik - {selectedHospital.Hospital.name}</div>
				<SearchBox
					rightElement={
						<ButtonAddTable
							text="Tambah Layanan Obat"
							onClick={() => history.push("/sub-admin-divisi/medicine/tambah")}
						/>
					}
					onSearch={keyword => {
						filterMedicines({...filter, search: keyword, page: 1, limit: dataLimit});
						setSearchKeyword(keyword);
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={medicines?.data?.currentPage}
					data={dataWithIDSerial}
					limit={dataLimit}
					loading={isLoading}
					striped={true}
					title="Data Obat"
					totalItems={medicines?.data?.totalItems}
					totalPage={medicines?.data?.totalPages}
					onLimitChange={limit => {
						setDataLimit(limit);
						filterMedicines({...filter, limit: dataLimit, search: searchKeyword});
					}}
					onPageChange={selectedPage => {
						filterMedicines({...filter, page: selectedPage, limit: dataLimit, search: searchKeyword});
					}}
				/>
			</>
		</DashboardLayout>
	);
}
