import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY_TAG_POST} from "./useQueryTagPost";

export function useAddTagPost() {
	return useMutation(values => axios.post("/master-tag-post/create", values).then(res => res.data));
}
export function useEditTagPost() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/master-tag-post/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_TAG_POST);
			queryClient.removeQueries(QUERY_TAG_POST);
		},
	});
}

export function useDeleteTagPost() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/master-tag-post/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_TAG_POST);
			queryClient.removeQueries(QUERY_TAG_POST);
		},
	});
}
