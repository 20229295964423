import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

export const QUERY = "rs_poliklinik";

export function useListAvailablePoliklinik() {
	const result = useQuery([QUERY], () => axios.get(`poliklinik/list`).then(res => res.data));

	return result;
}

export const fecthPoliklinik = filter => {
	const query = qs.stringify(filter);

	return axios.get(`poliklinik/?sortBy=id.desc&${query}`).then(res => res.data);
};

export function useListPoliklinikById(filter) {
	return useQuery({
		queryKey: [QUERY, filter],
		queryFn: key => fecthPoliklinik(filter),
		refetchOnMount: false,
		enabled: !!Object.keys(filter).length,
	});
}
