import {ButtonAddTable} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import {useLayananObat} from "hooks/SuperAdmin/LayananObat";
import {useApproveLayananObat} from "hooks/SuperAdmin/LayananObat/useMutationLayananObat";
import {useToast} from "hooks/useToast";
import {useEffect, useMemo, useState} from "react";
import {Button} from "react-bootstrap";
import {isMobile} from "react-device-detect";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";

const PaymentStatus = ({status}) => {
	if (status) {
		return (
			<Button className="ft-13" disabled={true} size="sm" variant="success">
				Sudah di Bayar
			</Button>
		);
	} else {
		return (
			<Button className="ft-13" disabled={true} size="sm" variant="secondary">
				Menunggu Pembayaran
			</Button>
		);
	}
};

const ApprovalStatus = ({status}) => {
	if (status === 1) {
		return (
			<Button className="ft-13" disabled={true} size="sm" variant="success">
				Sudah di Setujui
			</Button>
		);
	} else if (status === -1) {
		return (
			<Button className="ft-13" disabled={true} size="sm" variant="danger">
				Ditolak
			</Button>
		);
	} else {
		return (
			<Button className="ft-13" disabled={true} size="sm" variant="secondary">
				Menunggu Persetujuan
			</Button>
		);
	}
};

const DEFAULT_MODAL = {show: false, type: 0, data: null};

const MasterLayananObatList = () => {
	const {showToast} = useToast();
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading, filterLayanan} = useLayananObat();
	const {mutate, isLoading: isLoadingApprove} = useApproveLayananObat();
	const userData = useSelector(state => state.HospitalsReducer.selectedHospital);

	const [showModal, setShowModal] = useState(DEFAULT_MODAL);

	useEffect(() => {
		if (userData?.roleId === 1) {
			filterLayanan({page: 1, limit: dataLimit, search: "", hospitalId: userData.hospitalId});
		}
	}, [userData]);

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Nama Vendor",
				accessor: "vendor.name",
				maxWidth: 150,
			},
			{
				Header: "Rumah Sakit",
				accessor: "hospital.name",
				maxWidth: 150,
			},
			{
				Header: "Divisi",
				accessor: "division.name",
				maxWidth: 150,
			},
			{
				Header: "Nama Obat",
				accessor: "medicine.name",
				maxWidth: 150,
			},
			{
				Header: "Status Pembayaran",
				accessor: "statusPayment",
				Cell: ({row}) => <PaymentStatus status={row?.values?.statusPayment} />,
			},
			{
				Header: "Status Persetujuan",
				accessor: "status",
				Cell: ({row}) => <ApprovalStatus status={row?.values?.status} />,
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<div className="d-flex align-items-center gap-2">
						<a
							className="text-orange wait-pay cursor-pointer"
							onClick={() => history.push(`/layanan-obat/detail/${row?.values?.id}`)}>
							<span className="icon-ico-eye-open ft-18" />
						</a>
						{row?.values?.status === 0 ? (
							<a
								className="text-orange wait-pay cursor-pointer"
								onClick={() => history.push(`/layanan-obat/edit/${row?.values?.id}`)}>
								<span className="icon-ico-pencil ft-18" />
							</a>
						) : null}
						{row?.values?.status === 0 ? (
							<div className="d-flex flex-column">
								<a
									className="text-success wait-pay cursor-pointer fw-bold"
									onClick={() => setShowModal({show: true, data: row?.original, type: 1})}>
									Setuju
								</a>
								<a
									className="text-danger wait-pay cursor-pointer fw-bold"
									onClick={() => setShowModal({show: true, data: row?.original, type: -1})}>
									Tolak
								</a>
							</div>
						) : null}
					</div>
				),
			},
		],
		[],
	);

	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Obat",
				accessor: "name",
				Cell: ({row}) => {
					return (
						<div className="col-9">
							<div className="text-bold">{row.values.name}</div>
						</div>
					);
				},
			},

			{
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="col-3">
							<a
								className="text-orange wait-pay cursor-pointer"
								onClick={() => history.push(`/layanan-obat/detail/${row?.values?.id}`)}>
								<span className="icon-ico-eye-open ft-18" />
							</a>
							{row?.values?.status === 0 ? (
								<a
									className="text-orange wait-pay cursor-pointer"
									onClick={() => history.push(`/layanan-obat/edit/${row?.values?.id}`)}>
									<span className="icon-ico-pencil ft-18" />
								</a>
							) : null}
							{row?.values?.status === 0 ? (
								<div className="d-flex flex-column">
									<a
										className="text-success wait-pay cursor-pointer fw-bold"
										onClick={() => setShowModal({show: true, data: row?.original, type: 1})}>
										Setuju
									</a>
									<a
										className="text-danger wait-pay cursor-pointer fw-bold"
										onClick={() => setShowModal({show: true, data: row?.original, type: -1})}>
										Tolak
									</a>
								</div>
							) : null}
						</div>
					);
				},
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const handleConfirmApprove = () => {
		mutate(
			{id: showModal.data?.id, status: showModal.type},
			{
				onSuccess: () => {
					showToast(
						"success",
						`Data layanan obat ${showModal.data?.description} berhasil ${
							showModal.type === 1 ? "di setujui" : "di tolak"
						}`,
						3000,
					);
					window.scrollTo(0, 0);
					setShowModal(DEFAULT_MODAL);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
		console.log("APPROVE", showModal.data, showModal.type);
	};

	return (
		<DashboardLayout>
			<div className="text-bold text-dark mb-4">Data Layanan Obat</div>
			<SearchBox
				rightElement={
					<ButtonAddTable text="Tambah Layanan Obat" onClick={() => history.push("/layanan-obat/tambah")} />
				}
				onSearch={keyword => {
					setSearchKeyword(keyword);
					filterLayanan({page: 1, limit: dataLimit, search: keyword});
				}}
			/>
			<ModalConfirmation
				approveLabel="Ya"
				description={`Apakah Anda yakin ingin ${
					showModal.type === 1 ? "menyetujui" : "menolak"
				} data layanan obat "${showModal?.data?.description}"?`}
				loading={isLoadingApprove}
				rejectLabel="Batal"
				visible={showModal.show}
				onApprove={handleConfirmApprove}
				onClose={() => setShowModal(DEFAULT_MODAL)}
				onReject={() => setShowModal(DEFAULT_MODAL)}
			/>

			<BasicTable
				columns={isMobile ? columnsMobile : columns}
				currentPage={data?.data?.currentPage}
				data={dataWithIDSerial}
				limit={dataLimit}
				loading={isLoading}
				striped={true}
				title="Data Obat"
				totalItems={data?.data?.totalItems}
				totalPage={data?.data?.totalPages}
				onLimitChange={limitValue => {
					setDataLimit(limitValue);
					filterLayanan({page: 1, limit: limitValue, search: searchKeyword});
				}}
				onPageChange={selectedPage =>
					filterLayanan({page: selectedPage, limit: dataLimit, search: searchKeyword})
				}
			/>
		</DashboardLayout>
	);
};

export {MasterLayananObatList};
