import {SubmitButton} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import {useDeleteVendorPIC, useListVendorPIC} from "hooks/SuperAdmin/Vendor";
import {useStateCallback} from "hooks/useStateCallback";
import {useToast} from "hooks/useToast";
import {useMemo, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";

function ButtonAddPIC({onClick}) {
	return (
		<div class="w-20">
			<div class="row gx-2 align-items-center justify-content-end">
				<SubmitButton text="Tambah PIC" onClick={onClick} />
			</div>
		</div>
	);
}

const VendorPICListPage = () => {
	const {id} = useParams();
	const [page, setPage] = useState(1);
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading} = useListVendorPIC({page, limit: dataLimit, search: searchKeyword}, id);

	// Delete Data State
	const {mutate: deleteVendorPICById, isLoading: isDeletingData} = useDeleteVendorPIC();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteItemData, setDeleteItemData] = useStateCallback({id: null, name: null});
	const {showToast} = useToast();

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Nama PIC",
				accessor: "name",
				Cell: ({row}) => `${row?.original?.firstName} ${row?.original?.lastName}`,
			},
			{
				Header: "Email",
				accessor: "email",
			},
			{
				Header: "No Telp",
				accessor: "phoneNumber",
			},
			{
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/master-vendor/${id}/PIC/show/${row?.values?.id}`)}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/master-vendor/${id}/PIC/edit/${row?.values?.id}`)}>
							<span class="icon-ico-pencil ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => setDeleteItemData(row?.original, () => setShowDeleteModal(true))}>
							<span class="icon-ico-delete ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({...item, idSerial: (page - 1) * dataLimit + index + 1})) || [];

	const handleDeleteData = () => {
		deleteVendorPICById(
			{vendorId: id, picId: deleteItemData.id},
			{
				onSuccess: () => {
					showToast(
						"success",
						`Data PIC "${deleteItemData.firstName} ${deleteItemData.lastName}" berhasil dihapus`,
						3000,
					);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<>
				<ModalConfirmation
					description={`Apakah anda yakin ingin menghapus data PIC "${deleteItemData.firstName} ${deleteItemData.lastName}"?`}
					loading={isDeletingData}
					visible={showDeleteModal}
					onApprove={handleDeleteData}
					onClose={() => setShowDeleteModal(false)}
					onReject={() => setShowDeleteModal(false)}
				/>
				<div className="d-flex align-items-center gap-2 mb-4">
					<a class="text-dark" style={{cursor: "pointer"}} onClick={() => history.push("/master-vendor")}>
						<span class="icon-ico-back" />
					</a>
					<div class="text-bold text-dark">Data Vendor PIC</div>
				</div>
				<SearchBox
					rightElement={<ButtonAddPIC onClick={() => history.push("./PIC/tambah")} />}
					onSearch={keyword => setSearchKeyword(keyword)}
				/>

				<BasicTable
					columns={columns}
					currentPage={data?.data?.currentPage}
					data={dataWithIDSerial}
					limit={dataLimit}
					loading={isLoading}
					striped={true}
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => setDataLimit(limitValue)}
					onPageChange={selectedPage => setPage(selectedPage)}
				/>
			</>
		</DashboardLayout>
	);
};

export {VendorPICListPage};
