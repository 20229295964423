import clsx from "clsx";

export function ButtonLoader({className = "spinner"}) {
	return (
		<div className={className}>
			<div className="bounce1" />
			<div className="bounce2" />
			<div className="bounce3" />
		</div>
	);
}

export function SubmitButton({
	text = "Submit",
	className = "btn btn-green",
	onClick = () => {},
	disabled = false,
	loading = false,
	type = "submit",
}) {
	return (
		<button
			className={clsx(className, disabled && "btn-disabled")}
			disabled={disabled}
			href={null}
			type={type}
			onClick={!disabled && onClick}>
			{loading ? <ButtonLoader /> : text}
		</button>
	);
}
