import {
	PendaftaranPasienListPage,
	PendaftaranPasienAddPage,
	PendaftaranPasienDetailPage,
	PendaftaranPasienEditPage,
	PendaftaranPasienFileAddPage,
} from "pages/AdminAdmission/PendaftaranPasien";
import {AppointmentListPage, AppointmentAddPage, AppointmentDetailPage} from "pages/AdminAdmission/Appointment";
import {CheckInPatientsDetailPage, CheckInPatientsListPage} from "pages/AdminAdmission/CheckInPatients";

export const adminAdmissionRoutes = [
	{
		name: "admin-admission-master-pasien",
		path: "/pendaftaran-pasien",
		exact: true,
		isPrivate: true,
		component: PendaftaranPasienListPage,
	},
	{
		name: "admin-admission-tambah-pasien",
		path: "/pendaftaran-pasien/tambah",
		exact: true,
		isPrivate: true,
		component: PendaftaranPasienAddPage,
	},
	{
		name: "admin-admission-detail-pasien",
		path: "/pendaftaran-pasien/show/:id",
		exact: true,
		isPrivate: true,
		component: PendaftaranPasienDetailPage,
	},
	{
		name: "admin-admission-edit-pasien",
		path: "/pendaftaran-pasien/edit/:id",
		exact: true,
		isPrivate: true,
		component: PendaftaranPasienEditPage,
	},
	{
		name: "admin-laboratoriun-master-file-lab-add",
		path: "/file-labs/tambah/:idPatient",
		exact: true,
		isPrivate: true,
		component: PendaftaranPasienFileAddPage,
	},
	{
		name: "admin-admission-appointment-list",
		path: "/admission/appointment/list",
		exact: true,
		isPrivate: true,
		component: AppointmentListPage,
	},
	{
		name: "admin-admission-appointment-add",
		path: "/admission/appointment/add",
		exact: true,
		isPrivate: true,
		component: AppointmentAddPage,
	},
	{
		name: "admin-admission-appointment-edit",
		path: "/admission/appointment/edit/:id",
		exact: true,
		isPrivate: true,
		component: AppointmentAddPage,
	},
	{
		name: "admin-admission-appointment-detail",
		path: "/admission/appointment/detail/:id",
		exact: true,
		isPrivate: true,
		component: AppointmentDetailPage,
	},
	{
		name: "admin-admission-checkin-patients",
		path: "/admission/checkin-patients/list",
		exact: true,
		isPrivate: true,
		component: CheckInPatientsListPage,
	},
	{
		name: "admin-admission-checkin-patients-detail",
		path: "/admission/checkin-patients/detail/:id",
		exact: true,
		isPrivate: true,
		component: CheckInPatientsDetailPage,
	},
];
