import * as Yup from "yup";

export const ResetPasswordValidationSchema = Yup.object().shape({
	password: Yup.string()
		.required("Kata Sandi wajib diisi")
		.min(8, "Panjang kata sandi minimal 8 karakter")
		.matches(/[a-z]/, "Kata sandi harus memiliki minimal 1 huruf kecil")
		.matches(/[A-Z]/, "Kata sandi harus memiliki minimal 1 huruf kapital")
		.matches(/[0-9]/, "Kata sandi harus memiliki minimal 1 angka")
		.matches(/[^a-zA-Z\d]/, "Kata sandi harus memiliki minimal 1 spesial karakter"),
	password_confirmation: Yup.string()
		.oneOf([Yup.ref("password"), null], "Konfirmasi kata sandi tidak cocok")
		.required("Kata Sandi wajib diisi"),
});
