import clsx from "clsx";
import {ButtonAddTable} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation, ModalInfo} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import {useDeleteHargaAdministrasi, useListHargaAdministrasi} from "hooks/SuperAdmin/HargaAdministrasi";
import {useHospitals} from "hooks/SuperAdmin/Hospital";
import {useStateCallback} from "hooks/useStateCallback";
import {useToast} from "hooks/useToast";
import {useMemo, useState} from "react";
import {isMobile} from "react-device-detect";
import {useHistory} from "react-router-dom";
import {convertErrorMessageFormat, convertToCurency} from "utils/converter";

export function MasterHargaAdministrasiListPage() {
	const {hospitals} = useHospitals(true, 100);
	const listHospital = hospitals?.data?.rows || [];

	const [filter, setFilter] = useState({page: 1, search: "", limit: 10, sortBy: "id.desc"});
	const {data, isLoading} = useListHargaAdministrasi(filter);

	// Delete Data State
	const {mutate, isLoading: isDeletingData} = useDeleteHargaAdministrasi();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showInfoModal, setShowInfoModal] = useState(false);
	const [deleteItemData, setDeleteItemData] = useStateCallback({id: null, name: null});
	const {showToast} = useToast();

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Nama Rumah Sakit",
				accessor: "hospitalId",
				Cell: ({row}) => {
					return <span>{listHospital.find(e => e.id === row?.values?.hospitalId)?.name || ""}</span>;
				},
				maxWidth: 150,
			},
			{
				Header: "Harga",
				accessor: "price",
				Cell: ({row}) => {
					return <span>Rp {convertToCurency(row.values.price + "")}</span>;
				},
				maxWidth: 150,
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<>
							<a
								className="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/master-harga-administrasi/show/${row?.values?.id}`)}>
								<span className="icon-ico-eye-open ft-18" />
							</a>
							<a
								className="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/master-harga-administrasi/edit/${row?.values?.id}`)}>
								<span className="icon-ico-pencil ft-18" />
							</a>

							<a
								className={clsx("text-orange wait-pay")}
								data-placement="top"
								data-toggle="tooltip"
								style={{cursor: "pointer"}}
								title={!row.original.can_delete && "Data tidak dapat dihapus karena sudah digunakan"}
								onClick={() => {
									if (row.original.can_delete) {
										setDeleteItemData(row?.values, () => setShowDeleteModal(true));
									} else {
										setShowInfoModal(true);
									}
								}}>
								<span className="icon-ico-delete ft-18" />
							</a>
						</>
					);
				},
			},
		],
		[listHospital],
	);
	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Rumah Sakit",
				accessor: "hospitalId",
				Cell: ({row}) => {
					return (
						<div className="col-5">
							<span className="text-bold">
								{listHospital.find(e => e.id === row?.values?.hospitalId)?.name || ""}
							</span>
						</div>
					);
				},
			},
			{
				Header: "Harga",
				accessor: "price",
				Cell: ({row}) => {
					return (
						<div className="col-4">
							<span className="text-bold">{convertToCurency(row?.values?.price + "")}</span>
						</div>
					);
				},
			},
			{
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="col-3">
							<a
								className="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/master-harga-administrasi/show/${row?.values?.id}`)}>
								<span className="icon-ico-eye-open ft-18" />
							</a>
							<a
								className="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/master-harga-administrasi/edit/${row?.values?.id}`)}>
								<span className="icon-ico-pencil ft-18" />
							</a>

							<a
								className="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => {
									if (row.original.can_delete) {
										setDeleteItemData(row?.values, () => setShowDeleteModal(true));
									} else {
										setShowInfoModal(true);
									}
								}}>
								<span className="icon-ico-delete ft-18" />
							</a>
						</div>
					);
				},
			},
		],
		[listHospital],
	);

	const dataWithIDSerial = useMemo(() => {
		return (
			data?.data?.rows?.map((item, index) => ({
				...item,
				idSerial: (filter.page - 1) * filter.limit + index + 1,
			})) || []
		);
	}, [data, filter.limit, filter.page]);

	const handleDeleteData = () => {
		mutate(
			{id: deleteItemData.id},
			{
				onSuccess: () => {
					showToast(
						"success",
						`Data Harga Administrasi "${
							listHospital.find(e => e.id === deleteItemData.hospitalId)?.name || ""
						}" berhasil dihapus`,
						3000,
					);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<>
				<ModalInfo
					approveLabel="OK"
					description="Data tidak dapat dihapus karena sudah digunakan"
					title="Info"
					visible={showInfoModal}
					onApprove={() => setShowInfoModal(false)}
					onClose={() => setShowInfoModal(false)}
				/>
				<ModalConfirmation
					approveLabel="Ya"
					description={`Apakah Anda yakin ingin menghapus data Harga Administrasi "${
						listHospital.find(e => e.id === deleteItemData.hospitalId)?.name || ""
					}"?`}
					loading={isDeletingData}
					rejectLabel="Batal"
					visible={showDeleteModal}
					onApprove={handleDeleteData}
					onClose={() => setDeleteItemData({...deleteItemData, id: null}, () => setShowDeleteModal(false))}
					onReject={() => setDeleteItemData({...deleteItemData, id: null}, () => setShowDeleteModal(false))}
				/>
				<div className="text-bold text-dark mb-4">Data Harga Administrasi</div>
				<SearchBox
					rightElement={
						<ButtonAddTable
							text="Tambah Harga administrasi"
							onClick={() => history.push("/master-harga-administrasi/tambah")}
						/>
					}
					onSearch={keyword => {
						setFilter({...filter, search: keyword, page: 1});
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={data?.data?.currentPage}
					data={dataWithIDSerial}
					limit={filter.limit}
					loading={isLoading}
					striped={true}
					title="Data Harga Administrasi"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setFilter({...filter, limit: limitValue});
					}}
					onPageChange={selectedPage => {
						setFilter({...filter, page: selectedPage});
					}}
				/>
			</>
		</DashboardLayout>
	);
}
