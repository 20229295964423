/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-key */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {useState, useRef, useEffect, forwardRef} from "react";
import {useHistory, useParams} from "react-router-dom";
import clsx from "clsx";
import {Formik, Field} from "formik";
import * as Yup from "yup";
import {DashboardLayout} from "components/Layout";
import {BasicDropdown} from "components/Dropdown";
import {useHospitalsOptions} from "hooks/SuperAdmin/Hospital";
import {useAvailableSchedule} from "hooks/Doctor/Schedule";
import {useSelector, useDispatch} from "react-redux";
import {convertToCurency} from "utils/converter";
import {useListConsultationsById} from "hooks/AdminHospital/Consultation";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import {setFormValue} from "store/actions/medicalRecordForm";
import {capitalizeFirstLetter} from "utils/string";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import "react-datepicker/dist/react-datepicker.css";

const FormSchemaValidation = Yup.object().shape({
	type: Yup.string().required("Tipe konsultasi wajib di isi"),
	idHospital: Yup.string(),
	date: Yup.string().required("Pilih tanggal terlebih dahulu"),
	startTime: Yup.string().required("Pilih jam praktik terlebih dahulu"),
	endTime: Yup.string().required(),
});
const FormInitialValues = {
	type: "online",
	idHospital: null,
	date: "",
	startTime: "",
	endTime: "",
	price: null,
};

export function MakeSchedulePage() {
	const {id} = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const userProfile = useSelector(state => state.AuthReducer.profile);
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);
	const [availableHospital, setAvailableHospital] = useState([]);
	const [locationOptions, setLocationOptions] = useState([]);
	const [consultationOptions, setConsultationOptions] = useState([]);
	const formikRef = useRef();
	const medicalRecordForm = useSelector(state => state.MedicalRecordFormReducer.formValue);

	const {hospitalOptions: locations, isLoading: isLoadingLocationOptions} = useHospitalsOptions();
	const {data: consultations, isLoading: isLoadingConsultationOptions} = useListConsultationsById(
		selectedHospital.Hospital.id,
		{isActive: true},
	);
	const [filterSchedules, setFilterSchedules] = useState({
		limit: 365,
		page: 1,
		type: "online",
		hospitalId: "",
	});
	const {data: schedules, isLoading: isLoadingSchedules} = useAvailableSchedule({
		userId: userProfile.id,
		...filterSchedules,
	});
	const [dateInclude, setDateInclude] = useState([]);
	const [timeEnum, setTimeEnum] = useState({});
	const [timeOptions, setTimeOptions] = useState([]);
	const [isOnProcessSchedule, setIsOnProcessSchedule] = useState(false);

	useEffect(() => {
		if (medicalRecordForm.bookingId !== id) {
			dispatch(setFormValue(FormInitialValues));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [medicalRecordForm]);

	useEffect(() => {
		formikRef.current?.setFieldValue("idHospital", selectedHospital.Hospital.id);
	}, [selectedHospital]);

	useEffect(() => {
		if (schedules?.success) {
			setIsOnProcessSchedule(true);
			const timeEnumData = {};
			const dateOptions = schedules?.data
				?.filter(item => !!item.times.length)
				.map(date => {
					timeEnumData[date.date] = date.times
						.filter(time => time.remainingQuota > 0)
						.map(time => ({
							label: `${time.startTime} - ${time.endTime}`,
							value: JSON.stringify({
								startTime: time.startTime,
								endTime: time.endTime,
								price: date.prices[0],
							}),
						}));

					timeEnumData[date.date].unshift({
						label: `-`,
						value: JSON.stringify({
							startTime: "",
							endTime: "",
							price: "",
						}),
					});

					return new Date(date.date);
				});

			setTimeEnum({...timeEnumData});
			setDateInclude([...dateOptions]);
			setIsOnProcessSchedule(false);
		}
	}, [schedules]);

	const handleSubmit = formData => {
		if (formData.type === "online") formData.idHospital = undefined;
		dispatch(setFormValue({...medicalRecordForm, nextAppointment: formData}));
		history.goBack();
	};

	useEffect(() => {
		// formikRef?.current?.setFieldValue("userId", parseInt(userProfile.id) || null);
		let idHospitals = [];

		userProfile?.staff?.rsMany.forEach(item => {
			if (item.hospitalId && item.roleId == 2) idHospitals.push(item.hospitalId);
		});

		setAvailableHospital([...idHospitals]);
	}, [userProfile]);

	useEffect(() => {
		if (locations.length) {
			const hospital = locations[0].value;
			const dataLocations = locations.filter(location => availableHospital.includes(parseInt(location.value)));

			setLocationOptions([...dataLocations]);
			formikRef?.current.setFieldValue("hospitalId", hospital);
		}
	}, [locations, availableHospital]);

	useEffect(() => {
		if (consultations?.success) {
			const dataConsultationOptions = consultations?.data?.rows?.map(consultation => ({
				value: consultation?.id + "",
				label: `${consultation?.name}`,
			}));

			setConsultationOptions([...dataConsultationOptions]);
			// formikRef?.current.setFieldValue("consultationId", dataConsultationOptions[0].value);
		}
	}, [consultations]);

	const CustomInputDate = forwardRef(({value, onClick}, ref) => (
		<div className="form-filter position-relative" onClick={onClick} ref={ref}>
			<input type="text" className="form-control datepicker" placeholder="Pilih tanggal " value={value} />

			<div className="icon-right">
				<span className="icon-ico-date text-orange" />
			</div>
		</div>
	));

	return (
		<DashboardLayout>
			<Formik
				initialValues={
					medicalRecordForm.bookingId !== id ? FormInitialValues : medicalRecordForm.nextAppointment
				}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({
					handleSubmit,
					handleBlur,
					handleChange,
					values,
					errors,
					touched,
					setFieldValue,
					setFieldTouched,
				}) => (
					<div>
						<a
							onClick={() => history.goBack()}
							style={{cursor: "pointer"}}
							className="text-bold d-inline-block text-dark mb-4">
							<span className="icon-ico-back me-2" /> Kontrol Selanjutnya
						</a>

						<div className="row">
							<div className="col-xl-8">
								<div className="box-white ws p24 mb-3">
									<div className="row">
										<div className="col-sm-6 mb-3">
											<div className="text-dark mb-3">Tipe Konsultasi</div>
											<div className="row">
												<div className="col-6">
													<div className="form-check">
														<label>
															<Field
																className="me-2 form-check-input"
																name="type"
																type="radio"
																value="online"
																onChange={() => {
																	setDateInclude([]);
																	setFieldValue("type", "online");
																	setFieldTouched("type", true);
																	setFieldValue("hospitalId", null);
																	setFieldValue("date", "");
																	setFieldValue("startTime", "");
																	setFieldValue("endTime", "");
																	setFieldValue("price", "");
																	setFilterSchedules({
																		...filterSchedules,
																		type: "online",
																		hospitalId: "",
																	});
																}}
															/>
															Online
														</label>
													</div>
												</div>
												<div className="col-6">
													<div className="form-check">
														<label>
															<Field
																className="me-2 form-check-input"
																name="type"
																type="radio"
																value="offline"
																onChange={() => {
																	setDateInclude([]);
																	setFieldValue("type", "offline");
																	setFieldTouched("type", true);
																	setFieldValue("date", "");
																	setFieldValue("startTime", "");
																	setFieldValue("endTime", "");
																	setFieldValue("price", "");
																	setFieldValue(
																		"idHospital",
																		locationOptions?.[0]?.value,
																	);
																	setFilterSchedules({
																		...filterSchedules,
																		type: "offline",
																		hospitalId: locationOptions?.[0]?.value,
																	});
																}}
															/>
															Offline
														</label>
													</div>
												</div>
											</div>
											{errors?.type && touched?.type && (
												<p className="form-error-item-message">{errors?.type}</p>
											)}
										</div>
										<div className="col-6 mb-3">
											<div className={values.type === "online" ? "d-none" : ""}>
												<div className="ft-12 text-light-grey mb-2">Lokasi Praktik</div>
												<div className="form-filter position-relative">
													<BasicDropdown
														className={clsx(
															"form-control",
															errors?.idHospital && "form-control-error",
														)}
														data={locationOptions}
														name={`idHospital`}
														value={values?.idHospital}
														onSelect={fieldValue => {
															setFieldValue(`idHospital`, parseInt(fieldValue) || null);
														}}
													/>

													<div className="icon-right">
														<span className="icon-ico-chev-down text-orange" />
													</div>
												</div>
												{errors?.idHospital && touched?.idHospital && (
													<p className="form-error-item-message">{errors?.idHospital}</p>
												)}
											</div>
										</div>
										<div className="col-sm-6 mb-3 d-none">
											<div className="ft-12 text-light-grey mb-2">Konsultasi</div>
											<div className="form-filter position-relative">
												<BasicDropdown
													className={clsx(
														"form-control",
														errors?.consultationId && "form-control-error",
													)}
													data={consultationOptions}
													name={`consultationId`}
													value={values?.consultationId}
													onSelect={fieldValue => {
														setFieldValue(`consultationId`, parseInt(fieldValue) || null);
													}}
												/>

												<div className="icon-right">
													<span className="icon-ico-chev-down text-orange" />
												</div>
											</div>
										</div>
									</div>

									<div className="row align-items-center">
										<div className="col-sm-6 mb-3">
											<div className="ft-12 text-light-grey mb-2">Tanggal</div>
											{(isOnProcessSchedule || isLoadingSchedules) && (
												<Skeleton
													style={{
														flex: 1,
														height: 32,
													}}
												/>
											)}
											{!(isOnProcessSchedule || isLoadingSchedules) && (
												<>
													<DatePicker
														selected={values.date}
														onChange={date => {
															setFieldValue("date", date);
															setTimeOptions([
																...timeEnum[dayjs(new Date(date)).format("YYYY-MM-DD")],
															]);
															const defaultTime = JSON.parse(
																timeEnum[dayjs(new Date(date)).format("YYYY-MM-DD")][0]
																	.value,
															);

															setFieldValue("startTime", defaultTime.startTime);
															setFieldValue("endTime", defaultTime.endTime);
															setFieldValue("price", defaultTime.price);
														}}
														includeDates={dateInclude}
														customInput={<CustomInputDate />}
													/>
													{errors?.date && touched?.date && (
														<p className="form-error-item-message">{errors?.date}</p>
													)}
												</>
											)}
										</div>
										{values?.type === "online" && (
											<div class="col-sm-6 mb-3">
												<div class="ft-12 text-light-grey mb-2">Jam Praktek</div>
												<div class="form-filter position-relative">
													<BasicDropdown
														className={clsx(
															"form-control",
															errors?.time && "form-control-error",
														)}
														data={[...timeOptions]}
														name="time"
														onSelect={fieldValue => {
															const selectedTime = JSON.parse(fieldValue);

															setFieldValue("startTime", selectedTime.startTime);
															setFieldValue("endTime", selectedTime.endTime);
															setFieldValue("price", selectedTime.price);
														}}
													/>
													<div class="icon-right">
														<span class="icon-ico-chev-down text-orange" />
													</div>
													{errors?.startTime && touched?.startTime && (
														<p className="form-error-item-message">{errors?.startTime}</p>
													)}
												</div>
											</div>
										)}
										{values?.type === "offline" && (
											<div class="col-sm-6 mb-3">
												<div class="ft-12 text-light-grey mb-2">Jam Praktek</div>
												<div class="form-filter position-relative">
													<BasicDropdown
														className={clsx(
															"form-control",
															errors?.time && "form-control-error",
														)}
														data={[...timeOptions]}
														name="time"
														onSelect={fieldValue => {
															const selectedTime = JSON.parse(fieldValue);

															setFieldValue("startTime", selectedTime.startTime);
															setFieldValue("endTime", selectedTime.endTime);
															setFieldValue("price", selectedTime.price);
														}}
													/>
													<div class="icon-right">
														<span class="icon-ico-chev-down text-orange" />
													</div>
													{errors?.startTime && touched?.startTime && (
														<p className="form-error-item-message">{errors?.startTime}</p>
													)}
												</div>
											</div>
										)}
									</div>
								</div>
							</div>

							<div className="col-xl-4">
								<div className="box-white ws p24 mb-3">
									<div className="mb-3">
										<div className="ft-12 text-light-grey mb-2">Tipe Konsultasi</div>
										<div className="box-status online">{capitalizeFirstLetter(values.type)}</div>
									</div>
									<div className="mb-3">
										<div className="ft-12 text-light-grey mb-2">Lokasi Praktek</div>
										{values.type === "online" && <div className="text-dark">-</div>}
										{values.type === "offline" && (
											<div className="text-dark">
												{locationOptions?.find(item => item.value == values.idHospital)
													?.label || "-"}
											</div>
										)}
									</div>
									<div className="mb-3 d-none">
										<div className="ft-12 text-light-grey mb-2">Konsultasi</div>
										<div className="text-dark">Konsultasi Adrologi</div>
									</div>
									<div className="mb-3">
										<div className="ft-12 text-light-grey">Harga</div>
										<div className="text-dark">Rp {convertToCurency(`${values.price}` || "0")}</div>
									</div>
									<div className="mb-3">
										<div className="ft-12 text-light-grey">Tanggal</div>
										<div className="text-dark">
											{values.date ? dayjs(new Date(values.date)).format("DD MMMM YYYY") : "-"}
										</div>
									</div>
									<div className="mb-3">
										<div className="ft-12 text-light-grey">Jam Praktek(WIB)</div>
										<div className="bx-time">{`${values.startTime} - ${values.endTime}`}</div>
									</div>
								</div>

								<button onClick={handleSubmit} className="btn btn-orange w-100 r8 mb-3">
									Simpan
								</button>
							</div>
						</div>
					</div>
				)}
			</Formik>
		</DashboardLayout>
	);
}
