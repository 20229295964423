/* eslint-disable no-unused-vars */
import clsx from "clsx";
import {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {DashboardLayout} from "components/Layout";
import {BasicTable, SearchBox} from "components/Table";
import {useDivision, useDeleteDivision} from "hooks/SuperAdmin/Division";
import {ButtonAddTable} from "components/Button";
import {ModalConfirmation, ModalInfo} from "components/Modal";
import {useToast} from "hooks/useToast";
import {useStateCallback} from "hooks/useStateCallback";
import {convertErrorMessageFormat} from "utils/converter";

export function MasterDivisiListPage() {
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading, filterDivisions} = useDivision();

	// Delete Data State
	const {mutate, isLoading: isDeletingData} = useDeleteDivision();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showInfoModal, setShowInfoModal] = useState(false);
	const [deleteItemData, setDeleteItemData] = useStateCallback({id: null, name: null});
	const {showToast} = useToast();

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Nama Divisi",
				accessor: "name",
				maxWidth: 300,
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/master-divisi/show/${row?.values?.id}`)}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/master-divisi/edit/${row?.values?.id}`)}>
							<span class="icon-ico-pencil ft-18" />
						</a>
						<a
							class={clsx("text-orange wait-pay")}
							style={{cursor: "pointer"}}
							title={!row.original.can_delete && "Data tidak dapat dihapus karena sudah digunakan"}
							onClick={() => {
								if (row.original.can_delete) {
									setDeleteItemData(row?.values, () => setShowDeleteModal(true));
								} else {
									setShowInfoModal(true);
								}
							}}>
							<span class="icon-ico-delete ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Divisi",
				accessor: "name",
				Cell: ({row}) => {
					return (
						<div class="col-9">
							<div class="text-bold">{row.values.name}</div>
						</div>
					);
				},
			},

			{
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="col-3">
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/master-divisi/show/${row?.values?.id}`)}>
								<span class="icon-ico-eye-open ft-18" />
							</a>
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/master-divisi/edit/${row?.values?.id}`)}>
								<span class="icon-ico-pencil ft-18" />
							</a>

							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => {
									if (row.original.can_delete) {
										setDeleteItemData(row?.values, () => setShowDeleteModal(true));
									} else {
										setShowInfoModal(true);
									}
								}}>
								<span class="icon-ico-delete ft-18" />
							</a>
						</div>
					);
				},
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const handleDeleteData = () => {
		mutate(
			{id: deleteItemData.id},
			{
				onSuccess: () => {
					showToast("success", `Data divisi ${deleteItemData.name} berhasil dihapus`, 3000);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<>
				<ModalInfo
					approveLabel="OK"
					description="Data tidak dapat dihapus karena sudah digunakan"
					title="Info"
					visible={showInfoModal}
					onApprove={() => setShowInfoModal(false)}
					onClose={() => setShowInfoModal(false)}
				/>
				<ModalConfirmation
					approveLabel="Ya"
					description={`Apakah Anda yakin ingin menghapus data divisi "${deleteItemData.name}"?`}
					loading={isDeletingData}
					rejectLabel="Batal"
					visible={showDeleteModal}
					onApprove={handleDeleteData}
					onClose={() => setShowDeleteModal(false)}
					onReject={() => setShowDeleteModal(false)}
				/>
				<div class="text-bold text-dark mb-4">Data Divisi</div>
				<SearchBox
					rightElement={
						<ButtonAddTable text="Tambah Divisi" onClick={() => history.push("/master-divisi/tambah")} />
					}
					onSearch={keyword => {
						setSearchKeyword(keyword);
						filterDivisions({page: 1, limit: dataLimit, search: keyword});
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={data?.data?.currentPage}
					data={dataWithIDSerial}
					limit={dataLimit}
					loading={isLoading}
					striped={true}
					title="Data Divisi"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setDataLimit(limitValue);
						filterDivisions({page: 1, limit: limitValue, search: searchKeyword});
					}}
					onPageChange={selectedPage =>
						filterDivisions({page: selectedPage, limit: dataLimit, search: searchKeyword})
					}
				/>
			</>
		</DashboardLayout>
	);
}
