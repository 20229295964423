import clsx from "clsx";

export const Button = ({children, className = "", disabled = false, ...otherProps}) => {
	const variantClassName = clsx("btn", disabled && "btn-disabled", className);

	return (
		<button className={variantClassName} {...otherProps}>
			{children}
		</button>
	);
};
