import {isMobile} from "react-device-detect";
import {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {DashboardLayout} from "components/Layout";
import {BasicTable, SearchBox} from "components/Table";
import {ModalConfirmation} from "components/Modal";
import {ButtonAddTable, ToggleSwitch} from "components/Button";
import {useListConsultationsById, useChangeDataStatus} from "hooks/AdminHospital/Consultation";
import {useToast} from "hooks/useToast";
import {useStateCallback} from "hooks/useStateCallback";
import {convertErrorMessageFormat} from "utils/converter";

export function HospitalListConsultationPage() {
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataFilter, setDataFilter] = useState({page: 1, limit: 10, search: ""});
	const {data, isLoading} = useListConsultationsById(selectedHospital.Hospital.id, dataFilter);

	// Delete Data State
	const {mutate, isLoading: isUpdatingData} = useChangeDataStatus();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedItem, setSelectedItem] = useStateCallback({id: null, name: null});
	const {showToast} = useToast();

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Nama Jabatan",
				accessor: "name",
				Cell: ({row}) => {
					return row.original?.consultations?.name || "";
				},
			},

			{
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<>
							<ToggleSwitch
								isActive={row.original.status}
								onChange={() => {
									setSelectedItem({...row?.original.consultations, status: row.original.status}, () =>
										setShowDeleteModal(true),
									);
								}}
							/>
						</>
					);
				},
			},
		],
		[setSelectedItem],
	);
	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Jabatan",
				accessor: "name",
				Cell: ({row}) => {
					return (
						<div class="col-6">
							<div class="text-bold">{row.original.consultations.name}</div>
						</div>
					);
				},
			},

			{
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="col-6">
							<>
								<ToggleSwitch
									isActive={row.original.status}
									onChange={() => {
										setSelectedItem({...row?.original.consultations}, () =>
											setShowDeleteModal(true),
										);
									}}
								/>
							</>
						</div>
					);
				},
			},
		],
		[setSelectedItem],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (dataFilter.page - 1) * dataFilter.limit + index + 1,
		})) || [];

	console.log(dataWithIDSerial, "dataWithIDSerial");

	const handeChangeStatusItem = () => {
		mutate(
			{positionId: selectedItem.id, hospitalId: 29},
			{
				onSuccess: () => {
					showToast(
						"success",
						`Data jabatan "${selectedItem.name}" berhasil di ${
							selectedItem.status ? "non-aktifkan" : "aktifkan"
						}`,
						3000,
					);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
					setSelectedItem({id: null, name: null});
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	console.log(selectedItem);

	return (
		<DashboardLayout>
			<>
				<ModalConfirmation
					description={`Apakah anda yakin ingin  ${
						selectedItem.status ? "non-aktifkan" : "aktifkan"
					} data konsultasi "${selectedItem.name}"?`}
					loading={isUpdatingData}
					visible={showDeleteModal}
					onApprove={handeChangeStatusItem}
					onClose={() => setShowDeleteModal(false)}
					onReject={() => setShowDeleteModal(false)}
				/>
				<div class="text-bold text-dark mb-4">{`Data Konsultasi - ${selectedHospital.Hospital.name}`}</div>
				<SearchBox
					rightElement={
						<ButtonAddTable
							text="Manage Konsultasi"
							onClick={() => history.push("/hospital/consultation/add")}
						/>
					}
					onSearch={keyword => {
						setSearchKeyword(keyword);
						setDataFilter(prevState => ({...prevState, page: 1, search: keyword}));
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={data?.data?.currentPage}
					data={dataWithIDSerial}
					limit={dataFilter.limit}
					loading={isLoading}
					striped={true}
					title="Data Jabatan"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setDataFilter(prevState => ({...prevState, limit: limitValue, page: 1, search: searchKeyword}));
					}}
					onPageChange={selectedPage => setDataFilter(prevState => ({...prevState, page: selectedPage}))}
				/>
			</>
		</DashboardLayout>
	);
}
