import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY, QUERY_OPTIONS} from "./useQueryDivisi";

export function useAddDivision(options = {}) {
	return useMutation(values => axios.post("/division/create", values).then(res => res.data));
}
export function useEditDivision(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/division/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
			queryClient.invalidateQueries(QUERY_OPTIONS);
			queryClient.removeQueries(QUERY_OPTIONS);
		},
	});
}

export function useDeleteDivision(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/division/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
			queryClient.invalidateQueries(QUERY_OPTIONS);
			queryClient.removeQueries(QUERY_OPTIONS);
		},
	});
}
