/* eslint-disable no-unused-vars */
import * as Yup from "yup";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

export const phoneRegExp = /^\+?([ -]?\d+)+|\(\d+\)([ -]\d+)$/g;
const websiteUrlRegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/g;
const noScpecialCharRegExp = /[^a-zA-Z0-9]/g;
const numberOnlyRegExp = /[^0-9]/g;
const FILE_SIZE = 5242880; // 5MB

export const AddHostpitalFormSchemaValidation = Yup.object().shape({
	name: Yup.string().required("Nama Rumah Sakit wajib di isi"),
	address: Yup.string().required("Alamat wajib di isi"),
	shippingAddress: Yup.string().required("Alamat Pengiriman wajib di isi"),
	email: Yup.string().email("Email tidak valid").required("Email wajib di isi"),
	phone: Yup.string()
		.required("Nomor Telepon wajib di isi")
		.min(8, "Nomor Telepon tidak valid")
		.max(15, "Nomor Telepon tidak valid")
		.matches(phoneRegExp, "Nomor Telepon tidak valid"),
	fax: Yup.string().required("Fax wajib di isi"),
	website: Yup.string().required("Website wajib di isi").matches(websiteUrlRegExp, "Website tidak valid"),
	bankProvider: Yup.string().required("Nama Bank wajib di isi"),
	bankAccountNumber: Yup.string().required("Nomor Rekening wajib di isi"),
	bankAccountHolder: Yup.string().required("Nama Pemegang Rekening wajib di isi"),
	npw: Yup.string().required("NPWP wajib di isi").min(15, "NPWP tidak valid").max(16, "NPWP tidak valid"),
	// npw: Yup.string().required("NPWP wajib di isi").min(20, "NPWP tidak valid").max(21, "NPWP tidak valid"),
	logo: Yup.mixed()
		.required("Logo wajib di upload")
		.test("fileFormat", "Format logo tidak support, hanya menerima format .JPG .JPEG .PNG", value => {
			if (typeof value === "string") {
				return true;
			} else {
				return SUPPORTED_FORMATS.includes(value?.type);
			}
		})
		.test("fileSize", "Ukuran file untuk logo maksimal 5 MB", value => {
			if (typeof value === "string") {
				return true;
			} else {
				return value?.size <= FILE_SIZE;
			}
		}),
});
