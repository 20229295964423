import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

export function useBookingEdit(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(values => axios.post("/booking/update", values).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("patients");
			queryClient.removeQueries("patients");
		},
	});
}
