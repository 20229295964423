/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-key */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {useState, useRef, useEffect, useCallback} from "react";
import {useHistory} from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import clsx from "clsx";
import {Formik, Field, FieldArray} from "formik";
import * as Yup from "yup";
import {DashboardLayout} from "components/Layout";
import {ModalContainer} from "components/Modal";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";
import {BasicDropdown} from "components/Dropdown";
import {TimePicker} from "components/TimePicker";
import {useHospitalsOptions} from "hooks/SuperAdmin/Hospital";
import {useKonsultasiOptions} from "hooks/SuperAdmin/Konsultasi";
import {useAddSchedule, useDisabledTimeOptions} from "hooks/Doctor/Schedule";
import {useSelector} from "react-redux";
import {convertToCurency} from "utils/converter";
import moment from "moment";

const FormSchemaValidation = Yup.object().shape({
	type: Yup.string().required("Tipe konsultasi wajib di isi"),
	hospitalId: Yup.string(),
	// consultationId: Yup.string().nullable().required("Konsultasi wajib di isi"),
	schedules: Yup.array().of(
		Yup.object().shape({
			day: Yup.number().required("Hari praktik wajib di isi"),
			times: Yup.array().of(
				Yup.object().shape({
					startTime: Yup.string().required("Jam mulai wajib di isi"),
					endTime: Yup.string()
						.required("Jam selesai wajib di isi")
						.test("lessThanStartTime", "Jam selesai tidak boleh mendahului jam mulai", function (value) {
							const startTimeHour = parseInt(this.parent.startTime?.split(":")[0]);
							const startTimeMinute = parseInt(this.parent.startTime?.split(":")[1]);
							const endTimeHour = parseInt(value?.split(":")[0]);
							const endTimeMinute = parseInt(value?.split(":")[1]);

							if (endTimeHour < startTimeHour) return false;
							if (endTimeHour === startTimeHour && endTimeMinute < startTimeMinute) return false;

							return true;
						}),
					quota: Yup.string().required("Kuota wajib di isi"),
				}),
			),
			prices: Yup.array().of(
				Yup.object().shape({
					price: Yup.string().required("Harga wajib di isi"),
				}),
			),
		}),
	),
});
const FormInitialValues = {
	userId: "",
	type: "online",
	hospitalId: "",
	consultationId: null,
	schedules: [
		{
			day: 1,
			times: [{startTime: "", endTime: "", quota: ""}],
			prices: [{price: 0, str_price: "0"}],
		},
	],
};

const FormGenerateScheduleSchemaValidation = Yup.object().shape({
	slot: Yup.number().integer().required("Slot wajib diisi"),
	startTime: Yup.string().required("Jam mulai wajib diisi"),
	endTime: Yup.string()
		.required("Jam selesai wajib di isi")
		.test("lessThanStartTime", "Jam selesai tidak boleh mendahului jam mulai", function (value) {
			const startTimeHour = parseInt(this.parent.startTime?.split(":")[0]);
			const startTimeMinute = parseInt(this.parent.startTime?.split(":")[1]);
			const endTimeHour = parseInt(value?.split(":")[0]);
			const endTimeMinute = parseInt(value?.split(":")[1]);

			if (endTimeHour < startTimeHour) return false;
			if (endTimeHour === startTimeHour && endTimeMinute < startTimeMinute) return false;

			return true;
		}),
	restStartTime: Yup.string().test(
		"lessThanStartTime",
		"Jam mulai istirahat tidak boleh mendahului jam mulai atau lebih dari jam selesai praktik",
		function (value) {
			const startTimeHour = parseInt(this.parent.startTime?.split(":")[0]);
			const startTimeMinute = parseInt(this.parent.startTime?.split(":")[1]);
			const endTimeHour = parseInt(this.parent.endTime?.split(":")[0]);
			const endTimeMinute = parseInt(this.parent.endTime?.split(":")[1]);
			const startRestTimeHour = parseInt(value?.split(":")[0]);
			const startRestTimeMinute = parseInt(value?.split(":")[1]);

			if (startRestTimeHour < startTimeHour) return false;
			if (startRestTimeHour === startTimeHour && startRestTimeMinute < startTimeMinute) return false;
			if (startRestTimeHour > endTimeHour) return false;
			if (startRestTimeHour === startTimeHour && startRestTimeMinute > endTimeMinute) return false;

			return true;
		},
	),
	restEndTime: Yup.string().test(
		"lessThanStartTime",
		"Jam selesai istirahat tidak boleh mendahului jam mulai",
		function (value) {
			const restStartTimeHour = parseInt(this.parent.restStartTime?.split(":")[0]);
			const restStartTimeMinute = parseInt(this.parent.restStartTime?.split(":")[1]);
			const endTimeHour = parseInt(this.parent.endTime?.split(":")[0]);
			const endTimeMinute = parseInt(this.parent.endTime?.split(":")[1]);
			const restEndTimeHour = parseInt(value?.split(":")[0]);
			const restEndTimeMinute = parseInt(value?.split(":")[1]);

			if (restEndTimeHour < restStartTimeHour) return false;
			if (restEndTimeHour === restStartTimeHour && restEndTimeMinute < restStartTimeMinute) return false;
			if (restEndTimeHour > endTimeHour) return false;
			if (restEndTimeHour === endTimeHour && restEndTimeMinute > endTimeMinute) return false;

			return true;
		},
	),
	quota: Yup.number().required("Kuota wajib diisi"),
});

const DayOptions = [
	{
		value: 1,
		label: "Senin",
	},
	{
		value: 2,
		label: "Selasa",
	},
	{
		value: 3,
		label: "Rabu",
	},
	{
		value: 4,
		label: "Kamis",
	},
	{
		value: 5,
		label: "Jum'at",
	},
	{
		value: 6,
		label: "Sabtu",
	},
	{
		value: 7,
		label: "Minggu",
	},
];

export function ScheduleAddPage() {
	const [scheduleIndexToGenerate, setScheduleIndexToGenerate] = useState(null);
	const [errorGenerate, setErrorGenerate] = useState(null);
	const [openModalCopy, setOpenModalCopy] = useState(false);
	const [copyFrom, setCopyFrom] = useState(1);
	const [copyTo, setCopyTo] = useState(1);
	const [isCopy, setIsCopy] = useState(false);
	const history = useHistory();
	const userProfile = useSelector(state => state.AuthReducer.profile);
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);
	const [disabledHours, setDisabledHours] = useState([]);
	const [disabledHourMinutes, setDisabledHourMinutes] = useState([]);
	const [priceToEdit, setPriceToEdit] = useState("");
	const [strPriceToEdit, setStrPriceToEdit] = useState("");
	const [priceNumber, setPriceNumber] = useState("");
	const [strPriceNumber, setStrPriceNumber] = useState("");
	const {showToast} = useToast();
	const [availableHospital, setAvailableHospital] = useState([]);
	const {mutate, isLoading} = useAddSchedule();
	const [locationOptions, setLocationOptions] = useState([]);
	const [consultationOptions, setConsultationOptions] = useState([]);
	const formikRef = useRef();
	const formikGenerateRef = useRef();

	const {hospitalOptions: locations, isLoading: isLoadingLocationOptions} = useHospitalsOptions();
	const {data: consultations, isLoading: isLoadingConsultationOptions} = useKonsultasiOptions();
	const {disabledTimesPerDay, isLoading: isLoadingDisabledTimesOptions} = useDisabledTimeOptions(
		userProfile.id,
		selectedHospital.Hospital.id,
	);
	const handleSubmit = formData => {
		if (formData.type === "online") formData.hospitalId = undefined;
		mutate(formData, {
			onSuccess: () => {
				showToast("success", "Data berhasil ditambahkan", 3000);
				history.push("/doctor/schedule/list");
			},
			onError: res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
			},
		});
	};

	useEffect(() => {
		formikRef?.current?.setFieldValue("userId", parseInt(userProfile.id) || null);
		let idHospitals = [];

		userProfile?.staff?.rsMany.forEach(item => {
			if (item.hospitalId && item.roleId == 2) idHospitals.push(item.hospitalId);
		});

		setAvailableHospital([...idHospitals]);
	}, [userProfile]);

	useEffect(() => {
		if (locations.length) {
			const hospital = locations[0].value;
			const dataLocations = locations.filter(location => availableHospital.includes(parseInt(location.value)));

			setLocationOptions([...dataLocations]);
			formikRef?.current.setFieldValue("hospitalId", hospital);
		}
	}, [locations, availableHospital]);

	useEffect(() => {
		if (consultations?.success) {
			const dataConsultationOptions = consultations?.data?.rows?.map(consultation => ({
				value: consultation?.id + "",
				label: `${consultation?.name}`,
			}));

			setConsultationOptions([...dataConsultationOptions]);
			// formikRef?.current.setFieldValue("consultationId", dataConsultationOptions[0].value);
		}
	}, [consultations]);

	const getDisabledHours = day => {
		const disabledTime = disabledTimesPerDay.filter(item => item.day == day)?.[0];

		return disabledTime?.disabledHours || [];
	};

	const getDisabledHourMinutes = day => {
		const disabledTime = disabledTimesPerDay.filter(item => item.day == day)?.[0];

		return disabledTime?.disabledHourMinutes || [];
	};

	const savePrice = () => {
		formikRef?.current.setFieldValue(priceToEdit, priceNumber);
		formikRef?.current.setFieldValue(strPriceToEdit, strPriceNumber);

		setPriceNumber("");
		setPriceToEdit("");
		setStrPriceNumber("");
		setStrPriceToEdit("");
	};

	const closeModal = () => {
		setPriceNumber("");
		setPriceToEdit("");
		setStrPriceNumber("");
		setStrPriceToEdit("");
	};

	const closeModalGenerate = () => {
		setScheduleIndexToGenerate(null);
	};

	const closeModalCopy = () => {
		setOpenModalCopy(false);
	};

	const handleGenerateSchedule = formValue => {
		const currentDate = moment().format("MM/DD/yyyy");
		const {slot, restStartTime, restEndTime, startTime, endTime, quota, buffer} = formValue;

		const startTimeHour = parseInt(startTime.split(":")[0]);
		const startTimeMinute = parseInt(startTime.split(":")[1]);
		const startTimeInMinute = startTimeHour * 60 + startTimeMinute;

		const endTimeHour = parseInt(endTime.split(":")[0]);
		const endTimeMinute = parseInt(endTime.split(":")[1]);
		const endTimeInMinute = endTimeHour * 60 + endTimeMinute;

		const restStartTimeHour = restStartTime ? parseInt(restStartTime.split(":")[0]) : 0;
		const restStartTimeMinute = restStartTime ? parseInt(restStartTime.split(":")[1]) : 0;
		const restStartTimeInMinute = restStartTimeHour * 60 + restStartTimeMinute;

		const restEndTimeHour = restEndTime ? parseInt(restEndTime.split(":")[0]) : 0;
		const restEndTimeMinute = restEndTime ? parseInt(restEndTime.split(":")[1]) : 0;
		const restEndTimeInMinute = restEndTimeHour * 60 + restEndTimeMinute;

		// if (restStartTimeInMinute && (restStartTimeInMinute - startTimeInMinute) % slot !== 0) {
		// 	setErrorGenerate("Jam mulai istirahat harus sesuai dengan pembagian slot");
		// 	setTimeout(() => {
		// 		setErrorGenerate(null);
		// 	}, 3000);

		// 	return null;
		// }

		// if (restEndTimeInMinute && (restEndTimeInMinute - endTimeInMinute) % slot !== 0) {
		// 	setErrorGenerate(
		// 		"Selisih jam selesai istirahat dan jam selesai praktik harus sesuai dengan pembagian slot",
		// 	);
		// 	setTimeout(() => {
		// 		setErrorGenerate(null);
		// 	}, 3000);

		// 	return null;
		// }

		// if (!restStartTimeInMinute && !restEndTimeInMinute && (startTimeInMinute - endTimeInMinute) % slot !== 0) {
		// 	setErrorGenerate("Selisih jam mulai praktik dan jam selesai praktik harus sesuai dengan pembagian slot");
		// 	setTimeout(() => {
		// 		setErrorGenerate(null);
		// 	}, 3000);

		// 	return null;
		// }

		const hourCountInSlot = Math.floor(slot / 60);
		const minuteCountInSlot = Math.floor(slot % 60);

		let firstEndTimeHour = startTimeHour + hourCountInSlot;
		let firstEndTimeMinute = startTimeMinute + minuteCountInSlot;

		if (firstEndTimeMinute === 60) {
			firstEndTimeHour += 1;
			firstEndTimeMinute = 0;
		} else if (firstEndTimeMinute > 59) {
			firstEndTimeHour += 1;
			firstEndTimeMinute = firstEndTimeMinute % 60;
		}

		const schedules = [
			{
				startTime,
				endTime: `${
					firstEndTimeHour.toString().length > 1
						? firstEndTimeHour.toString()
						: "0" + firstEndTimeHour.toString()
				}:${
					firstEndTimeMinute.toString().length > 1
						? firstEndTimeMinute.toString()
						: "0" + firstEndTimeMinute.toString()
				}`,
				quota,
			},
		];
		let lastIndex = 0;

		if (!restStartTimeInMinute && !restEndTimeInMinute) {
			let timeRange = endTimeInMinute - startTimeInMinute;

			while (timeRange > slot) {
				const lastSlotEndHour = parseInt(schedules[lastIndex].endTime.split(":")[0]);
				const lastSlotEndMinute = parseInt(schedules[lastIndex].endTime.split(":")[1]);
				let hourToInsert = lastSlotEndHour + hourCountInSlot;
				let minuteToInsert = lastSlotEndMinute + minuteCountInSlot;

				if (minuteToInsert === 60) {
					hourToInsert += 1;
					minuteToInsert = 0;
				} else if (minuteToInsert > 59) {
					hourToInsert += 1;
					minuteToInsert = minuteToInsert % 60;
				}

				const hourToInsertInMinute = hourToInsert * 60 + minuteToInsert;

				if (hourToInsertInMinute <= endTimeInMinute) {
					// normalization
					hourToInsert = hourToInsert.toString();
					minuteToInsert = minuteToInsert.toString();

					if (hourToInsert.length < 2) hourToInsert = `0${hourToInsert}`;
					if (minuteToInsert.length < 2) minuteToInsert = `0${minuteToInsert}`;

					schedules.push({
						startTime: moment(`${currentDate} ${schedules[lastIndex].endTime}`)
							.add("minutes", buffer)
							.format("HH:mm"),
						endTime: moment(`${currentDate} ${hourToInsert}:${minuteToInsert}`)
							.add("minutes", buffer)
							.format("HH:mm"),
						quota,
					});
				}

				timeRange -= slot + buffer;
				lastIndex += 1;
			}
		} else {
			let timeRangeBeforeRest = restStartTimeInMinute - startTimeInMinute;

			while (timeRangeBeforeRest > slot) {
				const lastSlotEndHour = parseInt(schedules[lastIndex].endTime.split(":")[0]);
				const lastSlotEndMinute = parseInt(schedules[lastIndex].endTime.split(":")[1]);
				let hourToInsert = lastSlotEndHour + hourCountInSlot;
				let minuteToInsert = lastSlotEndMinute + minuteCountInSlot;

				if (minuteToInsert === 60) {
					hourToInsert += 1;
					minuteToInsert = 0;
				} else if (minuteToInsert > 59) {
					hourToInsert += 1;
					minuteToInsert = minuteToInsert % 60;
				}

				const hourToInsertInMinute = hourToInsert * 60 + minuteToInsert;

				if (hourToInsertInMinute <= restStartTimeInMinute) {
					// normalization
					hourToInsert = hourToInsert.toString();
					minuteToInsert = minuteToInsert.toString();

					if (hourToInsert.length < 2) hourToInsert = `0${hourToInsert}`;
					if (minuteToInsert.length < 2) minuteToInsert = `0${minuteToInsert}`;

					schedules.push({
						startTime: moment(`${currentDate} ${schedules[lastIndex].endTime}`)
							.add("minutes", buffer)
							.format("HH:mm"),
						endTime: moment(`${currentDate} ${hourToInsert}:${minuteToInsert}`)
							.add("minutes", buffer)
							.format("HH:mm"),
						quota,
					});
					lastIndex += 1;
				}
				timeRangeBeforeRest -= slot + buffer;
			}

			let timeRangeAfterRest = endTimeInMinute - restEndTimeInMinute;

			let indexAfterRest = 0;

			while (timeRangeAfterRest >= slot) {
				const lastSlotEndHour =
					indexAfterRest < 1
						? parseInt(restEndTime.split(":")[0])
						: parseInt(schedules[lastIndex].endTime.split(":")[0]);
				const lastSlotEndMinute =
					indexAfterRest < 1
						? parseInt(restEndTime.split(":")[1])
						: parseInt(schedules[lastIndex].endTime.split(":")[1]);

				let hourToInsert = lastSlotEndHour + hourCountInSlot;
				let minuteToInsert = lastSlotEndMinute + minuteCountInSlot;

				if (minuteToInsert === 60) {
					hourToInsert += 1;
					minuteToInsert = 0;
				} else if (minuteToInsert > 59) {
					hourToInsert += 1;
					minuteToInsert = minuteToInsert % 60;
				}

				const hourToInsertInMinute = hourToInsert * 60 + minuteToInsert;

				if (hourToInsertInMinute <= endTimeInMinute) {
					// normalization
					hourToInsert = hourToInsert.toString();
					minuteToInsert = minuteToInsert.toString();

					if (hourToInsert.length < 2) hourToInsert = `0${hourToInsert}`;
					if (minuteToInsert.length < 2) minuteToInsert = `0${minuteToInsert}`;

					schedules.push({
						startTime: moment(
							`${currentDate} ${indexAfterRest < 1 ? restEndTime : schedules[lastIndex].endTime}`,
						)
							.add("minutes", indexAfterRest < 1 ? 0 : buffer)
							.format("HH:mm"),
						endTime: moment(`${currentDate} ${hourToInsert}:${minuteToInsert}`)
							.add("minutes", indexAfterRest < 1 ? 0 : buffer)
							.format("HH:mm"),
						quota,
					});
				}
				timeRangeAfterRest -= slot + buffer;
				lastIndex += 1;
				indexAfterRest += 1;
			}
		}

		formikRef.current.setFieldValue(`schedules.${scheduleIndexToGenerate}.times`, []);
		formikRef.current.setFieldValue(`schedules.${scheduleIndexToGenerate}.times`, schedules);
		// formikGenerateRef.current.resetForm();
		closeModalGenerate();
	};

	const handleCopySchedule = (day, dayToCopy, scheduleIndex) => {
		const selectedSchedule = formikRef.current.values.schedules.filter(item => item.day == dayToCopy)[0];

		if (selectedSchedule && isCopy) {
			formikRef.current.setFieldValue(`schedules.${scheduleIndex}`, {
				...selectedSchedule,
				day,
			});
		} else {
			formikRef.current.setFieldValue(`schedules.${scheduleIndex}`, {
				day,
				times: [{startTime: "", endTime: "", quota: ""}],
				prices: [{price: "", str_price: ""}],
			});
		}

		setCopyFrom(1);
		setCopyTo(1);
		setIsCopy(false);
		setOpenModalCopy(false);
	};

	return (
		<>
			<ModalContainer visible={openModalCopy}>
				<div className="box-popup w-50" id="change-price">
					<div className="d-flex justify-content-between align-items-center mb-4">
						<div className="text-bold text-dark">Salin Jadwal Praktik</div>
						<div className="for-close">
							<button className="btn btn-close" type="button" onClick={closeModalCopy} />
						</div>
					</div>
					<div className="popup-content">
						<div class="row gx-2 mb-5">
							<div class="col-sm-12 col-12">
								<div class="setting-item">
									<label class="setting-label">Salin Hari?</label>
									<div class="row">
										<div className="col-sm-2 col-2">
											<label>
												<input
													checked={!isCopy}
													className="me-1"
													name="isCopy"
													placeholder=""
													type="radio"
													onChange={e => {
														setIsCopy(false);
													}}
												/>
												Tidak
											</label>
										</div>
										<div class="col-sm-2 col-2">
											<label>
												<input
													checked={isCopy}
													className="me-1"
													name="isCopy"
													placeholder=""
													type="radio"
													onChange={e => {
														setIsCopy(true);
													}}
												/>
												Ya
											</label>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-12 col-lg-6">
								<div class="setting-item">
									<label class="setting-label required">Hari</label>
									<BasicDropdown
										className={"form-control"}
										data={DayOptions}
										name="copyTo"
										value={copyTo}
										onSelect={fieldValue => {
											setCopyTo(fieldValue);
										}}
									/>
								</div>
							</div>
							<div class={!isCopy ? "col-sm-12 col-lg-6 d-none" : "col-sm-12 col-lg-6"}>
								<div class="setting-item">
									<label class="setting-label">Salin Dari Hari</label>
									<BasicDropdown
										className={"form-control"}
										data={DayOptions}
										name="copyFrom"
										value={copyFrom}
										onSelect={fieldValue => {
											setCopyFrom(fieldValue);
										}}
									/>
								</div>
							</div>
							<div class="col-12 text-center mt-5">
								<button
									className="btn btn-orange r8"
									type="button"
									onClick={() =>
										handleCopySchedule(
											copyTo,
											copyFrom,
											formikRef.current?.values?.schedules?.length,
										)
									}>
									Tambah
								</button>
							</div>
						</div>
					</div>
				</div>
			</ModalContainer>
			<ModalContainer visible={scheduleIndexToGenerate !== null}>
				<div className="box-popup w-50" id="change-price">
					<div className="d-flex justify-content-between align-items-center mb-4">
						<div className="text-bold text-dark">Generate Jadwal Praktik</div>
						<div className="for-close">
							<button className="btn btn-close" type="button" onClick={closeModalGenerate} />
						</div>
					</div>

					<div className="popup-content">
						<div className={errorGenerate ? "alert alert-danger" : "d-none"} role="alert">
							{errorGenerate}
						</div>
						<Formik
							initialValues={{
								slot: "",
								buffer: 0,
								restStartTime: "",
								restEndTime: "",
								startTime: "",
								endTime: "",
								quota: "",
							}}
							innerRef={formikGenerateRef}
							validationSchema={FormGenerateScheduleSchemaValidation}
							onSubmit={handleGenerateSchedule}>
							{({
								handleSubmit,
								handleBlur,
								handleChange,
								values,
								errors,
								touched,
								setFieldValue,
								setFieldTouched,
							}) => (
								<>
									<div class="row gx-2 mb-5">
										<div class="col-sm-6 col-lg-6">
											<div class="setting-item">
												<label class="setting-label required">Slot</label>
												<small className="text-grey ms-2">In minute</small>
												<input
													class={clsx(
														"form-control",
														errors?.slot && touched?.slot && "form-control-error",
													)}
													disabled={isLoading}
													name="slot"
													placeholder=""
													type="text"
													value={values?.slot}
													onBlur={handleBlur}
													onChange={e => {
														const value = e.target.value.replace(/\D/g, "");

														value && setFieldValue("slot", parseInt(value));
													}}
												/>
												{errors.slot && touched.slot && (
													<p className="form-error-item-message">{errors.slot}</p>
												)}
											</div>
										</div>
										<div class="col-sm-6 col-lg-6">
											<div class="setting-item">
												<label class="setting-label">Buffer</label>
												<small className="text-grey ms-2">In minute</small>
												<input
													class={clsx(
														"form-control",
														errors?.buffer && touched?.buffer && "form-control-error",
													)}
													disabled={isLoading}
													name="buffer"
													placeholder=""
													type="text"
													value={values?.buffer}
													onBlur={handleBlur}
													onChange={e => {
														const value = e.target.value.replace(/\D/g, "");

														value && setFieldValue("buffer", parseInt(value));
													}}
												/>
												{errors.buffer && touched.buffer && (
													<p className="form-error-item-message">{errors.buffer}</p>
												)}
											</div>
										</div>
										<div class="col-sm-6 col-6">
											<div class="setting-item">
												<label class="setting-label required">Jam Mulai</label>
												<TimePicker
													disabled={isLoading}
													value={values?.startTime}
													onChange={fieldValue => {
														setFieldTouched(`startTime`, true);
														setFieldValue(`startTime`, fieldValue?.format("HH:mm"));
													}}
												/>
												{errors?.startTime && touched?.startTime && (
													<p className="form-error-item-message">{errors?.startTime}</p>
												)}
											</div>
										</div>
										<div class="col-sm-6 col-6">
											<div class="setting-item">
												<label class="setting-label required">Jam Selesai</label>
												<TimePicker
													disabled={isLoading}
													value={values?.endTime}
													onChange={fieldValue => {
														setFieldTouched(`endTime`, true);
														setFieldValue(`endTime`, fieldValue?.format("HH:mm"));
													}}
												/>
												{errors?.endTime && touched?.endTime && (
													<p className="form-error-item-message">{errors?.endTime}</p>
												)}
											</div>
										</div>
										<div class="col-sm-6 col-6">
											<div class="setting-item">
												<label class="setting-label">Jam Mulai Istirahat</label>
												<TimePicker
													disabled={isLoading}
													value={values?.restStartTime}
													onChange={fieldValue => {
														setFieldTouched(`restStartTime`, true);
														setFieldValue(`restStartTime`, fieldValue?.format("HH:mm"));
													}}
												/>
												{errors?.restStartTime && touched?.restStartTime && (
													<p className="form-error-item-message">{errors?.restStartTime}</p>
												)}
											</div>
										</div>
										<div class="col-sm-6 col-6">
											<div class="setting-item">
												<label class="setting-label">Jam Selesai Istirahat</label>
												<TimePicker
													disabled={isLoading}
													value={values?.restEndTime}
													onChange={fieldValue => {
														setFieldTouched(`restEndTime`, true);
														setFieldValue(`restEndTime`, fieldValue?.format("HH:mm"));
													}}
												/>
												{errors?.restEndTime && touched?.restEndTime && (
													<p className="form-error-item-message">{errors?.restEndTime}</p>
												)}
											</div>
										</div>
										<div class="col-sm-12 col-lg-6">
											<div class="setting-item">
												<label class="setting-label required">Kuota</label>
												<input
													class={clsx(
														"form-control",
														errors?.quota && touched?.quota && "form-control-error",
													)}
													disabled={isLoading}
													name="quota"
													placeholder=""
													type="text"
													value={values?.quota}
													onBlur={handleBlur}
													onChange={e => {
														const value = e.target.value.replace(/\D/g, "");

														setFieldValue("quota", value);
													}}
												/>
												{errors.quota && touched.quota && (
													<p className="form-error-item-message">{errors.quota}</p>
												)}
											</div>
										</div>
									</div>
									<div class="col-12 text-center">
										<button
											className="btn btn-orange r8"
											disabled={!FormGenerateScheduleSchemaValidation.isValidSync(values)}
											type="button"
											onClick={handleSubmit}>
											Generate
										</button>
									</div>
								</>
							)}
						</Formik>
					</div>
				</div>
			</ModalContainer>
			<ModalContainer visible={priceToEdit}>
				<div className="box-popup w430" id="change-price">
					<div className="d-flex justify-content-between align-items-center mb-4">
						<div className="text-bold text-dark">Ubah Harga</div>
						<div className="for-close">
							<button className="btn btn-close" type="button" onClick={closeModal} />
						</div>
					</div>

					<div className="popup-content">
						<div className="ft-12 mb-2 text-light-grey text-bold">Masukkan harga</div>
						<div className="position-relative mb-3">
							<input
								className="form-control ns"
								placeholder="Rp"
								type="text"
								value={strPriceNumber}
								onChange={e => {
									let value = e.target.value.replace(/\D/g, "");

									setPriceNumber(value);

									value = convertToCurency(value);

									setStrPriceNumber(value);
								}}
							/>
						</div>
						<button className="btn btn-orange r8 w-100" type="button" onClick={savePrice}>
							Simpan
						</button>
					</div>
				</div>
			</ModalContainer>
			<DashboardLayout backTo={"/doctor/schedule/list"} title="Buat Jadwal Praktik">
				<Formik
					initialValues={FormInitialValues}
					innerRef={formikRef}
					validationSchema={FormSchemaValidation}
					onSubmit={handleSubmit}>
					{({
						handleSubmit,
						handleBlur,
						handleChange,
						values,
						errors,
						touched,
						setFieldValue,
						setFieldTouched,
					}) => (
						<div className="ws p15 mb-3">
							<div className="row">
								<div className="col-xl-8">
									<div className="box-white ws p24 mb-3">
										<div className="row">
											<div className="col-sm-6 mb-3">
												<div className="text-dark mb-3">Tipe Konsultasi</div>
												<div className="row">
													<div className="col-6">
														<div className="form-check">
															<label>
																<Field
																	className="me-2 form-check-input"
																	name="type"
																	type="radio"
																	value="online"
																	onChange={() => {
																		setFieldValue("type", "online");
																		setFieldTouched("type", true);
																		setFieldValue("hospitalId", null);
																	}}
																/>
																Online
															</label>
														</div>
													</div>
													<div className="col-6">
														<div className="form-check">
															<label>
																<Field
																	className="me-2 form-check-input"
																	name="type"
																	type="radio"
																	value="offline"
																	onChange={() => {
																		setFieldValue("type", "offline");
																		setFieldTouched("type", true);
																		setFieldValue(
																			"hospitalId",
																			locationOptions?.[0]?.value,
																		);
																	}}
																/>
																Offline
															</label>
														</div>
													</div>
												</div>
											</div>
											<div className="col-6 mb-3">
												<div className={values.type === "online" ? "d-none" : ""}>
													<div className="ft-12 text-light-grey mb-2">Lokasi Praktik</div>
													<div className="form-filter position-relative">
														<BasicDropdown
															className={clsx(
																"form-control",
																errors?.hospitalId && "form-control-error",
															)}
															data={locationOptions}
															name={`hospitalId`}
															value={values?.hospitalId}
															onSelect={fieldValue => {
																setFieldValue(
																	`hospitalId`,
																	parseInt(fieldValue) || null,
																);
															}}
														/>

														<div className="icon-right">
															<span className="icon-ico-chev-down text-orange" />
														</div>
													</div>
													{errors?.hospitalId && touched?.hospitalId && (
														<p className="form-error-item-message">{errors?.hospitalId}</p>
													)}
												</div>
											</div>
											<div className="col-sm-6 mb-3 d-none">
												<div className="ft-12 text-light-grey mb-2">Pilih Konsultasi</div>
												<div className="form-filter position-relative">
													<BasicDropdown
														className={clsx(
															"form-control",
															errors?.consultationId && "form-control-error",
														)}
														data={consultationOptions}
														name={`consultationId`}
														value={values?.consultationId}
														onSelect={fieldValue => {
															setFieldValue(
																`consultationId`,
																parseInt(fieldValue) || null,
															);
														}}
													/>

													<div className="icon-right">
														<span className="icon-ico-chev-down text-orange" />
													</div>
												</div>
												{errors?.consultationId && touched?.consultationId && (
													<p className="form-error-item-message">{errors?.consultationId}</p>
												)}
											</div>
										</div>

										<hr />

										<FieldArray name="schedules">
											{({insert, remove, push}) => (
												<>
													{values.schedules.length > 0 &&
														values.schedules.map((schedule, scheduleIndex) => (
															<div className="row gx-2 align-items-center mb-4">
																<div className="col">
																	<Accordion className="mb-4">
																		<Accordion.Item className="border-0">
																			<Accordion.Header>
																				Jadwal Praktik
																			</Accordion.Header>
																			<Accordion.Body>
																				<div className="ft-12 text-light-grey mb-2">
																					Hari Praktik
																				</div>
																				<div className="position-relative">
																					<BasicDropdown
																						className={clsx(
																							"form-control",
																							errors?.schedules?.[
																								scheduleIndex
																							]?.day &&
																								"form-control-error",
																						)}
																						data={DayOptions}
																						name={`schedules.${scheduleIndex}.day`}
																						value={
																							values?.schedules?.[
																								scheduleIndex
																							]?.day
																						}
																						onSelect={fieldValue => {
																							setFieldValue(
																								`schedules.${scheduleIndex}.day`,
																								fieldValue,
																							);
																						}}
																					/>

																					<div className="icon-right">
																						<span className="icon-ico-chev-down text-orange" />
																					</div>
																				</div>
																				<div class="row mt-4 align-items-center">
																					<div className={"col-6"}>
																						<div class="setting-item">
																							<button
																								className="btn btn-green px-2 py-0 r8"
																								type="button"
																								onClick={() =>
																									setScheduleIndexToGenerate(
																										scheduleIndex,
																									)
																								}>
																								Generate Jam Praktik
																							</button>
																						</div>
																					</div>
																				</div>
																				<div
																					className="collapse show"
																					id="col-2">
																					<div className="inner-jadwal">
																						<p className="text-dark mb-2">
																							Harga
																						</p>
																						<FieldArray name="prices">
																							{({
																								insert,
																								remove,
																								push,
																							}) => (
																								<>
																									{values
																										?.schedules?.[
																										scheduleIndex
																									]?.prices.length >
																										0 &&
																										values?.schedules?.[
																											scheduleIndex
																										]?.prices.map(
																											(
																												price,
																												priceIndex,
																											) => (
																												<div className="box-harga">
																													<div className="price-value text-dark">
																														Rp.
																														{
																															price.str_price
																														}
																													</div>
																													<div className="change-price">
																														<button
																															className="btn ubah-price text-orange"
																															onClick={() => {
																																setPriceToEdit(
																																	`schedules.${scheduleIndex}.prices.${priceIndex}.price`,
																																);
																																setStrPriceToEdit(
																																	`schedules.${scheduleIndex}.prices.${priceIndex}.str_price`,
																																);
																															}}>
																															Ubah
																														</button>
																													</div>
																													<div className="d-flex align-items-center">
																														<button
																															className={`btn ft-18 p-1 text-grey ${
																																priceIndex <
																																1
																																	? "hidden"
																																	: ""
																															}`}
																															onClick={() => {
																																if (
																																	priceIndex
																																) {
																																	let priceData =
																																		values
																																			.schedules[
																																			scheduleIndex
																																		]
																																			.prices;

																																	priceData.pop();

																																	setFieldValue(
																																		`schedules.${scheduleIndex}.prices`,
																																		[
																																			...priceData,
																																		],
																																	);
																																}
																															}}>
																															<span className="icon-ico-delete" />
																														</button>
																														<button
																															className={`btn ft-18 p-1 text-orange ${
																																priceIndex <
																																values
																																	?.schedules?.[
																																	scheduleIndex
																																]
																																	?.prices
																																	?.length -
																																	1
																																	? "hidden"
																																	: ""
																															}`}
																															onClick={() =>
																																setFieldValue(
																																	`schedules.${scheduleIndex}.prices`,
																																	[
																																		...values
																																			.schedules[
																																			scheduleIndex
																																		]
																																			.prices,
																																		{
																																			price: 0,
																																			str_price:
																																				"0",
																																		},
																																	],
																																)
																															}>
																															<span className="icon-ico-plus" />
																														</button>
																													</div>
																												</div>
																											),
																										)}
																								</>
																							)}
																						</FieldArray>
																						<FieldArray name="times">
																							{({
																								insert,
																								remove,
																								push,
																							}) => (
																								<>
																									{values
																										?.schedules?.[
																										scheduleIndex
																									]?.times.length >
																										0 &&
																										values?.schedules?.[
																											scheduleIndex
																										]?.times.map(
																											(
																												time,
																												timeIndex,
																											) => (
																												<div className="row gx-2">
																													<div className="col-sm-4 mb-2">
																														<div className="ft-12 text-light-grey mb-2">
																															Jam
																															Mulai
																														</div>
																														<div className="position-relative">
																															<TimePicker
																																disabled={
																																	isLoading
																																}
																																// disabledHourMinutes={getDisabledHourMinutes(
																																// 	values
																																// 		?.schedules?.[
																																// 		scheduleIndex
																																// 	]
																																// 		?.day,
																																// )}
																																// disabledHours={getDisabledHours(
																																// 	values
																																// 		?.schedules?.[
																																// 		scheduleIndex
																																// 	]
																																// 		?.day,
																																// )}
																																styleTopIcon={
																																	25
																																}
																																value={
																																	values
																																		?.schedules?.[
																																		scheduleIndex
																																	]
																																		?.times?.[
																																		timeIndex
																																	]
																																		.startTime
																																}
																																onChange={fieldValue => {
																																	setFieldTouched(
																																		`schedules.${scheduleIndex}.times.${timeIndex}.startTime`,
																																		true,
																																	);
																																	setFieldValue(
																																		`schedules.${scheduleIndex}.times.${timeIndex}.startTime`,
																																		fieldValue?.format(
																																			"HH:mm",
																																		),
																																	);
																																}}
																															/>
																															{errors
																																?.schedules?.[
																																scheduleIndex
																															]
																																?.times?.[
																																timeIndex
																															]
																																?.startTime &&
																																touched
																																	?.schedules?.[
																																	scheduleIndex
																																]
																																	?.times?.[
																																	timeIndex
																																]
																																	?.startTime && (
																																	<p className="form-error-item-message">
																																		{
																																			errors
																																				?.schedules?.[
																																				scheduleIndex
																																			]
																																				?.times?.[
																																				timeIndex
																																			]
																																				?.startTime
																																		}
																																	</p>
																																)}
																														</div>
																													</div>
																													<div className="col-sm-4 mb-2">
																														<div className="ft-12 text-light-grey mb-2">
																															Jam
																															Selesai
																														</div>
																														<div className="position-relative">
																															<TimePicker
																																disabled={
																																	isLoading
																																}
																																disabledHourMunites={
																																	disabledHourMinutes
																																}
																																disabledHours={
																																	disabledHours
																																}
																																styleTopIcon={
																																	25
																																}
																																value={
																																	values
																																		?.schedules?.[
																																		scheduleIndex
																																	]
																																		?.times?.[
																																		timeIndex
																																	]
																																		.endTime
																																}
																																onChange={fieldValue => {
																																	setFieldTouched(
																																		`schedules.${scheduleIndex}.times.${timeIndex}.endTime`,
																																		true,
																																	);
																																	setFieldValue(
																																		`schedules.${scheduleIndex}.times.${timeIndex}.endTime`,
																																		fieldValue?.format(
																																			"HH:mm",
																																		),
																																	);
																																}}
																															/>
																														</div>
																														{errors
																															?.schedules?.[
																															scheduleIndex
																														]
																															?.times?.[
																															timeIndex
																														]
																															?.endTime &&
																															touched
																																?.schedules?.[
																																scheduleIndex
																															]
																																?.times?.[
																																timeIndex
																															]
																																?.endTime && (
																																<p className="form-error-item-message">
																																	{
																																		errors
																																			?.schedules?.[
																																			scheduleIndex
																																		]
																																			?.times?.[
																																			timeIndex
																																		]
																																			?.endTime
																																	}
																																</p>
																															)}
																													</div>
																													<div className="col-sm-3 mb-2">
																														<div className="ft-12 text-light-grey mb-2">
																															Kuota
																														</div>
																														<div className="position-relative">
																															<input
																																class={clsx(
																																	"form-control",
																																	errors
																																		?.schedules?.[
																																		scheduleIndex
																																	]
																																		?.times?.[
																																		timeIndex
																																	]
																																		?.quota &&
																																		touched
																																			?.schedules?.[
																																			scheduleIndex
																																		]
																																			?.times?.[
																																			timeIndex
																																		]
																																			?.quota &&
																																		"form-control-error",
																																)}
																																disabled={
																																	isLoading
																																}
																																name={`schedules.${scheduleIndex}.times.${timeIndex}.quota`}
																																placeholder=""
																																type="text"
																																value={
																																	values
																																		?.schedules?.[
																																		scheduleIndex
																																	]
																																		?.times?.[
																																		timeIndex
																																	]
																																		?.quota
																																}
																																onBlur={
																																	handleBlur
																																}
																																onChange={e => {
																																	const value =
																																		e.target.value.replace(
																																			/\D/g,
																																			"",
																																		);

																																	if (
																																		values
																																	) {
																																		setFieldValue(
																																			`schedules.${scheduleIndex}.times.${timeIndex}.quota`,
																																			value,
																																		);
																																	} else {
																																		setFieldValue(
																																			`schedules.${scheduleIndex}.times.${timeIndex}.quota`,
																																			undefined,
																																		);
																																	}
																																}}
																															/>
																														</div>
																														{errors
																															?.schedules?.[
																															scheduleIndex
																														]
																															?.times?.[
																															timeIndex
																														]
																															?.quota &&
																															touched
																																?.schedules?.[
																																scheduleIndex
																															]
																																?.times?.[
																																timeIndex
																															]
																																?.quota && (
																																<p className="form-error-item-message">
																																	{
																																		errors
																																			?.schedules?.[
																																			scheduleIndex
																																		]
																																			?.times?.[
																																			timeIndex
																																		]
																																			?.quota
																																	}
																																</p>
																															)}
																													</div>
																													<div className="col-sm-1 mb-2 d-flex align-items-center">
																														<div className="d-flex">
																															<button
																																className={`btn ft-18 p-1 text-grey ${
																																	timeIndex <
																																	1
																																		? "hidden"
																																		: ""
																																}`}
																																onClick={() => {
																																	if (
																																		timeIndex
																																	) {
																																		let timeData =
																																			values
																																				.schedules[
																																				scheduleIndex
																																			]
																																				.times;

																																		timeData.pop();

																																		setFieldValue(
																																			`schedules.${scheduleIndex}.times`,
																																			[
																																				...timeData,
																																			],
																																		);
																																	}
																																}}>
																																<span className="icon-ico-delete" />
																															</button>
																															<button
																																className={`btn ft-18 p-1 text-orange ${
																																	timeIndex <
																																	values
																																		?.schedules?.[
																																		scheduleIndex
																																	]
																																		?.times
																																		?.length -
																																		1
																																		? "hidden"
																																		: ""
																																}`}
																																onClick={() =>
																																	setFieldValue(
																																		`schedules.${scheduleIndex}.times`,
																																		[
																																			...values
																																				.schedules[
																																				scheduleIndex
																																			]
																																				.times,
																																			{
																																				startTime:
																																					"",
																																				endTime:
																																					"",
																																				quota: "",
																																			},
																																		],
																																	)
																																}>
																																<span className="icon-ico-plus" />
																															</button>
																														</div>
																													</div>
																												</div>
																											),
																										)}
																								</>
																							)}
																						</FieldArray>
																					</div>
																				</div>
																			</Accordion.Body>
																		</Accordion.Item>
																	</Accordion>
																</div>
																<div className="col-lg-2">
																	<button
																		className={`btn ft-18 text-grey ${
																			scheduleIndex < 1 ? "hidden" : ""
																		}`}
																		onClick={() => {
																			if (scheduleIndex) remove(scheduleIndex);
																		}}>
																		<span className="icon-ico-delete" />
																	</button>
																	<button
																		className={`btn ft-18 p-1 text-orange ${
																			values.schedules.length - 1 != scheduleIndex
																				? "hidden"
																				: ""
																		}`}
																		onClick={() => setOpenModalCopy(true)}>
																		<span className="icon-ico-plus" />
																	</button>
																</div>
															</div>
														))}
												</>
											)}
										</FieldArray>
									</div>
								</div>

								<div className="col-xl-4">
									<div className="box-white ws p24 mb-3">
										<div className="mb-3">
											<div className="ft-12 text-light-grey mb-2">Tipe Konsultasi</div>
											{(formikRef.current?.values?.type === "online" ||
												!formikRef.current?.values?.type) && (
												<div className="box-consult online">
													<span className="icon-ico-zoom me-1" /> Online
												</div>
											)}
											{formikRef.current?.values?.type === "offline" && (
												<div className="box-consult offline">
													<span className="icon-ico-hospital me-1" /> Offline
												</div>
											)}
										</div>
										<div className="mb-3">
											<div className="ft-12 text-light-grey mb-2">Lokasi Praktik</div>
											<div className="text-dark">
												{values.type === "offline"
													? locationOptions.filter(
															location => location.value == values?.hospitalId,
													  )?.[0]?.label
													: "-"}
											</div>
										</div>
										<div className="mb-3">
											<div className="ft-12 text-light-grey mb-2">Konsultasi</div>
											<div className="text-dark">
												{
													consultationOptions.filter(
														consultation => consultation.value == values?.consultationId,
													)?.[0]?.label
												}
											</div>
										</div>
									</div>
									<button
										className="btn btn-orange w-100 r8 mb-3"
										disabled={!FormSchemaValidation.isValidSync(values)}
										loading={isLoading}
										text="Save"
										onClick={handleSubmit}>
										Simpan
									</button>
								</div>
							</div>
						</div>
					)}
				</Formik>
			</DashboardLayout>
		</>
	);
}
