import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY} from "./useQueryAppointment";

export function useAddAppoinment(options = {}) {
	return useMutation(values => axios.post("/v2/booking/create", values).then(res => res.data));
}
export function useEditAppoinment(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/booking/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}

export function useDeleteAppoinment(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/booking/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}

export function useAddMedicalRecord(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/medical_record/create", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}
