import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

export const QUERY = "medicine/hospital";

export const fetchHospitalMedicineByID = id => {
	return axios.get(`/medicine/hospital/${id}`).then(res => res.data);
};

export function useDetailHospitalMedicine(id) {
	return useQuery({
		queryKey: id && [QUERY, id],
		queryFn: () => fetchHospitalMedicineByID(id),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
}

// =================

async function getHospitalMedicines(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/medicine/hospital`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/medicine/hospital${id}`);

	return data;
}

export function useListHospitalMedicine(hospitalId) {
	const [filter, filterMedicines] = useState({
		page: 1,
		search: null,
		limit: 10,
		hospitalId,
	});
	const [medicineId, medicineById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery([QUERY, filter, medicineId], async () => getHospitalMedicines(medicineId, filter));

	return {data, isLoading, isError, error, filter, filterMedicines, medicineById};
}
