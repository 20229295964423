/* eslint-disable react/jsx-sort-props */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import clsx from "clsx";
import {useMemo, useState, useRef, useEffect, forwardRef} from "react";
import {useHistory, useParams} from "react-router-dom";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {FormCard} from "components/Form";
import {useDetailAppointment} from "hooks/Doctor/Appointment";
import {BodyComponent} from "reactjs-human-body";
import {Formik, FieldArray, Field} from "formik";
import {ModalContainer} from "components/Modal";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {BasicDropdown} from "components/Dropdown";
import {useListDiagnosa} from "hooks/SuperAdmin/Diagnosa";
import {useSelector, useDispatch} from "react-redux";
import {converToLocalGender} from "utils/converter";
import {useListLayananPemeriksaanPenunjangByUnit} from "hooks/AdminHospital/LayananPemeriksaanPenunjang";
import {useTodayPrices} from "hooks/Doctor/Schedule";
import {convertToCurency} from "utils/converter";
import {useListPemeriksaanPenunjang} from "hooks/SuperAdmin/PemeriksaanPenunjang";
import {useLayananPoliklinikTindakanOptions} from "hooks/AdminHospital/LayananPoliklinikTindakan";
import {useListHospitalMedicine} from "hooks/AdminHospital/Medicine";
import {SubmitButton} from "components/Button";
import {setFormValue} from "store/actions/medicalRecordForm";
import * as Yup from "yup";
import {useAddMedicalRecord} from "hooks/Doctor/MedicalRecord";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";
import {useAppointmentByPatient} from "hooks/Doctor/Appointment";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import {useDetailPendaftaranPasien} from "hooks/PendaftaranPasien";
import {ProfileImageInitial} from "components/ProfileImage";
import {ModalViewPDF} from "components/Modal/ModalViewPdf";
import {useListFileLabByPatientId} from "hooks/AdminLaboratorium/FileLab";
import {capitalizeFirstLetter} from "utils/string";

import "react-datepicker/dist/react-datepicker.css";

const FormSchemaValidation = Yup.object().shape({
	subjective: Yup.string().nullable(),
	objective: Yup.string().nullable(),
	keluhan: Yup.string().nullable(),
	riwayat: Yup.string().nullable(),
	diagnoseId: Yup.string().nullable(),
	diagnoseDifferential: Yup.string().nullable(),
	bodyCheckups: Yup.array(),
	bodyCheckupResult: Yup.string().nullable(),
	checkupSupports: Yup.array(),
	actionDivisionId: Yup.string().nullable(),
	actionDivisionPrice: Yup.string().nullable(),
	medicines: Yup.array(),
	servicePrice: Yup.string().nullable(),
});
const FormInitialValues = {
	bookingId: null,
	subjective: "",
	objective: "",
	keluhan: "",
	riwayat: "",
	diagnoseId: null,
	diagnoseDifferential: "",
	bodyCheckups: [],
	bodyCheckupResult: "",
	checkupSupports: [
		{
			unitId: null,
			checkupCategories: [
				{
					checkupCategoryId: null,
					appointmentDate: "",
					appointmentNote: "",
					actions: [
						{
							checkupSupportHospitalId: null,
							price: null,
						},
						{
							checkupSupportHospitalId: null,
							price: null,
						},
					],
				},
			],
		},
	],
	actionDivisionId: null,
	actionDivisionPrice: null,
	actionDivisionNote: "",
	medicines: [
		{
			medicineHospitalId: null,
			total: null,
			frequency: null,
			period: null,
			note: null,
			price: null,
			totalPrice: null,
		},
	],
	nextAppointment: {
		idHospital: null,
		idUserDoctor: null,
		idUserPatient: null,
		date: "",
		startTime: "",
		endTime: "",
		type: "online",
		price: "",
	},
	servicePrice: null,
};

const CustomInputDate = forwardRef(({value, onClick}, ref) => (
	<div className="form-filter position-relative" onClick={onClick} ref={ref}>
		<input type="text" className="form-control datepicker" placeholder="Pilih tanggal " value={value} />

		<div className="icon-right">
			<span className="icon-ico-date text-orange" />
		</div>
	</div>
));

export function MedicalRecordCreatePage() {
	const dispatch = useDispatch();
	const userProfile = useSelector(state => state.AuthReducer.profile);
	const history = useHistory();
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);
	const medicalRecordForm = useSelector(state => state.MedicalRecordFormReducer.formValue);
	const timelineRef = useRef();
	const formikRef = useRef();
	const {id} = useParams();
	const {data: detailAppointment, isLoading: loadingFetcing} = useDetailAppointment(id);
	const [selectedBody, setSelectedBody] = useState([]);
	const [isLoadingBody, setIsLoadingBody] = useState(false);
	const [openModalRequestAction, setOpenModalRequestAction] = useState(false);
	const [patientId, setPatientId] = useState(null);
	const [openModalPatient, setOpenModalPatient] = useState(false);
	const {data: detailPendaftaranPasien} = useDetailPendaftaranPasien(patientId);
	const [timelineScroll, setTimelineScroll] = useState(0);
	const {data: diagnoseData, isLoading: isFethingTitles} = useListDiagnosa({
		hospitalId: selectedHospital?.Hospital?.id,
		isActive: true,
	});
	const [typeDiagnoseOptions, setDiagnoseOptions] = useState([]);
	const [checkupSupportInputs, setCheckupSupportInputs] = useState([]);
	const {data: checkupSupportData, isLoading: isLoadingCheckupSupport} = useListLayananPemeriksaanPenunjangByUnit({
		hospitalId: selectedHospital.Hospital.id,
		divisionId: selectedHospital.Hospital.divisionId,
	});
	const {data: prices} = useTodayPrices({
		hospitalId: selectedHospital.Hospital.id,
		userId: userProfile.id,
		type: "offline",
	});
	const [priceOptions, setPriceOptions] = useState([]);
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [requestModalData, setRequestModalData] = useState({
		name: null,
		checkupSupportIndex: null,
		checkupCategoryIndex: null,
	});
	const [requestActionData, setRequestActionData] = useState([]);
	const {data: actionData} = useListPemeriksaanPenunjang({hospitalId: selectedHospital.Hospital.id, isActive: true});
	const {data: actionDivisionData} = useLayananPoliklinikTindakanOptions({hospitalId: selectedHospital.Hospital.id});
	const [actionDivisionOptions, setActionDivisionOptions] = useState([]);
	const {data: medicines} = useListHospitalMedicine({hospitalId: selectedHospital.Hospital.id});
	const [medicineOptions, setMedicineOptions] = useState([]);
	const [tmpCheckupReqValue, setTmpCheckupReqValue] = useState(null);
	const [isOldPatient, setIsOldPatient] = useState(false);
	const {mutate, isLoading} = useAddMedicalRecord();
	const {showToast} = useToast();
	const {data: dataHistory} = useAppointmentByPatient(patientId);
	const {data: dataFileLab} = useListFileLabByPatientId(patientId, {page: 1, limit: 10, orderBy: "id", sort: "desc"});
	const [openModalDetail, setOpenModalDetail] = useState(false);
	const [previewDocument, setPreviewDocument] = useState({
		show: false,
		fileSrc: null,
		title: undefined,
	});
	const [detailHistory, setDetailHistory] = useState({});

	const bodyEnum = useMemo(
		() => ({
			head: "Kepala",
			left_shoulder: "Bahu Kiri",
			right_shoulder: "Bahu Kanan",
			left_arm: "Lengan Kiri",
			right_arm: "Lengan Kanan",
			chest: "Dada",
			stomach: "Perut",
			left_leg: "Paha/Betis Kiri",
			right_leg: "Paha/Betis Kanan",
			left_hand: "Tangan Kiri",
			right_hand: "Tangan Kanan",
			left_foot: "Kaki Kiri",
			right_foot: "Kaki Kanan",
		}),
		[],
	);

	const bodyValue = useMemo(
		() => ({
			Kepala: "head",
			"Bahu Kiri": "left_shoulder",
			"Bahu Kanan": "right_shoulder",
			"Lengan Kiri": "left_arm",
			"Lengan Kanan": "right_arm",
			Dada: "chest",
			Perut: "stomach",
			"Paha/Betis Kiri": "left_leg",
			"Paha/Betis Kanan": "right_leg",
			"Tangan Kiri": "left_hand",
			"Tangan Kanan": "right_hand",
			"Kaki Kiri": "left_foot",
			"Kaki Kanan": "right_foot",
		}),
		[],
	);

	useEffect(() => {
		if (detailAppointment?.success) {
			setPatientId(detailAppointment.data.patient.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailAppointment]);

	useEffect(() => {
		if (medicalRecordForm.bookingId !== id) {
			dispatch(setFormValue(FormInitialValues));
		} else {
			const selectedBodyData = [];

			medicalRecordForm.bodyCheckups.forEach(item => selectedBodyData.push(bodyValue[item.locationName]));
			setSelectedBody([...selectedBodyData]);

			setIsLoadingBody(true);
			setTimeout(() => {
				setIsLoadingBody(false);
			}, [200]);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [medicalRecordForm]);

	useEffect(() => {
		if (prices.success) {
			const priceData =
				prices?.data?.map(item => ({
					value: item,
					label: "Rp " + convertToCurency(`${item}`),
				})) || [];

			setPriceOptions([{value: null, label: "-"}, ...priceData]);
		}
	}, [prices]);

	useEffect(() => {
		if (checkupSupportData?.success) {
			const insertedId = [];
			const checkupSupportValues = [];

			checkupSupportData?.data?.units?.forEach(item => {
				checkupSupportValues.push({
					unitId: item.id,
					checkupCategories: [
						{
							checkupCategoryId: null,
							appointmentDate: "",
							appointmentNote: "",
							actions: [],
						},
					],
				});
			});

			const availableCheckupSupport = checkupSupportData?.data?.units?.map(item => ({
				...item,
				categoryOptions: [
					{
						label: "-",
						value: "",
					},
					...item.checkupCategories.map(category => ({label: category.name, value: category.id})),
				],
			}));

			if (medicalRecordForm.bookingId !== id) {
				formikRef.current?.setFieldValue("checkupSupports", checkupSupportValues);
			}

			setCheckupSupportInputs([...availableCheckupSupport]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkupSupportData, loadingFetcing]);

	useEffect(() => {
		if (actionDivisionData?.success) {
			const insertedAction = [];
			const actionDivisionConverted =
				userProfile.staff.doctorType === "1"
					? []
					: actionDivisionData?.data?.rows
							?.filter(item => {
								if (item.Action && !insertedAction.includes(item.actionId)) {
									insertedAction.push(item.actionId);

									return true;
								} else return false;
							})
							?.map(item => ({
								label: `${item.Action?.title}`,
								value: JSON.stringify({
									id: item.id,
									price: item.sellPrice,
								}),
							}));

			setActionDivisionOptions([{label: "Hanya pemeriksaan biasa", value: {}}, ...actionDivisionConverted]);
		}
	}, [actionDivisionData, userProfile]);

	useEffect(() => {
		if (medicines?.success) {
			const inserted = [];
			const medicineData = medicines?.data?.rows
				?.filter(item => selectedHospital.divisionId === item.divisionId)
				?.filter(item => {
					if (!inserted.includes(item.medicine.name)) {
						inserted.push(item.medicine.name);

						return true;
					}

					return false;
				})
				?.map(item => ({
					label: item.medicine.name,
					value: item.id,
					objectData: item,
				}));

			setMedicineOptions([{label: "-", value: null}, ...medicineData]);
		}
	}, [medicines]);

	useEffect(() => {
		function handleScroll() {
			const {scrollHeight, scrollTop, clientHeight} = timelineRef.current;

			setTimelineScroll((scrollTop / (scrollHeight - clientHeight)) * 100);
		}
		if (timelineRef && timelineRef.current) {
			timelineRef.current.addEventListener("scroll", handleScroll);

			return () => {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				timelineRef.current?.removeEventListener("scroll", handleScroll);
			};
		}
	}, []);

	useEffect(() => {
		if (diagnoseData?.success) {
			const dataDiagnoseOption = diagnoseData?.data?.rows?.map(diagnose => ({
				value: diagnose?.id + "",
				label: `${diagnose?.name}`,
			}));

			setDiagnoseOptions([{value: "", label: "-"}, ...dataDiagnoseOption]);
		}
	}, [diagnoseData]);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar list appointment" title="Detail Appointment">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	const handleSubmit = async formValue => {
		formValue = {
			...formValue,
			bookingId: id,
			nextAppointment: {
				...formValue.nextAppointment,
				idUserDoctor: detailAppointment.data.idUserDoctor,
				idUserPatient: detailAppointment.data.idUserPatient,
			},
		};

		if (!formValue.nextAppointment?.date) {
			delete formValue.nextAppointment;
		}

		mutate(formValue, {
			onSuccess: () => {
				showToast("success", "Data berhasil ditambahkan", 3000);
				history.push("/doctor/appointment/list");
			},
			onError: res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
			},
		});
	};

	return (
		<DashboardLayout>
			<ModalViewPDF
				fileSrc={previewDocument.fileSrc}
				title={previewDocument.title}
				visible={previewDocument.show}
				onClose={() => setPreviewDocument({show: false, fileSrc: null, undefined})}
			/>
			<ModalContainer visible={openModalDetail}>
				<div id="open-detail" className="box-popup w430">
					<div className="d-flex justify-content-between align-items-center mb-4">
						<div className="text-bold text-dark">Detail Rekam Medis</div>
						<div className="for-close">
							<button className="btn btn-close" type="button" onClick={() => setOpenModalDetail(false)} />
						</div>
					</div>

					<div className="popup-content scroll">
						<div className="box-border p15 mb-2">
							<div className="text-bold text-dark mb-3">Pemeriksaan Penunjang</div>
							{!dataFileLab?.data?.rows?.length && <p className="text-muted">-</p>}
							{dataFileLab?.data?.rows?.map(item => (
								<div className="position-relative mb-2">
									<input type="text" className="form-control ns pe-5" value={item.name} />
									<div className="icon-right">
										<a
											className="text-orange"
											style={{cursor: "pointer"}}
											onClick={() => {
												setOpenModalDetail(false);
												setPreviewDocument({show: true, fileSrc: item.file, title: item.name});
											}}>
											<span className="icon-ico-eye-open" />
										</a>
									</div>
								</div>
							))}
						</div>
						<div className="box-border p15 mb-2">
							<div className="text-bold text-dark mb-3">Anamnesis</div>
							<div className="mb-2">
								<div className="ft-12 text-light-grey mb-1">Keluhan utama</div>
								<div className="text-dark">{detailHistory?.medicalRecord?.keluhan || "-"}</div>
							</div>
							<div className="mb-2">
								<div className="ft-12 text-light-grey mb-1">Riwayat penyakit</div>
								<div className="text-dark">{detailHistory?.medicalRecord?.riwayat || "-"}</div>
							</div>
							<hr />
							<div className="text-bold text-dark mb-3">Pemeriksaan Fisik</div>
							{!!!detailHistory?.medicalRecord?.bodyCheckups?.length && "-"}
							{detailHistory?.medicalRecord?.bodyCheckups?.map(item => (
								<div className="mb-2">
									<div className="ft-12 text-light-grey mb-1">Pemerikasaan {item.locationName}</div>
									<div className="text-dark">{item.note}</div>
								</div>
							))}
							{detailHistory?.medicalRecord?.bodyCheckupResult && (
								<div className="mb-2">
									<div className="ft-12 text-light-grey mb-1">Note</div>
									<div className="text-dark">{detailHistory?.medicalRecord?.bodyCheckupResult}</div>
								</div>
							)}
						</div>
						<div className="box-border p15 mb-2">
							<div className="text-bold text-dark mb-3">Tata Laksana</div>
							<div className="mb-2">
								<div className="ft-12 text-light-grey mb-1">Tindakan</div>
								<div className="text-dark">
									{detailHistory.medicalRecord?.actionDiv?.Action?.title || "Hanya pemeriksaan biasa"}
								</div>
								<div className="ft-12 text-light-grey mb-1">Note</div>
								<div className="text-dark">
									{detailHistory.medicalRecord?.actionDivisionNote || "-"}
								</div>
							</div>
							{/* <div className="row mb-2">
								<div className="col-6">
									<div className="mb-2">
										<div className="ft-12 text-light-grey mb-1">Operasi</div>
										<div className="text-dark">Tidak ada</div>
									</div>
								</div>
								<div className="col-6">
									<div className="mb-2">
										<div className="ft-12 text-light-grey mb-1">Note operasi</div>
										<div className="text-dark">-</div>
									</div>
								</div>
							</div> */}
							<hr />
							{!!detailHistory.medicalRecord?.mrMedicines?.length && (
								<>
									<div className="text-bold text-dark mb-3">Obat</div>
									<div className="obat-row">
										<div className="obat-col-1">
											<div className="text-600 text-grey">Nama obat</div>
										</div>
										<div className="obat-col-2">
											<div className="text-600 text-grey">Jumlah</div>
										</div>
										<div className="obat-col-3">
											<div className="text-600 text-grey">Aturan pakai</div>
										</div>
									</div>
									{detailHistory.medicalRecord?.mrMedicines?.map(item => (
										<div className="obat-row">
											<div className="obat-col-1">
												<div className="text-600 text-dark">
													{item?.medicineHospital?.medicine?.name}
												</div>
												<div className="text-600 text-grey ft-12">{item.note}</div>
											</div>
											<div className="obat-col-2">
												<div className="text-600 text-dark">{item.total}</div>
											</div>
											<div className="obat-col-3">
												<div className="text-600 text-dark">
													{item.frequency} X / {item.period} Hari
												</div>
											</div>
										</div>
									))}
								</>
							)}
						</div>
					</div>
				</div>
			</ModalContainer>
			<ModalContainer visible={openModalPatient}>
				<div class="box-popup w750 h-100">
					<div class="row justify-content-between sticky-top background-white">
						<div class="col-auto">
							<p class="text-dark fw-bolder">Detail Patient</p>
						</div>
						<div class="col-auto">
							<button class="btn" onClick={() => setOpenModalPatient(false)}>
								<span class="icon-ico-close" />
							</button>
						</div>
					</div>
					<div class="row popup-content overflow-auto w750 h-90">
						<div class="col-lg-6">
							<div class="row">
								<div class="col-12">
									<div class="border rounded p-2">
										<p class="text-dark">Info Patient</p>
										<div class="row">
											<div class="col-4">
												<div class="ft-12 text-light-grey mt-1">Title</div>
												<div class="position-relative">
													<input
														type="text"
														class="form-control ns"
														value={detailPendaftaranPasien?.data?.title}
														disabled
													/>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-6">
												<div class="ft-12 text-light-grey mt-1">First Name</div>
												<div class="position-relative">
													<input
														type="text"
														class="form-control ns"
														value={detailPendaftaranPasien?.data?.firstName}
														disabled
													/>
												</div>
											</div>
											<div class="col-6">
												<div class="ft-12 text-light-grey mt-1">Last Name</div>
												<div class="position-relative">
													<input
														type="text"
														class="form-control ns"
														value={detailPendaftaranPasien?.data?.lastName}
														disabled
													/>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-8">
												<div class="ft-12 text-light-grey mt-1">Day of Birth</div>
												<div class="position-relative">
													<input
														type="text"
														class="form-control ns"
														value={dayjs(detailPendaftaranPasien?.data?.dateOfBirth).format(
															"DD/MMMM/YYYY",
														)}
														disabled
													/>
												</div>
											</div>
											<div class="col-4">
												<div class="ft-12 text-light-grey mt-1">Sex</div>
												<div class="position-relative">
													<input
														type="text"
														class="form-control ns"
														value={converToLocalGender(
															detailPendaftaranPasien?.data?.gender,
														)}
														disabled
													/>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-8">
												<div class="ft-12 text-light-grey mt-1">Age</div>
												<div class="position-relative">
													<input
														type="text"
														class="form-control ns"
														value={detailPendaftaranPasien?.data?.dobText}
														disabled
													/>
												</div>
											</div>
										</div>
										{!!detailPendaftaranPasien?.data?.patient?.relations?.length && (
											<div class="row">
												<div class="col-6">
													<div class="ft-12 text-light-grey mt-1">Related Patient</div>
													{detailPendaftaranPasien?.data?.patient?.relations?.map(item => (
														<div class="position-relative mb-2">
															<input
																type="text"
																class="form-control ns"
																value={item.userDetail?.fullName}
																disabled
															/>
														</div>
													))}
												</div>
											</div>
										)}
										<div class="row">
											<div class="col-6">
												<div class="setting-item">
													<div class="change-img mt-4">
														<div class="for-img-change">
															<div class="bx-img-change">
																{!detailPendaftaranPasien?.data?.profilePhoto && (
																	<ProfileImageInitial
																		height={108}
																		isMale={
																			detailPendaftaranPasien?.data?.gender ===
																			"male"
																		}
																		text={`${detailPendaftaranPasien?.data?.firstName[0]}${detailPendaftaranPasien?.data?.lastName[0]}`}
																		width={108}
																	/>
																)}
																{detailPendaftaranPasien?.data?.profilePhoto && (
																	<img
																		alt=""
																		className="img-fluid"
																		src={
																			detailPendaftaranPasien?.data?.profilePhoto
																		}
																	/>
																)}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-12">
												<div class="ft-12 text-light-grey mt-1">Remark/Note</div>
												<div class="position-relative">
													<textarea
														type="text"
														class="form-control ns"
														value={detailPendaftaranPasien?.data?.patient?.remark}
														disabled
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row mt-4">
								<div class="col-12">
									<div class="border rounded p-2">
										<p class="text-dark">Info Patient</p>
										<div class="row">
											<div class="col-12">
												<div class="ft-12 text-light-grey mt-1">Addressed</div>
												<div class="position-relative">
													<textarea
														type="text"
														class="form-control ns"
														value={
															detailPendaftaranPasien?.data?.patient?.addressShipment
																?.address
														}
														disabled
													/>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-6">
												<div class="ft-12 text-light-grey mt-1">Country</div>
												<div class="position-relative">
													<input
														type="text"
														class="form-control ns"
														value={
															detailPendaftaranPasien?.data?.patient?.addressShipment
																?.country
														}
														disabled
													/>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-6">
												<div class="ft-12 text-light-grey mt-1">City</div>
												<div class="position-relative">
													<input
														type="text"
														class="form-control ns"
														value={
															detailPendaftaranPasien?.data?.patient?.addressShipment
																?.city
														}
														disabled
													/>
												</div>
											</div>
											<div class="col-6">
												<div class="ft-12 text-light-grey mt-1">State</div>
												<div class="position-relative">
													<input
														type="text"
														class="form-control ns"
														value={
															detailPendaftaranPasien?.data?.patient?.addressShipment
																?.province
														}
														disabled
													/>
												</div>
											</div>
											<div class="col-6">
												<div class="ft-12 text-light-grey mt-1">Post Code</div>
												<div class="position-relative">
													<input
														type="text"
														class="form-control ns"
														value={
															detailPendaftaranPasien?.data?.patient?.addressShipment
																?.postalCode
														}
														disabled
													/>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-6">
												<div class="ft-12 text-light-grey mt-1">Phone Number</div>
												<div class="position-relative">
													<input
														type="text"
														class="form-control ns"
														value={detailPendaftaranPasien?.data?.phoneNumber}
														disabled
													/>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-8">
												<div class="ft-12 text-light-grey mt-1">Email</div>
												<div class="position-relative">
													<input
														type="text"
														class="form-control ns"
														value={detailPendaftaranPasien?.data?.email}
														disabled
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row">
								<div class="col-12">
									<div class="border rounded p-2">
										<p class="text-dark">Other Information</p>
										{!!detailPendaftaranPasien?.data?.patient?.emergencyContacts?.length && (
											<div class="row">
												<div class="col-12">
													<div class="ft-12 text-light-grey mt-1">Emergency Contact</div>
													{detailPendaftaranPasien?.data?.patient?.emergencyContacts?.map(
														item => (
															<div class="row mt-2">
																<div class="col-6">
																	<div class="position-relative">
																		<input
																			type="text"
																			class="form-control ns"
																			value={item.phoneNumber}
																			disabled
																		/>
																	</div>
																</div>
																<div class="col-4">
																	<div class="position-relative">
																		<input
																			type="text"
																			class="form-control ns"
																			value={item.relation}
																			disabled
																		/>
																	</div>
																</div>
															</div>
														),
													)}
												</div>
											</div>
										)}
										<div class="row">
											<div class="col-6">
												<div class="ft-12 text-light-grey mt-1">Medical Number</div>
												<div class="position-relative">
													<input
														type="text"
														class="form-control ns"
														value={detailPendaftaranPasien?.data?.patient?.medicalNumber}
														disabled
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="row mt-4">
								<div class="col-12">
									<div class="border rounded p-2">
										<p class="text-dark">Communication Preference</p>
										<div class="row">
											<div class="col-12">
												<div class="ft-12 text-light-grey mt-1">Communication Preference</div>
												<small>
													Send information to sms & email when an appointment create, invoice,
													etc
												</small>
												<div class="position-relative">
													<label for="">
														<input
															className="me-2"
															type="checkbox"
															value="email"
															checked={detailPendaftaranPasien?.data?.patient?.communicationPreferences?.includes(
																"email",
															)}
															disabled
														/>{" "}
														Email
													</label>
												</div>
												<div class="position-relative">
													<label for="">
														<input
															className="me-2"
															type="checkbox"
															value="sms"
															checked={detailPendaftaranPasien?.data?.patient?.communicationPreferences?.includes(
																"sms",
															)}
															disabled
														/>{" "}
														SMS
													</label>
												</div>
											</div>
											<div class="row">
												<div class="col-8">
													<div class="ft-12 text-light-grey mt-1">Email Invoice To</div>
													<div class="position-relative">
														<input
															type="text"
															class="form-control ns"
															value={detailPendaftaranPasien?.data?.patient?.emailInvoice}
															disabled
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* Temporary disabled */}
							{/* <div class="row mt-4">
								<div class="col-12">
									<div class="border rounded p-2">
										<p class="text-dark">Refferal Source (for marketing)</p>
										<div class="row">
											<div class="col-8">
												<div class="ft-12 text-light-grey mt-1">Refferal Type</div>
												<div class="position-relative">
													<input
														type="text"
														class="form-control ns"
														value="Doctor"
														disabled
													/>
												</div>
											</div>
										</div>
										<div class="row">
											<div class="col-8">
												<div class="ft-12 text-light-grey mt-1">Sub-Category Type</div>
												<div class="position-relative">
													<input
														type="text"
														class="form-control ns"
														value="Dr. Alfian"
														disabled
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</ModalContainer>
			<Formik
				initialValues={medicalRecordForm.bookingId !== id ? FormInitialValues : medicalRecordForm}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, values, errors, touched, setFieldValue, resetForm}) => (
					<>
						<ModalContainer visible={openModalRequestAction}>
							<div id="req-tindakan" class="box-popup">
								<div class="d-flex justify-content-between align-items-center mb-4">
									<div class="text-bold text-dark">Request Tindakan {requestModalData.name}</div>
									<div class="for-close">
										<button
											class="btn btn-close"
											type="button"
											onClick={() => {
												setFieldValue(
													`checkupSupports.${requestModalData.checkupSupportIndex}.checkupCategories.${requestModalData.checkupCategoryIndex}`,
													tmpCheckupReqValue,
												);
												setOpenModalRequestAction(false);
											}}
										/>
									</div>
								</div>

								<div class="popup-content">
									<div class="row">
										{requestActionData.map(action => (
											<div class="col-sm-6 mb-3">
												<div class="form-check">
													<Field
														className="form-check-input"
														type="checkbox"
														checked={values?.checkupSupports?.[
															requestModalData.checkupSupportIndex
														]?.checkupCategories?.[
															requestModalData.checkupCategoryIndex
														]?.actions?.find(
															item => item.checkupSupportHospitalId === action.id,
														)}
														onChange={e => {
															if (e.target.checked) {
																setFieldValue(
																	`checkupSupports.${requestModalData.checkupSupportIndex}.checkupCategories.${requestModalData.checkupCategoryIndex}.actions`,
																	[
																		...values?.checkupSupports?.[
																			requestModalData.checkupSupportIndex
																		]?.checkupCategories?.[
																			requestModalData.checkupCategoryIndex
																		]?.actions,
																		{
																			checkupSupportHospitalId: action.id,
																			price: action.price,
																		},
																	],
																);
															} else {
																const filtered =
																	values?.checkupSupports?.[
																		requestModalData.checkupSupportIndex
																	]?.checkupCategories?.[
																		requestModalData.checkupCategoryIndex
																	]?.actions?.filter(
																		item =>
																			item.checkupSupportHospitalId !== action.id,
																	) || [];

																setFieldValue(
																	`checkupSupports.${requestModalData.checkupSupportIndex}.checkupCategories.${requestModalData.checkupCategoryIndex}.actions`,
																	[...filtered],
																);
															}
														}}
														value={action.id}
													/>
													<label class="form-check-label" htmlFor="c-1">
														{action.title}
													</label>
												</div>
											</div>
										))}

										<hr />
										<div class="row mb-3">
											<div class="col-sm-6">
												<div class="ft-12 text-light-grey mb-2">Tanggal periksa</div>
												<div class="form-filter position-relative">
													<DatePicker
														minDate={new Date()}
														selected={
															values?.checkupSupports?.[
																requestModalData.checkupSupportIndex
															]?.checkupCategories?.[
																requestModalData.checkupCategoryIndex
															]?.appointmentDate
														}
														onChange={date => {
															setFieldValue(
																`checkupSupports.${requestModalData.checkupSupportIndex}.checkupCategories.${requestModalData.checkupCategoryIndex}.appointmentDate`,
																date,
															);
														}}
														customInput={<CustomInputDate />}
													/>
												</div>
											</div>
										</div>
										<div class="row mb-4">
											<div class="col-sm-12">
												<div class="ft-12 text-light-grey mb-2">Note</div>
												<div class="position-relative">
													<input
														type="text"
														class="form-control ns"
														placeholder="Type something"
														name={`checkupSupports.${requestModalData.checkupSupportIndex}.checkupCategories.${requestModalData.checkupCategoryIndex}.appointmentNote`}
														value={
															values?.checkupSupports?.[
																requestModalData.checkupSupportIndex
															]?.checkupCategories?.[
																requestModalData.checkupCategoryIndex
															]?.appointmentNote
														}
														onBlur={handleBlur}
														onChange={handleChange}
													/>
												</div>
											</div>
										</div>
										<div class="row justify-content-center">
											<div class="col-sm-2">
												<button
													class="btn btn-orange r8 w-100 close"
													onClick={() => setOpenModalRequestAction(false)}>
													Simpan
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ModalContainer>
						<div>
							<a
								style={{cursor: "pointer"}}
								className="text-bold d-inline-block text-dark mb-4"
								onClick={() => history.push(`/doctor/appointment/show/${id}`)}>
								<span className="icon-ico-back me-2" />
								Buat Rekam Medis
							</a>

							<div id="ma" className="row">
								<div className="col-lg-8 col-xl-8 col-xxl-9">
									<div className="rm-inner">
										<div className="row align-items-center justify-content-between mb-4">
											<div className="col-sm-4">
												<div className="text-bold text-dark mb-4 mb-xl-0">Tipe Formulir</div>
											</div>
											<div className="col-sm-5">
												<div className="form-filter position-relative">
													<select
														className="form-select"
														onChange={e => setIsOldPatient(e.target.value === "2")}>
														<option selected value="1">
															Pasien Baru
														</option>
														<option value="2">Pasien Lama</option>
													</select>

													<div className="icon-right">
														<span className="icon-ico-chev-down text-orange" />
													</div>
												</div>
											</div>
										</div>
										{isOldPatient && (
											<>
												<div class="text-bold text-dark mb-4">Subjective dan Objective</div>
												<div class="box-white ws p24 mb-4">
													<div class="mb-3">
														<div class="ft-12 text-light-grey mb-2">Subjective</div>
														<input
															type="text"
															class="form-control ns"
															placeholder=""
															name="subjective"
															value={values.subjective}
															onChange={handleChange}
															onBlur={handleBlur}
														/>
													</div>
													<div class="mb-3">
														<div class="ft-12 text-light-grey mb-2">Objective</div>
														<input
															type="text"
															class="form-control ns"
															placeholder=""
															name="objective"
															value={values.objective}
															onChange={handleChange}
															onBlur={handleBlur}
														/>
													</div>
												</div>
											</>
										)}
										{!isOldPatient && (
											<>
												<div className="text-bold text-dark mb-4">Anamnesis</div>
												<div className="box-white ws p24 mb-4">
													<div className="mb-3">
														<div className="ft-12 text-light-grey mb-2">Keluhan utama</div>
														<input
															type="text"
															className="form-control ns"
															name="keluhan"
															value={values?.keluhan}
															onChange={handleChange}
														/>
													</div>
													<div className="mb-3">
														<div className="ft-12 text-light-grey mb-2">
															Riwayat penyakit
														</div>
														<input
															type="text"
															className="form-control ns"
															name="riwayat"
															value={values?.riwayat}
															onChange={handleChange}
														/>
													</div>
													<hr />
													<div className="text-bold text-dark mb-4">Pemeriksaan Fisik</div>
													<div className="row">
														<div className="col-sm-6">
															<div className="mb-3">
																<div className="ft-12 text-light-grey mb-2">Badan</div>
																<div className="form-filter position-relative">
																	<select className="form-select">
																		<option selected>Laki - laki</option>
																		<option value="1">Wanita</option>
																	</select>

																	<div className="icon-right">
																		<span className="icon-ico-chev-down text-orange" />
																	</div>
																</div>
															</div>
															<div className="mb-3">
																<div className="ft-12 text-light-grey mb-2">
																	Anggota badan
																</div>
																{/* NOTE: Temporary hide */}
																{false && (
																	<div className="form-filter position-relative">
																		<select className="form-select">
																			<option selected>Semua</option>
																			<option value="1">Badan 1</option>
																			<option value="2">Badan 2</option>
																			<option value="3">Badan 3</option>
																		</select>

																		<div className="icon-right">
																			<span className="icon-ico-chev-down text-orange" />
																		</div>
																	</div>
																)}
																<hr />
																<FieldArray name="bodyCheckups">
																	{({remove}) => (
																		<div>
																			{!!values.bodyCheckups.length &&
																				values.bodyCheckups.map(
																					(checkup, index) => (
																						<div
																							key={index}
																							className="mb-3">
																							<div className="ft-12 text-light-grey mb-2">
																								{`${index + 1}. `}{" "}
																								Pemeriksaan{" "}
																								{checkup.locationName}
																							</div>
																							<div className="row gx-2 align-items-center mb-3">
																								<div className="col-sm-10 col-10">
																									<div className="position-relative">
																										<input
																											type="text"
																											className="form-control ns"
																											placeholder="Masukkan hasil pemeriksaan"
																											name={`bodyCheckups.${index}.note`}
																											value={
																												values
																													.bodyCheckups[
																													index
																												]?.note
																											}
																											onChange={e =>
																												setFieldValue(
																													`bodyCheckups.${index}.note`,
																													e
																														.target
																														.value,
																												)
																											}
																										/>
																									</div>
																								</div>
																								<div className="col-sm-1 col-1 col-xxl-1">
																									<button
																										className="btn ft-18 p-1 text-grey"
																										onClick={() => {
																											const key =
																												bodyValue[
																													values
																														.bodyCheckups[
																														index
																													]
																														?.locationName
																												];

																											setSelectedBody(
																												preValue =>
																													preValue.filter(
																														item =>
																															item !==
																															key,
																													),
																											);

																											remove(
																												index,
																											);

																											setIsLoadingBody(
																												true,
																											);
																											setTimeout(() => {
																												setIsLoadingBody(
																													false,
																												);
																											}, [200]);
																										}}>
																										<span className="icon-ico-delete" />
																									</button>
																								</div>
																							</div>
																						</div>
																					),
																				)}
																		</div>
																	)}
																</FieldArray>
																<div className="mb-3">
																	<div className="ft-12 text-light-grey mb-2">
																		Hasil pemeriksaan keseluruhan
																	</div>
																	<div className="row gx-2 align-items-center mb-3">
																		<div className="col-sm-12">
																			<div className="position-relative">
																				<textarea
																					className="form-control ns"
																					rows="4"
																					placeholder="Masukkan hasil pemeriksaan"
																					name="bodyCheckupResult"
																					value={values?.bodyCheckupResult}
																					onChange={handleChange}
																				/>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="col-sm-6">
															<div className="cp">
																<div className="choose-part">
																	Silahkan pilih bagian tubuh
																</div>
															</div>
															<div
																className="choose-body-part text-center"
																style={{display: "flex", minHeight: "80%"}}>
																{isLoadingBody && (
																	<Skeleton
																		style={{
																			flex: 1,
																		}}
																		containerClassName="choose-body-skeleton"
																	/>
																)}
																{!isLoadingBody && (
																	<BodyComponent
																		partsInput={{
																			head: {
																				show: true,
																				selected: selectedBody.includes("head"),
																			},
																			left_shoulder: {
																				show: true,
																				selected:
																					selectedBody.includes(
																						"left_shoulder",
																					),
																			},
																			right_shoulder: {
																				show: true,
																				selected:
																					selectedBody.includes(
																						"right_shoulder",
																					),
																			},
																			left_arm: {
																				show: true,
																				selected:
																					selectedBody.includes("left_arm"),
																			},
																			right_arm: {
																				show: true,
																				selected:
																					selectedBody.includes("right_arm"),
																			},
																			chest: {
																				show: true,
																				selected:
																					selectedBody.includes("chest"),
																			},
																			stomach: {
																				show: true,
																				selected:
																					selectedBody.includes("stomach"),
																			},
																			left_leg: {
																				show: true,
																				selected:
																					selectedBody.includes("left_leg"),
																			},
																			right_leg: {
																				show: true,
																				selected:
																					selectedBody.includes("right_leg"),
																			},
																			left_hand: {
																				show: true,
																				selected:
																					selectedBody.includes("left_hand"),
																			},
																			right_hand: {
																				show: true,
																				selected:
																					selectedBody.includes("right_hand"),
																			},
																			left_foot: {
																				show: true,
																				selected:
																					selectedBody.includes("left_foot"),
																			},
																			right_foot: {
																				show: true,
																				selected:
																					selectedBody.includes("right_foot"),
																			},
																		}}
																		onClick={e => {
																			if (!selectedBody.includes(e)) {
																				setSelectedBody([...selectedBody, e]);
																				setFieldValue(
																					`bodyCheckups.${values.bodyCheckups.length}`,
																					{
																						locationName: bodyEnum[e],
																						note: "",
																					},
																				);
																			} else {
																				setSelectedBody(preValue =>
																					preValue.filter(item => item !== e),
																				);
																				const newValue =
																					values.bodyCheckups.filter(
																						item =>
																							item.locationName !==
																							bodyEnum[e],
																					);

																				setFieldValue(`bodyCheckups`, newValue);
																			}
																		}}
																	/>
																)}
															</div>
														</div>
													</div>
												</div>
											</>
										)}

										<div className="text-bold text-dark mb-4">Diagnosa</div>
										<div className="box-white ws p24 mb-4">
											<div className="mb-3">
												<div className="ft-12 text-light-grey mb-2">Diagnosa</div>
												<div className="form-filter position-relative">
													<BasicDropdown
														className={clsx(
															"form-control",
															errors?.diagnoseId && "form-control-error",
														)}
														data={typeDiagnoseOptions}
														name="Diagnosa"
														value={values?.diagnoseId}
														onSelect={fieldValue => {
															setFieldValue("diagnoseId", fieldValue);
														}}
													/>
													{/* {errors.title && <p className="form-error-item-message">{errors.title}</p>} */}
												</div>
											</div>
											<div className="mb-3">
												<div className="ft-12 text-light-grey mb-2">Diagnosa diferensial</div>
												<input
													type="text"
													className="form-control ns"
													name="diagnoseDifferential"
													value={values?.diagnoseDifferential}
													onChange={handleChange}
												/>
											</div>
										</div>
										{!!checkupSupportInputs.length && (
											<>
												<div className="text-bold text-dark mb-4">Pemeriksaan Penunjang</div>
												<div className="box-white ws p24 mb-4">
													{checkupSupportInputs.map((cs, index) => (
														<>
															<div className="text-bold text-dark mb-4">{cs.name}</div>
															<div className="ft-12 text-light-grey mb-2">
																Jenis {cs.name}
															</div>
															<FieldArray
																name={`checkupSupports.${index}.checkupCategories`}>
																{({remove: removeCategory}) => (
																	<div>
																		{values?.checkupSupports?.[
																			index
																		]?.checkupCategories?.map(
																			(category, categoryIndex) => (
																				<>
																					<div className="row align-items-center">
																						<div className="col-sm-9 col-10">
																							<div className="row gx-2 align-items-center mb-3">
																								<div className="col-sm-7 col-10">
																									<div className="form-filter position-relative">
																										<BasicDropdown
																											className={clsx(
																												"form-control",
																												errors
																													?.checkupCategories?.[
																													index
																												]
																													?.checkupCategoryId &&
																													"form-control-error",
																											)}
																											data={
																												cs.categoryOptions
																											}
																											name={`checkupSupports.${index}.checkupCategories.${categoryIndex}.checkupCategoryId`}
																											value={
																												values
																													?.checkupSupports?.[
																													index
																												]
																													?.checkupCategories?.[
																													categoryIndex
																												]
																													?.checkupCategoryId
																											}
																											onSelect={fieldValue => {
																												setFieldValue(
																													`checkupSupports.${index}.checkupCategories.${categoryIndex}.checkupCategoryId`,
																													fieldValue,
																												);
																											}}
																										/>
																										<div className="icon-right">
																											<span className="icon-ico-chev-down text-orange" />
																										</div>
																									</div>
																								</div>
																								<div className="col-sm-1 col-2 col-xxl-1">
																									{categoryIndex >
																										0 && (
																										<button
																											className="btn ft-18 p-1 text-grey"
																											onClick={() => {
																												removeCategory(
																													categoryIndex,
																												);
																											}}>
																											<span className="icon-ico-delete" />
																										</button>
																									)}
																									{categoryIndex ===
																										0 && (
																										<button
																											className="btn ft-18 p-1 text-orange"
																											onClick={() =>
																												setFieldValue(
																													`checkupSupports.${index}.checkupCategories`,
																													[
																														...values
																															?.checkupSupports?.[
																															index
																														]
																															?.checkupCategories,
																														{
																															checkupCategoryId:
																																null,
																															appointmentDate:
																																"",
																															appointmentNote:
																																"",
																															actions:
																																[],
																														},
																													],
																												)
																											}>
																											<span className="icon-ico-plus" />
																										</button>
																									)}
																								</div>
																							</div>
																						</div>
																						<div className="col-sm-3">
																							<button
																								className="btn btn-orange w-100 req-tindakan r8"
																								disabled={
																									!category.checkupCategoryId
																								}
																								onClick={() => {
																									const selectedCategory =
																										cs.checkupCategories.find(
																											item =>
																												item.id ==
																												category.checkupCategoryId,
																										);

																									setRequestActionData(
																										[
																											...selectedCategory.checkupSupportHospitals,
																										],
																									);
																									setTmpCheckupReqValue(
																										values
																											?.checkupSupports?.[
																											index
																										]
																											?.checkupCategories?.[
																											categoryIndex
																										],
																									);
																									setRequestModalData(
																										{
																											name: cs.name,
																											checkupSupportIndex:
																												index,
																											checkupCategoryIndex:
																												categoryIndex,
																										},
																									);
																									setOpenModalRequestAction(
																										true,
																									);
																								}}>
																								Request
																							</button>
																						</div>
																					</div>
																					<hr />
																				</>
																			),
																		)}
																	</div>
																)}
															</FieldArray>
														</>
													))}
												</div>
											</>
										)}

										<div className="text-bold text-dark mb-4">Tata Laksana</div>
										<div className="box-white ws p24 mb-4">
											<div className="ft-12 text-light-grey mb-2">Tindakan</div>
											<div className="row gx-2 align-items-center mb-3">
												<div className="col-sm-7">
													<div className="form-filter position-relative">
														<BasicDropdown
															className={clsx(
																"form-control",
																errors?.actionDivisionId && "form-control-error",
															)}
															data={actionDivisionOptions}
															name={`actionDivisionId`}
															value={values?.actionDivisionId}
															onSelect={fieldValue => {
																const selectValue = JSON.parse(fieldValue);

																setFieldValue(`actionDivisionId`, selectValue.id);
																setFieldValue(`actionDivisionPrice`, selectValue.price);
															}}
														/>
														<div className="icon-right">
															<span className="icon-ico-chev-down text-orange" />
														</div>
													</div>
												</div>
												<div className="col-sm-1 col-xxl-1" />
											</div>
											<div className="ft-12 text-light-grey mb-2">Note</div>
											<div className="row gx-0 align-items-center mb-3">
												<div className="col-sm-10">
													<div className="position-relative">
														<input
															type="text"
															className="form-control ns"
															value={values?.actionDivisionNote}
															name={`actionDivisionNote`}
															onChange={handleChange}
															onBlur={handleBlur}
														/>
													</div>
												</div>
											</div>
											<hr />
											<div className="text-bold text-dark mb-4">Obat</div>
											<FieldArray name="medicines">
												{({remove: removeMedicine}) => (
													<>
														{values?.medicines?.map((medicine, medicineIndex) => (
															<>
																<div className="row gx-2 align-items-end mb-3">
																	<div className="col-sm-4">
																		<div className="ft-12 text-light-grey mb-2">
																			Nama obat
																		</div>
																		<div className="form-filter position-relative">
																			<BasicDropdown
																				returnObject
																				className={clsx(
																					"form-control",
																					errors?.medicines?.[medicineIndex]
																						?.medicineHospitalId &&
																						"form-control-error",
																				)}
																				data={medicineOptions}
																				name={`medicines.${medicineIndex}.medicineHospitalId`}
																				value={
																					values?.medicines?.[medicineIndex]
																						?.medicineHospitalId
																				}
																				onSelect={(fieldValue, objectData) => {
																					setFieldValue(
																						`medicines.${medicineIndex}.medicineHospitalId`,
																						fieldValue,
																					);
																					setFieldValue(
																						`medicines.${medicineIndex}.price`,
																						objectData.price,
																					);
																				}}
																			/>
																			<div className="icon-right">
																				<span className="icon-ico-chev-down text-orange" />
																			</div>
																		</div>
																	</div>
																	<div className="col-sm-2">
																		<div className="ft-12 text-light-grey mb-2">
																			Jumlah
																		</div>
																		<div className="position-relative">
																			<input
																				type="text"
																				className="form-control ns"
																				value={
																					values?.medicines?.[medicineIndex]
																						?.total
																				}
																				name={`medicines.${medicineIndex}.total`}
																				onChange={e => {
																					e.target.value =
																						e.target.value.replace(
																							/\D/g,
																							"",
																						);
																					handleChange(e);
																					setFieldValue(
																						`medicines.${medicineIndex}.totalPrice`,
																						+e.target.value *
																							values?.medicines?.[
																								medicineIndex
																							]?.price,
																					);
																				}}
																			/>
																		</div>
																	</div>
																	<div className="col-sm-6 col-xl-5">
																		<div className="ft-12 text-light-grey mb-2">
																			Aturan pakai
																		</div>
																		<div className="row gx-2">
																			<div className="col-sm-5 mb-3 mb-sm-0 mb-xl-0 mb-lg-0">
																				<div className="position-relative">
																					<input
																						type="text"
																						className="form-control ns"
																						value={
																							values?.medicines?.[
																								medicineIndex
																							]?.frequency
																						}
																						name={`medicines.${medicineIndex}.frequency`}
																						onChange={e => {
																							e.target.value =
																								e.target.value.replace(
																									/\D/g,
																									"",
																								);
																							handleChange(e);
																						}}
																					/>
																					<div className="ico-right">
																						<div className="ft-13 text-grey">
																							X
																						</div>
																					</div>
																				</div>
																			</div>
																			<div className="col-sm-5 col-9">
																				<div className="position-relative">
																					<input
																						type="text"
																						className="form-control ns"
																						value={
																							values?.medicines?.[
																								medicineIndex
																							]?.period
																						}
																						name={`medicines.${medicineIndex}.period`}
																						onChange={e => {
																							e.target.value =
																								e.target.value.replace(
																									/\D/g,
																									"",
																								);
																							handleChange(e);
																						}}
																					/>
																					<div className="ico-right">
																						<div className="ft-13 text-grey">
																							Hari
																						</div>
																					</div>
																				</div>
																			</div>
																			<div className="col-sm-2 col-3">
																				<div className="d-flex ">
																					{medicineIndex !== 0 && (
																						<button
																							className="btn ft-18 p-1 text-grey"
																							onClick={() =>
																								removeMedicine(
																									medicineIndex,
																								)
																							}>
																							<span className="icon-ico-delete" />
																						</button>
																					)}
																					{values.medicines.length >
																						medicineIndex && (
																						<button
																							className="btn ft-18 p-1 text-orange"
																							onClick={() => {
																								setFieldValue(
																									"medicines",
																									[
																										...values.medicines,
																										{
																											medicineHospitalId:
																												null,
																											total: null,
																											frequency:
																												null,
																											period: null,
																											note: null,
																											price: null,
																											totalPrice:
																												null,
																										},
																									],
																								);
																							}}>
																							<span className="icon-ico-plus" />
																						</button>
																					)}
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="ft-12 text-light-grey mb-2">Note</div>
																<div className="row gx-0 align-items-center mb-3">
																	<div className="col-sm-10">
																		<div className="position-relative">
																			<input
																				type="text"
																				className="form-control ns"
																				value={
																					values?.medicines?.[medicineIndex]
																						?.note
																				}
																				name={`medicines.${medicineIndex}.note`}
																				onChange={handleChange}
																			/>
																		</div>
																	</div>
																</div>
															</>
														))}
													</>
												)}
											</FieldArray>

											<hr />
											<div className="row justify-content-between align-items-center">
												<div className="col-sm-3 col-lg-4">
													<div className="text-bold text-dark mb-4 mb-lg-0 mb-xl-0">
														Kontrol Selanjutnya
													</div>
												</div>
												<div className="col-sm-3 col-lg-4">
													<button
														className="btn btn-orange w-100 r8"
														onClick={() => {
															dispatch(setFormValue({...values, bookingId: id}));
															history.push(
																`/doctor/appointment/${id}/medical-record/make-schedule`,
															);
														}}>
														Buat Jadwal
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-lg-4 col-xl-4 col-xxl-3">
									<div className="rm-inner">
										<a
											href="#"
											className="box-white bg-logo form ws p24 mb-3"
											onClick={() => setOpenModalPatient(true)}>
											<div className="mb-2">
												<div className="ft-12 text-light-grey mb-1">Nomor</div>
												<div className="text-dark">
													{detailAppointment.data?.patient?.patient?.medicalRecordNumber ||
														"-"}
												</div>
											</div>
											<div className="mb-2">
												<div className="ft-12 text-light-grey mb-1">Status</div>
												{["pasien", "belum_dilayani", "sedang_dilayani"].includes(
													detailAppointment.data?.patientStatus,
												) && <div className="box-status not-serve">Belum dilayani</div>}
												{!["pasien", "belum_dilayani", "sedang_dilayani"].includes(
													detailAppointment.data?.patientStatus,
												) && <div className="box-status serve">Sudah dilayani</div>}
											</div>
											<div className="mb-2">
												<div className="ft-12 text-light-grey mb-1">Nama Pasien</div>
												<div className="text-dark">
													{detailAppointment.data.patient.fullName}
												</div>
											</div>
											<div className="mb-2">
												<div className="ft-12 text-light-grey mb-1">Kelamin</div>
												<div className="text-dark">
													{converToLocalGender(detailAppointment.data.patient.gender)}
												</div>
											</div>
											<div className="mb-2">
												<div className="ft-12 text-light-grey mb-1">Umur</div>
												<div className="text-dark">
													{detailAppointment.data.patient.dobText}
												</div>
											</div>
											<div className="mb-2">
												<div className="ft-12 text-light-grey mb-1">Status</div>
												<div className="text-dark">
													{capitalizeFirstLetter(
														detailAppointment.data.patient.maritalStatus,
													)}
												</div>
											</div>
										</a>

										<div className="time-line">
											<div className="box-line">
												<div className="ft-now text-orange">Now</div>
												<div className="for-line">
													<span className="default-line" />
													<span
														className="draw-line"
														style={{height: `${timelineScroll}%`}}
													/>
												</div>
												<div className="ft-then">Then</div>
											</div>
											<section className="timeline__section ma">
												<div
													className="wrapper ma"
													ref={timelineRef}
													style={{overflow: "scroll"}}>
													<div className="timeline">
														<ul>
															{!dataHistory?.data?.rows?.filter(
																item => item.medicalRecord,
															)?.length && (
																<li>
																	<span>
																		<div className="box-white wb ws p15">
																			Belum ada data
																		</div>
																	</span>
																</li>
															)}
															{dataHistory?.data?.rows
																?.filter(item => item.medicalRecord)
																?.map(history => (
																	<li onClick={() => setOpenModalDetail(true)}>
																		<a
																			href="#"
																			className="box-white open-detail wb ws p15">
																			<div className="d-flex">
																				<div className="circle-img">
																					<img
																						src={
																							history.doctor?.profilePhoto
																						}
																						className="img-fluid"
																					/>
																				</div>
																				<div className="ms-3">
																					<div className="text-600 text-dark">
																						Dr. {history.doctor?.fullName}
																					</div>
																					<div className="text-grey">
																						{dayjs(history.date).format(
																							"DD MMMM YYYY",
																						)}
																					</div>
																				</div>
																			</div>
																			{!!history.diagnose && (
																				<>
																					<hr className="my-2" />
																					<div className="text-light-grey ft-12 mb-0">
																						Diagnosis
																					</div>
																					<div className="text-dark">
																						{history.diagnose}
																					</div>
																				</>
																			)}
																		</a>
																	</li>
																))}
														</ul>
													</div>
												</div>
											</section>
										</div>
										{detailAppointment?.data?.type !== "online" && (
											<>
												<div className="ft-12 text-light-grey mb-2">Jasa konsultasi</div>
												<div className="form-filter position-relative mb-3">
													<BasicDropdown
														className={clsx(
															"form-control",
															errors?.servicePrice && "form-control-error",
														)}
														data={priceOptions}
														name="servicePrice"
														value={values?.servicePrice}
														onSelect={fieldValue => {
															setFieldValue("servicePrice", fieldValue);
														}}
													/>
													<div className="icon-right">
														<span className="icon-ico-chev-down text-orange" />
													</div>
												</div>
											</>
										)}
										<SubmitButton
											className="btn btn-orange r8 w-100"
											disabled={!FormSchemaValidation.isValidSync(values)}
											loading={isLoading}
											text="Simpan"
											onClick={handleSubmit}
										/>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</Formik>
		</DashboardLayout>
	);
}
