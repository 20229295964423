import dayjs from "dayjs";
import {useEffect, useMemo, useState} from "react";
import {IoMdPaper} from "react-icons/io";
import {getGMT, renderTimeWithTimeZone} from "utils/date";
import {loadLocalAssets} from "utils/loader";

function TimeItem({
	item,
	onSelect,
	selectedStartTime,
	timezone,
	disabledTime,
	disableMessage = "Mohon maaf, opsi ini belum bisa dipilih karena kuota pasien pada jam sebelumnya masih tersedia.",
}) {
	if (!disabledTime) {
		return (
			<div className="tc-items" onClick={() => onSelect(item)}>
				<input checked={item.startTime === selectedStartTime} name="mj" type="radio" />
				<label className="available">{`${renderTimeWithTimeZone(
					item.startTime,
					timezone,
				)} - ${renderTimeWithTimeZone(item.endTime, timezone)}`}</label>
			</div>
		);
	}

	if (disabledTime) {
		return (
			<div className="tc-items">
				<input disabled checked={item.startTime === selectedStartTime} name="mj" type="radio" />
				<label>{`${item.startTime} - ${item.endTime}`}</label>
				<div className="na-info">{disableMessage}</div>
			</div>
		);
	}
}

const timezone = "Asia/Jakarta";

export function SelectTime({selectedDayId, location, data, onSelect, day}) {
	const [selectedStartTime, setSelectedStartTime] = useState(null);
	const [, setSelectedEndTime] = useState(null);

	const timeSchedules = useMemo(() => {
		const timeSchedule = data.filter(item => item.id === selectedDayId);

		if (timeSchedule.length) {
			if (!location) return timeSchedule[0].times;
			if (location === 0) return timeSchedule[0].times?.filter(item => item.hospitalId === null);
			if (location) return timeSchedule[0].times?.filter(item => item.hospitalId === location);
		}

		return [];
	}, [data, selectedDayId, location]);

	const handleSelected = timeItem => {
		setSelectedStartTime(timeItem.startTime);
		setSelectedEndTime(timeItem.endTime);
		onSelect({
			startTime: timeItem.startTime,
			endTime: timeItem.endTime,
			prices: timeItem.prices || null,
			hospitalId: timeItem.hospitalId || null,
		});
	};

	useEffect(() => {
		/* Setting the selectedStartTime to null. */
		/* Setting the selectedStartTime to null. */
		setSelectedStartTime(null);
		setSelectedEndTime(null);
		onSelect({
			startTime: null,
			endTime: null,
			prices: null,
			hospitalId: location,
		});
	}, [selectedDayId]);

	return (
		<>
			<div className="tab-info">
				<div className="time-choose fading active ">
					<div className="inner-tc">
						{!selectedDayId && (
							<p
								className="text-center ft-13 mb-5 text-gray font-italic
">
								Silahkan pilih hari terlebih dahulu
							</p>
						)}
						{selectedDayId && (
							<>
								{timeSchedules.map(item => {
									const previousItem = timeSchedules[timeSchedules.indexOf(item) - 1];

									// Kalo libur, ga usah tampilin
									if (item?.isHoliday) {
										return null;
									}

									// Jika waktu konsultasi telah lewat, baik itu offline maupun online, disabled button
									if (
										dayjs(`${dayjs(day).format("DD MMM YYYY")} ${item.endTime}`).isBefore(
											`${dayjs().format("DD MMM YYYY HH:mm")}`,
										)
									) {
										return (
											<TimeItem
												key={item.startTime}
												disabledTime
												disableMessage="Maaf, kuota sudah penuh"
												item={item}
												selectedStartTime={selectedStartTime}
												timezone={"Asia/Jakarta"}
												onSelect={handleSelected}
											/>
										);
									}
									// Jika konsultasi online dan
									// Jika kuota pasien pada waktu konsultasi sebelumnya masih tersedia,
									// disabled button
									if (previousItem?.remainingQuota) {
										return (
											<TimeItem
												key={item.startTime}
												disabledTime
												item={item}
												selectedStartTime={selectedStartTime}
												timezone={timezone}
												onSelect={handleSelected}
											/>
										);
									}

									// Jika konsultasi online
									// dan kuota nya udah kosong maka disabled button

									if (item.remainingQuota === 0) {
										return (
											<TimeItem
												key={item.startTime}
												disabledTime
												disableMessage="Maaf, kuota sudah penuh"
												item={item}
												selectedStartTime={selectedStartTime}
												timezone={timezone}
												onSelect={handleSelected}
											/>
										);
									}

									// Jika type nya offline, maka tampilkan
									// kalo udah lewat jam bakal tervalidasi dengan conditional pertama
									if (item?.remainingQuota) {
										return (
											<TimeItem
												key={item.startTime}
												item={item}
												selectedStartTime={selectedStartTime}
												timezone={timezone}
												onSelect={handleSelected}
											/>
										);
									}
								})}
							</>
						)}
					</div>
					{selectedDayId && !timeSchedules?.length && (
						<div className="d-flex justify-content-center flex-column">
							<div className="text-center">
								<IoMdPaper className="icon-schedule-not-found" />
							</div>
							<p className="text-center ft-13 mb-5 text-gray font-italic">
								Tidak ada jadwal yang tersedia pada tanggal tersebut
							</p>
						</div>
					)}
					{!!timeSchedules.length && (
						<div className="gmt">
							<div className="img-globe">
								<img alt="Globe" className="img-fluid" src={loadLocalAssets("img/globe.svg")} />
							</div>
							<div className="ft-12 text-bold text-green">{getGMT(timezone)}</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
