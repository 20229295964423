/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
import {useSelector} from "react-redux";

import {ModalContainer} from "./ModalContainer";
import CONFIG from "config/env";
import {loadLocalAssets} from "utils/loader";

export function ModalProfile({visible, onClose, title}) {
	const userProfile = useSelector(state => state.AuthReducer.profile);
	const userZoom = useSelector(state => state.ZoomReducer.userZoom);
	const {
		ZOOM_INTEGRATION_LINK,
		ZOOM_INTEGRATION_RESPONSE_TYPE,
		ZOOM_INTEGRATION_CLIENT_ID,
		ZOOM_INTEGRATION_REDIRECT_URI,
	} = CONFIG.zoom;

	return (
		<ModalContainer visible={visible}>
			<div class="box-popup w550" id="withdraw">
				<div class="d-flex justify-content-between align-items-center mb-4">
					<div class="text-bold text-dark">{title}</div>
					<div class="for-close">
						<button class="btn btn-close" type="button" onClick={onClose} />
					</div>
				</div>
				<div class="popup-content pb-2">
					<div class="d-flex align-items-center">
						<div class="img-dp me-3">
							<img
								alt="profile"
								class="img-fluid"
								src={userProfile.profilePhoto || loadLocalAssets("img/default_avatar.png")}
							/>
						</div>
						<div class="desc-dp">
							<div class="name-dp">
								Dokter {userProfile.firstName} {userProfile.lastName}
							</div>
							<div class="text-grey">Dokter Ahli Gigi</div>
						</div>
					</div>
					<hr />
					<div class="row">
						<div class="col-6">
							<div class="ft-12 text-grey">Email</div>
							<p>{userProfile.email}</p>
						</div>
						<div class="col-6">
							<div class="ft-12 text-grey">Phone number</div>
							<p>{userProfile.phoneNumber}</p>
						</div>
					</div>
					<hr />
					<div class="d-flex justify-content-between align-items-center">
						<div class="">
							{!!userZoom && (
								<p className="mb-1">
									<span class="icon-ico-zoom me-2 text-primary" />
									Linked: {userZoom.display_name}
								</p>
							)}
							{!!!userZoom && <p className="text-danger">Silahkan masuk terlebih dahulu</p>}
						</div>
						<div class="">
							{!!userZoom && (
								<button
									className={"btn btn-orange r8 px-5"}
									onClick={() => {
										// NEED API: unlinked zoom
									}}>
									Unlinked
								</button>
							)}
							{!!!userZoom && (
								<a
									className={"btn btn-orange r8 px-5 " + (userZoom ? "disabled" : "")}
									href={`${ZOOM_INTEGRATION_LINK}?response_type=${ZOOM_INTEGRATION_RESPONSE_TYPE}&client_id=${ZOOM_INTEGRATION_CLIENT_ID}&redirect_uri=${ZOOM_INTEGRATION_REDIRECT_URI}`}
									rel="noreferrer"
									target="_blank">
									Masuk
								</a>
							)}
						</div>
					</div>
					<hr />
					<div class="text-dark ft-12 mb-4">Tempat Praktek</div>
					{userProfile?.staff?.rsMany
						?.filter(rs => rs.roleId === 2)
						?.map(rs => (
							<>
								<div class="praktek">
									<span class="text-purple icon-ico-hospital me-2 ft-18" /> {rs?.Hospital?.name}
								</div>
								<hr />
							</>
						))}
				</div>
			</div>
		</ModalContainer>
	);
}
