import _ from "lodash";
import clsx from "clsx";
import {HiChevronRight} from "react-icons/hi";
import {useState, useEffect, useMemo} from "react";
import {matchSorter} from "match-sorter";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {DashboardLayout} from "components/Layout";
import {SubmitButton} from "components/Button";
import {CircleLoader} from "components/Loader";
import {useAddUnit, useListUnitById} from "hooks/AdminHospital/Unit";
import {useListAvailableUnit} from "hooks/AdminHospital/Unit";
import {useStateCallback} from "hooks/useStateCallback";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";

export function HospitalAddUnitPage() {
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);
	const history = useHistory();

	const {data: availableUnits, isLoading: isLoadingAvailableUnits, isSuccess} = useListAvailableUnit();
	const {data: selectedUnits, isSuccess: selectedUnitsLoaded} = useListUnitById(29, {
		page: undefined,
		limit: undefined,
	});

	const {mutate, isLoading: isSubmitting} = useAddUnit();
	const {showToast} = useToast();

	const [dataItems, setDataItems] = useStateCallback([]);
	const [searchValue, setSearchValue] = useState("");
	const [selectedItems, setSelectedItems] = useStateCallback([]);
	const [selectedItem, setSelectedItem] = useState({source: null, item: null});

	const sourceDataItemsWithFilter = useMemo(() => {
		if (!dataItems.length) return [];
		if (!searchValue) return _.sortBy(dataItems, ["name"], ["asc"]);

		return matchSorter(dataItems, searchValue, {keys: ["name"]});
	}, [searchValue, dataItems, availableUnits]);

	const handleSubmit = () => {
		const formData = {
			hospitalId: selectedHospital.Hospital.id,
			unitIds: selectedItems.map(item => item.id),
		};

		mutate(formData, {
			onSuccess: () => {
				showToast("success", "Data berhasil ditambahkan", 3000);
				history.push("/hospital/unit/list");
			},
			onError: res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
			},
		});
	};

	const handleMoveData = () => {
		if (searchValue) setSearchValue("");
		if (selectedItem.source === "LEFT") {
			setSelectedItems(
				prevState => [...prevState, selectedItem.item],
				() => {
					setDataItems(
						dataItems.filter(item => item.id !== selectedItem.item.id),
						() => {
							setSelectedItem({source: null, item: null});
						},
					);
				},
			);
		}
		if (selectedItem.source === "RIGHT") {
			setDataItems(
				prevState => [...prevState, selectedItem.item],
				() => {
					setSelectedItems(
						selectedItems.filter(item => item.id !== selectedItem.item.id),
						() => {
							setSelectedItem({source: null, item: null});
						},
					);
				},
			);
		}
	};

	// Side Effects
	useEffect(() => {
		if (isSuccess) {
			setDataItems(availableUnits.data);
		}
	}, [isSuccess, setDataItems, availableUnits]);

	useEffect(() => {
		if (selectedUnits && availableUnits?.data) {
			const initialSelectedItems = selectedUnits.data.rows.map(item => ({
				id: item?.units?.id,
				name: item?.units?.name,
			}));

			const initialSelectedItemsId = initialSelectedItems.map(item => item.id);
			const resultFilter = availableUnits.data.filter(item => !initialSelectedItemsId.includes(item.id));

			setDataItems(resultFilter, () => {
				setSelectedItems(initialSelectedItems);
			});
		}
	}, [selectedUnits, selectedUnitsLoaded, setSelectedItems, availableUnits, isSuccess]);

	return (
		<DashboardLayout>
			<div className="box-white setting-content h-100 no-border p24">
				<div className="d-flex justify-content-between flex-wrap align-items-center mb-4">
					<div className="ttl-20">Management Unit Rumah Sakit</div>
				</div>

				<hr />

				<div className="text-bold mb-4">
					<a
						className="text-dark"
						style={{cursor: "pointer"}}
						onClick={() => history.push("/hospital/unit/list")}>
						<span className="icon-ico-back me-2" />
					</a>
					Daftar List Unit
				</div>

				{isLoadingAvailableUnits ? (
					<CircleLoader />
				) : (
					<>
						<div className="row gx-2 mb-5">
							<div className="col-lg-5">
								<label for="bootstrap-duallistbox-nonselected-list_duallistbox_demo1[]">
									List Unit
								</label>

								<div className="form-control ">
									<input
										className="dual-listbox-filter form-control"
										placeholder="Search ..."
										type="text"
										value={searchValue}
										onChange={event => setSearchValue(event.target.value)}
									/>
									<select
										className="form-control dual-listbox"
										multiple="multiple"
										style={{height: "328px"}}>
										{sourceDataItemsWithFilter.map(item => (
											<option
												key={`${item.id}-left`}
												className="duallist-box-select-item"
												onClick={() => setSelectedItem({source: "LEFT", item})}>
												{item.name}
											</option>
										))}
									</select>
								</div>
							</div>
							<div className="col-lg-1 d-flex flex-column justify-content-center align-content-center align-items-center">
								<div>
									<div>
										<button
											className="btn-dual-listbox-move"
											disabled={selectedItem.source !== "LEFT"}
											onClick={handleMoveData}>
											<HiChevronRight
												className={clsx(
													"ft-20 icon",
													selectedItem.source !== "LEFT" && "disabled",
												)}
											/>
										</button>
									</div>
									<div>
										{/* <button
											className="btn-dual-listbox-move"
											disabled={selectedItem.source !== "RIGHT"}
											onClick={handleMoveData}>
											<HiChevronLeft
												className={clsx(
													"ft-20 icon",
													!selectedItems.length && "disabled",
													selectedItem.source !== "RIGHT" && "disabled",
												)}
											/>
										</button> */}
									</div>
								</div>
							</div>
							<div className="col-lg-5">
								<label>List Unit di Rumah Sakit</label>

								<select className="form-control" multiple="multiple" style={{height: "430px"}}>
									{selectedItems.length ? (
										selectedItems.map(item => (
											<option
												key={`${item.id}-right`}
												className="duallist-box-select-item"
												onClick={() => setSelectedItem({source: "RIGHT", item})}>
												{item.name}
											</option>
										))
									) : (
										<option disabled className="text-center text-gray">
											Tidak ada data yang dipilih
										</option>
									)}
								</select>
							</div>
						</div>

						<div className="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!selectedItems.length}
								loading={isSubmitting}
								text="Save"
								onClick={handleSubmit}
							/>
						</div>
					</>
				)}
			</div>
		</DashboardLayout>
	);
}
