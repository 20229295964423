import {useEffect, useState, useCallback} from "react";
import RcTimePicker from "rc-time-picker";
// import "rc-time-picker/assets/index.css";

export function TimePicker({
	className,
	disabled = false,
	disabledHours = [],
	disabledHourMinutes = [],
	placeholder,
	disabledMinuteByStart = null,
	value,
	onChange,
	styleTopIcon,
}) {
	const [disabledMinutes, setDisabledMinutes] = useState([]);

	const getDisabledHours = () => {
		return disabledHours;
	};

	const getDisabledMinutes = useCallback(() => {
		if (disabledMinuteByStart) {
			const hour = parseInt(value?.split(":")[0]);
			const startHour = parseInt(disabledMinuteByStart?.split(":")[0]);
			const endMinute = parseInt(disabledMinuteByStart?.split(":")[1]);

			if (hour === startHour) {
				let dataMinute = [];
				let count = 0;

				while (count <= endMinute) {
					dataMinute.push(count++);
				}

				return dataMinute;
			}

			return [];
		} else {
			return disabledMinutes;
		}
	}, [disabledMinutes, disabledMinuteByStart, value]);

	useEffect(() => {
		disabledHourMinutes.forEach(item => {
			if (item.hour === parseInt(value?.split(":")[0])) {
				let dataMinute = [];

				while (item.minute.start <= item.minute.end) {
					dataMinute.push(item.minute.start++);
				}
				setDisabledMinutes([...dataMinute]);
			} else {
				setDisabledMinutes([]);
			}
		});
	}, [value, disabledHourMinutes]);

	const iconStyle = {
		position: "absolute",
		right: 10,
		top: styleTopIcon || 50,
		bottom: 0,
		alignItems: "center",
		justifyContent: "center",
		fontSize: "14px",
		fontStyle: "normal",
		display: "inline-block",
		lineHeight: "1",
		width: "20px",
		transition: "color 0.3s ease",
	};

	return (
		<div>
			<RcTimePicker
				focusOnOpen
				className={className}
				disabled={disabled}
				disabledHours={getDisabledHours}
				disabledMinutes={getDisabledMinutes}
				inputIcon={<span className="icon-ico-time" style={iconStyle} />}
				placeholder={placeholder || value ? value : "--:--"}
				showSecond={false}
				style={{}}
				onChange={onChange}
			/>
		</div>
	);
}
