import {AppointmentListPage, AppointmentDetailPage} from "pages/Perawat/Appointment";

export const perawatRoutes = [
	{
		name: "perawat-appointment-list",
		path: "/perawat/appointment/list",
		exact: true,
		isPrivate: true,
		component: AppointmentListPage,
	},
	{
		name: "perawat-appointment-detail",
		path: "/perawat/appointment/show/:id",
		exact: true,
		isPrivate: true,
		component: AppointmentDetailPage,
	},
];
