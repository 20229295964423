import React from "react";

export const ProfileImageInitial = React.forwardRef(({text, isMale, height, width, fontSize}, ref) => {
	return (
		<>
			<div
				ref={ref}
				// className="d-none"
				style={{
					backgroundColor: isMale ? "blue" : "#FF1493",
					height: height ? `${height}px` : "100px",
					width: width ? `${width}px` : "100px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<h1
					style={{
						fontSize: fontSize ? `${fontSize}px` : "42px",
						fontWeight: "700",
						color: "white",
					}}>
					{text}
				</h1>
			</div>
		</>
	);
});
