import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

export const QUERY = "rs_division";

export function useListAvailableDivision() {
	const result = useQuery([QUERY], () => axios.get(`division/list`).then(res => res.data));

	return result;
}

export const fetchDivision = filter => {
	const query = qs.stringify(filter);

	return axios.get(`division/?sortBy=id.desc&${query}`).then(res => res.data);
};

export function useListDivisionById(filter, enabled) {
	return useQuery({
		queryKey: [QUERY, filter],
		queryFn: () => fetchDivision(filter),
		refetchOnMount: false,
		enabled: !!enabled && !!Object.keys(filter).length && !!filter.hospitalId,
	});
}
