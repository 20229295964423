import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

export function useAddMedicineUnit(options = {}) {
	return useMutation(values => axios.post("/medicine/unit/create", values).then(res => res.data));
}
export function useEditMedicineUnit(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/medicine/unit/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("medicineUnit");
			queryClient.removeQueries("medicineUnit");
		},
	});
}

export function useDeleteMedicineUnit(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/medicine/unit/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("medicineUnit");
			queryClient.removeQueries("medicineUnit");
		},
	});
}
