import {useRef, useState, useEffect} from "react";
import clsx from "clsx";
import {useHistory} from "react-router-dom";
import {FieldArray, Formik} from "formik";
import * as Yup from "yup";
import {DashboardLayout} from "components/Layout";
import {SubmitButton} from "components/Button";
import {useAddMedicine} from "hooks/SuperAdmin/Medicine";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";
import {BasicDropdown} from "components/Dropdown";
import {useMedicineCategoryOptions} from "hooks/SuperAdmin/MedicineCategory";
import {useMedicineUnitOptions} from "hooks/SuperAdmin/MedicineUnit";
import {useSediaanOptions} from "hooks/SuperAdmin/SediaanObat";
import {Col, Row} from "react-bootstrap";

const FormSchemaValidation = Yup.object().shape({
	name: Yup.string().required("Nama obat wajib di isi"),
	medicineCategoryId: Yup.string().required("Kategori obat wajib di isi"),
	obat: Yup.array().of(
		Yup.object().shape({
			sediaan: Yup.number().required("Sediaan obat wajib di isi"),
			unit: Yup.number().required("Unit obat wajib di isi"),
		}),
	),
});
const FormInitialValues = {name: "", medicineCategoryId: "", obat: []};

export function MedicineAddPage() {
	const {showToast} = useToast();
	const history = useHistory();
	const {mutate, isLoading} = useAddMedicine();
	const formikRef = useRef();
	const [medicineCategoryOptions, setMedicineCategoryOptions] = useState([]);
	const {data: medicineCategories} = useMedicineCategoryOptions();
	const [medicineUnitOptions, setMedicineUnitOptions] = useState([]);
	const {data: medicineUnits} = useMedicineUnitOptions();
	const [sediaanOptions, setSediaanOptions] = useState([]);
	const {data: sediaanList} = useSediaanOptions();

	const handleSubmit = formData => {
		const payload = {
			medicineCategoryId: formData.medicineCategoryId,
			name: formData.name,
			units: formData.obat.map(o => o.unit),
			sediaans: formData.obat.map(o => o.sediaan),
		};

		mutate(payload, {
			onSuccess: () => {
				showToast("success", "Data berhasil ditambahkan", 3000);
				history.push("/obat");
			},
			onError: res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
			},
		});
	};

	useEffect(() => {
		if (medicineCategories?.success) {
			const dataMedicineCategory = medicineCategories?.data?.rows?.map(medicineCategory => ({
				value: medicineCategory?.id + "",
				label: `${medicineCategory?.name}`,
			}));

			setMedicineCategoryOptions([...dataMedicineCategory]);
			formikRef?.current.setFieldValue(`medicineCategoryId`, dataMedicineCategory[0].value);
		}
	}, [medicineCategories]);

	useEffect(() => {
		if (medicineUnits?.success) {
			const dataMedicineUnit = medicineUnits?.data?.rows?.map(medicineUnit => ({
				value: medicineUnit?.id,
				label: `${medicineUnit?.name}`,
			}));

			setMedicineUnitOptions([...dataMedicineUnit]);
			formikRef?.current.setFieldValue(`obat.0.unit`, dataMedicineUnit[0].value);
		}
	}, [medicineUnits]);

	useEffect(() => {
		if (sediaanList?.success) {
			const dataSediaan = sediaanList?.data?.rows?.map(sd => ({
				value: sd?.id,
				label: `${sd?.name}`,
			}));

			setSediaanOptions([...dataSediaan]);
			formikRef?.current.setFieldValue(`obat.0.sediaan`, dataSediaan[0].value);
		}
	}, [sediaanList]);

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, values, errors, setFieldValue, touched}) => (
					<div class="box-white setting-content h-100 no-border p24">
						<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
							<div class="ttl-20">Tambah Obat Baru</div>
						</div>

						<hr />

						<div class="text-bold mb-4">
							<a class="text-dark" style={{cursor: "pointer"}} onClick={() => history.push("/obat")}>
								<span class="icon-ico-back me-2" />
							</a>
							Daftar List Obat
						</div>

						<div class="row gx-2 mb-5">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Kategori Obat</label>
									<BasicDropdown
										className={clsx(
											"form-control",
											errors?.medicineCategoryId && "form-control-error",
										)}
										data={medicineCategoryOptions}
										name="medicineCategoryId"
										value={values?.medicineCategoryId}
										onSelect={fieldValue => {
											setFieldValue("medicineCategoryId", fieldValue);
										}}
									/>
									{errors.medicineCategoryId && touched.medicineCategoryId && (
										<p className="form-error-item-message">{errors.medicineCategoryId}</p>
									)}
								</div>
							</div>
						</div>

						<div class="row gx-2 mb-5">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Nama Obat</label>
									<input
										class={clsx(
											"form-control",
											errors?.name && touched.name && "form-control-error",
										)}
										disabled={isLoading}
										name="name"
										placeholder=""
										type="text"
										value={values?.name}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.name && touched.name && (
										<p className="form-error-item-message">{errors.name}</p>
									)}
								</div>
							</div>
						</div>

						<FieldArray name="obat">
							{({remove, push}) => (
								<>
									{values.obat?.map((o, idx) => {
										return (
											<Row key={`unit-${idx}`} className="gx-2 mb-5">
												<Col>
													<div class="setting-item">
														{idx === 0 ? (
															<label class="setting-label required">Unit Obat</label>
														) : null}
														<BasicDropdown
															className={clsx(
																"form-control",
																errors?.obat?.[idx] && "form-control-error",
															)}
															data={medicineUnitOptions}
															name={`obat.${idx}.unit`}
															value={o.unit}
															onSelect={fieldValue => {
																setFieldValue(`obat.${idx}.unit`, Number(fieldValue));
															}}
														/>
														{errors.obat?.[idx] && touched.obat?.[idx] && (
															<p className="form-error-item-message">
																{errors.obat?.[idx]}
															</p>
														)}
													</div>
												</Col>
												<Col>
													<div class="setting-item">
														{idx === 0 ? (
															<label class="setting-label required">Sediaan</label>
														) : null}
														<BasicDropdown
															className={clsx(
																"form-control",
																errors?.obat?.[idx] && "form-control-error",
															)}
															data={sediaanOptions}
															name={`obat.${idx}.sediaan`}
															value={o.sediaan}
															onSelect={fieldValue => {
																setFieldValue(
																	`obat.${idx}.sediaan`,
																	Number(fieldValue),
																);
															}}
														/>
														{errors.obat?.[idx] && touched.obat?.[idx] && (
															<p className="form-error-item-message">
																{errors.obat?.[idx]}
															</p>
														)}
													</div>
												</Col>
												<Col className="d-flex align-items-end" xs={1}>
													<div className="d-flex gap-2 mb-4">
														{idx === values?.obat.length - 1 ? (
															<a
																class={clsx("text-orange wait-pay")}
																style={{cursor: "pointer"}}
																onClick={() => {
																	push({
																		unit: medicineUnitOptions[0].value,
																		sediaan: sediaanOptions[0].value,
																	});
																}}>
																<span class="icon-ico-plus ft-18" />
															</a>
														) : null}
														{values?.obat.length > 1 ? (
															<a
																class={clsx("text-orange wait-pay")}
																style={{cursor: "pointer"}}
																onClick={() => {
																	remove(idx);
																}}>
																<span class="icon-ico-delete ft-18" />
															</a>
														) : null}
													</div>
												</Col>
											</Row>
										);
									})}
								</>
							)}
						</FieldArray>

						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!FormSchemaValidation.isValidSync(values)}
								loading={isLoading}
								text="Simpan"
								onClick={handleSubmit}
							/>
						</div>
					</div>
				)}
			</Formik>
		</DashboardLayout>
	);
}
