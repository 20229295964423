import * as Yup from "yup";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const FILE_SIZE = 5242880; // 5MB

export const FormSchemaValidation = Yup.object().shape({
	hospitalId: Yup.number(),
	leftIcon: Yup.mixed()
		.required("Icon kiri wajib di upload")
		.test("fileFormat", "Format logo tidak support, hanya menerima format .JPG .JPEG .PNG", value => {
			if (typeof value === "string") {
				return true;
			} else {
				return SUPPORTED_FORMATS.includes(value?.type);
			}
		})
		.test("fileSize", "Ukuran file untuk logo maksimal 5 MB", value => {
			if (typeof value === "string") {
				return true;
			} else {
				return value?.size <= FILE_SIZE;
			}
		}),
	rightIcon: Yup.mixed()
		.required("Icon kanan wajib di upload")
		.test("fileFormat", "Format logo tidak support, hanya menerima format .JPG .JPEG .PNG", value => {
			if (typeof value === "string") {
				return true;
			} else {
				return SUPPORTED_FORMATS.includes(value?.type);
			}
		})
		.test("fileSize", "Ukuran file untuk logo maksimal 5 MB", value => {
			if (typeof value === "string") {
				return true;
			} else {
				return value?.size <= FILE_SIZE;
			}
		}),
	header: Yup.string().required("Header wajib di isi"),
	content: Yup.string().required("Content wajib di isi"),
});
