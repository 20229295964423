import {useContext} from "react";
import {ToastStateContext} from "store/context/ToastContext";

export function useToast() {
	// eslint-disable-next-line no-unused-vars
	const [state, dispatch] = useContext(ToastStateContext);

	function showToast(type, message, timeOut) {
		dispatch({
			type: "SHOW_TOAST",
			payload: {
				type,
				message,
				timeOut,
			},
		});

		if (timeOut) {
			setTimeout(() => {
				dispatch({type: "HIDE_TOAST"});
			}, timeOut);
		}
	}

	return {showToast};
}
