import {useState} from "react";
import {useHistory} from "react-router-dom";
import {SubmitButton} from "components/Button";
import {OtpInput} from "components/Form";
import {loadLocalAssets} from "utils/loader";
import {useValidateOtp} from "hooks/Auth";

export function VerificationOtp() {
	const history = useHistory();
	const {mutate, isLoading} = useValidateOtp();
	const [errorSubmit, setErrorSubmit] = useState("");
	const handleSubmit = () => {
		mutate(
			{otp: otpValue},
			{
				onSuccess: () => {
					history.push(`/auth/reset-password?token=${otpValue}`);
				},
				onError: res => {
					setErrorSubmit(
						res?.response?.data?.message?.errors?.[0]?.msg ||
							`Opps, terjadi kesalahan silahkan coba lagi nanti`,
					);
				},
			},
		);
	};

	const [otpValue, setOtpValue] = useState("");

	const handleChangeOTP = value => {
		if (value.length === 6) {
			// Submit
		}
		setOtpValue(value);
	};

	return (
		<div className="change-password-wrapper">
			<div className="form-main">
				<div className="inner-fm">
					<img alt="logo" className="img-fluid mb-5" src={loadLocalAssets("img/logo.svg")} />

					<div className="ttl-28 mb-4">Verifikasi OTP</div>
					<div className="form-change-password">
						<div className="label-fl mb-2">OTP</div>
						<div className="position-relative">
							<OtpInput
								shouldAutoFocus
								className="col-2"
								inputStyle="col-3 form-control otp-form"
								numInputs={6}
								value={otpValue}
								onChange={handleChangeOTP}
							/>
						</div>
						{errorSubmit && <p className="form-error-item-message">{errorSubmit}</p>}
					</div>

					<SubmitButton
						className="btn btn-orange w-100"
						disabled={otpValue.length !== 6}
						loading={isLoading}
						text="Kirim"
						onClick={handleSubmit}
					/>
				</div>
			</div>
			<div className="img-main" style={{backgroundImage: `url(${loadLocalAssets("img/bg-login.jpg")})`}} />
		</div>
	);
}
