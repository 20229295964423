/* eslint-disable no-unused-vars */
import * as Yup from "yup";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

export const EditStaffFormSchemaValidation = Yup.object().shape({
	// title: Yup.string().required("Title wajib di isi"),
	// firstName: Yup.string().required("Nama depan wajib di isi"),
	// lastName: Yup.string().required("Nama belakang wajib di isi"),
	// nickname: Yup.string().required("Nama panggilan wajib di isi"),
	// dateOfBirth: Yup.string().required("Tanggal lahir wajib di isi"),
	// password: Yup.string().min(8, "Kata sandi minimal 8 karakter").nullable(),
	// nip: Yup.string().required("NIP wajib di isi"),
	noStr: Yup.string().required("Nomor STR wajib di isi"),
	// employeeType: Yup.number().required("Tipe karyawan wajib di isi"),
	// doctorType: Yup.string()
	// 	.nullable()
	// 	.when("employeeType", {
	// 		is: value => value == 2,
	// 		then: Yup.string().required("Tipe dokter wajib di isi"),
	// 	}),
	// policlinicId: Yup.string()
	// 	.nullable()
	// 	.when("doctorType", {
	// 		is: value => value == 2,
	// 		then: Yup.string().required("Poliklinik wajib di isi"),
	// 	}),
	// bookingOnline: Yup.boolean()
	// 	.nullable()
	// 	.when("employeeType", {
	// 		is: value => value == 2,
	// 		then: Yup.boolean().required("Booking wajib di isi"),
	// 	}),
	// address: Yup.string().required("Alamat wajib di isi"),
	email: Yup.string().email("Email tidak valid").required("Email wajib di isi"),
	phoneNumber: Yup.string()
		.required("Nomor telepon wajib di isi")
		.min(8, "Nomor telepon minimal 8 angka")
		.max(15, "Nomor telepon maximal 15 angka"),
	// profilePhoto: Yup.mixed()
	// 	.test("fileFormat", "Format logo tidak support, hanya menerima format .JPG .JPEG .PNG", value => {
	// 		if (value) {
	// 			if (typeof value === "string") {
	// 				return true;
	// 			} else {
	// 				return SUPPORTED_FORMATS.includes(value?.type);
	// 			}
	// 		} else return true;
	// 	})
	// 	.test("fileSize", "Ukuran foto profil staff maksimal 5 Mb", value => {
	// 		if (value) {
	// 			if (typeof value === "string") {
	// 				return true;
	// 			} else {
	// 				return value?.size < 5242880;
	// 			}
	// 		} else return true;
	// 	}),
});
