import {Col, Container, Row} from "react-bootstrap";
import {loadLocalAssets} from "utils/loader";

const ZoomIntegration = () => {
	return (
		<div class="login-wrapper">
			<Container className="my-5 misc-page">
				<Row>
					<Col>
						<section>
							<div className="title">
								Bagaimana cara melakukan Izin Akses Zoom & Melakukan Appointment yg terintegrasi dengan
								Zoom Pada Web ProMedik?
							</div>
						</section>
						<section>
							<ol>
								<li>
									Login pada Web{" "}
									<a href="https://staff.promedik.com/" rel="noreferrer" target="_blank">
										https://staff.promedik.com/
									</a>
									<div className="mt-3 mb-5 text-center">
										<img className="img-fluid w-75" src={loadLocalAssets("img/1-Login.png")} />
									</div>
								</li>
								<li>
									Setelah Berhasil Login Klik Bagian{" "}
									<strong>“Icon Profil di bagian kanan atas”</strong>.
									<div className="mt-3 mb-5 text-center">
										<img
											className="img-fluid w-75"
											src={loadLocalAssets("img/2-6-Appoinment.png")}
										/>
									</div>
								</li>
								<li>
									Setelah klik Icon Profile , maka akan menampilkan dropdown pilihan berikut kemudian
									pilih <strong>“Profile”</strong>
									<div className="mt-3 mb-5 text-center">
										<img
											className="img-fluid"
											src={loadLocalAssets("img/3-Dropdown-Profile.png")}
										/>
									</div>
								</li>
								<li>
									Setelah memilih profile, maka akan menampilkan halaman Profile Dokter, Untuk{" "}
									<strong>Memberikan akses/Linked Zoom</strong>, user diwajibkan melakukan klik Button{" "}
									<strong>“Masuk”</strong> yang nanti nya akan di arahkan ke Page Login Zoom untuk
									melakukan Login.
									<div className="mt-3 mb-5 text-center">
										<img className="img-fluid" src={loadLocalAssets("img/4-Lnked-Zoom.png")} />
									</div>
								</li>
								<li>
									Jikalau user berhasil menyambungkan akun Zoom,maka akan tampil halaman berikut,
									Untuk <strong>melakukan Unlinked Zoom</strong>, user hanya tinggal mengklik{" "}
									<strong>Button Unlinked</strong>.
									<div className="mt-3 mb-5 text-center">
										<img className="img-fluid" src={loadLocalAssets("img/5-Unlinked-Zoom.png")} />
									</div>
								</li>
								<li>
									Dan untuk melakukan Appoinment yg terintegrasi dengan Zoom, user hanya tinggal
									memilih/mengklik “Icon Zoom” yg ada pada list Data Appoinment
									<div className="mt-3 mb-5 text-center">
										<img
											className="img-fluid w-75"
											src={loadLocalAssets("img/2-6-Appoinment.png")}
										/>
									</div>
								</li>
							</ol>
						</section>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default ZoomIntegration;
