import clsx from "clsx";
import {useState, useRef, useEffect, useMemo, useLayoutEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {Formik} from "formik";
import {useDropzone} from "react-dropzone";
import {DashboardLayout} from "components/Layout";
import {SubmitButton} from "components/Button";
import {Select} from "components/Form";
import {PatientDropdown} from "components/Dropdown";
import {convertErrorMessageFormat} from "utils/converter";
import {converToLocalGender} from "utils/converter";
import {useToast} from "hooks/useToast";
import {useEditFileLab, useDetailFileLab} from "hooks/AdminLaboratorium/FileLab";
import {useListPatient} from "hooks/AdminAdmission/Patient";
import {useUploadFile} from "hooks/Misc";
import {useHospitals} from "hooks/SuperAdmin/Hospital";
import dayjs from "dayjs";

import {FileLabFormSchemaValidation} from "./schema";

const FormInitialValues = {
	name: "",
	fileBuffer: null,
	blobUrl: "",
	file: "",
	visibility: "",
	idPatient: "",
	idHospital: "",
	date: "",
};

const visibilityOptions = [
	{
		value: "visible",
		label: "Live",
	},
	{
		value: "hidden",
		label: "Staff Only",
	},
];

export function MasterFileLabEditPage() {
	const history = useHistory();

	const roleId = useSelector(state => state.AuthReducer.roleId);

	const [searchPatient, setSearchPatient] = useState("");

	useEffect(
		() => {
			if (roleId === 5) history.push("/file-labs");
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[roleId],
	);

	const {getRootProps, getInputProps} = useDropzone({
		maxFiles: 1,
		accept: {
			"application/pdf": [],
		},
		onDropAccepted: files => {
			formikRef.current.setFieldValue("blobUrl", URL.createObjectURL(files[0]));
			formikRef.current.setFieldValue("fileBuffer", files[0]);
		},
		onDropRejected: res => {
			formikRef.current.setFieldError(
				"fileBuffer",
				"Hanya bisa menerima maksimal 1 file dan hanya menerima format .PDF",
			);
		},
	});

	// Data detail
	const {id} = useParams();
	const {data: detailFile = {}, isLoading: isFetchingDetail} = useDetailFileLab(id);

	// Master data patient
	const {
		data: patients,
		isLoading: isFethingPatients,
		setFilter: setFilterPatients,
	} = useListPatient({
		page: 1,
		limit: null,
		search: undefined,
		idHospital: undefined,
	});
	const [patientsOptions, setPatientsOptions] = useState([]);

	const {showToast} = useToast();
	const formikRef = useRef();
	const inputFileRef = useRef(null);

	const {mutate: updateDataFileLab, isLoading} = useEditFileLab();
	const {mutate: uploadFile, isLoading: isUploading} = useUploadFile();

	const triggerUpdateData = formDataValue => {
		updateDataFileLab(
			{
				...formDataValue,
				idLabResult: parseInt(id),
				idHospital: parseInt(formDataValue.idHospital),
				idPatient: parseInt(formDataValue.idPatient),
			},
			{
				onSuccess: () => {
					showToast("success", "Data file labs berhasil di update", 3000);
					window.scrollTo(0, 0);
					history.push(`/file-labs/detail/${detailFile.data?.patient?.idUser}`);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	const handleSubmitForm = formValue => {
		const {fileBuffer, blobUrl, ...formDataValue} = formValue;

		if (fileBuffer && typeof fileBuffer !== "string") {
			let formData = new FormData();

			formData.append("file", fileBuffer);

			uploadFile(formData, {
				onSuccess: result => {
					triggerUpdateData({...formDataValue, file: result.data.file, name: fileBuffer.name});
				},
			});
		} else {
			triggerUpdateData(formDataValue);
		}
	};

	const handleUploadFile = e => {
		const file = e.target.files[0];

		formikRef.current.setFieldValue("blobUrl", URL.createObjectURL(file));
		formikRef.current.setFieldValue("fileBuffer", file);
	};

	// When data detail is loaded, set formik initial values
	useLayoutEffect(() => {
		if (detailFile?.success && formikRef?.current) {
			formikRef.current.setFieldValue("name", detailFile.data.name);
			formikRef.current.setFieldValue("visibility", detailFile.data.visibility);
			formikRef.current.setFieldValue("idPatient", detailFile.data?.idPatient + "");
			formikRef.current.setFieldValue("idHospital", detailFile.data.idHospital + "");
			formikRef.current.setFieldValue("file", detailFile.data.file);
			formikRef.current.setFieldValue("blobUrl", detailFile.data.file);
			formikRef.current.setFieldValue("fileBuffer", detailFile.data.file);
			formikRef.current.setFieldValue("date", dayjs(detailFile.data.date).format("YYYY-MM-DDTHH:mm"));
			setSearchPatient(findRelatedId(detailFile.data?.idPatient + ""));
			setFilterPatients({
				page: 1,
				limit: null,
				search: undefined,
				idHospital: detailFile.data.idHospital,
			});
			setTimeout(() => {
				formikRef.current.validateForm();
			}, 1000);
		}
	}, [detailFile, formikRef]);

	// When patiens data is fetched, update to state
	useEffect(() => {
		if (patients?.success) {
			setPatientsOptions(
				patients?.data?.rows?.map(patient => ({
					value: patient?.patient?.id + "",
					label: `${patient?.firstName} ${patient?.lastName} [${patient?.patient?.medicalRecordNumber}]`,
					desc: `${converToLocalGender(patient?.gender)} - ${patient?.email} - ${patient?.phoneNumber}`,
					image: patient?.profilePhoto,
				})),
			);
		}
	}, [patients]);

	const {hospitals} = useHospitals(true, 100);
	const listHospitalOptions = useMemo(() => {
		const arr = hospitals?.data?.rows || [];

		return arr.map(e => ({label: e.name, value: e.id}));
	}, [hospitals]);

	const findRelatedId = value => {
		return patientsOptions.find(x => x.value === value)?.label || value;
	};

	return (
		<DashboardLayout>
			{isFetchingDetail ? (
				<p>Loading...</p>
			) : (
				<Formik
					initialValues={FormInitialValues}
					innerRef={formikRef}
					validationSchema={FileLabFormSchemaValidation}
					onSubmit={handleSubmitForm}>
					{({handleSubmit, handleBlur, handleChange, values, errors, setFieldValue, isValid}) => {
						return (
							<div class="box-white setting-content h-100 no-border p24">
								<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
									<div class="ttl-20">Edit file laboratorium</div>
								</div>

								<hr />

								<div class="text-bold mb-4">
									<a
										class="text-dark"
										style={{cursor: "pointer"}}
										onClick={() =>
											history.push(`/file-labs/detail/${detailFile.data?.patient?.idUser}`)
										}>
										<span class="icon-ico-back me-2" />
									</a>
									Detail Hasil Laboratorium
								</div>

								<div class="row gx-2 mb-5">
									<div class="col-sm-6 col-6">
										<Select
											required
											label="Visibility"
											name="visibility"
											options={visibilityOptions}
											placeholder="Pilih jenis visibility..."
											value={values.visibility}
											onChange={selected => setFieldValue("visibility", selected.value)}
										/>
										<div class="setting-item">
											<label class="setting-label required">Rumah Sakit</label>
											<select
												className={clsx(
													"form-control",
													errors?.idHospital && "form-control-error",
												)}
												disabled={true}
												name="idHospital"
												value={values?.idHospital}
												onBlur={handleBlur}
												onChange={e => {
													setFieldValue("idHospital", e.target.value);
													setFilterPatients({
														page: 1,
														search: "",
														limit: null,
														idHospital: e.target.value,
													});
												}}>
												<option value="">Pilih Rumah Sakit</option>
												{listHospitalOptions.map(option => (
													<option value={option.value}>{option.label}</option>
												))}
											</select>
											{errors.idHospital && (
												<p className="form-error-item-message">{errors.idHospital}</p>
											)}
										</div>
										<PatientDropdown
											disabled
											required
											data={patientsOptions}
											label="Pasien"
											loading={isFethingPatients}
											name="idPatient"
											placeholder="Ketikan nama, email atau nomor handphone pasien.."
											searchKeyValue={["label", "desc"]}
											value={searchPatient}
											onBlur={handleBlur}
											onChange={selectedValue => {
												setFieldValue("idPatient", selectedValue.value);
												setSearchPatient(findRelatedId(selectedValue.value));
											}}
											onInput={e => {
												setSearchPatient(e);
											}}
										/>
										<div class="setting-item">
											<label class="setting-label required">Tanggal</label>
											<input
												className={clsx("form-control", errors?.date && "form-control-error")}
												name="date"
												type="datetime-local"
												value={values?.date}
												onBlur={handleBlur}
												onChange={handleChange}
											/>
											{errors.date && <p className="form-error-item-message">{errors.date}</p>}
										</div>
									</div>

									<div class="col-sm-6 col-6" />
									<div class="col-sm-12 col-12">
										<div
											class="item-wrapper one"
											{...getRootProps({className: "dropzone"})}
											style={{cursor: "pointer"}}>
											<div class="item">
												<div class="item-inner">
													<div class="item-content">
														<div class="image-upload">
															<label for="fileBuffer" style={{cursor: "pointer"}}>
																<img alt="" class="uploaded-image" src="" />
																<div class="h-100">
																	<div class="dplay-tbl">
																		<div class="dplay-tbl-cell">
																			<span className="icon-upload-cloud icon-large" />

																			<h5>
																				{values.fileBuffer || values.blobUrl ? (
																					<>
																						<b>
																							{values.fileBuffer?.name ||
																								detailFile.data?.name}
																						</b>
																						<div
																							style={{marginTop: "10px"}}>
																							<button
																								className="btn btn-preview-file"
																								onClick={() => {
																									window.open(
																										values.blobUrl,
																										"_blank",
																									);
																								}}>
																								Lihat file
																							</button>
																							<button
																								className="btn btn-preview-file"
																								onClick={() =>
																									inputFileRef?.current?.click()
																								}>
																								Ganti file
																							</button>
																						</div>
																					</>
																				) : (
																					<b>
																						Pilih file yang akan di upload
																					</b>
																				)}
																			</h5>
																			{!values.fileBuffer && (
																				<h6 class="mt-10 mb-70 ft-14">
																					Hanya menerima format pdf
																				</h6>
																			)}
																		</div>
																	</div>
																</div>
																<input
																	ref={inputFileRef}
																	accept="application/pdf"
																	class="image-input"
																	name="fileBuffer"
																	type="file"
																	value=""
																	onChange={handleUploadFile}
																	{...getInputProps()}
																/>
															</label>
														</div>
														{errors?.fileBuffer && (
															<p className="form-error-item-message">
																{errors?.fileBuffer}
															</p>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="text-center my-3">
									<SubmitButton
										className="btn btn-orange d-inline-block mw-250"
										disabled={!isValid}
										loading={isLoading || isUploading}
										text="Save"
										onClick={handleSubmit}
									/>
								</div>
							</div>
						);
					}}
				</Formik>
			)}
		</DashboardLayout>
	);
}
