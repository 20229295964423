import Autocomplete from "react-google-autocomplete";
import clsx from "clsx";
import CONFIG from "config/env";

const API_KEY = CONFIG.endpoint.GOOGLE_API_KEY;

export function GoogleSugestionDropdown({
	disabled,
	onSelect,
	level,
	isCountry = false,
	error,
	defaultValue,
	type,
	full = false,
}) {
	let types = [];

	if (level) types = [`administrative_area_level_${level}`];
	if (isCountry) types = ["country"];
	if (type) types = [type];

	return (
		<Autocomplete
			apiKey={API_KEY}
			className={clsx("form-control", error && "form-control-error")}
			defaultValue={defaultValue}
			disabled={disabled}
			language="id"
			options={{
				types: types,
			}}
			placeholder=""
			onChange={e => onSelect(e.target.value, {})}
			onPlaceSelected={(place, inputRef) => {
				let text = place.formatted_address;
				let component = {};

				const lat = place.geometry.location.lat();
				const long = place.geometry.location.lng();

				place.address_components.forEach(item => {
					component[item.types[0]] = item.long_name;
				});

				if (!full) {
					text = place.formatted_address.split(",")[0];

					if (type === "postal_code") {
						text =
							place.address_components.filter(item => item.types.includes("postal_code"))?.[0]
								?.long_name || text;
					}
				}

				component = {...component, lat, long};

				inputRef.value = text;
				onSelect(text, component);
			}}
		/>
	);
}
