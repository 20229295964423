import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

export function useAddSchedule(options = {}) {
	return useMutation(values => axios.post("/schedule/create", values).then(res => res.data));
}
export function useEditSchedule(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/schedule/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("schedules");
			queryClient.removeQueries("schedules");
		},
	});
}

export function useDeleteSchedule(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/schedule/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("schedules");
			queryClient.removeQueries("schedules");
		},
	});
}
