import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

export function useAddPendaftaranPasien(options = {}) {
	return useMutation(values => axios.post("/patient/create", values).then(res => res.data));
}
export function useEditPendaftaranPasien(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/patient/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("patients");
			queryClient.removeQueries("patients");
		},
	});
}

export function useDeletePendaftaranPasien(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/patient/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("patients");
			queryClient.removeQueries("patients");
		},
	});
}

export function useAddAddress(options = {}) {
	return useMutation(values => axios.post("/patient/address/create", values).then(res => res.data));
}

export function useSyncPendaftaranPasien(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/sync").then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("patients");
			queryClient.removeQueries("patients");
		},
	});
}
