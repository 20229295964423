import clsx from "clsx";
import {SubmitButton} from "components/Button";
import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {useFormik} from "formik";
import {useDetailHargaPendaftaranObat, useEditHargaPendaftaranObat} from "hooks/SuperAdmin/HargaPendaftaranObat";
import {useHospitals} from "hooks/SuperAdmin/Hospital";
import {useToast} from "hooks/useToast";
import {useEffect, useMemo, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {convertErrorMessageFormat, convertToCurency} from "utils/converter";
import * as Yup from "yup";

const FormSchemaValidation = Yup.object().shape({
	hospitalId: Yup.string().required("Rumah sakit wajib di isi"),
	price: Yup.number()
		.required("Harga wajib diisi")
		.min(1, "Harga wajib diisi")
		.transform(v => (Number.isNaN(v) ? 0 : v)),
});
const FormInitialValues = {hospitalId: "", price: 0};

const HargaPendaftaranObatEdit = () => {
	const {showToast} = useToast();
	const history = useHistory();
	const matchParams = useRouteMatch();
	const {params} = matchParams;
	const {id} = params;

	const {isLoading: isFetching, data: resHargaAdministrasi} = useDetailHargaPendaftaranObat(id);

	const {mutate, isLoading: isSubmitting} = useEditHargaPendaftaranObat();

	const [price, setPrice] = useState(0);
	const [priceString, setPriceString] = useState("");

	const {values, errors, handleBlur, handleChange, setFieldValue, setFieldTouched, handleSubmit} = useFormik({
		initialValues: FormInitialValues,
		validationSchema: FormSchemaValidation,
		onSubmit: values => {
			mutate(
				{id: resHargaAdministrasi.data.id, ...values},
				{
					onSuccess: () => {
						showToast("success", "Data berhasil diperbarui");
						history.push("/harga-pendaftaran-obat");
					},
					onError: res => {
						showToast(
							"error",
							convertErrorMessageFormat(res.response.status, res.response.data.message),
							null,
						);
					},
				},
			);
		},
	});

	useEffect(() => {
		if (resHargaAdministrasi) {
			const {hospitalId, price} = resHargaAdministrasi.data;

			setFieldValue("hospitalId", hospitalId);
			setFieldValue("price", price);
			setPrice(price);
			setPriceString(convertToCurency(price + ""));

			setTimeout(() => {
				setFieldTouched("hospitalId", true);
				setFieldTouched("price", true);
			});
		}
	}, [resHargaAdministrasi]);

	useEffect(() => {
		setFieldValue("price", price);
		setTimeout(() => {
			setFieldTouched("price", true);
		});
	}, [price]);

	const {hospitals} = useHospitals(true, 100);
	const listHospitalOptions = useMemo(() => {
		const arr = hospitals?.data?.rows || [];

		return arr.map(e => ({label: e.name, value: e.id}));
	}, [hospitals]);

	if (isFetching) {
		return (
			<DashboardLayout>
				<FormCard title="Edit Harga Pendaftaran Obat">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<form onSubmit={handleSubmit}>
				<div class="box-white setting-content h-100 no-border p24">
					<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
						<div class="ttl-20">Edit Harga Pendaftaran Obat</div>
					</div>

					<hr />

					<div class="text-bold mb-4">
						<a
							class="text-dark"
							style={{cursor: "pointer"}}
							onClick={() => history.push("/harga-pendaftaran-obat")}>
							<span class="icon-ico-back me-2" />
						</a>
						Daftar Harga Pendaftaran Obat
					</div>

					<div class="row gx-2 mb-5">
						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Rumah Sakit</label>
								<select
									className={clsx("form-control", errors?.diffFactor && "form-control-error")}
									disabled={isSubmitting}
									name="hospitalId"
									value={values?.hospitalId}
									onBlur={handleBlur}
									onChange={handleChange}>
									<option value="">Pilih Rumah Sakit</option>
									{listHospitalOptions.map(option => (
										<option value={option.value}>{option.label}</option>
									))}
								</select>
								{errors.hospitalId && <p className="form-error-item-message">{errors.hospitalId}</p>}
							</div>
						</div>

						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Harga</label>
								<input
									className="form-control"
									disabled={isSubmitting}
									placeholder="Rp"
									type="text"
									value={priceString}
									onChange={e => {
										let value = e.target.value.replace(/\D/g, "");

										setPrice(parseInt(value));

										value = convertToCurency(value);

										setPriceString(value);
									}}
								/>
								{errors.price && <p className="form-error-item-message">{errors.price}</p>}
							</div>
						</div>
					</div>

					<div class="text-center my-3">
						<SubmitButton
							className="btn btn-orange d-inline-block mw-250"
							disabled={!FormSchemaValidation.isValidSync(values)}
							loading={isSubmitting}
							text="Simpan Perubahan"
						/>
					</div>
				</div>
			</form>
		</DashboardLayout>
	);
};

export {HargaPendaftaranObatEdit};
