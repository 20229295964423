import clsx from "clsx";
import {SubmitButton} from "components/Button";
import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {FieldArray, Formik} from "formik";
import {useUploadFile} from "hooks/Misc";
import {useAddVendor, useAddVendorPIC, useGenerateVendorCode} from "hooks/SuperAdmin/Vendor";
import {useToast} from "hooks/useToast";
import {useEffect, useRef, useState} from "react";
import PhoneInput from "react-phone-number-input";
import {useHistory} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";
import {loadLocalAssets} from "utils/loader";
import * as Yup from "yup";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const FILE_SIZE = 5242880; // 5MB

const FormSchemaValidation = Yup.object().shape({
	vendorCode: Yup.string(),
	vendorName: Yup.string().required("Nama vendor wajib di isi"),
	email: Yup.string().email("Email yang dimasukkan harus sesuai dengan format yang valid"),
	phoneNumber: Yup.string()
		.max(15, "Nomor Telepon maximal 15 digit")
		.test("inputLength", "Nomor Telepon minimal 9 digit", value => {
			if (!value) return true;

			return !!value && (value.length === 2 || value.length >= 9);
		}),
	icon: Yup.mixed()
		.test("fileFormat", "Format logo tidak support, hanya menerima format .JPG .JPEG .PNG", value => {
			if (value === undefined) return true;
			if (typeof value === "string") {
				return true;
			} else {
				return SUPPORTED_FORMATS.includes(value?.type);
			}
		})
		.test("fileSize", "Ukuran file untuk logo maksimal 5 MB", value => {
			if (value === undefined) return true;
			if (typeof value === "string") {
				return true;
			} else {
				return value?.size <= FILE_SIZE;
			}
		})
		.nullable(),
	pic: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required("Nama PIC wajib di isi"),
			email: Yup.string()
				.email("Email yang dimasukkan harus sesuai dengan format yang valid")
				.required("Email wajib di isi"),
			phoneNumber: Yup.string().required("Nomor telepon wajib di isi").min(9).max(15),
		}),
	),
});

const FormInitialValues = {vendorCode: "", vendorName: "", email: "", phoneNumber: "62", icon: undefined, pic: []};
const DEFAULT_PIC = {name: "", email: "", phoneNumber: "62"};

const MasterVendorAddPage = () => {
	const {showToast} = useToast();
	const history = useHistory();
	const {mutate, isLoading} = useAddVendor();
	const {mutate: uploadFile} = useUploadFile();
	const {mutateAsync: picAdd} = useAddVendorPIC();
	const {data: code} = useGenerateVendorCode();

	const [logoPreview, setLogoPreview] = useState(null);
	const formikRef = useRef();

	const handleEnd = (values, pic) => {
		mutate(values, {
			onSuccess: async result => {
				for (const i of pic) {
					await picAdd({id: result?.data?.id, formData: i});
				}

				showToast("success", "Data berhasil ditambahkan", 3000);
				history.push("/master-vendor");
			},
			onError: res => {
				showToast(
					"error",
					convertErrorMessageFormat(res.response.status, res.response.data.message.message),
					null,
				);
			},
		});
	};

	const handleSubmit = values => {
		const {icon, phoneNumber, pic, ...formDataValue} = values;

		if (icon === undefined || icon === "" || icon === null) {
			handleEnd({icon: null, phoneNumber: phoneNumber.length > 2 ? phoneNumber : "", ...formDataValue}, pic);
		} else {
			let formData = new FormData();

			uploadFile(formData, {
				onSuccess: result => {
					handleEnd(
						{
							icon: result.data?.file,
							phoneNumber: phoneNumber.length > 2 ? phoneNumber : "",
							...formDataValue,
						},
						pic,
					);
				},
			});

			formData.append("file", icon);
		}
	};

	useEffect(() => {
		if (code) {
			formikRef?.current?.setFieldValue("vendorCode", code);
		}
	}, [code]);

	const handleUploadLogo = e => {
		const file = e.target.files[0];
		const reader = new FileReader();

		if (!file) return;
		if (!file.type.includes("image")) {
			formikRef?.current?.setFieldError(
				"icon",
				"Format logo tidak support, hanya menerima format .JPG .JPEG .PNG",
			);

			return;
		}
		if (file.size > 5242880) {
			formikRef?.current?.setFieldError("icon", "Ukuran file untuk logo maksimal 5 MB");

			return;
		}

		reader.onload = () => {
			setLogoPreview(reader.result);
		};
		reader.readAsDataURL(file);
		formikRef.current.setFieldValue("icon", file);
	};

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, values, errors, touched, setFieldValue}) => (
					<FormCard
						backTitle="Daftar list vendor"
						title="Tambah Vendor Baru"
						onBackButtonClick={() => history.push("/master-vendor")}>
						<div class="row gx-2 mb-5">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Kode Vendor</label>
									<input
										class={clsx(
											"form-control",
											touched.vendorCode && errors?.vendorCode && "form-control-error",
										)}
										disabled={true}
										name="vendorCode"
										placeholder=""
										type="text"
										value={values?.vendorCode}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{touched.vendorCode && errors.vendorCode && (
										<p className="form-error-item-message">{errors.vendorCode}</p>
									)}
								</div>
							</div>
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Nama Vendor</label>
									<input
										class={clsx(
											"form-control",
											touched.vendorName && errors?.vendorName && "form-control-error",
										)}
										disabled={isLoading}
										name="vendorName"
										placeholder=""
										type="text"
										value={values?.vendorName}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{touched.vendorName && errors.vendorName && (
										<p className="form-error-item-message">{errors.vendorName}</p>
									)}
								</div>
							</div>
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label">Email</label>
									<input
										class={clsx(
											"form-control",
											touched.email && errors?.email && "form-control-error",
										)}
										disabled={isLoading}
										name="email"
										placeholder=""
										type="text"
										value={values?.email}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{touched.email && errors.email && (
										<p className="form-error-item-message">{errors.email}</p>
									)}
								</div>
							</div>
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label">No Telepon</label>
									<PhoneInput
										className={clsx(
											touched?.phoneNumber && errors?.phoneNumber && "form-control-error",
										)}
										countryCallingCodeEditable={false}
										defaultCountry="ID"
										international={true}
										name="phoneNumber"
										placeholder="Nomor Telepon"
										value={`+${values.phoneNumber}`}
										onBlur={handleBlur}
										onChange={val => {
											setFieldValue("phoneNumber", val?.replace(/\D/g, ""));
										}}
									/>
									{errors.phoneNumber && touched?.phoneNumber && (
										<p className="form-error-item-message">{errors.phoneNumber}</p>
									)}
								</div>
							</div>
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label">Logo</label>
									<div class="change-img mb-4">
										<div class="for-img-change">
											<div class="bx-img-change">
												<img
													alt="Logo Rumah Sakit"
													class="img-fluid"
													src={
														logoPreview
															? logoPreview
															: loadLocalAssets("img/user-default.jpg")
													}
												/>
											</div>
										</div>
										<div class="for-info-change">
											<div class="upload-img-patient">
												<input id="upload" type="file" onChange={handleUploadLogo} />
												<label class="btn-upload-img-patient" for="upload">
													<img
														alt="Upload logo"
														class="img-fluid me-2"
														src={loadLocalAssets("img/icn-cam.svg")}
													/>
													Upload logo
												</label>
											</div>
											<div class="ft-12 text-grey">*Ukuran file: maks 5 Mb (.JPG .JPEG .PNG)</div>
										</div>
									</div>
									{errors?.icon && <p className="form-error-item-message">{errors?.icon}</p>}
								</div>
							</div>
						</div>

						<div class="row gx-2 mb-5">
							<FieldArray name="pic">
								{({remove, push}) => (
									<>
										<div className="col-12 d-flex align-items-center justify-content-between">
											<div className="text-bold">PIC</div>
											{values?.pic.length === 0 ? (
												<a
													className={clsx("text-orange wait-pay")}
													style={{cursor: "pointer"}}
													onClick={() => {
														push(DEFAULT_PIC);
													}}>
													<span className="icon-ico-plus ft-18" />
												</a>
											) : null}
										</div>
										{values.pic.map((item, idx) => {
											return (
												<>
													<div className="col-12 col-lg-6">
														<div className="setting-item">
															<label class="setting-label required">Nama PIC</label>
															<input
																class={clsx(
																	"form-control",
																	touched?.pic?.[idx]?.name &&
																		errors?.pic?.[idx]?.name &&
																		"form-control-error",
																)}
																disabled={isLoading}
																name={`pic.${idx}.name`}
																placeholder=""
																type="text"
																value={values?.pic?.[idx]?.name}
																onBlur={handleBlur}
																onChange={handleChange}
															/>
															{touched?.pic?.[idx]?.name && errors?.pic?.[idx]?.name && (
																<p className="form-error-item-message">
																	{errors?.pic?.[idx]?.name}
																</p>
															)}
														</div>
													</div>
													<div className="col-12 col-lg-6">
														<div className="setting-item">
															<label class="setting-label required">Email</label>
															<input
																class={clsx(
																	"form-control",
																	touched?.pic?.[idx]?.email &&
																		errors?.pic?.[idx]?.email &&
																		"form-control-error",
																)}
																disabled={isLoading}
																name={`pic.${idx}.email`}
																placeholder=""
																type="text"
																value={values?.pic?.[idx]?.email}
																onBlur={handleBlur}
																onChange={handleChange}
															/>
															{touched?.pic?.[idx]?.email &&
																errors?.pic?.[idx]?.email && (
																	<p className="form-error-item-message">
																		{errors?.pic?.[idx]?.email}
																	</p>
																)}
														</div>
													</div>
													<div className="col-12 col-lg-6">
														<div className="setting-item">
															<label class="setting-label required">No Telepon</label>
															<PhoneInput
																className={clsx(
																	touched?.pic?.[idx]?.phoneNumber &&
																		errors?.pic?.[idx]?.phoneNumber &&
																		"form-control-error",
																)}
																countryCallingCodeEditable={false}
																defaultCountry="ID"
																international={true}
																name={`pic.${idx}.phoneNumber`}
																placeholder="Nomor Telepon"
																value={`+${values?.pic?.[idx]?.phoneNumber}`}
																onBlur={handleBlur}
																onChange={val => {
																	setFieldValue(
																		`pic.${idx}.phoneNumber`,
																		val?.replace(/\D/g, ""),
																	);
																}}
															/>
															{errors?.pic?.[idx]?.phoneNumber &&
																touched?.pic?.[idx]?.phoneNumber && (
																	<p className="form-error-item-message">
																		{errors?.pic?.[idx]?.phoneNumber}
																	</p>
																)}
														</div>
													</div>
													<div className="col-1 d-flex align-items-center">
														{idx === values?.pic.length - 1 ? (
															<a
																className={clsx("text-orange wait-pay mt-3")}
																style={{cursor: "pointer"}}
																onClick={() => {
																	push(DEFAULT_PIC);
																}}>
																<span className="icon-ico-plus ft-18" />
															</a>
														) : null}
														<a
															className={clsx("text-orange wait-pay mt-3")}
															style={{cursor: "pointer"}}
															onClick={() => {
																remove(idx);
															}}>
															<span className="icon-ico-delete ft-18" />
														</a>
													</div>
												</>
											);
										})}
									</>
								)}
							</FieldArray>
						</div>

						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!FormSchemaValidation.isValidSync(values)}
								loading={isLoading}
								text="Save"
								onClick={handleSubmit}
							/>
						</div>
					</FormCard>
				)}
			</Formik>
		</DashboardLayout>
	);
};

export {MasterVendorAddPage};
