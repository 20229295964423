import {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {DashboardLayout} from "components/Layout";
import {BasicTable, SearchBox} from "components/Table";
import {ButtonAddTable, ToggleSwitch} from "components/Button";
import {useDoctors, usePublishDoctor} from "hooks/Doctor";
import {isMobile} from "react-device-detect";
import {useStateCallback} from "hooks/useStateCallback";
import {ModalConfirmation} from "components/Modal";
import {convertErrorMessageFormat} from "utils/converter";
import {useToast} from "hooks/useToast";
import {useDeleteStaff} from "hooks/SuperAdmin/Staff";

export function DoctorListPage() {
	const [dataLimit, setDataLimit] = useState(10);
	const [searchKeyword, setSearchKeyword] = useState("");
	const {data: doctors, isLoading, filterDoctors} = useDoctors();

	const {mutate: deleteDoctorById, isLoading: isDeletingData} = useDeleteStaff();
	const {mutate: updateDoctorById, isLoading: isUpdatingData} = usePublishDoctor();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteItemData, setDeleteItemData] = useStateCallback({id: null, name: null});
	const [showPublishModal, setShowPublishModal] = useState(false);
	const [publishItemData, setPublishItemData] = useStateCallback({id: null, name: null});
	const {showToast} = useToast();

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			// {
			// 	Header: "Status Karyawan",
			// 	accessor: "staff.employeeStatusText",
			// },
			{
				Header: "Kode NIP",
				accessor: "staff.nip",
			},
			{
				Header: "Nama Depan",
				accessor: "firstName",
				maxWidth: 150,
			},
			{
				Header: "Nama Belakang",
				accessor: "lastName",
				maxWidth: 150,
			},
			{
				Header: "No Telp",
				accessor: "phoneNumber",
				maxWidth: 150,
			},
			{
				Header: "Usia",
				accessor: "dobText",
				Cell: ({row}) => row?.values?.["dobText"] || "-",
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/doctor/show/${row?.original?.staff?.id}`)}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/staff/edit/${row?.original?.staff?.id}`)}>
							<span class="icon-ico-pencil ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => {
								setDeleteItemData(row?.original, () => setShowDeleteModal(true));
							}}>
							<span class="icon-ico-delete ft-18" />
						</a>
						<ToggleSwitch
							isActive={row.original.isPublish}
							onChange={() => {
								setPublishItemData(row?.original, () => setShowPublishModal(true));
							}}
						/>
					</>
				),
			},
		],
		[],
	);

	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Karyawan",
				accessor: "firstName",
				Cell: ({row}) => (
					<div class="col-9">
						<div class="text-bold">{`${row?.original?.firstName} ${row?.original?.lastName}`}</div>
					</div>
				),
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/doctor/show/${row?.original?.staff?.id}`)}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/staff/edit/${row?.original?.staff?.id}`)}>
							<span class="icon-ico-pencil ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => {
								setDeleteItemData(row?.original, () => setShowDeleteModal(true));
							}}>
							<span class="icon-ico-delete ft-18" />
						</a>
						<ToggleSwitch
							isActive={row.original.isPublish}
							onChange={() => {
								setPublishItemData(row?.original, () => setShowPublishModal(true));
							}}
						/>
					</>
				),
			},
		],
		[],
	);

	const dataWithIDSerial =
		doctors?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (doctors?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const handleDeleteData = () => {
		deleteDoctorById(
			{id: deleteItemData.staff.id},
			{
				onSuccess: () => {
					showToast("success", `Data dokter "${deleteItemData.fullName}" berhasil dihapus`, 3000);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	const handeChangeStatusItem = () => {
		updateDoctorById(
			{id: publishItemData.staff.id},
			{
				onSuccess: () => {
					showToast(
						"success",
						`Data "${publishItemData.fullName}" berhasil di ${
							publishItemData.isPublish ? "non-aktifkan" : "aktifkan"
						}`,
						3000,
					);
					window.scrollTo(0, 0);
					setShowPublishModal(false);
					setPublishItemData({id: null, name: null});
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<>
				<ModalConfirmation
					approveLabel="Ya"
					description={`Apakah Anda yakin ingin  ${
						publishItemData.isPublish ? "non-aktifkan" : "aktifkan"
					} data "${publishItemData.fullName}"?`}
					loading={isUpdatingData}
					rejectLabel="Batal"
					visible={showPublishModal}
					onApprove={handeChangeStatusItem}
					onClose={() => setShowPublishModal(false)}
					onReject={() => setShowPublishModal(false)}
				/>
				<ModalConfirmation
					approveLabel="Ya"
					description={`Apakah Anda yakin ingin menghapus data "${deleteItemData.fullName}"?`}
					loading={isDeletingData}
					rejectLabel="Batal"
					visible={showDeleteModal}
					onApprove={handleDeleteData}
					onClose={() => setShowDeleteModal(false)}
					onReject={() => setShowDeleteModal(false)}
				/>
				<div class="text-bold text-dark mb-4">Data Dokter</div>
				<SearchBox
					rightElement={<ButtonAddTable text="Tambah Dokter" onClick={() => history.push("/staff/tambah")} />}
					onSearch={keyword => {
						filterDoctors({search: keyword, page: 1, limit: dataLimit});
						setSearchKeyword(keyword);
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={doctors?.data?.currentPage}
					data={dataWithIDSerial}
					limit={dataLimit}
					loading={isLoading}
					striped={true}
					title="Data Dokter"
					totalItems={doctors?.data?.totalItems}
					totalPage={doctors?.data?.totalPages}
					onLimitChange={limit => {
						filterDoctors({limit, page: 1, search: searchKeyword});
						setDataLimit(limit);
					}}
					onPageChange={selectedPage =>
						filterDoctors({page: selectedPage, limit: dataLimit, search: searchKeyword})
					}
				/>
			</>
		</DashboardLayout>
	);
}
