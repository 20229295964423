import {useHistory, useParams} from "react-router-dom";
import {DashboardLayout} from "components/Layout";
import {SubmitButton} from "components/Button";
import {CircleLoader} from "components/Loader";
import {FormCard} from "components/Form";
import {useDetailMedicine} from "hooks/SuperAdmin/Medicine";
import {useMedicineUnitOptions} from "hooks/SuperAdmin/MedicineUnit";
import {useSediaanOptions} from "hooks/SuperAdmin/SediaanObat";
import {useEffect, useState} from "react";

export function MedicineDetailPage() {
	const history = useHistory();
	const {id} = useParams();
	const {data: detailMedicine, isLoading: loadingFetcing} = useDetailMedicine(id);
	const {data: medicineUnits} = useMedicineUnitOptions();
	const {data: sediaanList} = useSediaanOptions();

	const [unitsName, setUnitsName] = useState("");
	const [sediaansName, setSediaansName] = useState("");

	useEffect(() => {
		if (detailMedicine) {
			if (medicineUnits?.success) {
				const detailUnits = detailMedicine?.data?.unitV2?.map(u => u.unitId);
				const dataMedicineUnit = medicineUnits?.data?.rows
					?.filter(medicineUnit => detailUnits.includes(medicineUnit?.id))
					.map(medicineUnit => medicineUnit.name)
					.join(", ");

				setUnitsName(dataMedicineUnit);
			}

			if (sediaanList?.success) {
				const detailSediaans = detailMedicine?.data?.sediaan?.map(u => u.sediaanId);
				const dataSediaan = sediaanList?.data?.rows
					?.filter(sd => detailSediaans.includes(sd?.id))
					.map(sd => sd.name)
					.join(", ");

				setSediaansName(dataSediaan);
			}
		}
	}, [detailMedicine, medicineUnits, sediaanList]);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar list unit obat" title="Detail Obat">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar List Obat"
				title={`Detail Data Obat ${detailMedicine?.data?.name}`}
				onBackButtonClick={() => history.push("/obat")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<label class="setting-label ">Kategori Obat</label>
							<p className="text-bold text-grey">{detailMedicine?.data?.category?.name}</p>
						</div>
					</div>
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<label class="setting-label ">Nama Obat</label>
							<p className="text-bold text-grey">{detailMedicine?.data?.name}</p>
						</div>
					</div>
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<label class="setting-label ">Unit Obat</label>
							<p className="text-bold text-grey">{unitsName}</p>
						</div>
					</div>
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<label class="setting-label ">Sediaan</label>
							<p className="text-bold text-grey">{sediaansName}</p>
						</div>
					</div>
				</div>

				<div class="text-center my-3">
					<SubmitButton
						className="btn btn-orange d-inline-block mw-250"
						text="Ubah Data Ini"
						onClick={() => history.push(`/obat/edit/${detailMedicine?.data?.id}`)}
					/>
				</div>
			</FormCard>
		</DashboardLayout>
	);
}
