import clsx from "clsx";
import {SubmitButton} from "components/Button";
import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {Formik} from "formik";
import {useDetailVendorPIC, useEditVendorPIC} from "hooks/SuperAdmin/Vendor";
import {useToast} from "hooks/useToast";
import {useEffect, useRef} from "react";
import PhoneInput from "react-phone-number-input";
import {useHistory, useParams} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";
import * as Yup from "yup";

const FormSchemaValidation = Yup.object().shape({
	name: Yup.string().required("Nama vendor wajib di isi"),
	email: Yup.string()
		.email("Email yang dimasukkan harus sesuai dengan format yang valid")
		.required("Email wajib di isi"),
	phoneNumber: Yup.string().required("Nomor telepon wajib di isi").min(9).max(15),
});

const FormInitialValues = {name: "", email: "", phoneNumber: "62"};

const VendorPICEditPage = () => {
	const formikRef = useRef();
	const {showToast} = useToast();
	const history = useHistory();
	const {id, picId} = useParams();
	const {data: detailPIC, isLoading: isFetching} = useDetailVendorPIC(id, picId);

	const {mutate, isLoading} = useEditVendorPIC();

	const handleSubmit = formData => {
		mutate(
			{id, picId, formData},
			{
				onSuccess: () => {
					showToast("success", "Data berhasil diubah", 3000);
					history.push(`/master-vendor/${id}/PIC`);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	useEffect(() => {
		formikRef.current?.setFieldValue("name", `${detailPIC?.data?.firstName} ${detailPIC?.data?.lastName}`);
		formikRef.current?.setFieldValue("email", detailPIC?.data?.email);
		formikRef.current?.setFieldValue("phoneNumber", detailPIC?.data?.phoneNumber);
	}, [detailPIC]);

	if (isFetching) {
		return (
			<DashboardLayout>
				<FormCard title="Edit Vendor">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, values, errors, touched, setFieldValue}) => (
					<FormCard
						backTitle="Daftar list PIC"
						title="Edit PIC"
						onBackButtonClick={() => history.push(`/master-vendor/${id}/PIC`)}>
						<div class="row gx-2 mb-5">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Nama PIC</label>
									<input
										class={clsx(
											"form-control",
											touched.name && errors?.name && "form-control-error",
										)}
										disabled={isLoading}
										name="name"
										placeholder=""
										type="text"
										value={values?.name}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{touched.name && errors.name && (
										<p className="form-error-item-message">{errors.name}</p>
									)}
								</div>
							</div>
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Email</label>
									<input
										class={clsx(
											"form-control",
											touched.email && errors?.email && "form-control-error",
										)}
										disabled={isLoading}
										name="email"
										placeholder=""
										type="text"
										value={values?.email}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{touched.email && errors.email && (
										<p className="form-error-item-message">{errors.email}</p>
									)}
								</div>
							</div>
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">No Telepon</label>
									<PhoneInput
										className={clsx(
											touched?.phoneNumber && errors?.phoneNumber && "form-control-error",
										)}
										countryCallingCodeEditable={false}
										defaultCountry="ID"
										international={true}
										name="phoneNumber"
										placeholder="Nomor Telepon"
										value={`+${values.phoneNumber}`}
										onBlur={handleBlur}
										onChange={val => {
											setFieldValue("phoneNumber", val?.replace(/\D/g, ""));
										}}
									/>
									{errors.phoneNumber && touched?.phoneNumber && (
										<p className="form-error-item-message">{errors.phoneNumber}</p>
									)}
								</div>
							</div>
						</div>

						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!FormSchemaValidation.isValidSync(values)}
								loading={isLoading}
								text="Save"
								onClick={handleSubmit}
							/>
						</div>
					</FormCard>
				)}
			</Formik>
		</DashboardLayout>
	);
};

export {VendorPICEditPage};
