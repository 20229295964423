import clsx from "clsx";
import {useState} from "react";

export function Select({
	name,
	onChange,
	onClick,
	value,
	error,
	disabled,
	containerClassName = "setting-item",
	label,
	required,
	placeholder = "Select something",
	options = [],
	loading = false,
}) {
	const [selectedItem, setSelectedItem] = useState(value || {});

	const handleSelectedItem = selectedValue => {
		const selectedOptions = options?.find(option => option.value === selectedValue) || {};

		setSelectedItem(selectedOptions);
		onChange(selectedOptions);
	};

	return (
		<div class={containerClassName}>
			<label class={clsx("setting-label ", required && "required")}>{label}</label>
			<div class="for-setting position-relative">
				<select
					class="form-select"
					disabled={disabled || loading}
					name={name}
					onChange={e => handleSelectedItem(e?.target?.value)}
					onClick={onClick}>
					{!value && (
						<option disabled hidden selected value="">
							{loading ? "Loading..." : placeholder}
						</option>
					)}
					{options.map(item => {
						return (
							<option
								key={`${item.value}-${item.label}`}
								selected={
									selectedItem?.value === item?.value ||
									value === item?.value ||
									value?.value === item?.value
								}
								value={item.value}>
								{item.label}
							</option>
						);
					})}
				</select>
			</div>
			{error && <p className="form-error-item-message">{error}</p>}
		</div>
	);
}
