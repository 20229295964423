import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {ProfileImageInitial} from "components/ProfileImage";
import dayjs from "dayjs";
import {useArticleUser} from "hooks/SuperAdmin/Article";
import {useEffect} from "react";
import {Button} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import {converToLocalGender} from "utils/converter";

const UserPasienDetailPage = () => {
	const history = useHistory();
	const {id} = useParams();
	const {data: detail, setArticleUserId} = useArticleUser("patient");

	useEffect(() => {
		if (id) {
			setArticleUserId(id);
		}
	}, [id]);

	return (
		<DashboardLayout>
			<FormCard
				action={<Button variant="outline-danger">Suspend User</Button>}
				backTitle="Daftar List User"
				title={`Detail Data`}
				// title={`Detail Data ${detail?.data?.firstName} ${detail?.data?.lastName}`}
				onBackButtonClick={() => history.push("/user")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<div class="change-img mb-4">
								<div class="for-img-change">
									<div class="bx-img-change">
										{!detail?.data?.profilePhoto && (
											<ProfileImageInitial
												height={108}
												isMale={detail?.data?.gender === "male"}
												text={`${detail?.data?.firstName[0]}${detail?.data?.lastName[0]}`}
												width={108}
											/>
										)}
										{detail?.data?.profilePhoto && (
											<img alt="" className="img-fluid" src={detail?.data?.profilePhoto} />
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<label class="setting-label ">Title</label>
							<p className="text-bold text-grey">{detail?.data?.title || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Depan</label>
							<p className="text-bold text-grey">{detail?.data?.firstName || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Belakang</label>
							<p className="text-bold text-grey">{detail?.data?.lastName || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Panggilan</label>
							<p className="text-bold text-grey">{detail?.data?.nickname || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Email</label>
							<p className="text-bold text-grey">{detail?.data?.email || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nomor Telepon</label>
							<p className="text-bold text-grey">{detail?.data?.phoneNumber || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Jenis Kelamin</label>
							<p className="text-bold text-grey">{converToLocalGender(detail?.data?.gender) || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Status Pernikahan</label>
							<p className="text-bold text-grey">{detail?.data?.maritalStatus || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Tanggal Lahir</label>
							<p className="text-bold text-grey">
								{dayjs(detail?.data?.dateOfBirth).format("DD-MMM-YYYY") || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">ID Number/KTP</label>
							<p className="text-bold text-grey">{detail?.data?.patient?.nik || "-"}</p>
						</div>
					</div>
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<label class="setting-label ">Kontak Gawat Darurat</label>
							<ul className="list-group list-group-flush col-6">
								{detail?.data?.patient?.emergencyContacts?.map(item => (
									<li className="list-group-item text-bold text-grey col-6">
										{item.relation}: {item.phoneNumber}
									</li>
								))}
							</ul>
							{!detail?.data?.patient?.emergencyContacts && <p>-</p>}
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Remark</label>
							<p className="text-bold text-grey">{detail?.data?.patient?.remark || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Medicare Number</label>
							<p className="text-bold text-grey">{detail?.data?.patient?.medicareNumber || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nomor Rekam Medis</label>
							<p className="text-bold text-grey">{detail?.data?.patient?.medicalRecordNumber || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Email Invoice</label>
							<p className="text-bold text-grey">{detail?.data?.patient?.emailInvoice || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Comunication Preference</label>
							<p className="text-bold text-grey">
								{detail?.data?.patient?.communicationPreferences?.map((item, index) => {
									return index === 0 ? item : ` & ${item}`;
								})}
								{!detail?.data?.patient?.communicationPreferences && "-"}
							</p>
						</div>
					</div>
				</div>

				{/* addressPayment */}
				<p className="text-bold text-dark mt-4 mb-2">Alamat Pembayaran</p>
				<div class="row gx-2 mb-5">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Alamat</label>
							<p className="text-bold text-grey">
								{detail?.data?.patient?.addressPayment?.address || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Alamat Detail</label>
							<p className="text-bold text-grey">
								{detail?.data?.patient?.addressPayment?.addressDetail || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Koordinat</label>
							<p className="text-bold text-grey">
								{detail?.data?.patient?.addressPayment?.longitude || "-"}
							</p>
							<p className="text-bold text-grey">
								{detail?.data?.patient?.addressPayment?.latitude || "-"}
							</p>
						</div>
					</div>
				</div>

				{/* addresShipment */}
				<p className="text-bold text-dark mt-4 mb-2">Alamat Pengantaran</p>
				<div class="row gx-2 mb-5">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Depan</label>
							<p className="text-bold text-grey">
								{detail?.data?.patient?.addressShipment?.firstName || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Belakang</label>
							<p className="text-bold text-grey">
								{detail?.data?.patient?.addressShipment?.lastName || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Email</label>
							<p className="text-bold text-grey">
								{detail?.data?.patient?.addressShipment?.email || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nomor Telepon</label>
							<p className="text-bold text-grey">
								{detail?.data?.patient?.addressShipment?.phoneNumber || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Perusahaan</label>
							<p className="text-bold text-grey">
								{detail?.data?.patient?.addressShipment?.companyName || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Negara</label>
							<p className="text-bold text-grey">
								{detail?.data?.patient?.addressShipment?.country || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Provinsi</label>
							<p className="text-bold text-grey">
								{detail?.data?.patient?.addressShipment?.province || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Kota</label>
							<p className="text-bold text-grey">{detail?.data?.patient?.addressShipment?.city || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Kode Pos</label>
							<p className="text-bold text-grey">
								{detail?.data?.patient?.addressShipment?.postalCode || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Alamat</label>
							<p className="text-bold text-grey">
								{detail?.data?.patient?.addressShipment?.address || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Alamat Detail</label>
							<p className="text-bold text-grey">
								{detail?.data?.patient?.addressShipment?.addressDetail || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Koordinat</label>
							<p className="text-bold text-grey">
								{detail?.data?.patient?.addressShipment?.longitude || "-"}
							</p>
							<p className="text-bold text-grey">{detail?.data?.patient?.addressShipment?.latitude}</p>
						</div>
					</div>
				</div>
			</FormCard>
		</DashboardLayout>
	);
};

export {UserPasienDetailPage};
