import {BounceLoader} from "components/Loader/BounceLoader";
import {useDoctorSchedules} from "hooks/Doctor";
import {useEffect, useMemo, useState} from "react";

import {SelectDay} from "./SelectDay";
import {SelectTime} from "./SelectTime";

export function ScheduleSelect(props) {
	const {type, doctorId, hospitalId, onSelectDateTime} = props;

	const [currentPage, setCurrentPage] = useState(1);
	const [selectedDay, setSelectedDay] = useState({id: null, date: null});
	const [selectedTime, setSelectedTime] = useState(null);

	useEffect(
		() => {
			if (selectedDay.date && selectedTime.startTime) onSelectDateTime({selectedDay, selectedTime});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedTime, selectedDay],
	);

	useEffect(() => {
		if (type || doctorId) {
			setCurrentPage(1);
		}
	}, [type, doctorId]);

	useEffect(() => {
		setSelectedDay({id: null, date: null});
	}, [currentPage]);

	const {data: doctorDateSchedules, isLoading: fetchingSchedule} = useDoctorSchedules({
		// hospitalId: selectedLocation.hospitalId || undefined,
		userId: doctorId,
		type,
		page: currentPage,
		limit: 7,
	});

	const schedules = useMemo(() => {
		if (doctorDateSchedules?.data) {
			return doctorDateSchedules.data.map(item => {
				return {
					...item,
					id: item.day,
					originalDate: item.date,
				};
			});
		}

		return [];
	}, [doctorDateSchedules?.data]);

	if (fetchingSchedule)
		return (
			<div
				className="d-flex justify-content-center align-items-center align-content-center"
				style={{height: "40vh"}}>
				<BounceLoader color="#45969b" />
			</div>
		);

	return (
		<div className="inner-pick-day">
			<SelectDay
				currentPage={currentPage}
				data={schedules}
				selectedDayId={selectedDay?.id}
				// selectedLocation={selectedLocation.hospitalId}
				onNext={() => setCurrentPage(currentPage + 1)}
				onPrev={() => setCurrentPage(currentPage - 1)}
				onSelect={selected => {
					setSelectedDay(selected);
				}}
			/>

			<SelectTime
				data={schedules}
				day={selectedDay?.originalDate}
				location={type === "offline" ? hospitalId : null}
				selectedDayId={selectedDay?.id}
				onSelect={selected => {
					setSelectedTime(selected);

					if (selected?.prices?.length) {
						setSelectedDay(prev => ({...prev, prices: selected.prices}));
					}
				}}
			/>
		</div>
	);
}
