import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY_PEMERIKSAAN_PENUNJANG} from "./useQueryPemeriksaanPenunjang";

export function useAddPemeriksaanPenunjang() {
	return useMutation(values => axios.post("/checkup_support/create", values).then(res => res.data));
}
export function useUpdatePemeriksaanPenunjang() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/checkup_support/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_PEMERIKSAAN_PENUNJANG);
		},
	});
}

export function useDeletePemeriksaanPenunjang() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/checkup_support/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_PEMERIKSAAN_PENUNJANG);
		},
	});
}

export function useChangeStatusPemeriksaanPenunjang() {
	const queryClient = useQueryClient();

	return useMutation(
		formData => axios.post("/checkup_support/change_status_checkup_support", formData).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(QUERY_PEMERIKSAAN_PENUNJANG);
			},
		},
	);
}
