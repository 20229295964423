import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

export const QUERY = "fileLabs";

export function useListFileLab(filter) {
	const result = useQuery([QUERY, filter], () =>
		axios.get(`/patient/lab/patient?${qs.stringify(filter)}`).then(res => res.data),
	);

	return result;
}

export function useListFileLabByPatientId(id, filter) {
	const result = useQuery(
		[QUERY, id, filter],
		() => axios.get(`/patient/lab/?idPatient=${id}&${qs.stringify(filter)}`).then(res => res.data),
		{
			enabled: !!id,
		},
	);

	return result;
}

// Fetch File By ID

export const fetchFileLabById = id => {
	return axios.get(`patient/lab/${id}`).then(res => res.data);
};

export function useDetailFileLab(id) {
	return useQuery({
		queryKey: id && [QUERY, id],
		queryFn: key => fetchFileLabById(id),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
}
