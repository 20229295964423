/* eslint-disable no-unused-vars */
import {useEffect, useRef, useState} from "react";
import clsx from "clsx";
import * as Yup from "yup";
import {useHistory} from "react-router-dom";
import {DashboardLayout} from "components/Layout";
import {Formik} from "formik";
import {SubmitButton} from "components/Button";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";
import {useActionCategoryOptions} from "hooks/SuperAdmin/ActionCategory";
import {useEditAction, useDetailAction} from "hooks/SuperAdmin/Action";
import {CircleLoader} from "components/Loader";
import {FormCard} from "components/Form";
import {useParams} from "react-router-dom";
import {BasicDropdown} from "components/Dropdown";

const FormSchemaValidation = Yup.object().shape({
	title: Yup.string().required("Nama tindakan wajib di isi"),
	actionCategoryId: Yup.number().required("Kategori tindakan wajib di isi"),
});
const FormInitialValues = {title: "", actionCategoryId: ""};

export function ActionEditPage() {
	const [actionCategoryOptions, setActionCategoryOptions] = useState([]);
	const {data: actionCategories} = useActionCategoryOptions();
	const {showToast} = useToast();
	const formikRef = useRef();
	const history = useHistory();
	const params = useParams();
	const {id} = params;
	const {isLoading: loadingFetcing, error, isError, data: detailActionCategory} = useDetailAction(id);
	const {mutate, isLoading: isSubmitting} = useEditAction();
	const handleSubmit = formData => {
		mutate(
			{id, ...formData},
			{
				onSuccess: () => {
					showToast("success", "Data berhasil diperbarui");
					history.push("/tindakan");
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	useEffect(() => {
		if (actionCategories?.success) {
			const dataActionCategory = actionCategories?.data?.rows?.map(actionCategory => ({
				value: actionCategory?.id + "",
				label: `${actionCategory?.title}`,
			}));

			setActionCategoryOptions([...dataActionCategory]);
			formikRef?.current?.setFieldValue(`actionCategoryId`, dataActionCategory[0].value);
		}
	}, [actionCategories]);

	useEffect(() => {
		if (detailActionCategory?.success) {
			Object.keys(FormInitialValues).forEach(key => {
				formikRef?.current?.setFieldValue(key, detailActionCategory?.data?.[key]);
			});
		}
	}, [detailActionCategory, id]);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard title="Edit Tindakan">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, values, errors, touched, setFieldValue}) => (
					<FormCard
						backTitle="Daftar List Tindakan"
						title="Edit Tindakan"
						onBackButtonClick={() => history.push("/tindakan")}>
						<div class="row gx-2 mb-5">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Tindakan</label>
									<BasicDropdown
										className={clsx(
											"form-control",
											errors?.actionCategoryId && "form-control-error",
										)}
										data={actionCategoryOptions}
										name="actionCategoryId"
										value={values?.actionCategoryId}
										onSelect={fieldValue => {
											setFieldValue("actionCategoryId", fieldValue);
										}}
									/>
									{errors.actionCategoryId && (
										<p className="form-error-item-message">{errors.actionCategoryId}</p>
									)}
								</div>
							</div>
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Nama Tindakan</label>
									<input
										class={clsx(
											"form-control",
											errors?.title && touched?.title && "form-control-error",
										)}
										disabled={isSubmitting}
										name="title"
										placeholder=""
										type="text"
										value={values?.title}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.title && touched?.title && (
										<p className="form-error-item-message">{errors.title}</p>
									)}
								</div>
							</div>
						</div>

						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!FormSchemaValidation.isValidSync(values)}
								loading={isSubmitting}
								text="Simpan Perubahan"
								onClick={handleSubmit}
							/>
						</div>
					</FormCard>
				)}
			</Formik>
		</DashboardLayout>
	);
}
