import {useHistory, useParams} from "react-router-dom";
import {loadLocalAssets} from "utils/loader";
import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {SubmitButton} from "components/Button";
import {useDetailHospital} from "hooks/SuperAdmin/Hospital";

export function MasterRumahSakitDetailPage() {
	const history = useHistory();
	const params = useParams();
	const {id} = params;

	const {data: detailHospital, isLoading: loadingFetcing} = useDetailHospital(id);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar List Rumah Sakit" title="Edit Data Rumah Sakit">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar List Rumah Sakit"
				title={`Detail Data ${detailHospital?.data?.name}`}
				onBackButtonClick={() => history.push("/master-rumah-sakit")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label ">Logo</label>
							<div class="change-img mb-4">
								<div class="for-img-change">
									<div class="bx-img-change">
										<img
											alt="Logo Rumah Sakit"
											class="img-fluid"
											src={
												detailHospital?.data?.logo
													? detailHospital?.data?.logo
													: loadLocalAssets("img/user-default.jpg")
											}
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Nama Rumah Sakit</label>
							<p className="text-bold text-grey">{detailHospital?.data?.name}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Alamat</label>
							<p className="text-bold text-grey">{detailHospital?.data?.address}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Alama Pengiriman</label>
							<p className="text-bold text-grey">{detailHospital?.data?.shippingAddress}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Nomor Telepon</label>
							<p className="text-bold text-grey">{detailHospital?.data?.phone}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Email</label>
							<p className="text-bold text-grey">{detailHospital?.data?.email}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Fax</label>
							<p className="text-bold text-grey">{detailHospital?.data?.fax}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">NPWP</label>
							<p className="text-bold text-grey">{detailHospital?.data?.npw}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Website</label>
							<p className="text-bold text-grey">
								<a href={detailHospital?.data?.website} rel="noreferrer" target="_blank">
									{detailHospital?.data?.website}
								</a>
							</p>
						</div>
					</div>

					<div class="col-sm-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Bank</label>
							<p className="text-bold text-grey">{detailHospital?.data?.bankProvider}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">No Rekening Bank</label>
							<p className="text-bold text-grey">{detailHospital?.data?.bankAccountNumber}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">A.N Bank</label>
							<p className="text-bold text-grey">{detailHospital?.data?.bankAccountHolder}</p>
						</div>
					</div>
				</div>

				<div class="text-center my-3">
					<SubmitButton
						className="btn btn-orange d-inline-block mw-250"
						text="Ubah Data Ini"
						onClick={() => history.push(`/master-rumah-sakit/edit/${id}`)}
					/>
				</div>
			</FormCard>
		</DashboardLayout>
	);
}
