import {useMutation, useQueryClient} from "react-query";
import axios from "services/axios";

async function addStaff(data) {
	await axios.post("/staff/create", data);
}

export function useAddStaff() {
	const queryClient = useQueryClient();

	const {mutate, isLoading, isError, data, error} = useMutation(data => addStaff(data), {
		onSuccess: () => {
			queryClient.invalidateQueries("staffs");
			queryClient.removeQueries("staffs");
			queryClient.invalidateQueries("doctors");
			queryClient.removeQueries("doctors");
		},
	});

	return {mutate, isLoading, isError, data, error};
}

export function useEditStaff(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(values => axios.post("/staff/update", values).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("staffs");
			queryClient.invalidateQueries("doctors");
		},
	});
}

export function useDeleteStaff(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(values => axios.post("/staff/delete", values).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("staffs");
			queryClient.removeQueries("staffs");
			queryClient.invalidateQueries("doctors");
			queryClient.removeQueries("doctors");
		},
	});
}
