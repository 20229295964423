import {createSlice} from "@reduxjs/toolkit";

const initialState = {
	formValue: {
		bookingId: null,
		subjective: "",
		objective: "",
		keluhan: "",
		riwayat: "",
		diagnoseId: null,
		diagnoseDifferential: "",
		bodyCheckups: [],
		bodyCheckupResult: "",
		checkupSupports: [
			{
				unitId: null,
				checkupCategories: [
					{
						checkupCategoryId: null,
						appointmentDate: "",
						appointmentNote: "",
						actions: [
							{
								checkupSupportHospitalId: null,
								price: null,
							},
							{
								checkupSupportHospitalId: null,
								price: null,
							},
						],
					},
				],
			},
		],
		actionDivisionId: null,
		actionDivisionPrice: null,
		medicines: [
			{
				medicineHospitalId: null,
				total: null,
				frequency: null,
				period: null,
				note: null,
				price: null,
				totalPrice: null,
			},
		],
		nextAppointment: {
			idHospital: null,
			idUserDoctor: null,
			idUserPatient: null,
			date: "",
			startTime: "",
			endTime: "",
			type: "",
			price: null,
		},
		servicePrice: null,
	},
};

export const medicalRecordFormSlice = createSlice({
	name: "@MEDICAL_RECORD_FORM",
	initialState,
	reducers: {
		setFormValue: (state, action) => {
			state.formValue = action.payload;
		},
	},
});

export const {setFormValue} = medicalRecordFormSlice.actions;

export default medicalRecordFormSlice.reducer;
