import {DashboardLayout} from "components/Layout";
import {useState} from "react";
import {isMobile} from "react-device-detect";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

import {TabReportPost} from "./TabReportPost";
import {TabReportComment} from "./TabReportComment";

const ReportListPage = () => {
	const [tab, setTab] = useState(0);

	return (
		<DashboardLayout>
			<Tabs className="mb-0" selectedIndex={tab} onSelect={index => setTab(index)}>
				<div class="" style={isMobile ? {"overflow-x": "auto", "white-space": "nowrap"} : {}}>
					<TabList className="border-0">
						<Tab>Report Post - Artikel</Tab>
						<Tab>Report Comment</Tab>
					</TabList>
				</div>

				<div className="box-white setting-content h-100 no-border p24">
					<TabPanel>
						<TabReportPost />
					</TabPanel>
					<TabPanel>
						<TabReportComment />
					</TabPanel>
				</div>
			</Tabs>
		</DashboardLayout>
	);
};

export {ReportListPage};
