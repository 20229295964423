/* eslint-disable no-unused-vars */
import {useEffect, useState, useRef} from "react";
import clsx from "clsx";
import {SubmitButton} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {Formik} from "formik";
import {useAddHospitalMedicine} from "hooks/AdminHospital/Medicine";
import {useToast} from "hooks/useToast";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {convertErrorMessageFormat, convertToCurency} from "utils/converter";
import * as Yup from "yup";
import {useMedicineCategoryOptions} from "hooks/AdminHospital/MedicineCategory";
import {useMedicineUnitOptions} from "hooks/AdminHospital/MedicineUnit";
import {useMedicineOptions} from "hooks/AdminHospital/Medicine";
import {BasicDropdown} from "components/Dropdown";
import {useListDivisionById} from "hooks/AdminHospital/Division";

const FormSchemaValidation = Yup.object().shape({
	divisionId: Yup.string().required("Divisi wajib di isi"),
	medicineId: Yup.string().required("Nama Obat wajib di isi"),
	type: Yup.string().required("Status pakai wajib di isi"),
	stock: Yup.string().required("Stok wajib di isi"),
	buyPrice: Yup.number()
		.required("Harga beli wajib diisi")
		.min(1, "Harga beli wajib diisi")
		.transform(v => (Number.isNaN(v) ? 0 : v)),
	sellPrice: Yup.number()
		.required("Harga jual wajib diisi")
		.min(1, "Harga jual wajib diisi")
		.transform(v => (Number.isNaN(v) ? 0 : v)),
});
const FormInitialValues = {
	hospitalId: "",
	divisionId: "",
	medicineId: "",
	type: "habis_pakai",
	stock: "",
	buyPrice: 0,
	buyPriceStr: "",
	sellPrice: 0,
	sellPriceAStr: "",
	statusApproval: "pending",
};

export function MedicineAddPage() {
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);
	const {showToast} = useToast();
	const history = useHistory();
	const {mutate, isLoading} = useAddHospitalMedicine();
	const formikRef = useRef();
	const [medicineCategoryOptions, setMedicineCategoryOptions] = useState([]);
	const {data: medicineCategories} = useMedicineCategoryOptions(selectedHospital?.Hospital?.id);
	const [medicineUnitOptions, setMedicineUnitOptions] = useState([]);
	const {data: medicineUnits} = useMedicineUnitOptions(selectedHospital?.Hospital?.id);
	const [medicineOptions, setMedicineOptions] = useState([]);
	const {data: medicines} = useMedicineOptions(selectedHospital?.Hospital?.id);
	const {data: divisions} = useListDivisionById({hospitalId: selectedHospital.Hospital.id, isActive: true});
	const [divisionOptions, setDivisionOptions] = useState([]);
	const statusProductOptions = [
		{
			value: "habis_pakai",
			label: "Habis Pakai",
		},
		{
			value: "tidak_habis_pakai",
			label: "Tidak Habis Pakai",
		},
	];
	const handleSubmit = formData => {
		mutate(formData, {
			onSuccess: () => {
				showToast("success", "Data berhasil ditambahkan", 3000);
				history.push("/sub-admin-divisi/medicine/list");
			},
			onError: res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
			},
		});
	};

	useEffect(() => {
		if (medicineCategories?.success) {
			const dataMedicineCategory = medicineCategories?.data?.rows?.map(medicineCategory => ({
				value: medicineCategory?.id + "",
				label: `${medicineCategory?.name}`,
			}));

			setMedicineCategoryOptions([...dataMedicineCategory]);
			formikRef?.current.setFieldValue(`medicineCategoryId`, dataMedicineCategory[0].value);
		}
	}, [medicineCategories]);

	useEffect(() => {
		if (medicineUnits?.success) {
			const dataMedicineUnit = medicineUnits?.data?.rows?.map(medicineUnit => ({
				value: medicineUnit?.id + "",
				label: `${medicineUnit?.name}`,
			}));

			setMedicineUnitOptions([...dataMedicineUnit]);
			formikRef?.current.setFieldValue(`medicineUnitId`, dataMedicineUnit[0].value);
		}
	}, [medicineUnits]);

	useEffect(() => {
		if (medicines?.success) {
			const dataMedicine = medicines?.data?.rows?.map(medicine => ({
				value: medicine?.id + "",
				label: `${medicine?.name}`,
			}));

			setMedicineOptions([...dataMedicine]);
			formikRef?.current.setFieldValue(`medicineId`, dataMedicine[0].value);
		}
	}, [medicines]);

	useEffect(() => {
		formikRef.current.setFieldValue("hospitalId", selectedHospital?.Hospital?.id);
	}, [selectedHospital?.Hospital?.id]);

	useEffect(() => {
		if (divisions?.success) {
			const dataDivision = divisions?.data?.rows?.map(division => ({
				value: division?.id + "",
				label: `${division?.name}`,
			}));

			setDivisionOptions([...dataDivision]);
			formikRef?.current.setFieldValue(`divisionId`, dataDivision[0]?.value);
		}
	}, [divisions]);

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, values, errors, setFieldValue, touched}) => (
					<div class="box-white setting-content h-100 no-border p24">
						<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
							<div class="ttl-20">Tambah Obat Baru</div>
						</div>

						<hr />

						<div class="text-bold mb-4">
							<a
								class="text-dark"
								style={{cursor: "pointer"}}
								onClick={() => history.push("/sub-admin-divisi/medicine/list")}>
								<span class="icon-ico-back me-2" />
							</a>
							Daftar List Obat
						</div>

						<div class="row gx-2">
							{/* <div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Kategori Obat</label>
									<BasicDropdown
										className={clsx(
											"form-control",
											errors?.medicineCategoryId && "form-control-error",
										)}
										data={medicineCategoryOptions}
										name="medicineCategoryId"
										value={values?.medicineCategoryId}
										onSelect={fieldValue => {
											setFieldValue("medicineCategoryId", fieldValue);
										}}
									/>
									{errors.medicineCategoryId && touched.medicineCategoryId && (
										<p className="form-error-item-message">{errors.medicineCategoryId}</p>
									)}
								</div>
							</div> */}
						</div>
						{/* <div class="row gx-2">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Unit Obat</label>
									<BasicDropdown
										className={clsx("form-control", touched?.medicineUnitId && && errors?.medicineUnitId && "form-control-error")}
										data={medicineUnitOptions}
										name="medicineUnitId"
										value={values?.medicineUnitId}
										onSelect={fieldValue => {
											setFieldValue("medicineUnitId", fieldValue);
										}}
									/>
									{errors.medicineUnitId && touched.medicineUnitId && (
										<p className="form-error-item-message">{errors.medicineUnitId}</p>
									)}
								</div>
							</div>
						</div> */}
						<div class="row gx-2">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Nama Obat</label>
									<BasicDropdown
										className={clsx(
											"form-control",
											errors?.medicineId && touched?.medicineId && "form-control-error",
										)}
										data={medicineOptions}
										name="medicineId"
										value={values?.medicineId}
										onSelect={fieldValue => {
											setFieldValue("medicineId", fieldValue);
										}}
									/>
									{errors.medicineId && touched.medicineId && (
										<p className="form-error-item-message">{errors.medicineId}</p>
									)}
								</div>
							</div>
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Divisi</label>
									<BasicDropdown
										className={clsx(
											"form-control",
											errors?.divisionId && touched?.divisionId && "form-control-error",
										)}
										data={divisionOptions}
										name="divisionId"
										value={values?.divisionId}
										onSelect={fieldValue => {
											setFieldValue("divisionId", fieldValue);
										}}
									/>
									{errors.divisionId && touched?.divisionId && (
										<p className="form-error-item-message">{errors.divisionId}</p>
									)}
								</div>
							</div>
						</div>
						<div class="row gx-2">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Harga Beli</label>
									<input
										class={clsx(
											"form-control",
											errors?.buyPrice && touched?.buyPrice && "form-control-error",
										)}
										disabled={isLoading}
										name="buyPrice"
										placeholder=""
										type="text"
										value={values?.buyPriceStr}
										onBlur={handleBlur}
										onChange={e => {
											let value = e.target.value.replace(/\D/g, "");

											setFieldValue(`buyPrice`, value);

											value = convertToCurency(value);

											setFieldValue(`buyPriceStr`, value);
										}}
									/>
									{errors.buyPrice && touched?.buyPrice && (
										<p className="form-error-item-message">{errors.buyPrice}</p>
									)}
								</div>
							</div>
						</div>
						<div class="row gx-2">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Harga Jual</label>
									<input
										class={clsx(
											"form-control",
											errors?.sellPrice && touched?.sellPrice && "form-control-error",
										)}
										disabled={isLoading}
										name="sellPrice"
										placeholder=""
										type="text"
										value={values?.sellPriceStr}
										onBlur={handleBlur}
										onChange={e => {
											let value = e.target.value.replace(/\D/g, "");

											setFieldValue(`sellPrice`, value);

											value = convertToCurency(value);

											setFieldValue(`sellPriceStr`, value);
										}}
									/>
									{errors.sellPrice && touched?.sellPrice && (
										<p className="form-error-item-message">{errors.sellPrice}</p>
									)}
								</div>
							</div>
						</div>
						<div class="row gx-2">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Status Product</label>
									<BasicDropdown
										className={clsx(
											"form-control",
											errors?.statusProduct && touched?.statusProduct && "form-control-error",
										)}
										data={statusProductOptions}
										name="statusProduct"
										value={values?.statusProduct}
										onSelect={fieldValue => {
											setFieldValue("statusProduct", fieldValue);
										}}
									/>
									{errors.statusProduct && touched?.statusProduct && (
										<p className="form-error-item-message">{errors.statusProduct}</p>
									)}
								</div>
							</div>
						</div>
						<div class="row gx-2 mb-5">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Stok</label>
									<input
										class={clsx(
											"form-control",
											errors?.stock && touched?.stock && "form-control-error",
										)}
										disabled={isLoading}
										name="stock"
										placeholder=""
										type="text"
										value={values?.stock}
										onBlur={handleBlur}
										onChange={e => {
											let value = e.target.value.replace(/\D/g, "");

											setFieldValue(`stock`, value);
										}}
									/>
									{errors.stock && touched?.stock && (
										<p className="form-error-item-message">{errors.stock}</p>
									)}
								</div>
							</div>
						</div>
						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!FormSchemaValidation.isValidSync(values)}
								loading={isLoading}
								text="Simpan"
								onClick={handleSubmit}
							/>
						</div>
					</div>
				)}
			</Formik>
		</DashboardLayout>
	);
}
