import * as Yup from "yup";

const SUPPORTED_FORMATS = ["application/pdf"];

const FileLabFormSchemaValidation = Yup.object().shape({
	visibility: Yup.string().required("Visibility wajib di pilih"),
	idPatient: Yup.string().required("Pasien wajib di pilih"),
	idHospital: Yup.string().required("Rumah Sakit wajib di pilih"),
	date: Yup.string().required("Tanggal wajib di pilih"),
	fileBuffer: Yup.mixed()
		.required("File wajib di upload")
		.test("fileFormat", "Format file tidak mendukung, hanya menerima format .PDF", value => {
			if (!value) return false;
			if (typeof value === "string") {
				return true;
			} else {
				return value.every(e => SUPPORTED_FORMATS.includes(e.type));
			}
		}),
});

export {FileLabFormSchemaValidation};
