import {useQuery} from "react-query";
import axios from "services/axios";

export function useListVendor({page, search, limit}) {
	const query = "vendor";
	const result = useQuery([query, page, limit, search], () =>
		axios.get(`/v2/vendor?page=${page}&search=${search}&limit=${limit}`).then(res => res.data),
	);

	return result;
}

export function useListVendorPIC({page, search, limit}, vendorId) {
	const query = "vendorPIC";
	const result = useQuery([query, page, search], () =>
		axios.get(`/v2/vendor/${vendorId}/pic?page=${page}&search=${search}&limit=${limit}`).then(res => res.data),
	);

	return result;
}

// Fetch Vendor By ID

export const fetchVendor = vendorId => {
	return axios.get(`/v2/vendor/${vendorId}/show`).then(res => res.data);
};

export function useDetailVendor(vendorId) {
	return useQuery({
		queryKey: vendorId && ["vendor", vendorId],
		queryFn: key => fetchVendor(vendorId),
		refetchOnMount: true,
		refetchOnWindowFocus: false,
		enabled: !!vendorId,
	});
}

export function useDetailVendorPIC(vendorId, picId) {
	return useQuery({
		queryKey: picId && ["vendorPIC", picId],
		queryFn: () => axios.get(`/v2/vendor/${vendorId}/pic/${picId}/show`).then(res => res.data),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!picId,
	});
}

export function useGenerateVendorCode() {
	return useQuery([], () => axios.get("/v2/vendor/medicine/generate_code").then(res => res.data?.data));
}
