import {LoginPage, ChangePassword, ForgotPassword, VerificationOtp} from "pages/Auth";

export const authenticationRoutes = [
	{
		name: "admin-authentication-login",
		path: "/auth/login",
		exact: true,
		isPrivate: false,
		component: LoginPage,
	},
	{
		name: "admin-authentication-change-password",
		path: "/auth/reset-password",
		exact: true,
		isPrivate: false,
		component: ChangePassword,
	},
	{
		name: "admin-authentication-forgot-password",
		path: "/auth/forgot-password",
		exact: true,
		isPrivate: false,
		component: ForgotPassword,
	},
	{
		name: "admin-authentication-verification-otp",
		path: "/auth/verification-otp",
		exact: true,
		isPrivate: false,
		component: VerificationOtp,
	},
];
