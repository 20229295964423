const statusColor = {
	bgColor: {
		pasien: "#c1c1c1",
		belum_dilayani: "#ffa1a1",
		sedang_dilayani: "#ffc2a4",
		menunggu_lab: "#ffc2a4",
		menunggu_pembayaran: "#ffffc0",
		menunggu_obat: "#ffffc0",
		pulang: "#c1ffb6",
	},
	statusText: {
		pasien: "Abu-Abu",
		belum_dilayani: "Merah",
		sedang_dilayani: "Orange Pastel",
		menunggu_lab: "Orange Pastel",
		menunggu_pembayaran: "Kuning Pastel",
		menunggu_obat: "Kuning Pastel",
		pulang: "Hijau",
	},
};

export default statusColor;
