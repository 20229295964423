/* eslint-disable no-unused-vars */
import {useMemo, useState} from "react";
import dayjs from "dayjs";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {BasicTable} from "components/Table";
import {DatePicker} from "components/DatePicker";
import {useSelector} from "react-redux";
import {ButtonAddTable, SubmitButton} from "components/Button";
import {useScheduleWorks} from "hooks/Doctor/ScheduleWork";
import {useScheduleHolidays} from "hooks/Doctor/ScheduleHoliday";
import {useSchedules} from "hooks/Doctor/Schedule";
import {DashboardLayout} from "components/Layout";

const daysDetail = [
	{
		id: 1,
		name: "Senin",
	},
	{
		id: 2,
		name: "Selasa",
	},
	{
		id: 3,
		name: "Rabu",
	},
	{
		id: 4,
		name: "Kamis",
	},
	{
		id: 5,
		name: "Jum'at",
	},
	{
		id: 6,
		name: "Sabtu",
	},
	{
		id: 7,
		name: "Minggu",
	},
];

export function ScheduleListPage() {
	const userProfile = useSelector(state => state.AuthReducer.profile);
	const history = useHistory();
	const [dataLimit, setDataLimit] = useState(10);
	const [searchKeyword, setSearchKeyword] = useState("");
	const {data: schedules, isLoading: isLoadingSchedules, filterSchedules} = useSchedules(userProfile.id);
	const {
		data: scheduleWorks,
		isLoading: isLoadingScheduleWorks,
		filterScheduleWorks,
	} = useScheduleWorks(userProfile.id);
	const {
		data: scheduleHolidays,
		isLoading: isLoadingScheduleHolidays,
		filterScheduleHolidays,
	} = useScheduleHolidays(userProfile.id);
	const [filterDateScheduleWork, setFilterDateScheduleWork] = useState("");
	const [filterDateScheduleHoliday, setFilterDateScheduleHoliday] = useState("");

	const columnsSchedule = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Tipe Konsultasi",
				accessor: "type",
				Cell: ({row}) => {
					return (
						<>
							<span
								className={
									row.original.type === "online" ? "icon-ico-zoom me-2" : "icon-ico-hospital me-2"
								}
							/>{" "}
							{row.original.type}
						</>
					);
				},
			},
			{
				Header: "Hari",
				accessor: "days",
				Cell: ({row}) => {
					let text = [];

					row?.values?.days?.forEach(day => {
						const dayText = daysDetail.filter(item => item.id == day)[0].name;

						text.push(dayText);
					});

					return (
						<p>
							{text.map((item, index) => (
								<>
									{item}
									{text.length === index + 1 ? "" : ", "}
								</>
							))}
						</p>
					);
				},
			},
			{
				Header: "Lokasi Praktik",
				accessor: "hospital.name",
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay disabled"
							style={{cursor: "pointer"}}
							onClick={() => {
								history.push(`/doctor/schedule/edit/${row?.values?.id}`);
							}}>
							<span class="icon-ico-pencil ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const columnsScheduleWork = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Tipe Konsultasi",
				accessor: "type",
				Cell: ({row}) => {
					return <p>{row?.values?.type > 1 ? "offline" : "online"}</p>;
				},
			},
			{
				Header: "Tanggal",
				accessor: "startDate",
				Cell: ({row}) => {
					return (
						<p>
							{moment(row?.values?.startDate).format("DD/MM/YYYY")} {" - "}
							{moment(row?.original?.endDate).format("DD/MM/YYYY")}
						</p>
					);
				},
			},
			{
				Header: "Jam Praktik",
				accessor: "ScheduleWorkingTimes",
				Cell: ({row}) => (
					<>
						{row.values.ScheduleWorkingTimes?.map((schedule, index) => (
							<>
								{schedule.startTime} {" - "} {schedule.endTime}{" "}
								{row.values.ScheduleWorkingTimes.length !== index + 1 && ", "}
							</>
						))}
					</>
				),
			},
			{
				Header: "Lokasi Praktik",
				accessor: "Hospital.name",
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => {
								history.push(`/doctor/schedule/work/edit/${row?.values?.id}`);
							}}>
							<span class="icon-ico-pencil ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const columnsScheduleHoliday = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Tanggal",
				accessor: "startDate",
				Cell: ({row}) => {
					return (
						<p>
							{moment(row?.values?.startDate).format("DD/MM/YYYY")} {" - "}
							{moment(row?.original?.endDate).format("DD/MM/YYYY")}
						</p>
					);
				},
			},
			{
				Header: "Jam Mulai - Jam Selesai",
				accessor: "ScheduleHolidayTimes",
				Cell: ({row}) => (
					<>
						{row.values.ScheduleHolidayTimes?.map((schedule, index) => (
							<>
								{schedule.startTime} {" - "} {schedule.endTime}{" "}
								{row.values.ScheduleHolidayTimes.length !== index + 1 && ", "}
							</>
						))}
					</>
				),
			},
			{
				Header: "Keterangan",
				accessor: "keterangan",
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => {
								history.push(`/doctor/schedule/holiday/edit/${row?.values?.id}`);
							}}>
							<span class="icon-ico-pencil ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const dataScheduleWithIDSerial =
		schedules?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (schedules?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const dataScheduleWorkWithIDSerial =
		scheduleWorks?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (scheduleWorks?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const dataScheduleHolidayWithIDSerial =
		scheduleHolidays?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (scheduleHolidays?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	return (
		<DashboardLayout>
			<div class="box-white setting-content h-100 no-border p24">
				<div className="mb-5">
					<div class="d-flex flex-wrap justify-content-between align-items-center">
						<div class="text-bold text-dark mb-4">Jadwal Praktik</div>
						<ButtonAddTable
							text="Tambah Jadwal Praktik"
							onClick={() => history.push("/doctor/schedule/add")}
						/>
					</div>

					<BasicTable
						columns={columnsSchedule}
						currentPage={schedules?.data?.currentPage}
						data={dataScheduleWithIDSerial}
						limit={dataLimit}
						loading={isLoadingSchedules}
						striped={true}
						title="Data Jadwal Praktik"
						totalItems={schedules?.data?.totalItems}
						totalPage={schedules?.data?.totalPages}
						onLimitChange={limit => {
							filterSchedules({limit, page: 1, search: searchKeyword});
							setDataLimit(limit);
						}}
						onPageChange={selectedPage =>
							filterSchedules({page: selectedPage, limit: dataLimit, search: searchKeyword})
						}
					/>
				</div>
				<div className="mb-5">
					<div class="d-flex flex-wrap justify-content-between align-items-center">
						<div class="text-bold text-dark mb-4">Jadwal Libur</div>
						<div class="d-flex flex-wrap align-items-center justify-content-end row col-10">
							<div class="col-2 d-flex flex-wrap align-items-center justify-content-end">
								<a
									className="text-green"
									style={{cursor: "pointer"}}
									onClick={() => {
										setFilterDateScheduleHoliday({});
										filterScheduleHolidays({
											page: 1,
											limit: dataLimit,
										});
									}}>
									Reset filter
								</a>
							</div>
							<div className="form-filter position-relative col-4">
								<DatePicker
									onChange={selectedDate => {
										setFilterDateScheduleHoliday(selectedDate);
										filterScheduleHolidays({
											page: 1,
											limit: dataLimit,
											endDate: selectedDate.endDate,
											startDate: selectedDate.startDate,
										});
									}}
									onHide={selectedDate => {
										if (filterDateScheduleHoliday.startDate) {
											setFilterDateScheduleHoliday(selectedDate);
										}
									}}>
									<input
										className="form-control"
										placeholder="Pilih tanggal"
										type="text"
										value={
											(filterDateScheduleHoliday.startDate &&
												dayjs(filterDateScheduleHoliday.startDate, "YYYY-MM-DD").format(
													"DD/MM/YYYY",
												) +
													" - " +
													dayjs(filterDateScheduleHoliday.endDate, "YYYY-MM-DD").format(
														"DD/MM/YYYY",
													)) ||
											""
										}
									/>
								</DatePicker>
								<div className="icon-right me-2">
									<span className="icon-ico-date text-orange" />
								</div>
							</div>
							<div class="col-4">
								<SubmitButton
									className="w-100 btn btn-green"
									text="Tambah Jadwal Libur"
									onClick={() => history.push("/doctor/schedule/holiday/add")}
								/>
							</div>
						</div>
					</div>

					<BasicTable
						columns={columnsScheduleHoliday}
						currentPage={scheduleHolidays?.data?.currentPage}
						data={dataScheduleHolidayWithIDSerial}
						limit={dataLimit}
						loading={isLoadingScheduleHolidays}
						striped={true}
						title="Data Jadwal Libur"
						totalItems={scheduleHolidays?.data?.totalItems}
						totalPage={scheduleHolidays?.data?.totalPages}
						onLimitChange={limit => {
							filterScheduleHolidays({limit, page: 1, search: searchKeyword});
							setDataLimit(limit);
						}}
						onPageChange={selectedPage =>
							filterScheduleHolidays({page: selectedPage, limit: dataLimit, search: searchKeyword})
						}
					/>
				</div>
				<div className="mb-5">
					<div class="d-flex flex-wrap justify-content-between align-items-center">
						<div class="text-bold text-dark mb-4">Jadwal Masuk</div>
						<div class="d-flex flex-wrap align-items-center justify-content-end row col-10">
							<div class="col-2 d-flex flex-wrap align-items-center justify-content-end">
								<a
									className="text-green"
									style={{cursor: "pointer"}}
									onClick={() => {
										setFilterDateScheduleWork({});
										filterScheduleWorks({
											page: 1,
											limit: dataLimit,
										});
									}}>
									Reset filter
								</a>
							</div>
							<div className="form-filter position-relative col-4">
								<DatePicker
									onChange={selectedDate => {
										setFilterDateScheduleWork(selectedDate);
										filterScheduleWorks({
											page: 1,
											limit: dataLimit,
											endDate: selectedDate.endDate,
											startDate: selectedDate.startDate,
										});
									}}
									onHide={selectedDate => {
										if (filterDateScheduleWork.startDate) {
											setFilterDateScheduleWork(selectedDate);
										}
									}}>
									<input
										className="form-control"
										placeholder="Pilih tanggal"
										type="text"
										value={
											(filterDateScheduleWork.startDate &&
												dayjs(filterDateScheduleWork.startDate, "YYYY-MM-DD").format(
													"DD/MM/YYYY",
												) +
													" - " +
													dayjs(filterDateScheduleWork.endDate, "YYYY-MM-DD").format(
														"DD/MM/YYYY",
													)) ||
											""
										}
									/>
								</DatePicker>
								<div className="icon-right me-2">
									<span className="icon-ico-date text-orange" />
								</div>
							</div>
							<div class="col-4">
								<SubmitButton
									className="w-100 btn btn-green"
									text="Tambah Jadwal Masuk"
									onClick={() => history.push("/doctor/schedule/work/add")}
								/>
							</div>
						</div>
					</div>

					<BasicTable
						columns={columnsScheduleWork}
						currentPage={scheduleWorks?.data?.currentPage}
						data={dataScheduleWorkWithIDSerial}
						limit={dataLimit}
						loading={isLoadingScheduleWorks}
						striped={true}
						title="Data Jadwal Masuk"
						totalItems={scheduleWorks?.data?.totalItems}
						totalPage={scheduleWorks?.data?.totalPages}
						onLimitChange={limit => {
							filterScheduleWorks({limit, page: 1, search: searchKeyword});
							setDataLimit(limit);
						}}
						onPageChange={selectedPage =>
							filterScheduleWorks({page: selectedPage, limit: dataLimit, search: searchKeyword})
						}
					/>
				</div>
			</div>
		</DashboardLayout>
	);
}
