import {useHistory, useParams} from "react-router-dom";
import {loadLocalAssets} from "utils/loader";
import {DashboardLayout} from "components/Layout";
import {SubmitButton} from "components/Button";
import {CircleLoader} from "components/Loader";
import {FormCard} from "components/Form";
import {useDetailPoliklinik} from "hooks/SuperAdmin/Poliklinik";

export function MasterPoliklinikDetailPage() {
	const history = useHistory();
	const {id} = useParams();
	const {data: detailPoliklinik, isLoading: loadingFetcing} = useDetailPoliklinik(id);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar list poliklinik" title="Detail Poliklinik">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar List Poliklinik"
				title={`Detail Data Poliklinik ${detailPoliklinik?.data?.name}`}
				onBackButtonClick={() => history.push("/master-poliklinik")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label ">Icon</label>
							<div class="change-img mb-4">
								<div class="for-img-change">
									<div class="bx-img-change">
										<img
											alt="Icon Rumah Sakit"
											class="img-fluid"
											src={
												detailPoliklinik?.data?.icon
													? detailPoliklinik?.data?.icon
													: loadLocalAssets("img/user-default.jpg")
											}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Poliklinik</label>
							<p className="text-bold text-grey">{detailPoliklinik?.data?.name}</p>
						</div>
					</div>
				</div>

				<div class="text-center my-3">
					<SubmitButton
						className="btn btn-orange d-inline-block mw-250"
						text="Ubah Data Ini"
						onClick={() => history.push(`/master-poliklinik/edit/${detailPoliklinik?.data?.id}`)}
					/>
				</div>
			</FormCard>
		</DashboardLayout>
	);
}
