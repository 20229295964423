import {Button, SubmitButton} from "components/Button";
import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import statusColor from "config/patientStatus";
import {useDetailAppointment, useEditAppoinment} from "hooks/Doctor/Appointment";
import {useMemo} from "react";
import {useHistory, useParams} from "react-router-dom";
import {convertToCurency} from "utils/converter";
import {converISOtoDate, getDayName} from "utils/date";
import {getTranslatedKeyword} from "utils/string";
import {isAfter} from "date-fns";
import {ModalConfirmation} from "components/Modal";
import useDisclosure from "hooks/core/useDisclosure";

export function AppointmentDetailPage() {
	const history = useHistory();
	const {id} = useParams();
	const {data, isLoading} = useDetailAppointment(id);
	const appointment = data?.data;

	const patient = appointment?.patient;
	const doctor = appointment?.doctor;

	const updateMutation = useEditAppoinment();

	const cancelModal = useDisclosure();

	const handleCancel = () => {
		updateMutation.mutate(
			{
				id: id,
				bookingStatus: "canceled",
			},
			{
				onSettled: cancelModal.onClose,
			},
		);
	};

	const showCancelButton = useMemo(() => {
		if (!appointment) return false;

		const {bookingStatus, patientStatus, date} = appointment;

		return bookingStatus !== "canceled" && patientStatus === "pasien" && isAfter(new Date(), new Date(date));
	}, [appointment]);

	if (isLoading) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar list appointment" title="Detail Appointment">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<ModalConfirmation
				approveLabel="Ya"
				description={`Apakah Anda yakin akan membatalkan data pasien ini?`}
				loading={updateMutation.isLoading}
				rejectLabel="Batal"
				visible={cancelModal.isOpen}
				onApprove={handleCancel}
				onClose={cancelModal.onClose}
				onReject={cancelModal.onClose}
			/>

			<FormCard
				backTitle="Daftar List Appointment"
				title={`Detail Data Appointment ${patient?.fullName}`}
				onBackButtonClick={() => history.push("/admission/appointment/list")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Pasien</label>
							<p className="text-bold text-grey">{patient?.fullName}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Dokter</label>
							<p className="text-bold text-grey">{doctor?.fullName}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Jadwal Appointment</label>
							<p className="text-bold text-grey">
								{`${getDayName(data?.data?.date, "ID-id")}, ${converISOtoDate(data?.data?.date)}`}{" "}
								<br />
								{`${data?.data?.startTime} - ${data?.data?.endTime}`} WIB
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Tipe Konsultasi</label>
							<p className="text-bold text-grey">{data?.data?.type}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Harga</label>
							<p className="text-bold text-grey">{convertToCurency(data?.data?.price + "")}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Status Transaksi</label>
							<p className="text-bold text-grey">
								{getTranslatedKeyword(data?.data?.transaction?.paymentStatus.toLowerCase())}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Status Pasien</label>
							<p className="text-bold text-grey">
								<div
									className="box-status me-2"
									style={{
										background: statusColor.bgColor[data?.data?.patientStatus],
										color: "#fff",
									}}
								/>
								{data?.data?.patientStatus}
							</p>
						</div>
					</div>
				</div>

				{appointment?.patientStatus === "pasien" && appointment?.bookingStatus !== "canceled" && (
					<div class="d-flex align-items-center justify-content-center flex-column gap-4 my-3">
						<SubmitButton
							className="btn btn-orange d-inline-block mw-250"
							text="Ubah Data Ini"
							onClick={() => history.push(`/admission/appointment/edit/${data?.data?.id}`)}
						/>

						{showCancelButton && <Button onClick={cancelModal.onOpen}>Batalkan</Button>}
					</div>
				)}
			</FormCard>
		</DashboardLayout>
	);
}
