import {useMemo} from "react";
import dayjs from "dayjs";
import clsx from "clsx";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation} from "swiper";

SwiperCore.use([Navigation]);

const swiperBreakPoints = {
	360: {
		slidesPerView: 4,
		spaceBetween: 2,
	},
	370: {
		slidesPerView: 4,
		spaceBetween: 5,
	},
	480: {
		slidesPerView: 5,
		spaceBetween: 5,
	},
	767: {
		slidesPerView: 2,
		spaceBetween: 10,
	},
	768: {
		slidesPerView: 5,
		spaceBetween: 10,
	},
	960: {
		slidesPerView: 5,
		spaceBetween: 10,
	},
	1024: {
		slidesPerView: 7,
		spaceBetween: 10,
	},
	1200: {
		slidesPerView: 7,
		spaceBetween: 5,
	},
};

export function SelectDay({data = [], selectedDayId, onSelect, currentPage, onNext = () => {}, onPrev = () => {}}) {
	const dateSchedules = useMemo(() => {
		const dateSchedules = data.map(item => {
			const date = dayjs(item.date, "YYYY-MM-DD").format("DD MMM");
			const day = dayjs(item.date, "YYYY-MM-DD").format("ddd");

			return {
				...item,
				date,
				day,
			};
		});

		return dateSchedules;
	}, [data]);

	return (
		<>
			<div className="text-bold text-center mb-4 ft-16">Pilih Hari</div>

			<div className="nav-week">
				<div className="week swiper-button-next" onClick={onNext}>
					{`After ${dateSchedules?.[dateSchedules.length - 1]?.date}`}{" "}
					<span className="icon-ico-triangle-right" />
				</div>
				<div
					className={clsx("week swiper-button-prev", currentPage <= 1 && "swiper-button-disabled")}
					onClick={onPrev}>
					<span className="icon-ico-triangle-left" /> {`Before ${dateSchedules?.[0]?.date}`}
				</div>
			</div>

			<div className="choose-jadwal position-relative">
				<div>
					<Swiper
						breakpoints={swiperBreakPoints}
						className="sliderJadwal thisWeek"
						navigation={{
							prevEl: "#jadwal.swiper-button-prev",
							nextEl: "#jadwal.swiper-button-next",
						}}
						pagination={false}
						slidesPerView={7}>
						{dateSchedules.map(item => {
							return (
								<SwiperSlide
									key={item.date}
									onClick={() => {
										if (
											!item.isFullDayHoliday &&
											item.times?.length &&
											!item.times?.every(item => item?.isHoliday)
										) {
											onSelect(item);
										}
									}}>
									<button
										className={clsx("date-choose", selectedDayId === item.id && "active")}
										disabled={
											!item.times?.length ||
											item.times?.every(item => item?.isHoliday) ||
											item.isFullDayHoliday
										}>
										<div className="dc-inner">
											{item.day}
											<span>{item.date}</span>
										</div>
									</button>
								</SwiperSlide>
							);
						})}
					</Swiper>
					<div className="j-a swiper-button-next" id="jadwal" />
					<div className="j-a swiper-button-prev" id="jadwal" />
				</div>
			</div>
			<div className="choose-jadwal position-relative">
				<span aria-atomic="true" aria-live="assertive" className="swiper-notification" />
				<div className="indicator">
					<span>{currentPage > 1 ? "Minggu Depan" : "Minggu ini"}</span>
				</div>
			</div>
		</>
	);
}
