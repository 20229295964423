import {DashboardLayout} from "components/Layout";
import {useState} from "react";
import {isMobile} from "react-device-detect";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

import {TabUserPasien} from "./TabUserPasien";
import {TabUserStaff} from "./TabUserStaff";

const UserListPage = () => {
	const [tab, setTab] = useState(0);

	return (
		<DashboardLayout>
			<Tabs className="mb-0" selectedIndex={tab} onSelect={index => setTab(index)}>
				<div class="" style={isMobile ? {"overflow-x": "auto", "white-space": "nowrap"} : {}}>
					<TabList className="border-0">
						<Tab>Pasien</Tab>
						<Tab>Dokter</Tab>
						<Tab>Staff</Tab>
					</TabList>
				</div>

				<div className="box-white setting-content h-100 no-border p24">
					<TabPanel>
						<TabUserPasien />
					</TabPanel>
					<TabPanel>
						<TabUserStaff roleId={2} title="Dokter" />
					</TabPanel>
					<TabPanel>
						<TabUserStaff title="Staff" />
					</TabPanel>
				</div>
			</Tabs>
		</DashboardLayout>
	);
};

export {UserListPage};
