import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {useDetailLayananPemeriksaanPenunjang} from "hooks/AdminHospital/LayananPemeriksaanPenunjang";
import {useHistory, useParams} from "react-router-dom";
import {convertToCurency} from "utils/converter";

export function LayananPemeriksaanPenunjangDetailPage() {
	const history = useHistory();
	const {id} = useParams();
	const {data, isLoading} = useDetailLayananPemeriksaanPenunjang(id);

	if (isLoading) {
		return (
			<DashboardLayout>
				<FormCard
					backTitle="Daftar list Layanan Pemeriksaan Penunjang"
					title="Detail Layanan Pemeriksaan Penunjang">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar List Layanan Pemeriksaan Penunjang"
				title={`Detail Data Pemeriksaan Penunjang ${data?.data?.checkupSupports?.title}`}
				onBackButtonClick={() => history.push("/hospital/service-checkup-support/list")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-6 col-12">
						<div class="setting-item">
							<label class="setting-label ">Divisi</label>
							<p className="text-bold text-grey">{data?.data?.Division?.name}</p>
						</div>
					</div>
					<div class="col-sm-6 col-12">
						<div class="setting-item">
							<label class="setting-label ">Unit</label>
							<p className="text-bold text-grey">{data?.data?.units?.name}</p>
						</div>
					</div>
					<div class="col-sm-6 col-12">
						<div class="setting-item">
							<label class="setting-label ">Kategori Pemeriksaan</label>
							<p className="text-bold text-grey">{data?.data?.checkupCategories?.name}</p>
						</div>
					</div>
					<div class="col-sm-6 col-12">
						<div class="setting-item">
							<label class="setting-label ">Nama Pemeriksaan</label>
							<p className="text-bold text-grey">{data?.data?.checkupSupports?.title}</p>
						</div>
					</div>
					<div class="col-sm-6 col-12">
						<div class="setting-item">
							<label class="setting-label ">Harga Beli</label>
							<p className="text-bold text-grey">Rp {convertToCurency(data?.data?.buyPrice + "")}</p>
						</div>
					</div>
					<div class="col-sm-6 col-12">
						<div class="setting-item">
							<label class="setting-label ">Harga Jual</label>
							<p className="text-bold text-grey">Rp {convertToCurency(data?.data?.sellPrice + "")}</p>
						</div>
					</div>
					<div class="col-sm-6 col-12">
						<div class="setting-item">
							<label class="setting-label ">Status Produk</label>
							<p className="text-bold text-grey">
								{data?.data?.statusProduct === 1 ? "Tidak Habis Pakai" : "Habis Pakai"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-12">
						<div class="setting-item">
							<label class="setting-label ">Stok</label>
							<p className="text-bold text-grey">{data?.data?.stock}</p>
						</div>
					</div>
					<div class="col-sm-6 col-12">
						<div class="setting-item">
							<label class="setting-label ">Status Approval</label>
							<p className="text-bold text-grey">
								{typeof data?.data?.status !== "boolean"
									? "Belum ditentukan"
									: data?.data?.status
									? "Disetujui"
									: "Ditolak"}
							</p>
						</div>
					</div>
				</div>
			</FormCard>
		</DashboardLayout>
	);
}
