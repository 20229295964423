import {BasicTable, SearchBox} from "components/Table";
import {useArticleUser} from "hooks/SuperAdmin/Article";
import {useMemo, useState} from "react";
import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";

const TabUserStaff = ({title, roleId}) => {
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading, setFilter} = useArticleUser("staff/full", roleId);

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Name",
				accessor: "fullName",
			},
			{
				Header: "Email",
				accessor: "email",
			},
			{
				Header: "No Telepon",
				accessor: "phoneNumber",
			},
			{
				Header: "Aksi",
				accessor: "staff",
				Cell: ({row}) => (
					<div className="d-flex align-items-center gap-3">
						<a
							class="text-orange wait-pay d-flex"
							style={{cursor: "pointer"}}
							onClick={() =>
								history.push(`/user/${roleId === 2 ? "dokter" : "staff"}/${row?.values?.staff?.id}`)
							}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
						<Button variant="outline-danger">Suspend User</Button>
					</div>
				),
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	return (
		<div>
			<h1 className="m-0 mb-3">User - {title}</h1>
			<SearchBox
				rightElement={null}
				onSearch={keyword => {
					setSearchKeyword(keyword);
					setFilter({page: 1, limit: dataLimit, search: keyword});
				}}
			/>
			<BasicTable
				columns={columns}
				currentPage={data?.data?.currentPage}
				data={dataWithIDSerial}
				limit={dataLimit}
				loading={isLoading}
				striped={true}
				title={`Data User ${title}`}
				totalItems={data?.data?.totalItems}
				totalPage={data?.data?.totalPages}
				onLimitChange={limitValue => {
					setDataLimit(limitValue);
					setFilter({page: 1, limit: limitValue, search: searchKeyword});
				}}
				onPageChange={selectedPage => setFilter({page: selectedPage, limit: dataLimit, search: searchKeyword})}
			/>
		</div>
	);
};

export {TabUserStaff};
