import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getSediaanObat(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/sediaan`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/sediaan/${id}`);

	return data;
}

function useSediaanObat() {
	const [filter, filterSediaan] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [sediaanId, sediaanById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["sediaan", filter, sediaanId], async () => getSediaanObat(sediaanId, filter));

	return {data, isLoading, isError, error, filter, filterSediaan, sediaanById};
}

const fetchSediaan = sediaanId => {
	return axios.get(`/sediaan/${sediaanId}`).then(res => res.data);
};

function useDetailSediaan(sediaanId) {
	return useQuery({
		queryKey: sediaanId && ["sediaan", sediaanId],
		queryFn: key => fetchSediaan(sediaanId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!sediaanId,
	});
}

function useSediaanOptions() {
	const result = useQuery(["sediaanOptions"], async () => await axios.get(`/sediaan`).then(res => res.data));

	return result;
}

export {useSediaanObat, fetchSediaan, useDetailSediaan, useSediaanOptions};
