import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {useListKategoriPemeriksaan} from "hooks/SuperAdmin/KategoriPemeriksaan";
import {useDetailPemeriksaanPenunjang} from "hooks/SuperAdmin/PemeriksaanPenunjang";
import {useHistory, useParams} from "react-router-dom";
import {getTranslatedKeyword} from "utils/string";

export function PemeriksaanPenunjangDetailPage() {
	const history = useHistory();
	const {id} = useParams();
	const {data, isLoading} = useDetailPemeriksaanPenunjang(id);
	const listKategoriPemeriksaanQuery = useListKategoriPemeriksaan();
	const listKategoriPemeriksaan = listKategoriPemeriksaanQuery.data?.data?.rows || [];
	const detailPemeriksaanPenunjang = data?.data;
	const kategoriPemeriksaan =
		listKategoriPemeriksaan.find(e => e.id === detailPemeriksaanPenunjang?.checkupCategoryId)?.name || "";

	const formulaRenderer = () => {
		if (!detailPemeriksaanPenunjang) return null;

		return detailPemeriksaanPenunjang.CheckupSupportDetails.map((formula, idx) => (
			<div key={formula.id}>
				<div class="row gx-2">
					<div class="col-sm-6 col-12">
						<div class="setting-item">
							<label class="setting-label ">Faktor Pembeda</label>
							<p className="text-bold text-grey">{getTranslatedKeyword(formula.diffFactor)}</p>
						</div>
					</div>
					{formula.gender && (
						<div class="col-sm-6 col-12">
							<div class="setting-item">
								<label class="setting-label ">Jenis Kelamin</label>
								<p className="text-bold text-grey">{getTranslatedKeyword(formula.gender)}</p>
							</div>
						</div>
					)}
					{formula.limit && (
						<div class="col-sm-6 col-12">
							<div class="setting-item">
								<label class="setting-label ">Batasan</label>
								<p className="text-bold text-grey">{getTranslatedKeyword(formula.limit)}</p>
							</div>
						</div>
					)}
					{formula.diffValueFirst && (
						<div class="col-sm-6 col-12">
							<div class="setting-item">
								<label class="setting-label ">
									Nilai Pembeda {formula.diffValueLast ? "Pertama" : ""}
								</label>
								<p className="text-bold text-grey">{formula.diffValueFirst}</p>
							</div>
						</div>
					)}
					{formula.diffUnitFirst && (
						<div class="col-sm-6 col-12">
							<div class="setting-item">
								<label class="setting-label ">
									Satuan Nilai Pembeda {formula.diffValueLast ? "Pertama" : ""}
								</label>
								<p className="text-bold text-grey">{getTranslatedKeyword(formula.diffUnitFirst)}</p>
							</div>
						</div>
					)}
					{formula.diffValueLast && (
						<div class="col-sm-6 col-12">
							<div class="setting-item">
								<label class="setting-label ">Nilai Pembeda Kedua</label>
								<p className="text-bold text-grey">{formula.diffValueLast}</p>
							</div>
						</div>
					)}
					{formula.diffUnitLast && (
						<div class="col-sm-6 col-12">
							<div class="setting-item">
								<label class="setting-label ">Satuan Nilai Pembeda Kedua</label>
								<p className="text-bold text-grey">{getTranslatedKeyword(formula.diffUnitLast)}</p>
							</div>
						</div>
					)}
				</div>
				<div class="row gx-2">
					<div class="col-sm-6 col-12">
						<div class="setting-item">
							<label class="setting-label ">Batasan Rujukan</label>
							<p className="text-bold text-grey">{getTranslatedKeyword(formula.referenceLimit)}</p>
						</div>
					</div>
					<div class="col-sm-6 col-12">
						<div class="setting-item">
							<label class="setting-label ">
								Nilai Rujukan {formula.referenceValueLast ? "Pertama" : ""}
							</label>
							<p className="text-bold text-grey">{formula.referenceValueFirst}</p>
						</div>
					</div>
					{formula.referenceValueLast && (
						<div class="col-sm-6 col-12">
							<div class="setting-item">
								<label class="setting-label ">Nilai Rujukan Kedua</label>
								<p className="text-bold text-grey">{formula.referenceValueLast}</p>
							</div>
						</div>
					)}
					<div class="col-sm-6 col-12">
						<div class="setting-item">
							<label class="setting-label ">Satuan Nilai Rujukan</label>
							<p className="text-bold text-grey">{formula.referenceUnit}</p>
						</div>
					</div>
				</div>
				{idx < detailPemeriksaanPenunjang?.CheckupSupportDetails?.length - 1 && <hr />}
			</div>
		));
	};

	if (isLoading) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar list Pemeriksaan Penunjang" title="Detail Pemeriksaan Penunjang">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar List Pemeriksaan Penunjang"
				title={`Detail Data Pemeriksaan Penunjang ${data?.data?.title}`}
				onBackButtonClick={() => history.push("/hospital/checkup-support/list")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-6 col-12">
						<div class="setting-item">
							<label class="setting-label ">Kategori Pemeriksaan</label>
							<p className="text-bold text-grey">{kategoriPemeriksaan}</p>
						</div>
					</div>
					<div class="col-sm-6 col-12">
						<div class="setting-item">
							<label class="setting-label ">Nama Pemeriksaan</label>
							<p className="text-bold text-grey">{detailPemeriksaanPenunjang?.title}</p>
						</div>
					</div>

					{formulaRenderer()}
				</div>
			</FormCard>
		</DashboardLayout>
	);
}
