import {createContext, useContext, useState} from "react";

const layoutContext = createContext({
	showSubmenu: false,
	selectedShowSubmenu: "",
	onToggleSubmenu: () => {},
	onSetSelectedSubmenu: () => {},
});

export default function LayoutContextProvider({children}) {
	const [showSubmenu, setShowSubmenu] = useState(false);
	const [selectedShowSubmenu, setSelectedShowSubmenu] = useState(null);

	const onToggleSubmenu = () => setShowSubmenu(!showSubmenu);
	const onSetSelectedSubmenu = value => setSelectedShowSubmenu(value);

	return (
		<layoutContext.Provider value={{showSubmenu, selectedShowSubmenu, onToggleSubmenu, onSetSelectedSubmenu}}>
			{children}
		</layoutContext.Provider>
	);
}

export const useLayoutContext = () => useContext(layoutContext);
