import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";

export const QUERY_KATEGORI_PEMERIKSAAN = "checkup_category";

export function useListKategoriPemeriksaan(filter) {
	const result = useQuery([QUERY_KATEGORI_PEMERIKSAAN, filter], () =>
		axios.get(`/checkup_category?${qs.stringify(filter)}`).then(res => res.data),
	);

	return result;
}

export const fetchKategoriPemeriksaanByID = id => {
	return axios.get(`/checkup_category/${id}`).then(res => res.data);
};

export function useDetailKategoriPemeriksaan(id) {
	return useQuery({
		queryKey: id && [QUERY_KATEGORI_PEMERIKSAAN, id],
		queryFn: () => fetchKategoriPemeriksaanByID(id),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!id,
	});
}
