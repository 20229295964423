import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

export function useAddTag(options = {}) {
	return useMutation(values => axios.post("/master_tag/create", values).then(res => res.data));
}
export function useEditTag(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/master_tag/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("tag");
			queryClient.removeQueries("tag");
		},
	});
}

export function useDeleteTag(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/master_tag/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("tag");
			queryClient.removeQueries("tag");
		},
	});
}
