import {SubmitButton} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {ModalUploadFile} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import {useListFileLab} from "hooks/AdminLaboratorium/FileLab";
import {useMemo, useState} from "react";
import {isMobile} from "react-device-detect";
import {useSelector} from "react-redux";
import {useHistory, Link} from "react-router-dom";

export const getFormattedDobText = value => {
	return value?.replace("Th", " Tahun").replace("Bl", " Bulan").replace("Hr", " Hari");
};

export const getFormattedGenderText = value => {
	if (value === "male") return "Laki-laki";
	if (value === "female") return "Perempuan";

	return value;
};

function ButtonAddNewFile({onClick}) {
	return (
		<div>
			<div class="row gx-2 align-items-center justify-content-end">
				<SubmitButton text="Upload File" onClick={onClick} />
			</div>
		</div>
	);
}

export function MasterFileLabListPage() {
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);
	const history = useHistory();
	const [filter, setFilter] = useState({page: 1, search: "", limit: 10, idHospital: selectedHospital.Hospital.id});
	const {data, isLoading} = useListFileLab(filter);

	const [showModalUpload, setShowModalUpload] = useState(false);

	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Nama Pasien",
				accessor: "fullName",
			},
			{
				Header: "Nomor Rekam Medis",
				accessor: "patient.medicalRecordNumber",
			},
			{
				Header: "Umur",
				accessor: "dobText",
				Cell: ({row}) => {
					return `${getFormattedDobText(row.values.dobText)}`;
				},
			},
			{
				Header: "Jenis Kelamin",
				accessor: "gender",
				Cell: ({row}) => {
					return `${getFormattedGenderText(row.values.gender)}`;
				},
			},

			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<>
							<Link
								class="text-orange wait-pay outline-0 border-0 bg-transparent"
								data-placement="top"
								data-toggle="tooltip"
								style={{cursor: "pointer"}}
								title="View File"
								to={`/file-labs/detail/${row?.original?.patient?.idUser}`}>
								<span class="icon-ico-eye-open ft-18" />
							</Link>
						</>
					);
				},
			},
		],
		[],
	);

	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Patient",
				accessor: "fullName",
				Cell: ({row}) => {
					return (
						<div class="col-8">
							<div className="text-table-small text-grey text-elipsis">{row.values.fullName}</div>
						</div>
					);
				},
			},

			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="col-2">
							<Link
								class="text-orange wait-pay outline-0 border-0 bg-transparent"
								data-placement="top"
								data-toggle="tooltip"
								style={{cursor: "pointer"}}
								title="View File"
								to={`/file-labs/detail/${row?.original?.patient?.idUser}`}>
								<span class="icon-ico-eye-open ft-18" />
							</Link>
						</div>
					);
				},
			},
		],
		[],
	);

	const dataWithIDSerial = useMemo(() => {
		return (
			data?.data?.rows?.map((item, index) => ({
				...item,
				idSerial: (filter.page - 1) * filter.limit + index + 1,
			})) || []
		);
	}, [data, filter.limit, filter.page]);

	return (
		<>
			<ModalUploadFile visible={showModalUpload} onClose={() => setShowModalUpload(false)} />

			<DashboardLayout>
				<div class="text-bold text-dark mb-4">Management File Lab</div>
				<SearchBox
					rightElement={
						<div className="row position-relative search-hospital-container  align-items-center justify-content-end">
							<div className="col-lg-7 col-sm-12" />
							<div className="col-lg-5 col-sm-12">
								<ButtonAddNewFile onClick={() => history.push("/file-labs/tambah")} />
							</div>
						</div>
					}
					onSearch={keyword => {
						setFilter({...filter, search: keyword, page: 1});
					}}
				/>
				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={data?.data?.currentPage}
					data={dataWithIDSerial}
					limit={filter.limit}
					loading={isLoading}
					striped={true}
					title="Data File Laboratorium"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setFilter({...filter, limit: limitValue, page: 1});
					}}
					onPageChange={selectedPage => {
						setFilter({...filter, page: selectedPage});
					}}
				/>
			</DashboardLayout>
		</>
	);
}
