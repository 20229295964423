import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {useArticleUser} from "hooks/SuperAdmin/Article";
import {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import {loadLocalAssets} from "utils/loader";

const UserDokterDetailPage = () => {
	const {type, id} = useParams();
	const history = useHistory();
	const {data: detailStaff, setArticleUserId} = useArticleUser("staff");

	const [detail, setDetail] = useState({});

	useEffect(() => {
		if (id) {
			setArticleUserId(id);
		}
	}, [id]);

	useEffect(() => {
		if (detailStaff?.success) {
			setDetail({...detailStaff?.data?.staffDetail, ...detailStaff?.data?.userDetail});
		}
	}, [detailStaff]);

	return (
		<DashboardLayout>
			<FormCard
				action={
					<Button style={{textTransform: "capitalize"}} variant="outline-danger">
						Suspend {type}
					</Button>
				}
				backTitle="Daftar List User"
				title={`Detail Data`}
				// title={`Detail Data ${detailPendaftaranPasien?.data?.firstName} ${detailPendaftaranPasien?.data?.lastName}`}
				onBackButtonClick={() => history.push("/user")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label ">Foto Profil</label>
							<div class="change-img mb-4">
								<div class="for-img-change">
									<div class="bx-img-change">
										<img
											alt="Profile"
											class="img-fluid"
											src={
												detail?.profilePhoto
													? detail?.profilePhoto
													: loadLocalAssets("img/user-default.jpg")
											}
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Title</label>
							<p className="text-bold text-grey">{detail?.title}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Nama Depan</label>
							<p className="text-bold text-grey">{detail?.firstName}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Nama Belakang</label>
							<p className="text-bold text-grey">{detail?.lastName}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Nama Panggilan</label>
							<p className="text-bold text-grey">{detail?.nickname}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Email</label>
							<p className="text-bold text-grey">{detail?.email}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Nomor Telepon</label>
							<p className="text-bold text-grey">{detail?.phoneNumber}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Usia</label>
							<p className="text-bold text-grey">{detail?.dobText}</p>
						</div>
					</div>

					<div class="col-sm-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label ">Status Karyawan</label>
							<p className="text-bold text-grey">{detail?.employeeStatusText}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">NIP</label>
							<p className="text-bold text-grey">{detail?.nip}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">No STR</label>
							<p className="text-bold text-grey">{detail?.noStr}</p>
						</div>
					</div>
				</div>
			</FormCard>
		</DashboardLayout>
	);
};

export {UserDokterDetailPage};
