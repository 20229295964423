import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {useDetailVendorPIC} from "hooks/SuperAdmin/Vendor";
import {useHistory, useParams} from "react-router-dom";

const VendorPICDetailPage = () => {
	const history = useHistory();
	const {id, picId} = useParams();
	const {data: detailVendorPIC, isLoading: loadingFetcing} = useDetailVendorPIC(id, picId);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar list PIC" title="Detail PIC">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar list PIC"
				title="Detail PIC"
				onBackButtonClick={() => history.push(`/master-vendor/${id}/PIC`)}>
				<div class="row gx-2 mb-5">
					<div class="col-12">
						<div class="setting-item">
							<label class="setting-label required">Nama PIC</label>
							<p className="text-bold text-grey">
								{detailVendorPIC?.data?.firstName} {detailVendorPIC?.data?.lastName}
							</p>
						</div>
					</div>
					<div class="col-12">
						<div class="setting-item">
							<label class="setting-label required">Email</label>
							<p className="text-bold text-grey">{detailVendorPIC?.data?.email}</p>
						</div>
					</div>
					<div class="col-12">
						<div class="setting-item">
							<label class="setting-label required">No Telepon</label>
							<p className="text-bold text-grey">+{detailVendorPIC?.data?.phoneNumber}</p>
						</div>
					</div>
				</div>
			</FormCard>
		</DashboardLayout>
	);
};

export {VendorPICDetailPage};
