import {useMemo, useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getLayananObat(id, filter) {
	if (!id) {
		const {data} = await axios.get(`/medicine_service`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}
	const {data} = await axios.get(`/medicine_service/${id}`);

	return data;
}

function useLayananObat() {
	const [filter, filterLayanan] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [layananId, layananById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["medicine_service", filter, layananId], async () => getLayananObat(layananId, filter));

	return {data, isLoading, isError, error, filter, filterLayanan, layananById};
}

const fetchLayanan = layananId => {
	return axios.get(`/v2/vendor/medicine/${layananId}`).then(res => res.data);
};

function useDetailLayanan(layananId) {
	return useQuery({
		queryKey: layananId && ["medicine_service", layananId],
		queryFn: key => fetchLayanan(layananId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!layananId,
	});
}

function useLayananOptions() {
	const result = useQuery(["layananOptions"], async () => await axios.get(`/medicine_service`).then(res => res.data));

	return result;
}

function useVendorOptions() {
	const result = useQuery(["vendorOptions"], async () => await axios.get(`/v2/vendor/all`).then(res => res.data));

	const vendorOptions = useMemo(() => {
		if (result.isSuccess) {
			return result?.data?.data?.rows?.map(v => ({
				value: v?.id + "",
				label: v?.name,
			}));
		}

		return [];
	}, [result]);

	return {vendorOptions, isLoading: result.isLoading};
}

export {useLayananObat, fetchLayanan, useDetailLayanan, useLayananOptions, useVendorOptions};
