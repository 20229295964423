/* eslint-disable no-unused-vars */
import clsx from "clsx";
import {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {DashboardLayout} from "components/Layout";
import {BasicTable, SearchBox} from "components/Table";
import {
	useLayananPoliklinikTindakan,
	useDeleteLayananPoliklinikTindakan,
} from "hooks/AdminHospital/LayananPoliklinikTindakan";
import {ButtonAddTable} from "components/Button";
import {ModalConfirmation, ModalInfo} from "components/Modal";
import {useToast} from "hooks/useToast";
import {useStateCallback} from "hooks/useStateCallback";
import {convertErrorMessageFormat, convertToCurency} from "utils/converter";
import {useSelector} from "react-redux";

const getStatusApproval = approval => {
	if (typeof approval !== "boolean") return "Menunggu Persetujuan";

	return approval ? "Disetujui" : "Ditolak";
};

export function LayananPoliklinikTindakanListPage() {
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);

	const {permission: userPermission} = useSelector(state => state.AuthReducer);
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading, filterLayananPoliklinikTindakan} = useLayananPoliklinikTindakan();

	// Delete Data State
	const {mutate, isLoading: isDeletingData} = useDeleteLayananPoliklinikTindakan();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showInfoModal, setShowInfoModal] = useState(false);
	const [deleteItemData, setDeleteItemData] = useStateCallback({id: null, name: null});
	const {showToast} = useToast();

	const getPermission = (...permission) => {
		return permission.includes(userPermission);
	};

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Unit",
				accessor: "Unit.name",
			},
			{
				Header: "Poliklinik",
				accessor: "Poliklinik.name",
			},
			{
				Header: "Kategori Tindakan",
				accessor: "ActionCategory.title",
			},
			{
				Header: "Tindakan",
				accessor: "Action.title",
			},
			{
				Header: "Harga Beli",
				accessor: "buyPrice",
				Cell: ({row}) => <>Rp. {convertToCurency(row?.values?.buyPrice?.toString())}</>,
			},
			{
				Header: "Harga Jual",
				accessor: "sellPrice",
				Cell: ({row}) => <>Rp. {convertToCurency(row?.values?.sellPrice?.toString())}</>,
			},
			{
				Header: "Status Produk",
				accessor: "statusProduct",
				Cell: ({row}) => <>{row?.values?.statusProduct === 1 ? "Habis Dipakai" : "Tidak Habis Dipakai"}</>,
			},
			{
				Header: "Stok",
				accessor: "stock",
			},
			{
				Header: "Status",
				accessor: "isApprove",
				Cell: ({row}) => {
					return <span className="font-bold">{getStatusApproval(row?.values?.isApprove)}</span>;
				},
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() =>
								history.push(`/sub-admin-divisi/layanan-poliklinik-tindakan/show/${row?.values?.id}`)
							}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
						{getPermission("CRUD", "CRU") && (
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() =>
									history.push(
										`/sub-admin-divisi/layanan-poliklinik-tindakan/edit/${row?.values?.id}`,
									)
								}>
								<span class="icon-ico-pencil ft-18" />
							</a>
						)}
						{getPermission("CRUD") && (
							<a
								class={clsx("text-orange wait-pay")}
								style={{cursor: "pointer"}}
								title={!row.original.can_delete && "Data tidak dapat dihapus karena sudah digunakan"}
								onClick={() => {
									setDeleteItemData(row?.values, () => setShowDeleteModal(true));
									// if (row.original.can_delete) {
									// 	setDeleteItemData(row?.values, () => setShowDeleteModal(true));
									// } else {
									// 	setShowInfoModal(true);
									// }
								}}>
								<span class="icon-ico-delete ft-18" />
							</a>
						)}
					</>
				),
			},
		],
		[],
	);

	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Kategori Tindakan",
				accessor: "title",
				Cell: ({row}) => {
					return (
						<div class="col-9">
							<div class="text-bold">{row.values.title}</div>
						</div>
					);
				},
			},

			{
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="col-3">
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() =>
									history.push(
										`/sub-admin-divisi/layanan-poliklinik-tindakan/show/${row?.values?.id}`,
									)
								}>
								<span class="icon-ico-eye-open ft-18" />
							</a>
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() =>
									history.push(
										`/sub-admin-divisi/layanan-poliklinik-tindakan/edit/${row?.values?.id}`,
									)
								}>
								<span class="icon-ico-pencil ft-18" />
							</a>

							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => {
									setDeleteItemData(row?.values, () => setShowDeleteModal(true));
									// if (row.original.can_delete) {
									// 	setDeleteItemData(row?.values, () => setShowDeleteModal(true));
									// } else {
									// 	setShowInfoModal(true);
									// }
								}}>
								<span class="icon-ico-delete ft-18" />
							</a>
						</div>
					);
				},
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const handleDeleteData = () => {
		mutate(
			{id: deleteItemData.id},
			{
				onSuccess: () => {
					showToast("success", `Data kategori tindakan ${deleteItemData.name} berhasil dihapus`, 3000);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<>
				<ModalInfo
					approveLabel="OK"
					description="Data tidak dapat dihapus karena sudah digunakan"
					title="Info"
					visible={showInfoModal}
					onApprove={() => setShowInfoModal(false)}
					onClose={() => setShowInfoModal(false)}
				/>
				<ModalConfirmation
					approveLabel="Ya"
					description={`Apakah Anda yakin ingin menghapus data kategori tindakan "${deleteItemData.title}"?`}
					loading={isDeletingData}
					rejectLabel="Batal"
					visible={showDeleteModal}
					onApprove={handleDeleteData}
					onClose={() => setShowDeleteModal(false)}
					onReject={() => setShowDeleteModal(false)}
				/>
				<div class="text-bold text-dark mb-4">Data Tindakan - {selectedHospital.Hospital.name}</div>
				<SearchBox
					rightElement={
						<ButtonAddTable
							text="Tambah Layanan Poliklinik"
							onClick={() => history.push("/sub-admin-divisi/layanan-poliklinik-tindakan/tambah")}
						/>
					}
					onSearch={keyword => {
						setSearchKeyword(keyword);
						filterLayananPoliklinikTindakan({page: 1, limit: dataLimit, search: keyword});
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={data?.data?.currentPage}
					data={dataWithIDSerial}
					limit={dataLimit}
					loading={isLoading}
					striped={true}
					title="Data Kategori Tindakan"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setDataLimit(limitValue);
						filterLayananPoliklinikTindakan({page: 1, limit: limitValue, search: searchKeyword});
					}}
					onPageChange={selectedPage =>
						filterLayananPoliklinikTindakan({page: selectedPage, limit: dataLimit, search: searchKeyword})
					}
				/>
			</>
		</DashboardLayout>
	);
}
