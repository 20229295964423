import {BasicDropdown} from "../Dropdown";

import {ModalContainer} from "./ModalContainer";

const mockDropdownData = [
	{label: "Only me", value: 1},
	{label: "Only my team", value: 2},
	{label: "All", value: 3},
];

export function ModalUploadFile({visible, onClose, onSubmit}) {
	return (
		<ModalContainer visible={visible}>
			<div class="box-popup w650" id="withdraw">
				<div class="d-flex justify-content-between align-items-center mb-4">
					<div class="text-bold text-dark">Upload new file</div>
					<div class="for-close">
						<button class="btn btn-close" type="button" onClick={onClose} />
					</div>
				</div>

				<div class="popup-content">
					<div class="col-sm-12">
						<div class="ft-12 text-bold text-grey">Keterangan</div>
						<textarea
							class="form-control"
							placeholder="Write something about your documents..."
							rows="4"
							spellcheck="false"
						/>
					</div>
					<hr />{" "}
					<div className="mb-3">
						<div class="ft-12 text-bold text-grey">Visibility</div>
						<BasicDropdown data={mockDropdownData} />
					</div>
					<hr />
					<div class="position-relative mb-3">
						<div class="item-wrapper one">
							<div class="item">
								<div class="item-inner">
									<div class="item-content">
										<div class="image-upload">
											<label for="file_upload" style={{cursor: "pointer"}}>
												<img alt="" class="uploaded-image" src="" />
												<div class="h-100">
													<div class="dplay-tbl">
														<div class="dplay-tbl-cell">
															{/* <i class="fa fa-cloud-upload"></i> */}
															<h5>
																<b>Choose Your Image to Upload</b>
															</h5>
															<h6 class="mt-10 mb-70">Or Drop Your Image Here</h6>
														</div>
													</div>
												</div>
												<input
													class="image-input"
													data-required="image"
													data-traget-resolution="image_resolution"
													id="file_upload"
													name="image_name"
													type="file"
													value=""
												/>
											</label>{" "}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<button class="btn btn-orange r8 w-100" type="button">
						Upload
					</button>
				</div>
			</div>
		</ModalContainer>
	);
}
