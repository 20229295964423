/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import {useState, useRef, useEffect} from "react";
import clsx from "clsx";
import {useHistory} from "react-router-dom";
import {Formik, Field, FieldArray} from "formik";
import * as Yup from "yup";
import {SubmitButton} from "components/Button";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";
import {BasicDropdown} from "components/Dropdown";
import {TimePicker} from "components/TimePicker";
import {useHospitalsOptions} from "hooks/SuperAdmin/Hospital";
import {useAddSchedule, useDisabledTimeOptions, useDetailSchedule} from "hooks/Doctor/Schedule";
import {Collapse} from "react-collapse";
import {convertToCurency} from "utils/converter";
import {ModalContainer} from "components/Modal";
import moment from "moment";

const FormSchemaValidation = Yup.object().shape({
	type: Yup.string().required("Tipe konsultasi wajib di isi"),
	hospitalId: Yup.string().nullable(),
	schedules: Yup.array().of(
		Yup.object().shape({
			day: Yup.number().required("Hari praktik wajib di isi"),
			times: Yup.array().of(
				Yup.object().shape({
					startTime: Yup.string().required("Jam mulai wajib di isi"),
					endTime: Yup.string()
						.required("Jam selesai wajib di isi")
						.test("lessThanStartTime", "Jam selesai tidak boleh mendahului jam mulai", function (value) {
							const startTimeHour = parseInt(this.parent.startTime?.split(":")[0]);
							const startTimeMinute = parseInt(this.parent.startTime?.split(":")[1]);
							const endTimeHour = parseInt(value?.split(":")[0]);
							const endTimeMinute = parseInt(value?.split(":")[1]);

							if (endTimeHour < startTimeHour) return false;
							if (endTimeHour === startTimeHour && endTimeMinute < startTimeMinute) return false;

							return true;
						}),
					quota: Yup.string().required("Kuota wajib di isi"),
				}),
			),
			prices: Yup.array().of(
				Yup.object().shape({
					price: Yup.string().required("Harga wajib di isi"),
				}),
			),
		}),
	),
});
const FormInitialValues = {
	userId: "",
	type: "online",
	hospitalId: "",
	schedules: [
		{
			day: 1,
			times: [{startTime: "", endTime: "", quota: ""}],
			prices: [{price: "", str_price: ""}],
		},
	],
};

const FormGenerateScheduleSchemaValidation = Yup.object().shape({
	slot: Yup.number().integer().required("Slot wajib diisi"),
	startTime: Yup.string().required("Jam mulai wajib diisi"),
	endTime: Yup.string()
		.required("Jam selesai wajib di isi")
		.test("lessThanStartTime", "Jam selesai tidak boleh mendahului jam mulai", function (value) {
			const startTimeHour = parseInt(this.parent.startTime?.split(":")[0]);
			const startTimeMinute = parseInt(this.parent.startTime?.split(":")[1]);
			const endTimeHour = parseInt(value?.split(":")[0]);
			const endTimeMinute = parseInt(value?.split(":")[1]);

			if (endTimeHour < startTimeHour) return false;
			if (endTimeHour === startTimeHour && endTimeMinute < startTimeMinute) return false;

			return true;
		}),
	restStartTime: Yup.string().test(
		"lessThanStartTime",
		"Jam mulai istirahat tidak boleh mendahului jam mulai atau lebih dari jam selesai praktik",
		function (value) {
			const startTimeHour = parseInt(this.parent.startTime?.split(":")[0]);
			const startTimeMinute = parseInt(this.parent.startTime?.split(":")[1]);
			const endTimeHour = parseInt(this.parent.endTime?.split(":")[0]);
			const endTimeMinute = parseInt(this.parent.endTime?.split(":")[1]);
			const startRestTimeHour = parseInt(value?.split(":")[0]);
			const startRestTimeMinute = parseInt(value?.split(":")[1]);

			if (startRestTimeHour < startTimeHour) return false;
			if (startRestTimeHour === startTimeHour && startRestTimeMinute < startTimeMinute) return false;
			if (startRestTimeHour > endTimeHour) return false;
			if (startRestTimeHour === startTimeHour && startRestTimeMinute > endTimeMinute) return false;

			return true;
		},
	),
	restEndTime: Yup.string().test(
		"lessThanStartTime",
		"Jam selesai istirahat tidak boleh mendahului jam mulai",
		function (value) {
			const restStartTimeHour = parseInt(this.parent.restStartTime?.split(":")[0]);
			const restStartTimeMinute = parseInt(this.parent.restStartTime?.split(":")[1]);
			const endTimeHour = parseInt(this.parent.endTime?.split(":")[0]);
			const endTimeMinute = parseInt(this.parent.endTime?.split(":")[1]);
			const restEndTimeHour = parseInt(value?.split(":")[0]);
			const restEndTimeMinute = parseInt(value?.split(":")[1]);

			if (restEndTimeHour < restStartTimeHour) return false;
			if (restEndTimeHour === restStartTimeHour && restEndTimeMinute < restStartTimeMinute) return false;
			if (restEndTimeHour > endTimeHour) return false;
			if (restEndTimeHour === endTimeHour && restEndTimeMinute > endTimeMinute) return false;

			return true;
		},
	),
	quota: Yup.number().required("Kuota wajib diisi"),
});

const DayOptions = [
	{
		value: 1,
		label: "Senin",
	},
	{
		value: 2,
		label: "Selasa",
	},
	{
		value: 3,
		label: "Rabu",
	},
	{
		value: 4,
		label: "Kamis",
	},
	{
		value: 5,
		label: "Jum'at",
	},
	{
		value: 6,
		label: "Sabtu",
	},
	{
		value: 7,
		label: "Minggu",
	},
];

export function ScheduleEditPage({scheduleId, userId, onUnmounted, availableHospital, setTab}) {
	const [scheduleIndexToGenerate, setScheduleIndexToGenerate] = useState(null);
	const [openModalCopy, setOpenModalCopy] = useState(false);
	const [copyFrom, setCopyFrom] = useState(1);
	const [copyTo, setCopyTo] = useState(1);
	const [isCopy, setIsCopy] = useState(false);
	const [errorGenerate, setErrorGenerate] = useState(null);
	const [collapseOpen, setCollapseOpen] = useState(null);
	const {showToast} = useToast();
	const history = useHistory();
	const {mutate, isLoading} = useAddSchedule();
	const {data: detailSchedule} = useDetailSchedule(scheduleId);
	const [locationOptions, setLocationOptions] = useState([]);
	// const [consultationOptions, setConsultationOptions] = useState([]);
	const formikRef = useRef();
	const formikGenerateRef = useRef();

	const {hospitalOptions: locations, isLoading: isLoadingLocationOptions} = useHospitalsOptions();
	const {
		disabledTimesPerDay,
		isLoading: isLoadingDisabledTimesOptions,
		filterDisabledTimeOptions,
	} = useDisabledTimeOptions(userId);
	const handleSubmit = formData => {
		if (formData.type === "online") formData.hospitalId = undefined;
		mutate(formData, {
			onSuccess: () => {
				showToast("success", "Data berhasil ditambahkan", 3000);
				setTab(1);
			},
			onError: res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
			},
		});
	};

	useEffect(() => {
		formikRef?.current?.setFieldValue("userId", userId);
	}, [userId]);

	useEffect(() => {
		if (locations.length) {
			const hospital = locations[0].value;
			const dataLocations = locations.filter(location => availableHospital.includes(parseInt(location.value)));

			setLocationOptions([...dataLocations]);
			formikRef?.current.setFieldValue("hospitalId", hospital);
		}
	}, [locations, availableHospital]);

	const getDisabledHours = day => {
		const disabledTime = disabledTimesPerDay.filter(item => item.day == day)?.[0];

		return disabledTime?.disabledHours || [];
	};

	const getDisabledHourMinutes = day => {
		const disabledTime = disabledTimesPerDay.filter(item => item.day == day)?.[0];

		return disabledTime?.disabledHourMinutes || [];
	};

	useEffect(() => {
		if (detailSchedule?.success) {
			const {schedules} = detailSchedule?.data;
			const detailData = detailSchedule?.data;

			detailData.schedules = [];
			Object.keys(FormInitialValues).forEach(key => {
				formikRef?.current.setFieldValue(key, detailData?.[key]);
			});

			let scheduleIndex = 0;

			schedules.forEach((item, index) => {
				if (item.times.length) {
					const prices = item.prices.map(price => ({
						price: price.price,
						str_price: convertToCurency(`${price.price}`),
					}));

					item.prices = prices;
					formikRef?.current.setFieldValue(`schedules.${scheduleIndex}`, item);
					scheduleIndex += 1;
				}
			});
		}
	}, [detailSchedule, scheduleId]);

	useEffect(() => {
		return () => onUnmounted();
	}, [scheduleId, onUnmounted]);

	const getEndTimeDisabledHour = startTime => {
		let hours = [];

		if (startTime) {
			const startHour = parseInt(startTime.split(":")[0]);
			let countHour = 0;

			while (countHour < startHour) {
				hours.push(countHour++);
			}
		}

		return hours;
	};

	const closeModal = () => {
		setScheduleIndexToGenerate(null);
	};

	const closeModalCopy = () => {
		setOpenModalCopy(false);
	};

	const handleGenerateSchedule = formValue => {
		const currentDate = moment().format("MM/DD/yyyy");
		const {slot, restStartTime, restEndTime, startTime, endTime, quota, buffer} = formValue;

		const startTimeHour = parseInt(startTime.split(":")[0]);
		const startTimeMinute = parseInt(startTime.split(":")[1]);
		const startTimeInMinute = startTimeHour * 60 + startTimeMinute;

		const endTimeHour = parseInt(endTime.split(":")[0]);
		const endTimeMinute = parseInt(endTime.split(":")[1]);
		const endTimeInMinute = endTimeHour * 60 + endTimeMinute;

		const restStartTimeHour = restStartTime ? parseInt(restStartTime.split(":")[0]) : 0;
		const restStartTimeMinute = restStartTime ? parseInt(restStartTime.split(":")[1]) : 0;
		const restStartTimeInMinute = restStartTimeHour * 60 + restStartTimeMinute;

		const restEndTimeHour = restEndTime ? parseInt(restEndTime.split(":")[0]) : 0;
		const restEndTimeMinute = restEndTime ? parseInt(restEndTime.split(":")[1]) : 0;
		const restEndTimeInMinute = restEndTimeHour * 60 + restEndTimeMinute;

		// if (restStartTimeInMinute && (restStartTimeInMinute - startTimeInMinute) % slot !== 0) {
		// 	setErrorGenerate("Jam mulai istirahat harus sesuai dengan pembagian slot");
		// 	setTimeout(() => {
		// 		setErrorGenerate(null);
		// 	}, 3000);

		// 	return null;
		// }

		// if (restEndTimeInMinute && (restEndTimeInMinute - endTimeInMinute) % slot !== 0) {
		// 	setErrorGenerate(
		// 		"Selisih jam selesai istirahat dan jam selesai praktik harus sesuai dengan pembagian slot",
		// 	);
		// 	setTimeout(() => {
		// 		setErrorGenerate(null);
		// 	}, 3000);

		// 	return null;
		// }

		// if (!restStartTimeInMinute && !restEndTimeInMinute && (startTimeInMinute - endTimeInMinute) % slot !== 0) {
		// 	setErrorGenerate("Selisih jam mulai praktik dan jam selesai praktik harus sesuai dengan pembagian slot");
		// 	setTimeout(() => {
		// 		setErrorGenerate(null);
		// 	}, 3000);

		// 	return null;
		// }

		const hourCountInSlot = Math.floor(slot / 60);
		const minuteCountInSlot = Math.floor(slot % 60);

		let firstEndTimeHour = startTimeHour + hourCountInSlot;
		let firstEndTimeMinute = startTimeMinute + minuteCountInSlot;

		if (firstEndTimeMinute === 60) {
			firstEndTimeHour += 1;
			firstEndTimeMinute = 0;
		} else if (firstEndTimeMinute > 59) {
			firstEndTimeHour += 1;
			firstEndTimeMinute = firstEndTimeMinute % 60;
		}

		const schedules = [
			{
				startTime,
				endTime: `${
					firstEndTimeHour.toString().length > 1
						? firstEndTimeHour.toString()
						: "0" + firstEndTimeHour.toString()
				}:${
					firstEndTimeMinute.toString().length > 1
						? firstEndTimeMinute.toString()
						: "0" + firstEndTimeMinute.toString()
				}`,
				quota,
			},
		];
		let lastIndex = 0;

		if (!restStartTimeInMinute && !restEndTimeInMinute) {
			let timeRange = endTimeInMinute - startTimeInMinute;

			while (timeRange > slot) {
				const lastSlotEndHour = parseInt(schedules[lastIndex].endTime.split(":")[0]);
				const lastSlotEndMinute = parseInt(schedules[lastIndex].endTime.split(":")[1]);
				let hourToInsert = lastSlotEndHour + hourCountInSlot;
				let minuteToInsert = lastSlotEndMinute + minuteCountInSlot;

				if (minuteToInsert === 60) {
					hourToInsert += 1;
					minuteToInsert = 0;
				} else if (minuteToInsert > 59) {
					hourToInsert += 1;
					minuteToInsert = minuteToInsert % 60;
				}

				const hourToInsertInMinute = hourToInsert * 60 + minuteToInsert;

				if (hourToInsertInMinute <= endTimeInMinute) {
					// normalization
					hourToInsert = hourToInsert.toString();
					minuteToInsert = minuteToInsert.toString();

					if (hourToInsert.length < 2) hourToInsert = `0${hourToInsert}`;
					if (minuteToInsert.length < 2) minuteToInsert = `0${minuteToInsert}`;

					schedules.push({
						startTime: moment(`${currentDate} ${schedules[lastIndex].endTime}`)
							.add("minutes", buffer)
							.format("HH:mm"),
						endTime: moment(`${currentDate} ${hourToInsert}:${minuteToInsert}`)
							.add("minutes", buffer)
							.format("HH:mm"),
						quota,
					});
				}

				timeRange -= slot + buffer;
				lastIndex += 1;
			}
		} else {
			let timeRangeBeforeRest = restStartTimeInMinute - startTimeInMinute;

			while (timeRangeBeforeRest > slot) {
				const lastSlotEndHour = parseInt(schedules[lastIndex].endTime.split(":")[0]);
				const lastSlotEndMinute = parseInt(schedules[lastIndex].endTime.split(":")[1]);
				let hourToInsert = lastSlotEndHour + hourCountInSlot;
				let minuteToInsert = lastSlotEndMinute + minuteCountInSlot;

				if (minuteToInsert === 60) {
					hourToInsert += 1;
					minuteToInsert = 0;
				} else if (minuteToInsert > 59) {
					hourToInsert += 1;
					minuteToInsert = minuteToInsert % 60;
				}

				const hourToInsertInMinute = hourToInsert * 60 + minuteToInsert;

				if (hourToInsertInMinute <= restStartTimeInMinute) {
					// normalization
					hourToInsert = hourToInsert.toString();
					minuteToInsert = minuteToInsert.toString();

					if (hourToInsert.length < 2) hourToInsert = `0${hourToInsert}`;
					if (minuteToInsert.length < 2) minuteToInsert = `0${minuteToInsert}`;

					schedules.push({
						startTime: moment(`${currentDate} ${schedules[lastIndex].endTime}`)
							.add("minutes", buffer)
							.format("HH:mm"),
						endTime: moment(`${currentDate} ${hourToInsert}:${minuteToInsert}`)
							.add("minutes", buffer)
							.format("HH:mm"),
						quota,
					});
					lastIndex += 1;
				}
				timeRangeBeforeRest -= slot + buffer;
			}

			let timeRangeAfterRest = endTimeInMinute - restEndTimeInMinute;

			let indexAfterRest = 0;

			while (timeRangeAfterRest >= slot) {
				const lastSlotEndHour =
					indexAfterRest < 1
						? parseInt(restEndTime.split(":")[0])
						: parseInt(schedules[lastIndex].endTime.split(":")[0]);
				const lastSlotEndMinute =
					indexAfterRest < 1
						? parseInt(restEndTime.split(":")[1])
						: parseInt(schedules[lastIndex].endTime.split(":")[1]);

				let hourToInsert = lastSlotEndHour + hourCountInSlot;
				let minuteToInsert = lastSlotEndMinute + minuteCountInSlot;

				if (minuteToInsert === 60) {
					hourToInsert += 1;
					minuteToInsert = 0;
				} else if (minuteToInsert > 59) {
					hourToInsert += 1;
					minuteToInsert = minuteToInsert % 60;
				}

				const hourToInsertInMinute = hourToInsert * 60 + minuteToInsert;

				if (hourToInsertInMinute <= endTimeInMinute) {
					// normalization
					hourToInsert = hourToInsert.toString();
					minuteToInsert = minuteToInsert.toString();

					if (hourToInsert.length < 2) hourToInsert = `0${hourToInsert}`;
					if (minuteToInsert.length < 2) minuteToInsert = `0${minuteToInsert}`;

					schedules.push({
						startTime: moment(
							`${currentDate} ${indexAfterRest < 1 ? restEndTime : schedules[lastIndex].endTime}`,
						)
							.add("minutes", indexAfterRest < 1 ? 0 : buffer)
							.format("HH:mm"),
						endTime: moment(`${currentDate} ${hourToInsert}:${minuteToInsert}`)
							.add("minutes", indexAfterRest < 1 ? 0 : buffer)
							.format("HH:mm"),
						quota,
					});
				}
				timeRangeAfterRest -= slot + buffer;
				lastIndex += 1;
				indexAfterRest += 1;
			}
		}

		formikRef.current.setFieldValue(`schedules.${scheduleIndexToGenerate}.times`, []);
		formikRef.current.setFieldValue(`schedules.${scheduleIndexToGenerate}.times`, schedules);
		// formikGenerateRef.current.resetForm();
		closeModal();
	};

	const handleCopySchedule = (day, dayToCopy, scheduleIndex) => {
		const selectedSchedule = formikRef.current.values.schedules.filter(item => item.day == dayToCopy)[0];

		if (selectedSchedule && isCopy) {
			formikRef.current.setFieldValue(`schedules.${scheduleIndex}`, {
				...selectedSchedule,
				day,
			});
		} else {
			formikRef.current.setFieldValue(`schedules.${scheduleIndex}`, {
				day,
				times: [{startTime: "", endTime: "", quota: ""}],
				prices: [{price: "", str_price: ""}],
			});
		}

		setCopyFrom(1);
		setCopyTo(1);
		setIsCopy(false);
		setOpenModalCopy(false);
	};

	return (
		<>
			<ModalContainer visible={openModalCopy}>
				<div className="box-popup w-50" id="change-price">
					<div className="d-flex justify-content-between align-items-center mb-4">
						<div className="text-bold text-dark">Salin Jadwal Praktik</div>
						<div className="for-close">
							<button className="btn btn-close" type="button" onClick={closeModalCopy} />
						</div>
					</div>
					<div className="popup-content">
						<div class="row gx-2 mb-5">
							<div class="col-sm-12 col-12">
								<div class="setting-item">
									<label class="setting-label">Salin Hari?</label>
									<div class="row">
										<div className="col-sm-2 col-2">
											<label>
												<input
													checked={!isCopy}
													className="me-1"
													name="isCopy"
													placeholder=""
													type="radio"
													onChange={e => {
														setIsCopy(false);
													}}
												/>
												Tidak
											</label>
										</div>
										<div class="col-sm-2 col-2">
											<label>
												<input
													checked={isCopy}
													className="me-1"
													name="isCopy"
													placeholder=""
													type="radio"
													onChange={e => {
														setIsCopy(true);
													}}
												/>
												Ya
											</label>
										</div>
									</div>
								</div>
							</div>
							<div class="col-sm-12 col-lg-6">
								<div class="setting-item">
									<label class="setting-label required">Hari</label>
									<BasicDropdown
										className={"form-control"}
										data={DayOptions}
										name="copyTo"
										value={copyTo}
										onSelect={fieldValue => {
											setCopyTo(fieldValue);
										}}
									/>
								</div>
							</div>
							<div class={!isCopy ? "col-sm-12 col-lg-6 d-none" : "col-sm-12 col-lg-6"}>
								<div class="setting-item">
									<label class="setting-label">Salin Dari Hari</label>
									<BasicDropdown
										className={"form-control"}
										data={DayOptions}
										name="copyFrom"
										value={copyFrom}
										onSelect={fieldValue => {
											setCopyFrom(fieldValue);
										}}
									/>
								</div>
							</div>
							<div class="col-12 text-center mt-5">
								<button
									className="btn btn-orange r8"
									type="button"
									onClick={() =>
										handleCopySchedule(
											copyTo,
											copyFrom,
											formikRef.current?.values?.schedules?.length,
										)
									}>
									Tambah
								</button>
							</div>
						</div>
					</div>
				</div>
			</ModalContainer>
			<ModalContainer visible={scheduleIndexToGenerate !== null}>
				<div className="box-popup w-50" id="change-price">
					<div className="d-flex justify-content-between align-items-center mb-4">
						<div className="text-bold text-dark">Generate Jadwal Praktik</div>
						<div className="for-close">
							<button className="btn btn-close" type="button" onClick={closeModal} />
						</div>
					</div>

					<div className="popup-content">
						<div className={errorGenerate ? "alert alert-danger" : "d-none"} role="alert">
							{errorGenerate}
						</div>
						<Formik
							initialValues={{
								slot: "",
								buffer: 0,
								restStartTime: "",
								restEndTime: "",
								startTime: "",
								endTime: "",
								quota: "",
							}}
							innerRef={formikGenerateRef}
							validationSchema={FormGenerateScheduleSchemaValidation}
							onSubmit={handleGenerateSchedule}>
							{({
								handleSubmit,
								handleBlur,
								handleChange,
								values,
								errors,
								touched,
								setFieldValue,
								setFieldTouched,
							}) => (
								<>
									<div class="row gx-2 mb-5">
										<div class="col-sm-6 col-lg-6">
											<div class="setting-item">
												<label class="setting-label required">Slot</label>
												<small className="text-grey ms-2">In minute</small>
												<input
													class={clsx(
														"form-control",
														errors?.slot && touched?.slot && "form-control-error",
													)}
													disabled={isLoading}
													name="slot"
													placeholder=""
													type="text"
													value={values?.slot}
													onBlur={handleBlur}
													onChange={e => {
														const value = e.target.value.replace(/\D/g, "");

														value && setFieldValue("slot", parseInt(value));
													}}
												/>
												{errors.slot && touched.slot && (
													<p className="form-error-item-message">{errors.slot}</p>
												)}
											</div>
										</div>
										<div class="col-sm-6 col-lg-6">
											<div class="setting-item">
												<label class="setting-label">Buffer</label>
												<small className="text-grey ms-2">In minute</small>
												<input
													class={clsx(
														"form-control",
														errors?.buffer && touched?.buffer && "form-control-error",
													)}
													disabled={isLoading}
													name="buffer"
													placeholder=""
													type="text"
													value={values?.buffer}
													onBlur={handleBlur}
													onChange={e => {
														const value = e.target.value.replace(/\D/g, "");

														value && setFieldValue("buffer", parseInt(value));
													}}
												/>
												{errors.buffer && touched.buffer && (
													<p className="form-error-item-message">{errors.buffer}</p>
												)}
											</div>
										</div>
										<div class="col-sm-6 col-6">
											<div class="setting-item">
												<label class="setting-label required">Jam Mulai</label>
												<TimePicker
													disabled={isLoading}
													value={values?.startTime}
													onChange={fieldValue => {
														setFieldTouched(`startTime`, true);
														setFieldValue(`startTime`, fieldValue?.format("HH:mm"));
													}}
												/>
												{errors?.startTime && touched?.startTime && (
													<p className="form-error-item-message">{errors?.startTime}</p>
												)}
											</div>
										</div>
										<div class="col-sm-6 col-6">
											<div class="setting-item">
												<label class="setting-label required">Jam Selesai</label>
												<TimePicker
													disabled={isLoading}
													value={values?.endTime}
													onChange={fieldValue => {
														setFieldTouched(`endTime`, true);
														setFieldValue(`endTime`, fieldValue?.format("HH:mm"));
													}}
												/>
												{errors?.endTime && touched?.endTime && (
													<p className="form-error-item-message">{errors?.endTime}</p>
												)}
											</div>
										</div>
										<div class="col-sm-6 col-6">
											<div class="setting-item">
												<label class="setting-label">Jam Mulai Istirahat</label>
												<TimePicker
													disabled={isLoading}
													value={values?.restStartTime}
													onChange={fieldValue => {
														setFieldTouched(`restStartTime`, true);
														setFieldValue(`restStartTime`, fieldValue?.format("HH:mm"));
													}}
												/>
												{errors?.restStartTime && touched?.restStartTime && (
													<p className="form-error-item-message">{errors?.restStartTime}</p>
												)}
											</div>
										</div>
										<div class="col-sm-6 col-6">
											<div class="setting-item">
												<label class="setting-label">Jam Selesai Istirahat</label>
												<TimePicker
													disabled={isLoading}
													value={values?.restEndTime}
													onChange={fieldValue => {
														setFieldTouched(`restEndTime`, true);
														setFieldValue(`restEndTime`, fieldValue?.format("HH:mm"));
													}}
												/>
												{errors?.restEndTime && touched?.restEndTime && (
													<p className="form-error-item-message">{errors?.restEndTime}</p>
												)}
											</div>
										</div>
										<div class="col-sm-12 col-lg-6">
											<div class="setting-item">
												<label class="setting-label required">Kuota</label>
												<input
													class={clsx(
														"form-control",
														errors?.quota && touched?.quota && "form-control-error",
													)}
													disabled={isLoading}
													name="quota"
													placeholder=""
													type="text"
													value={values?.quota}
													onBlur={handleBlur}
													onChange={e => {
														const value = e.target.value.replace(/\D/g, "");

														setFieldValue("quota", value);
													}}
												/>
												{errors.quota && touched.quota && (
													<p className="form-error-item-message">{errors.quota}</p>
												)}
											</div>
										</div>
									</div>
									<div class="col-12 text-center">
										<button
											className="btn btn-orange r8"
											disabled={!FormGenerateScheduleSchemaValidation.isValidSync(values)}
											type="button"
											onClick={handleSubmit}>
											Generate
										</button>
									</div>
								</>
							)}
						</Formik>
					</div>
				</div>
			</ModalContainer>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({
					handleSubmit,
					handleBlur,
					handleChange,
					values,
					errors,
					touched,
					setFieldValue,
					setFieldTouched,
				}) => (
					<div class="box-white setting-content h-100 no-border p24">
						<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
							<div class="ttl-20">Buat Jadwal Praktik</div>
						</div>

						<hr />

						<div class="text-bold mb-4">
							<a class="text-dark" style={{cursor: "pointer"}} onClick={() => history.push("/doctor")}>
								<span class="icon-ico-back me-2" />
							</a>
							Daftar List Dokter
						</div>

						<div class="row gx-2 mb-5">
							<div class="col-sm-6 col-6">
								<div class="setting-item">
									<label class="setting-label required">Tipe Konsultasi</label>
									<div className="col-sm-6 col-6">
										<label>
											<Field
												className="me-2"
												name="type"
												type="radio"
												value="online"
												onChange={() => {
													setFieldValue("type", "online");
													setFieldTouched("type", true);
													setFieldValue("hospitalId", null);
												}}
											/>
											Online
										</label>
									</div>
									<div class="col-sm-6 col-6">
										<label>
											<Field
												className="me-2"
												name="type"
												type="radio"
												value="offline"
												onChange={() => {
													setFieldValue("type", "offline");
													setFieldTouched("type", true);
													setFieldValue("hospitalId", locationOptions?.[0]?.value);
													filterDisabledTimeOptions({
														hospitalId: locationOptions?.[0]?.value,
													});
												}}
											/>
											Offline
										</label>
									</div>
								</div>
							</div>
							<div className={values.type === "online" ? "d-none" : "col-sm-6 col-6"}>
								<div class="setting-item">
									<label class="setting-label">Lokasi Praktik</label>
									<BasicDropdown
										className={clsx("form-control", errors?.hospitalId && "form-control-error")}
										data={locationOptions}
										disabled={values.type === "online"}
										name={`hospitalId`}
										value={values?.hospitalId}
										onSelect={fieldValue => {
											setFieldValue(`hospitalId`, fieldValue);
											filterDisabledTimeOptions({hospitalId: fieldValue});
										}}
									/>
									{errors?.hospitalId && touched?.hospitalId && (
										<p className="form-error-item-message">{errors?.hospitalId}</p>
									)}
								</div>
							</div>
						</div>

						<div id="accordion">
							<div class="card mt-5">
								<div
									class="card-header d-flex justify-content-between"
									id="headingOne"
									style={{cursor: "pointer"}}
									onClick={() => setCollapseOpen(!collapseOpen)}>
									<h3 class="mb-0">
										<button className="btn" onClick={() => setCollapseOpen(!collapseOpen)}>
											Jadwal Praktik
										</button>
									</h3>
									<h3 style={{"margin-top": "-10px"}}>&#8964;</h3>
								</div>
								<Collapse isOpened={collapseOpen}>
									<div>
										<div class="card-body">
											<FieldArray name="schedules">
												{({insert, remove, push}) => (
													<>
														{values?.schedules.length > 0 &&
															values?.schedules.map((schedule, scheduleIndex) => (
																<div class="row gx-2 mb-5">
																	<div class="col-sm-6 col-6">
																		<div class="setting-item">
																			<label class="setting-label required">
																				Hari Praktik
																			</label>
																			<BasicDropdown
																				className={clsx(
																					"form-control",
																					errors?.schedules?.[scheduleIndex]
																						?.day && "form-control-error",
																				)}
																				data={DayOptions}
																				name={`schedules.${scheduleIndex}.day`}
																				value={
																					values?.schedules?.[scheduleIndex]
																						?.day
																				}
																				onSelect={fieldValue => {
																					setFieldValue(
																						`schedules.${scheduleIndex}.day`,
																						fieldValue,
																					);
																				}}
																			/>
																			{errors?.schedules?.[scheduleIndex]?.day &&
																				touched?.schedules?.[scheduleIndex]
																					?.day && (
																					<p className="form-error-item-message">
																						{
																							errors?.schedules?.[
																								scheduleIndex
																							]?.day
																						}
																					</p>
																				)}
																		</div>
																		<div className={"col-12"}>
																			<div class="setting-item">
																				<button
																					className="btn btn-green px-2 py-0 r8"
																					type="button"
																					onClick={() =>
																						setScheduleIndexToGenerate(
																							scheduleIndex,
																						)
																					}>
																					Generate Jam Praktik
																				</button>
																			</div>
																		</div>
																	</div>
																	<div class="col-sm-12 col-12">
																		<div class="setting-item">
																			<FieldArray name="times">
																				{({insert, remove, push}) => (
																					<div>
																						{values?.schedules?.[
																							scheduleIndex
																						]?.times.length > 0 &&
																							values?.schedules?.[
																								scheduleIndex
																							]?.times.map(
																								(time, timeIndex) => (
																									<div
																										key={timeIndex}
																										className="row">
																										<div className="col-3">
																											<div class="setting-item">
																												<label class="setting-label required">
																													Jam
																													Mulai
																												</label>
																												<TimePicker
																													disabled={
																														isLoading
																													}
																													disabledHourMinutes={getDisabledHourMinutes(
																														values
																															?.schedules?.[
																															scheduleIndex
																														]
																															?.day,
																													)}
																													disabledHours={getDisabledHours(
																														values
																															?.schedules?.[
																															scheduleIndex
																														]
																															?.day,
																													)}
																													placeholder={
																														values
																															?.schedules?.[
																															scheduleIndex
																														]
																															?.times?.[
																															timeIndex
																														]
																															.startTime
																													}
																													value={
																														values
																															?.schedules?.[
																															scheduleIndex
																														]
																															?.times?.[
																															timeIndex
																														]
																															.startTime
																													}
																													onChange={fieldValue => {
																														setFieldTouched(
																															`schedules.${scheduleIndex}.times.${timeIndex}.startTime`,
																															true,
																														);
																														setFieldValue(
																															`schedules.${scheduleIndex}.times.${timeIndex}.startTime`,
																															fieldValue?.format(
																																"HH:mm",
																															),
																														);
																													}}
																												/>
																												{errors
																													?.schedules?.[
																													scheduleIndex
																												]
																													?.times?.[
																													timeIndex
																												]
																													?.startTime &&
																													touched
																														?.schedules?.[
																														scheduleIndex
																													]
																														?.times?.[
																														timeIndex
																													]
																														?.startTime && (
																														<p className="form-error-item-message">
																															{
																																errors
																																	?.schedules?.[
																																	scheduleIndex
																																]
																																	?.times?.[
																																	timeIndex
																																]
																																	?.startTime
																															}
																														</p>
																													)}
																											</div>
																										</div>
																										<div className="col-3">
																											<div class="setting-item">
																												<label class="setting-label required">
																													Jam
																													Selesai
																												</label>
																												<TimePicker
																													disabled={
																														isLoading
																													}
																													disabledHours={getEndTimeDisabledHour(
																														values
																															?.schedules?.[
																															scheduleIndex
																														]
																															?.times?.[
																															timeIndex
																														]
																															.startTime,
																													)}
																													disabledMinuteByStart={
																														values
																															?.schedules?.[
																															scheduleIndex
																														]
																															?.times?.[
																															timeIndex
																														]
																															.startTime
																													}
																													placeholder={
																														values
																															?.schedules?.[
																															scheduleIndex
																														]
																															?.times?.[
																															timeIndex
																														]
																															.endTime
																													}
																													value={
																														values
																															?.schedules?.[
																															scheduleIndex
																														]
																															?.times?.[
																															timeIndex
																														]
																															.endTime
																													}
																													onChange={fieldValue => {
																														setFieldTouched(
																															`schedules.${scheduleIndex}.times.${timeIndex}.endTime`,
																															true,
																														);
																														setFieldValue(
																															`schedules.${scheduleIndex}.times.${timeIndex}.endTime`,
																															fieldValue?.format(
																																"HH:mm",
																															),
																														);
																													}}
																												/>
																												{errors
																													?.schedules?.[
																													scheduleIndex
																												]
																													?.times?.[
																													timeIndex
																												]
																													?.endTime &&
																													touched
																														?.schedules?.[
																														scheduleIndex
																													]
																														?.times?.[
																														timeIndex
																													]
																														?.endTime && (
																														<p className="form-error-item-message">
																															{
																																errors
																																	?.schedules?.[
																																	scheduleIndex
																																]
																																	?.times?.[
																																	timeIndex
																																]
																																	?.endTime
																															}
																														</p>
																													)}
																											</div>
																										</div>
																										<div
																											className={
																												"col-3"
																											}>
																											<div class="setting-item">
																												<label class="setting-label required">
																													Kuota
																												</label>
																												<input
																													class={clsx(
																														"form-control",
																														errors
																															?.schedules?.[
																															scheduleIndex
																														]
																															?.times?.[
																															timeIndex
																														]
																															?.quota &&
																															touched
																																?.schedules?.[
																																scheduleIndex
																															]
																																?.times?.[
																																timeIndex
																															]
																																?.quota &&
																															"form-control-error",
																													)}
																													disabled={
																														isLoading
																													}
																													name={`schedules.${scheduleIndex}.times.${timeIndex}.quota`}
																													placeholder=""
																													type="text"
																													value={
																														values
																															?.schedules?.[
																															scheduleIndex
																														]
																															?.times?.[
																															timeIndex
																														]
																															?.quota
																													}
																													onBlur={
																														handleBlur
																													}
																													onChange={e => {
																														const value =
																															e.target.value.replace(
																																/\D/g,
																																"",
																															);

																														if (
																															values
																														) {
																															setFieldValue(
																																`schedules.${scheduleIndex}.times.${timeIndex}.quota`,
																																value,
																															);
																														} else {
																															setFieldValue(
																																`schedules.${scheduleIndex}.times.${timeIndex}.quota`,
																																undefined,
																															);
																														}
																													}}
																												/>
																												{errors
																													?.schedules?.[
																													scheduleIndex
																												]
																													?.times?.[
																													timeIndex
																												]
																													?.quota &&
																													touched
																														?.schedules?.[
																														scheduleIndex
																													]
																														?.times?.[
																														timeIndex
																													]
																														?.quota && (
																														<p className="form-error-item-message">
																															{
																																errors
																																	?.schedules?.[
																																	scheduleIndex
																																]
																																	?.times?.[
																																	timeIndex
																																]
																																	?.quota
																															}
																														</p>
																													)}
																											</div>
																										</div>
																										<div className="col-2 row align-items-center">
																											<a
																												className="col-2"
																												style={{
																													cursor: "pointer",
																													fontSize:
																														"24px",
																												}}
																												onClick={() =>
																													setFieldValue(
																														`schedules.${scheduleIndex}.times`,
																														[
																															...values
																																.schedules[
																																scheduleIndex
																															]
																																.times,
																															{
																																startTime:
																																	"",
																																endTime:
																																	"",
																																quota: "",
																															},
																														],
																													)
																												}>
																												<span class="icon-ico-plus" />
																											</a>
																											<a
																												className={
																													timeIndex >
																													0
																														? "col-2"
																														: "d-none"
																												}
																												style={{
																													cursor: "pointer",
																													fontSize:
																														"24px",
																												}}
																												onClick={() => {
																													if (
																														timeIndex
																													) {
																														let timeData =
																															values
																																.schedules[
																																scheduleIndex
																															]
																																.times;

																														timeData.pop();

																														setFieldValue(
																															`schedules.${scheduleIndex}.times`,
																															[
																																...timeData,
																															],
																														);
																													}
																												}}>
																												<span class="icon-ico-delete" />
																											</a>
																										</div>
																									</div>
																								),
																							)}
																					</div>
																				)}
																			</FieldArray>
																		</div>
																	</div>
																	<div class="col-sm-12 col-12">
																		<div class="setting-item">
																			<FieldArray name="prices">
																				{({insert, remove, push}) => (
																					<div>
																						{values?.schedules?.[
																							scheduleIndex
																						]?.prices.length > 0 &&
																							values?.schedules?.[
																								scheduleIndex
																							]?.prices.map(
																								(price, priceIndex) => (
																									<div
																										key={priceIndex}
																										className="row">
																										<div className="col-3">
																											<div class="setting-item">
																												<label class="setting-label required">
																													Harga
																												</label>
																												<input
																													class={clsx(
																														"form-control",
																														errors
																															?.schedules?.[
																															scheduleIndex
																														]
																															?.prices?.[
																															priceIndex
																														]
																															?.startTime &&
																															touched
																																?.schedules?.[
																																scheduleIndex
																															]
																																?.prices?.[
																																priceIndex
																															]
																																?.price &&
																															"form-control-error",
																													)}
																													disabled={
																														isLoading
																													}
																													name={`schedules.${scheduleIndex}.prices.${priceIndex}.str_price`}
																													placeholder=""
																													type="text"
																													value={
																														values
																															?.schedules?.[
																															scheduleIndex
																														]
																															?.prices?.[
																															priceIndex
																														]
																															?.str_price
																													}
																													onBlur={
																														handleBlur
																													}
																													onChange={e => {
																														let value =
																															e.target.value.replace(
																																/\D/g,
																																"",
																															);

																														setFieldValue(
																															`schedules.${scheduleIndex}.prices.${priceIndex}.price`,
																															value,
																														);

																														value =
																															convertToCurency(
																																value,
																															);

																														setFieldValue(
																															`schedules.${scheduleIndex}.prices.${priceIndex}.str_price`,
																															value,
																														);
																													}}
																												/>
																												{errors
																													?.schedules?.[
																													scheduleIndex
																												]
																													?.prices?.[
																													priceIndex
																												]
																													?.price &&
																													touched
																														?.schedules?.[
																														scheduleIndex
																													]
																														?.prices?.[
																														priceIndex
																													]
																														?.price && (
																														<p className="form-error-item-message">
																															{
																																errors
																																	?.schedules?.[
																																	scheduleIndex
																																]
																																	?.prices?.[
																																	priceIndex
																																]
																																	?.price
																															}
																														</p>
																													)}
																											</div>
																										</div>
																										{price.req_price && (
																											<>
																												<div className="col-3">
																													<div class="setting-item">
																														<label class="setting-label">
																															Request
																															Perubahan
																															Harga
																														</label>
																														<input
																															class={clsx(
																																"form-control",
																																errors
																																	?.schedules?.[
																																	scheduleIndex
																																]
																																	?.prices?.[
																																	priceIndex
																																]
																																	?.req_price
																																	?.startTime &&
																																	touched
																																		?.schedules?.[
																																		scheduleIndex
																																	]
																																		?.prices?.[
																																		priceIndex
																																	]
																																		?.req_price &&
																																	"form-control-error",
																															)}
																															disabled={
																																true
																															}
																															name={`schedules.${scheduleIndex}.prices.${priceIndex}.req_price`}
																															placeholder=""
																															type="text"
																															value={
																																values
																																	?.schedules?.[
																																	scheduleIndex
																																]
																																	?.prices?.[
																																	priceIndex
																																]
																																	?.req_price
																															}
																															onBlur={
																																handleBlur
																															}
																															onChange={e => {
																																const value =
																																	e.target.value.replace(
																																		/\D/g,
																																		"",
																																	);

																																setFieldValue(
																																	`schedules.${scheduleIndex}.prices.${priceIndex}.price`,
																																	value,
																																);
																															}}
																														/>
																													</div>
																												</div>
																												<div className="col-3 row align-items-center justify-content-between">
																													<button
																														className="col-6 btn btn-success"
																														onClick={() =>
																															setFieldValue(
																																`schedules.${scheduleIndex}.prices.${priceIndex}.price`,
																																values
																																	?.schedules?.[
																																	scheduleIndex
																																]
																																	?.prices?.[
																																	priceIndex
																																]
																																	?.req_price,
																															)
																														}>
																														Setujui
																													</button>
																													<button
																														className="col-6 btn btn-danger"
																														onClick={() =>
																															setFieldValue(
																																`schedules.${scheduleIndex}.prices.${priceIndex}.req_price`,
																																null,
																															)
																														}>
																														Tolak
																													</button>
																												</div>
																											</>
																										)}
																										<div className="col-2 row align-items-center">
																											<a
																												className="col-2"
																												style={{
																													cursor: "pointer",
																													fontSize:
																														"24px",
																												}}
																												onClick={() =>
																													setFieldValue(
																														`schedules.${scheduleIndex}.prices`,
																														[
																															...values
																																.schedules[
																																scheduleIndex
																															]
																																.prices,
																															{
																																price: "",
																																str_price:
																																	"",
																															},
																														],
																													)
																												}>
																												<span class="icon-ico-plus" />
																											</a>
																											<a
																												className={
																													priceIndex >
																													0
																														? "col-2"
																														: "d-none"
																												}
																												style={{
																													cursor: "pointer",
																													fontSize:
																														"24px",
																												}}
																												onClick={() => {
																													if (
																														priceIndex
																													) {
																														let priceData =
																															values
																																.schedules[
																																scheduleIndex
																															]
																																.prices;

																														priceData.pop();

																														setFieldValue(
																															`schedules.${scheduleIndex}.prices`,
																															[
																																...priceData,
																															],
																														);
																													}
																												}}>
																												<span class="icon-ico-delete" />
																											</a>
																										</div>
																									</div>
																								),
																							)}
																					</div>
																				)}
																			</FieldArray>
																		</div>
																	</div>
																</div>
															))}
														<button
															className="btn btn-success"
															type="button"
															onClick={() => setOpenModalCopy(true)}>
															Tambah Hari
														</button>
													</>
												)}
											</FieldArray>
										</div>
									</div>
								</Collapse>
							</div>
						</div>
						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!FormSchemaValidation.isValidSync(values)}
								loading={isLoading}
								text="Simpan"
								onClick={handleSubmit}
							/>
						</div>
					</div>
				)}
			</Formik>
		</>
	);
}
