import {useMutation, useQueryClient} from "react-query";
import axios from "services/axios";

export const usePublishDoctor = () => {
	const queryClient = useQueryClient();

	return useMutation(({id}) => axios.patch(`/staff/publish/${id}`).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("doctors");
			queryClient.removeQueries("doctors");
		},
	});
};
