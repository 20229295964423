import clsx from "clsx";
import {Formik} from "formik";
import {useEffect, useMemo, useRef, useState} from "react";
import {useDropzone} from "react-dropzone";
import {useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {SubmitButton} from "components/Button";
import {PatientDropdown} from "components/Dropdown";
import {Select} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {AddFileLabFormSchemaValidation} from "config/form/FormValidation/AdminLaboratorium";
import {useListPatient} from "hooks/AdminAdmission/Patient";
import {useAddFileLab} from "hooks/AdminLaboratorium/FileLab";
import {useUploadFile} from "hooks/Misc";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat, converToLocalGender} from "utils/converter";
import {getTodayDate} from "utils/date";
import qs from "query-string";

const FormInitialValues = {
	fileBuffer: undefined,
	visibility: "",
	idPatient: "",
	idHospital: "",
	date: getTodayDate("YYYY-MM-DD"),
};

const visibilityOptions = [
	{
		value: "visible",
		label: "Live",
	},
	{
		value: "hidden",
		label: "Staff Only",
	},
];

export function MasterFileLabAddPage() {
	const formikRef = useRef();
	const history = useHistory();
	const params = useLocation();
	const idPatient = useMemo(() => {
		const parsed = qs.parse(params.search);

		if (!parsed) return null;

		return parseInt(parsed.idPatient);
	}, [params]);

	const [searchPatient, setSearchPatient] = useState("");

	useEffect(() => {
		if (idPatient) formikRef.current.setFieldValue("idPatient", idPatient + "");
	}, [idPatient]);

	const {getRootProps, getInputProps} = useDropzone({
		accept: {
			"application/pdf": [],
		},
		onDropAccepted: files => {
			formikRef.current.setFieldValue("fileBuffer", files);
		},
		onDropRejected: res => {
			formikRef.current.setFieldError("fileBuffer", "Hanya menerima format .PDF");
		},
	});

	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);

	// Master data patient
	const {data: patients, isLoading: isFethingPatients} = useListPatient({
		page: 1,
		search: null,
		limit: null,
		idHospital: selectedHospital?.hospitalId,
	});
	const [patientsOptions, setPatientsOptions] = useState([]);

	const {showToast} = useToast();

	const {mutate: createDataFileLab, isLoading} = useAddFileLab();
	const {mutateAsync: uploadFile, isLoading: isUploading} = useUploadFile();

	const uploadAllFiles = async files => {
		const promises = await Promise.all(
			files.map(file => {
				let formData = new FormData();

				formData.append("file", file);

				return uploadFile(formData);
			}),
		);

		return promises.map((promise, idx) => ({
			name: files[idx]?.name ?? "",
			url: promise.data.file ?? "",
		}));
	};

	const handleSubmitForm = async formValue => {
		const {fileBuffer, ...formDataValue} = formValue;

		const idPatient = patients?.data?.rows?.find(item => item.patient.idUser === parseInt(formDataValue.idPatient))
			?.patient?.id;

		if (!idPatient) return;

		let files = await uploadAllFiles(fileBuffer);

		createDataFileLab(
			{
				...formDataValue,
				idHospital: selectedHospital.Hospital.id,
				idPatient: idPatient,
				files,
			},
			{
				onSuccess: () => {
					showToast("success", "Data file labs berhasil ditambahkan", 3000);
					window.scrollTo(0, 0);
					history.push("/file-labs");
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	const handleUploadFile = e => {
		const file = e.target.files[0];

		formikRef.current.setFieldValue("fileBuffer", file);
	};

	// When patiens data is fetched, update to state

	useEffect(() => {
		if (patients?.success) {
			setPatientsOptions(
				patients?.data?.rows?.map(patient => ({
					value: patient?.patient?.idUser + "",
					label: `${patient?.firstName} ${patient?.lastName} [${patient?.patient?.medicalRecordNumber}]`,
					desc: `${converToLocalGender(patient?.gender)} - ${patient?.email} - ${patient?.phoneNumber}`,
					image: patient?.profilePhoto,
				})),
			);
		}
	}, [patients]);

	const findRelatedId = value => {
		return patientsOptions.find(x => x.value === value)?.label || value;
	};

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={AddFileLabFormSchemaValidation}
				onSubmit={handleSubmitForm}>
				{({handleSubmit, handleBlur, handleChange, values, errors, touched, setFieldValue, setTouched}) => {
					return (
						<div class="box-white setting-content h-100 no-border p24">
							<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
								<div class="ttl-20">Upload file baru</div>
							</div>

							<hr />

							<div class="text-bold mb-4">
								<a
									class="text-dark"
									style={{cursor: "pointer"}}
									onClick={() =>
										history.push(idPatient ? `/file-labs/detail/${idPatient}` : "/file-labs")
									}>
									<span class="icon-ico-back me-2" />
								</a>
								{idPatient ? "Daftar Hasil Laboratorium" : "Daftar List File Lab"}
							</div>

							<div class="row gx-2 mb-5">
								<div class="col-sm-6 col-6">
									<Select
										required
										label="Visibility"
										name="visibility"
										options={visibilityOptions}
										placeholder="Pilih jenis visibility..."
										onChange={selected => setFieldValue("visibility", selected.value)}
									/>
									<div class="setting-item">
										<label class="setting-label required">Rumah Sakit</label>
										<input
											disabled
											class={clsx("form-control")}
											name="idHospital"
											placeholder=""
											type="text"
											value={selectedHospital.Hospital.name}
										/>
									</div>

									<PatientDropdown
										required
										data={patientsOptions}
										disabled={!!idPatient}
										label="Pasien"
										loading={isFethingPatients}
										name="idPatient"
										placeholder="Ketikan nama, email atau nomor handphone pasien.."
										searchKeyValue={["label", "desc"]}
										value={searchPatient}
										onBlur={handleBlur}
										onChange={selectedValue => {
											setFieldValue("idPatient", selectedValue.value);
											setSearchPatient(findRelatedId(selectedValue.value));
										}}
										onInput={e => {
											setSearchPatient(e);
										}}
									/>
								</div>

								<div class="col-sm-6 col-6" />
								<div class="col-sm-12 col-12">
									<div
										class="item-wrapper one "
										{...getRootProps({className: "dropzone"})}
										style={{cursor: "pointer"}}>
										<div class="item">
											<div class="item-inner">
												<div class="item-content">
													<div class="image-upload">
														<label for="file_upload" style={{cursor: "pointer"}}>
															<img alt="" class="uploaded-image" src="" />
															<div class="h-100">
																<div class="dplay-tbl">
																	<div class="dplay-tbl-cell">
																		{/* <i class="fa fa-cloud-upload"></i> */}
																		<span className="icon-upload-cloud icon-large" />

																		<h5>
																			{values.fileBuffer ? (
																				<>
																					<ul>
																						{values.fileBuffer?.map(
																							(file, idx) => (
																								<li
																									key={idx}
																									className="text-start">
																									{file.name}
																								</li>
																							),
																						)}
																					</ul>
																				</>
																			) : (
																				<b>Pilih file yang akan di upload</b>
																			)}
																		</h5>
																		{!values.fileBuffer && (
																			<h6 class="mt-10 mb-70 ft-14">
																				Hanya menerima format pdf
																			</h6>
																		)}
																	</div>
																</div>
															</div>
															<input
																accept="application/pdf"
																class="image-input"
																name="fileBuffer"
																type="file"
																value=""
																onChange={handleUploadFile}
																{...getInputProps()}
															/>
														</label>
													</div>
													{errors?.fileBuffer && (
														<p className="form-error-item-message">{errors?.fileBuffer}</p>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="text-center my-3">
								<SubmitButton
									className="btn btn-orange d-inline-block mw-250"
									disabled={!AddFileLabFormSchemaValidation.isValidSync(values)}
									loading={isLoading || isUploading}
									text="Save"
									onClick={handleSubmit}
								/>
							</div>
						</div>
					);
				}}
			</Formik>
		</DashboardLayout>
	);
}
