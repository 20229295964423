import {useHistory, useParams} from "react-router-dom";
import {DashboardLayout} from "components/Layout";
import {SubmitButton} from "components/Button";
import {CircleLoader} from "components/Loader";
import {FormCard} from "components/Form";
import {useDetailKategoriPemeriksaan} from "hooks/SuperAdmin/KategoriPemeriksaan";

export function MasterKategoriPemeriksaanDetailPage() {
	const history = useHistory();
	const {id} = useParams();
	const {data, isLoading} = useDetailKategoriPemeriksaan(id);

	if (isLoading) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar list kategori pemeriksaan" title="Detail Kategori pemeriksaan">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar List Kategori Pemeriksaan"
				title={`Detail Data Kategori Pemeriksaan ${data?.data?.name}`}
				onBackButtonClick={() => history.push("/master-kategori-pemeriksaan")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Kategori Pemeriksaan</label>
							<p className="text-bold text-grey">{data?.data?.name}</p>
						</div>
					</div>
				</div>

				<div class="text-center my-3">
					<SubmitButton
						className="btn btn-orange d-inline-block mw-250"
						text="Ubah Data Ini"
						onClick={() => history.push(`/master-kategori-pemeriksaan/edit/${data?.data?.id}`)}
					/>
				</div>
			</FormCard>
		</DashboardLayout>
	);
}
