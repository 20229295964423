import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY} from "./useQueryPoliklinik";

export function useAddPoliklinik() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/poliklinik/add_poliklinik", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}

export function useChangeDataStatus() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/poliklinik/change_status_poliklinik", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}
