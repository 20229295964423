import clsx from "clsx";
import {DatePicker} from "components/DatePicker";
import {DashboardLayout} from "components/Layout";
import {BasicTable, SearchBox} from "components/Table";
import statusColor from "config/patientStatus";
import dayjs from "dayjs";
import {useListAppoitments} from "hooks/AdminAdmission/Appoitment";
import {useMemo, useState} from "react";
import {isMobile} from "react-device-detect";
import {BsThreeDotsVertical} from "react-icons/bs";
import {useHistory} from "react-router-dom";
import {capitalizeFirstLetter} from "utils/string";

export function AppointmentListPage() {
	const history = useHistory();

	const [filter, setFilter] = useState({
		page: 1,
		search: "",
		limit: 10,
		date: null,
	});
	const {data: appoitments, isLoading} = useListAppoitments({
		idConsutation: null,
		limit: filter.limit,
		page: filter.page,
		search: filter.search,
		date: filter.date,
	});

	const [showActionMobile, setShowActionMobile] = useState(null);

	const consultationTypeRenderer = type => {
		return (
			<span
				className={
					type === "offline"
						? "text-purple ft-18 icon-ico-hospital me-2"
						: "text-primary ft-18 icon-ico-zoom me-2"
				}
			/>
		);
	};

	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
				width: 100,
			},
			{
				Header: "Status Pasien",
				accessor: "patientStatus",
				Cell: ({row}) => {
					return (
						<div
							className="box-status "
							style={{
								background: statusColor.bgColor[row?.original?.patientStatus],
								color: "#fff",
							}}
						/>
					);
				},
			},
			{
				Header: "Nama Pasien",
				accessor: "patientName",
				Cell: ({row}) => {
					return (
						<span>
							{consultationTypeRenderer(row?.original?.type)} {row?.original?.patient?.fullName}
						</span>
					);
				},
				maxWidth: 150,
			},
			{
				Header: "Nama Dokter",
				accessor: "doctorName",
				Cell: ({row}) => {
					return row.original?.doctor?.firstName + " " + row.original?.doctor?.lastName;
				},
				maxWidth: 150,
			},
			{
				Header: "Unit & Layanan",
				accessor: "unitName",
				Cell: ({row}) => {
					return row.original?.doctor?.staff?.policlinic?.name || "-";
				},
				maxWidth: 150,
			},
			{
				Header: "Jadwal",
				accessor: "scheduleDate",
				Cell: ({row}) => {
					return (
						row.original.date && (
							<>
								<div>{`${dayjs(row.original.date).format("DD MMMM YYYY")}`}</div>
								<div>{`${row.original.startTime} - ${row.original.endTime}`}</div>
							</>
						)
					);
				},
			},
			{
				Header: "Status",
				accessor: "visibility",
				Cell: ({row}) => {
					return row.original.paymentStatus && capitalizeFirstLetter(row.original.paymentStatus);
				},
			},

			{
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="d-flex flex-row  align-items-center" style={{width: 150}}>
							<a
								class="text-orange wait-pay mr10"
								data-placement="top"
								data-toggle="tooltip"
								style={{cursor: "pointer"}}
								title="Edit data"
								onClick={() => history.push("/appointment/show/" + row.original.id)}>
								<span class="icon-ico-eye-open ft-18" />
							</a>
						</div>
					);
				},
			},
		],
		[],
	);

	const columnsMobile = useMemo(
		() => [
			{
				Header: "Rumah Sakit",
				accessor: "hospital",
				Cell: ({row}) => {
					return (
						<div class="col-7">
							<div class="text-table-medium text-bold text-elipsis">
								{consultationTypeRenderer(row?.original?.type)} {row?.original?.patient?.fullName}
							</div>
							<div className="text-table-small text-grey text-elipsis">
								<div
									className="box-status "
									style={{
										background: statusColor.bgColor[row?.original?.patientStatus],
										color: "#fff",
									}}
								/>
							</div>
							<div
								class="text-grey text-table-small"
								style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
								<div>{row.original?.doctor?.firstName + " " + row.original?.doctor?.lastName}</div>
								<div>{row.original.status && capitalizeFirstLetter(row.original.status)}</div>
							</div>
						</div>
					);
				},
			},
			{
				Header: "Schedule",
				accessor: "updatedAt",
				Cell: ({row}) => {
					return (
						<div class="col-4">
							{/* <div className="text-table-medium">{row.original.idUserDoctor}</div> */}
							<div
								class="text-grey text-table-small"
								style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
								<div>{`${dayjs(row.original.date).format("DD MMMM YYYY")}`}</div>
							</div>
						</div>
					);
				},
			},

			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="col-2">
							<div class="dropdown dropstart">
								<span
									style={{padding: "5px"}}
									onClick={() => {
										if (showActionMobile !== null) {
											setShowActionMobile(null);
										} else {
											setShowActionMobile(row.original.id);
										}
									}}>
									<BsThreeDotsVertical />
								</span>

								<ul class={clsx("dropdown-menu", showActionMobile === row.original.id && "show")}>
									<li>
										<a
											class="dropdown-item text-table-medium"
											data-placement="top"
											data-toggle="tooltip"
											style={{cursor: "pointer"}}
											title="Edit data"
											onClick={() => history.push("/appointment/show/" + row.original.id)}>
											Detail
										</a>
									</li>
								</ul>
							</div>
						</div>
					);
				},
			},
		],
		[showActionMobile],
	);

	// eslint-disable-next-line no-unused-vars
	const dataWithIDSerial = useMemo(() => {
		return (
			appoitments?.data?.rows?.map((item, index) => ({
				...item,
				idSerial: (filter.page - 1) * filter.limit + index + 1,
			})) || []
		);
	}, [appoitments, filter.limit, filter.page]);

	return (
		<>
			<DashboardLayout>
				<div class="text-bold text-dark mb-4">List Appointments</div>
				<SearchBox
					rightElement={
						<div className="row position-relative search-hospital-container align-items-center justify-content-end">
							<div className="col-lg-6 col-sm-12">
								<div className="mt-3 form-filter position-relative">
									<div className="form-filter position-relative">
										<DatePicker
											singleDatePicker
											onChange={selectedDate => {
												setFilter({
													...filter,
													date: dayjs(selectedDate, "MM/DD/YYYY").format("YYYY-MM-DD"),
												});
											}}>
											<input
												className="form-control"
												placeholder="Pilih tanggal"
												type="text"
												value={
													(filter.date &&
														dayjs(filter.date, "YYYY-MM-DD").format("DD MMMM YYYY")) ||
													""
												}
											/>
										</DatePicker>
										<div className="icon-right">
											<span className="icon-ico-date text-orange" />
										</div>
									</div>
								</div>
							</div>
						</div>
					}
					onSearch={keyword => {
						setFilter({...filter, search: keyword, page: 1});
					}}
				/>
				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={filter.page}
					data={dataWithIDSerial}
					limit={filter.limit}
					loading={isLoading}
					striped={true}
					title="Data File Laboratorium"
					totalItems={appoitments?.data?.totalItems}
					totalPage={appoitments?.data?.totalPages}
					onLimitChange={limitValue => {
						setFilter({...filter, limit: limitValue, page: 1});
					}}
					onPageChange={selectedPage => {
						setFilter({...filter, page: selectedPage});
					}}
				/>
			</DashboardLayout>
		</>
	);
}
