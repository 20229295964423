import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY} from "./useQueryKonsultasi";

export function useAddKonsultasi(options = {}) {
	return useMutation(values => axios.post("/consultation/create", values).then(res => res.data));
}
export function useUpdateKonsultasi(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/consultation/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}

export function useDeleteKonsultasi(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/consultation/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}
