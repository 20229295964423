import clsx from "clsx";
import {SubmitButton} from "components/Button";
import {DashboardLayout} from "components/Layout";
import Role from "config/Role";
import {useFormik} from "formik";
import {useAddLayananPemeriksaanPenunjang} from "hooks/AdminHospital/LayananPemeriksaanPenunjang";
import {useListDivisi} from "hooks/SuperAdmin/Division";
import {useListKategoriPemeriksaan} from "hooks/SuperAdmin/KategoriPemeriksaan";
import {useListPemeriksaanPenunjang} from "hooks/SuperAdmin/PemeriksaanPenunjang";
import {useHospitalUnits} from "hooks/SuperAdmin/Unit";
import {useToast} from "hooks/useToast";
import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {convertErrorMessageFormat, convertToCurency} from "utils/converter";
import {REGEX_NUMBER} from "utils/regex";
import * as Yup from "yup";

const FormSchemaValidation = Yup.object().shape({
	unitId: Yup.string().required("Unit wajib diisi"),
	divisionId: Yup.string().optional(),
	checkupCategoryId: Yup.string().required("Kategori pemeriksaan wajib diisi"),
	checkupSupportId: Yup.string().required("Pemeriksaan penunjang wajib diisi"),
	buyPrice: Yup.number()
		.required("Harga beli wajib diisi")
		.min(1, "Harga beli wajib diisi")
		.transform(v => (Number.isNaN(v) ? 0 : v)),
	sellPrice: Yup.number()
		.required("Harga jual wajib diisi")
		.min(1, "Harga jual wajib diisi")
		.transform(v => (Number.isNaN(v) ? 0 : v)),
	statusProduct: Yup.string().required("Status produk wajib diisi"),
	stock: Yup.number().required("Stok wajib diisi").min(1, "Stok wajib diisi"),
});
const FormInitialValues = {
	unitId: "",
	divisionId: "",
	checkupCategoryId: "",
	checkupSupportId: "",
	buyPrice: "",
	sellPrice: "",
	statusProduct: "",
	stock: "",
};

export function LayananPemeriksaanPenunjangAddPage() {
	const {showToast} = useToast();
	const history = useHistory();

	const {roleId: selectedRoleId} = useSelector(state => state.AuthReducer);

	const {values, errors, handleBlur, handleChange, touched, setFieldValue, setFieldTouched, handleSubmit} = useFormik(
		{
			initialValues: FormInitialValues,
			validationSchema: FormSchemaValidation,
			onSubmit: values => {
				const divisionId =
					selectedRoleId === Role.SUB_ADMIN.id
						? selectedHospital.divisionId
						: values.divisionId === "none"
						? null
						: parseInt(values.divisionId);

				const payload = {
					hospitalId: selectedHospital?.Hospital?.id,
					unitId: parseInt(values.unitId),
					divisionId,
					checkupCategoryId: parseInt(values.checkupCategoryId),
					checkupSupportId: parseInt(values.checkupSupportId),
					buyPrice,
					sellPrice,
					statusProduct: parseInt(values.statusProduct),
					stock: parseInt(values.stock),
					status: roleId !== 1 ? null : true,
				};

				console.log("%c 🚀 payload", "color: green; font-weight: bold;", payload);

				mutate(payload, {
					onSuccess: () => {
						showToast("success", "Data berhasil ditambahkan", 3000);
						history.push("/hospital/service-checkup-support/list");
					},
					onError: res => {
						showToast(
							"error",
							convertErrorMessageFormat(res.response.status, res.response.data.message),
							null,
						);
					},
				});
			},
		},
	);

	const {checkupCategoryId} = values;

	useEffect(
		() => {
			if (checkupCategoryId) {
				setFieldValue("checkupSupportId", "");
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[checkupCategoryId],
	);

	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);

	const hospitalUnitsQuery = useHospitalUnits({
		page: 1,
		limit: null,
		isActive: true,
		sortBy: "id.desc",
		hospitalId: selectedHospital?.Hospital?.id,
	});
	const hospitalUnitOptions = useMemo(() => {
		const arr = hospitalUnitsQuery.data?.data?.rows || [];

		return arr.map(e => ({label: e.name, value: e.id}));
	}, [hospitalUnitsQuery.data]);

	const {roleId} = useSelector(state => state.AuthReducer);

	const kategoriPemeriksaanQuery = useListKategoriPemeriksaan({
		page: 1,
		limit: 100,
		isActive: true,
		sortBy: "id.desc",
		hospitalId: selectedHospital?.Hospital?.id,
	});
	const kategoriPemeriksaanOptions = useMemo(() => {
		const arr = kategoriPemeriksaanQuery.data?.data?.rows || [];

		return arr.map(e => ({label: e.name, value: e.id}));
	}, [kategoriPemeriksaanQuery.data]);

	const pemeriksaanPenunjangQuery = useListPemeriksaanPenunjang(
		{
			page: 1,
			limit: null,
			isActive: true,
			sortBy: "id.desc",
			hospitalId: selectedHospital?.Hospital?.id,
			checkupCategoryId: parseInt(values.checkupCategoryId),
		},
		{
			enabled: !!values.checkupCategoryId,
		},
	);
	const pemeriksaanPenunjangOptions = useMemo(() => {
		const arr = pemeriksaanPenunjangQuery.data?.data?.rows || [];

		return arr.map(e => ({label: e.title, value: e.id}));
	}, [pemeriksaanPenunjangQuery.data]);

	const divisiQuery = useListDivisi({
		page: 1,
		limit: 100,
		sortBy: "id.desc",
		hospitalId: selectedHospital?.Hospital?.id,
		isActive: true,
	});
	const divisiOptions = useMemo(() => {
		const arr = divisiQuery.data?.data?.rows || [];

		return arr.map(e => ({label: e.name, value: e.id}));
	}, [divisiQuery.data]);

	const {mutate, isLoading} = useAddLayananPemeriksaanPenunjang();

	const [buyPrice, setBuyPrice] = useState(0);
	const [buyPriceString, setBuyPriceString] = useState("");

	const [sellPrice, setSellPrice] = useState(0);
	const [sellPriceString, setSellPriceString] = useState("");

	useEffect(() => {
		if (buyPrice) {
			setFieldValue("buyPrice", buyPrice);
			setTimeout(() => {
				setFieldTouched("buyPrice", true);
			});
		}
	}, [buyPrice]);

	useEffect(() => {
		if (sellPrice) {
			setFieldValue("sellPrice", sellPrice);
			setTimeout(() => {
				setFieldTouched("sellPrice", true);
			});
		}
	}, [sellPrice]);

	return (
		<DashboardLayout>
			<form onSubmit={handleSubmit}>
				<div class="box-white setting-content h-100 no-border p24">
					<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
						<div class="ttl-20">Tambah Layanan Pemeriksaan Penunjang</div>
					</div>

					<hr />

					<div class="text-bold mb-4">
						<a
							class="text-dark"
							style={{cursor: "pointer"}}
							onClick={() => history.push("/hospital/service-checkup-support/list")}>
							<span class="icon-ico-back me-2" />
						</a>
						Daftar List Layanan Pemeriksaan Penunjang
					</div>

					<div class="row gx-2 mb-5">
						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Unit</label>
								<select
									className={clsx(
										"form-control",
										errors?.unitId && touched.unitId && "form-control-error",
									)}
									disabled={isLoading}
									name="unitId"
									value={values?.unitId}
									onBlur={handleBlur}
									onChange={handleChange}>
									<option value="">Pilih Unit</option>
									{hospitalUnitOptions.map(option => (
										<option value={option.value}>{option.label}</option>
									))}
								</select>
								{errors.unitId && touched.unitId && (
									<p className="form-error-item-message">{errors.unitId}</p>
								)}
							</div>
						</div>

						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Divisi</label>
								<select
									className={clsx(
										"form-control",
										errors?.divisionId && touched.divisionId && "form-control-error",
									)}
									disabled={isLoading || selectedRoleId === Role.SUB_ADMIN.id}
									name="divisionId"
									value={
										selectedRoleId === Role.SUB_ADMIN.id
											? selectedHospital.divisionId
											: values?.divisionId
									}
									onBlur={handleBlur}
									onChange={handleChange}>
									<option value="">Pilih Divisi</option>
									{divisiOptions.map(option => (
										<option value={option.value}>{option.label}</option>
									))}
									<option value="none">None</option>
								</select>
								{errors.divisionId && touched.divisionId && (
									<p className="form-error-item-message">{errors.divisionId}</p>
								)}
							</div>
						</div>

						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Kategori Pemeriksaan</label>
								<select
									className={clsx(
										"form-control",
										errors?.checkupCategoryId && touched.checkupCategoryId && "form-control-error",
									)}
									disabled={isLoading}
									name="checkupCategoryId"
									value={values?.checkupCategoryId}
									onBlur={handleBlur}
									onChange={handleChange}>
									<option value="">Pilih Kategori Pemeriksaan</option>
									{kategoriPemeriksaanOptions.map(option => (
										<option value={option.value}>{option.label}</option>
									))}
								</select>
								{errors.checkupCategoryId && touched.checkupCategoryId && (
									<p className="form-error-item-message">{errors.checkupCategoryId}</p>
								)}
							</div>
						</div>

						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Nama Pemeriksaan</label>
								<select
									className={clsx(
										"form-control",
										errors?.checkupSupportId && touched.checkupSupportId && "form-control-error",
									)}
									disabled={isLoading || !values.checkupCategoryId}
									name="checkupSupportId"
									value={values?.checkupSupportId}
									onBlur={handleBlur}
									onChange={handleChange}>
									<option value="">Pilih Pemeriksaan</option>
									{pemeriksaanPenunjangOptions.map(option => (
										<option value={option.value}>{option.label}</option>
									))}
								</select>
								{errors.checkupSupportId && touched.checkupSupportId && (
									<p className="form-error-item-message">{errors.checkupSupportId}</p>
								)}
							</div>
						</div>

						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Status Produk</label>
								<select
									className={clsx(
										"form-control",
										errors?.statusProduct && touched.statusProduct && "form-control-error",
									)}
									disabled={isLoading}
									name="statusProduct"
									value={values?.statusProduct}
									onBlur={handleBlur}
									onChange={handleChange}>
									<option value="">Pilih Status Produk</option>
									<option value="0">Habis Pakai</option>
									<option value="1">Tidak Habis Pakai</option>
								</select>
								{errors.statusProduct && touched.statusProduct && (
									<p className="form-error-item-message">{errors.statusProduct}</p>
								)}
							</div>
						</div>

						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Harga Beli</label>
								<input
									className={clsx(
										"form-control",
										errors?.buyPrice && touched.buyPrice && "form-control-error",
									)}
									placeholder="Rp"
									type="text"
									value={buyPriceString}
									onChange={e => {
										let value = e.target.value.replace(/\D/g, "");

										setBuyPrice(parseInt(value));

										value = convertToCurency(value);

										setBuyPriceString(value);
									}}
								/>
								{errors.buyPrice && touched.buyPrice && (
									<p className="form-error-item-message">{errors.buyPrice}</p>
								)}
							</div>
						</div>

						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Harga Jual</label>
								<input
									className={clsx(
										"form-control",
										errors?.sellPrice && touched.sellPrice && "form-control-error",
									)}
									placeholder="Rp"
									type="text"
									value={sellPriceString}
									onChange={e => {
										let value = e.target.value.replace(/\D/g, "");

										setSellPrice(parseInt(value));

										value = convertToCurency(value);

										setSellPriceString(value);
									}}
								/>
								{errors.sellPrice && touched.sellPrice && (
									<p className="form-error-item-message">{errors.sellPrice}</p>
								)}
							</div>
						</div>

						<div className="col-sm-12 col-lg-6  col-xs-12">
							<div className="setting-item">
								<label className="setting-label required">Stok</label>
								<input
									className={clsx(
										"form-control",
										errors?.stock && touched.stock && "form-control-error",
									)}
									disabled={isLoading}
									name="stock"
									placeholder="Contoh: 50"
									type="text"
									value={values?.stock}
									onBlur={() => {
										handleBlur();
									}}
									onChange={event => {
										const value = event.target.value;

										if (REGEX_NUMBER.test(value) || value === "") {
											setFieldValue("stock", value);
										}
									}}
								/>
								{errors.stock && touched.stock && (
									<p className="form-error-item-message">{errors.stock}</p>
								)}
							</div>
						</div>
					</div>

					<div class="text-center my-3">
						<SubmitButton
							className="btn btn-orange d-inline-block mw-250"
							disabled={!FormSchemaValidation.isValidSync(values)}
							loading={isLoading}
							text="Simpan"
						/>
					</div>
				</div>
			</form>
		</DashboardLayout>
	);
}
