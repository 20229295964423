import {ButtonAddTable} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import Role from "config/Role";
import {
	useApproveLayananPemeriksaanPenunjang,
	useListLayananPemeriksaanPenunjang,
} from "hooks/AdminHospital/LayananPemeriksaanPenunjang";
import {useStateCallback} from "hooks/useStateCallback";
import {useToast} from "hooks/useToast";
import {useMemo, useState} from "react";
import {Button} from "react-bootstrap";
import {isMobile} from "react-device-detect";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {convertErrorMessageFormat, convertToCurency} from "utils/converter";

const getStatusApproval = approval => {
	if (typeof approval !== "boolean") {
		return (
			<Button className="ft-13" disabled={true} size="sm" variant="secondary">
				Menunggu Persetujuan
			</Button>
		);
	}

	return (
		<Button className="ft-13" disabled={true} size="sm" variant={approval ? "success" : "danger"}>
			{approval ? "Disetujui" : "Ditolak"}
		</Button>
	);
};

export function LayananPemeriksaanPenunjangListPage() {
	const {permission: userPermission, roleId} = useSelector(state => state.AuthReducer);

	const allowEdit = [Role.SUB_ADMIN.id].includes(roleId);

	const {showToast} = useToast();
	const history = useHistory();

	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);

	const auth = useSelector(state => state.AuthReducer);

	const [filter, setFilter] = useState({
		page: 1,
		search: "",
		limit: 10,
		sortBy: "id.desc",
		hospitalId: selectedHospital?.Hospital?.id,
		divisionId: selectedHospital?.divisionId,
	});
	const {data, isLoading} = useListLayananPemeriksaanPenunjang(filter);

	const approvalMutation = useApproveLayananPemeriksaanPenunjang();

	const [selectedItem, setSelectedItem] = useStateCallback({id: null, medicineName: null});
	const [approvalChoice, setApprovalChoice] = useState(null);
	const [showApprovalModal, setShowApproval] = useState(false);

	const getPermission = (...permission) => {
		return permission.includes(userPermission);
	};

	const actionColumnRenderer = row => {
		return (
			<div className="d-block d-md-flex align-items-center ">
				<div className="d-flex align-items-center justify-content-around">
					<a
						className="text-orange wait-pay"
						style={{cursor: "pointer"}}
						onClick={() => history.push(`/hospital/service-checkup-support/show/${row?.values?.id}`)}>
						<span className="icon-ico-eye-open ft-18" />
					</a>
					{(getPermission("CRUD", "CRU") || allowEdit) && (
						<a
							className="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/hospital/service-checkup-support/edit/${row?.values?.id}`)}>
							<span className="icon-ico-pencil ft-18" />
						</a>
					)}
				</div>
			</div>
		);
	};

	const handleClickApproval = (approval, row) => {
		setSelectedItem(row?.original);
		setApprovalChoice(approval);
		setShowApproval(true);
	};

	const approvalColumnRenderer = row => {
		if (typeof row.values.status === "boolean")
			return (
				<Button className="ft-13" disabled={true} size="sm" variant={row.values.status ? "success" : "danger"}>
					{row.values.status ? "Disetujui" : "Ditolak"}
				</Button>
			);

		return (
			<div className="d-flex gap-1">
				<Button className="ft-13" size="sm" variant="success" onClick={() => handleClickApproval(true, row)}>
					Setuju
				</Button>
				<Button className="ft-13" size="sm" variant="danger" onClick={() => handleClickApproval(false, row)}>
					Tolak
				</Button>
			</div>
		);
	};

	const columns = useMemo(
		() => {
			let arr = [
				{
					Header: "No",
					accessor: "idSerial",
				},
				{
					Header: "Unit",
					accessor: "units",
					Cell: ({row}) => {
						return <span className="font-bold">{row?.values?.units?.name}</span>;
					},
				},
				{
					Header: "Kategori Pemeriksaan",
					accessor: "checkupCategories",
					Cell: ({row}) => {
						return <span className="font-bold">{row?.values?.checkupCategories?.name}</span>;
					},
				},
				{
					Header: "Nama Pemeriksaan",
					accessor: "checkupSupports",
					Cell: ({row}) => {
						return <span className="font-bold">{row?.values?.checkupSupports?.title}</span>;
					},
				},
				{
					Header: "Harga Beli",
					accessor: "buyPrice",
					Cell: ({row}) => {
						return <span className="font-bold">Rp {convertToCurency(row?.values?.buyPrice + "")}</span>;
					},
				},
				{
					Header: "Harga Jual",
					accessor: "sellPrice",
					Cell: ({row}) => {
						return <span className="font-bold">Rp {convertToCurency(row?.values?.sellPrice + "")}</span>;
					},
				},
				{
					Header: "Status Produk",
					accessor: "statusProduct",
					Cell: ({row}) => {
						return (
							<span className="font-bold">
								{row?.values?.statusProduct === 1 ? "Tidak Habis Pakai" : "Habis Pakai"}
							</span>
						);
					},
				},
				{
					Header: "Stok",
					accessor: "stock",
					Cell: ({row}) => {
						return <span className="font-bold">{row?.values?.stock}</span>;
					},
				},
			];

			if ([4, 5, 6].includes(auth.roleId)) {
				arr.push({
					Header: "Status",
					accessor: "status",
					Cell: ({row}) => {
						return <span className="font-bold">{getStatusApproval(row?.values?.status)}</span>;
					},
				});
			} else {
				arr.push({
					Header: "Status",
					accessor: "status",
					Cell: ({row}) => approvalColumnRenderer(row),
				});
			}

			return [
				...arr,
				{
					Header: "Aksi",
					accessor: "id",
					Cell: ({row}) => actionColumnRenderer(row),
				},
			];
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[auth],
	);

	const columnsMobile = useMemo(
		() => {
			const arr = [
				{
					Header: "Nama Pemeriksaan",
					accessor: "checkupSupports",
					Cell: ({row}) => {
						return (
							<div className="col-4">
								<span className="font-bold">{row?.values?.checkupSupports?.title}</span>
							</div>
						);
					},
				},
			];

			if (auth.roleId === 4) {
				arr.push({
					Header: "Persetujuan",
					accessor: "status",
					Cell: ({row}) => {
						return <div className="col-4">{getStatusApproval(row?.values?.status)}</div>;
					},
				});
			} else {
				arr.push({
					Header: "Persetujuan",
					accessor: "status",
					Cell: ({row}) => <div className="col-4">{approvalColumnRenderer(row)}</div>,
				});
			}

			return [
				...arr,
				{
					Header: "Action",
					accessor: "id",
					Cell: ({row}) => {
						return <div className="col-4">{actionColumnRenderer(row)}</div>;
					},
				},
			];
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[auth],
	);

	const dataWithIDSerial = useMemo(() => {
		return (
			data?.data?.rows?.map((item, index) => ({
				...item,
				idSerial: (filter.page - 1) * filter.limit + index + 1,
			})) || []
		);
	}, [data, filter]);

	const handleApproval = () => {
		approvalMutation.mutate(
			{id: selectedItem.id, status: approvalChoice},
			{
				onSuccess: () => {
					showToast("success", "Data persetujuan layanan pemeriksaan pemeriksaan berhasil diperbarui", 3000);
					window.scrollTo(0, 0);
					setShowApproval(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<>
				<ModalConfirmation
					approveLabel="Ya"
					description={`Apakah Anda yakin ingin ${
						approvalChoice ? "menyetujui" : "menolak"
					} layanan pemeriksaan penunjang "${selectedItem?.checkupSupports?.title}"`}
					loading={approvalMutation.isLoading}
					rejectLabel="Batal"
					visible={showApprovalModal}
					onApprove={handleApproval}
					onClose={() => setShowApproval(false)}
					onReject={() => setShowApproval(false)}
				/>

				<div className="text-bold text-dark mb-4">
					Data Pemeriksaan Penunjang - {selectedHospital.Hospital.name}
				</div>
				<SearchBox
					rightElement={
						<ButtonAddTable
							text="Tambah Layanan Pemeriksaan Penunjang"
							onClick={() => history.push("/hospital/service-checkup-support/add")}
						/>
					}
					onSearch={keyword => {
						setFilter({...filter, search: keyword, page: 1});
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={data?.data?.currentPage}
					data={dataWithIDSerial}
					limit={filter.limit}
					loading={isLoading}
					striped={true}
					title="Data Pemeriksaan Penunjang"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setFilter({...filter, limit: limitValue});
					}}
					onPageChange={selectedPage => {
						setFilter({...filter, page: selectedPage});
					}}
				/>
			</>
		</DashboardLayout>
	);
}
