import axios from "services/axios";
import {useState} from "react";
import {useQuery} from "react-query";

async function getArticlePost(id, filter) {
	if (!id) {
		const {data} = await axios.get("/article", {params: {...filter}});

		return data;
	}

	const {data} = await axios.get(`/article/${id}`);

	return data;
}

export const QUERY_ARTICLE_POST = "article-post";

export const useArticlePost = () => {
	const [filter, setFilter] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [articlePostId, setArticlePostId] = useState(null);
	const {
		data = [],
		isLoading,
		isError,
		error,
	} = useQuery([QUERY_ARTICLE_POST, filter, articlePostId], async () => getArticlePost(articlePostId, filter));

	return {data, isLoading, isError, error, filter, setFilter, setArticlePostId};
};
