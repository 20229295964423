class Role {
	static ADMIN_RUMAH_SAKIT = {id: 1, name: "Admin Rumah Sakit"};
	static DOKTER = {id: 2, name: "Dokter"};
	static PERAWAT = {id: 3, name: "Perawat"};
	static SUB_ADMIN = {id: 4, name: "Sub Admin"};
	static SUB_STAFF = {id: 5, name: "Sub Staff"};
	static SUB_SUPERVISI = {id: 6, name: "Sub Supervisi"};
	static ADMISSION = {id: 7, name: "Admission"};
	static SUPER_ADMIN = {id: 8, name: "Super Admin"};

	static get() {
		return [
			this.ADMIN_RUMAH_SAKIT,
			this.DOKTER,
			this.PERAWAT,
			this.SUB_ADMIN,
			this.SUB_STAFF,
			this.SUB_SUPERVISI,
			this.ADMISSION,
			this.SUPER_ADMIN,
		];
	}
}

export default Role;
