import clsx from "clsx";
import {useHistory} from "react-router-dom";
import {loadLocalAssets} from "utils/loader";
import {Formik} from "formik";
import * as Yup from "yup";
import {DashboardLayout} from "components/Layout";
import {SubmitButton} from "components/Button";
import {useAddPoliklinik} from "hooks/SuperAdmin/Poliklinik";
import {useToast} from "hooks/useToast";
import {convertErrorMessageFormat} from "utils/converter";
import {useRef, useState} from "react";
import {useUploadFile} from "hooks/Misc";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const FILE_SIZE = 5242880; // 5MB

const FormSchemaValidation = Yup.object().shape({
	name: Yup.string().required("Nama poliklinik wajib di isi"),
	icon: Yup.mixed()
		.test("fileFormat", "Format icon tidak support, hanya menerima format .JPG .JPEG .PNG", value => {
			if (typeof value === "string" || value === null || !value) {
				return true;
			} else {
				return SUPPORTED_FORMATS.includes(value?.type);
			}
		})
		.test("fileSize", "Ukuran file untuk icon maksimal 5 MB", value => {
			if (typeof value === "string" || value === null || !value) {
				return true;
			} else {
				return value?.size <= FILE_SIZE;
			}
		}),
});
const FormInitialValues = {name: "", icon: ""};

export function MasterPoliklinikAddPage() {
	const {showToast} = useToast();
	const formikRef = useRef();
	const history = useHistory();
	const [iconPreview, setIconPreview] = useState(null);
	const {mutate, isLoading} = useAddPoliklinik();
	const {mutate: uploadFile} = useUploadFile();
	const handleSubmit = values => {
		const {icon, ...rest} = values;
		let formData = new FormData();

		if (icon) {
			uploadFile(formData, {
				onSuccess: result => {
					mutate(
						{icon: result.data?.file, ...rest},
						{
							onSuccess: () => {
								showToast("success", "Data berhasil ditambahkan", 3000);
								history.push("/master-poliklinik");
							},
							onError: res => {
								showToast(
									"error",
									convertErrorMessageFormat(res.response.status, res.response.data.message),
									null,
								);
							},
						},
					);
				},
			});
		} else {
			mutate(
				{icon: null, ...rest},
				{
					onSuccess: () => {
						showToast("success", "Data berhasil ditambahkan", 3000);
						history.push("/master-poliklinik");
					},
					onError: res => {
						showToast(
							"error",
							convertErrorMessageFormat(res.response.status, res.response.data.message),
							null,
						);
					},
				},
			);
		}

		formData.append("file", icon);
	};

	const handleUploadLogo = e => {
		const file = e.target.files[0];
		const reader = new FileReader();

		if (!file) return;
		if (!file.type.includes("image")) {
			formikRef?.current?.setFieldError(
				"icon",
				"Format icon tidak support, hanya menerima format .JPG .JPEG .PNG",
			);

			return;
		}
		if (file.size > 5242880) {
			formikRef?.current?.setFieldError("icon", "Ukuran file untuk icon maksimal 5 MB");

			return;
		}

		reader.onload = () => {
			setIconPreview(reader.result);
		};
		reader.readAsDataURL(file);
		formikRef.current.setFieldValue("icon", file);
	};

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={FormSchemaValidation}
				onSubmit={handleSubmit}>
				{({handleSubmit, handleBlur, handleChange, values, touched, errors}) => (
					<div class="box-white setting-content h-100 no-border p24">
						<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
							<div class="ttl-20">Tambah Poliklinik Baru</div>
						</div>

						<hr />

						<div class="text-bold mb-4">
							<a
								class="text-dark"
								style={{cursor: "pointer"}}
								onClick={() => history.push("/master-poliklinik")}>
								<span class="icon-ico-back me-2" />
							</a>
							Daftar List Poliklinik
						</div>

						<div class="row gx-2 mb-5">
							<div class="col-sm-12 col-lg-6  col-xs-12">
								<div class="setting-item">
									<label class="setting-label">Icon</label>
									<div class="change-img mb-4">
										<div class="for-img-change">
											<div class="bx-img-change">
												<img
													alt="Icon Poliklinik"
													class="img-fluid"
													src={
														iconPreview
															? iconPreview
															: loadLocalAssets("img/user-default.jpg")
													}
												/>
											</div>
										</div>
										<div class="for-info-change">
											<div class="upload-img-patient">
												<input id="upload" type="file" onChange={handleUploadLogo} />
												<label class="btn-upload-img-patient" for="upload">
													<img
														alt="Upload logo"
														class="img-fluid me-2"
														src={loadLocalAssets("img/icn-cam.svg")}
													/>
													Upload icon
												</label>
											</div>
											<div class="ft-12 text-grey">*Ukuran file: maks 5 Mb (.JPG .JPEG .PNG)</div>
										</div>
									</div>
									{errors?.icon && touched?.icon && (
										<p className="form-error-item-message">{errors?.icon}</p>
									)}
								</div>
							</div>
							<div class="col-sm-12 col-lg-6">
								<div class="setting-item">
									<label class="setting-label required">Nama Poliklinik</label>
									<input
										class={clsx(
											"form-control",
											errors?.name && touched?.name && "form-control-error",
										)}
										disabled={isLoading}
										name="name"
										placeholder="Contoh: Poliklinik Mata"
										type="text"
										value={values?.name}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.name && touched?.name && (
										<p className="form-error-item-message">{errors.name}</p>
									)}
								</div>
							</div>
						</div>

						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={!FormSchemaValidation.isValidSync(values)}
								loading={isLoading}
								text="Simpan"
								onClick={handleSubmit}
							/>
						</div>
					</div>
				)}
			</Formik>
		</DashboardLayout>
	);
}
