import {ToggleSwitch} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import {useChangeStatusDiagnosa, useListDiagnosa} from "hooks/SuperAdmin/Diagnosa";
import {useStateCallback} from "hooks/useStateCallback";
import {useToast} from "hooks/useToast";
import {useMemo, useState} from "react";
import {isMobile} from "react-device-detect";
import {useSelector} from "react-redux";
import {convertErrorMessageFormat} from "utils/converter";
import {handleSort} from "utils/sort";

export function DiagnosaListPage() {
	const {showToast} = useToast();

	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);
	const [filter, setFilter] = useState({
		page: 1,
		search: "",
		limit: 10,
		sortBy: "id.desc",
		hospitalId: selectedHospital?.Hospital?.id,
	});
	const {data, isLoading} = useListDiagnosa(filter);

	const {mutate, isLoading: isUpdatingData} = useChangeStatusDiagnosa();

	const [selectedItem, setSelectedItem] = useStateCallback({id: null, medicineName: null});
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const actionColumnRenderer = row => {
		return (
			<ToggleSwitch
				isActive={row.original.isActive}
				onChange={() => {
					setSelectedItem(row.original, () => setShowDeleteModal(true));
				}}
			/>
		);
	};

	const columns = useMemo(
		() => [
			{
				Header: "Nama Diagnosa",
				accessor: "name",
			},
			{
				centered: true,
				Header: "Aksi",
				accessor: "isActive",
				width: "200px",
				Cell: ({row}) => actionColumnRenderer(row),
				sortType: handleSort,
			},
		],
		[],
	);
	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Diagnosa",
				accessor: "name",
				Cell: ({row}) => {
					return (
						<div className="col-9">
							<div className="text-bold">{row.values.name}</div>
						</div>
					);
				},
			},
			{
				Header: "Action",
				accessor: "isActive",
				Cell: ({row}) => {
					return <div className="col-3">{actionColumnRenderer(row)}</div>;
				},
				sortType: handleSort,
			},
		],
		[],
	);

	const handeChangeStatusItem = () => {
		mutate(
			{diagnosesId: selectedItem.id, hospitalId: selectedHospital.Hospital.id},
			{
				onSuccess: () => {
					showToast(
						"success",
						`Data diagnosa "${selectedItem.name}" berhasil di ${
							selectedItem.isActive ? "non-aktifkan" : "aktifkan"
						}`,
						3000,
					);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
					setSelectedItem({id: null, name: null});
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<>
				<ModalConfirmation
					approveLabel="Ya"
					description={`Apakah Anda yakin ingin  ${
						selectedItem.statusActive === "active" ? "non-aktifkan" : "aktifkan"
					} data diagnosa "${selectedItem.name}"?`}
					loading={isUpdatingData}
					rejectLabel="Batal"
					visible={showDeleteModal}
					onApprove={handeChangeStatusItem}
					onClose={() => setShowDeleteModal(false)}
					onReject={() => setShowDeleteModal(false)}
				/>

				<div className="text-bold text-dark mb-4">Data Diagnosa - {selectedHospital.Hospital.name}</div>
				<SearchBox
					onSearch={keyword => {
						setFilter({...filter, search: keyword, page: 1});
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={data?.data?.currentPage}
					data={data?.data?.rows || []}
					disableSortBy={false}
					limit={filter.limit}
					loading={isLoading}
					striped={true}
					title="Data Diagnosa"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setFilter({...filter, limit: limitValue});
					}}
					onPageChange={selectedPage => {
						setFilter({...filter, page: selectedPage});
					}}
				/>
			</>
		</DashboardLayout>
	);
}
