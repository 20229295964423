import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY} from "./useQueryPolinik";

export function useAddPoliklinik() {
	return useMutation(values => axios.post("/poliklinik/create", values).then(res => res.data));
}
export function useUpdatePoliklinik(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/poliklinik/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}

export function useDeletePoliklinik(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/poliklinik/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}
