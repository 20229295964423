import {ButtonAddTable} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import {useDeleteTagPost, useTagPost} from "hooks/SuperAdmin/Article";
import {useStateCallback} from "hooks/useStateCallback";
import {useToast} from "hooks/useToast";
import {truncate} from "lodash";
import {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";

const TagPostListPage = () => {
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading, setFilter} = useTagPost();

	const {mutate, isLoading: isDeletingData} = useDeleteTagPost();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteItemData, setDeleteItemData] = useStateCallback({id: null, name: null});
	const {showToast} = useToast();

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Tag",
				accessor: "tag",
				Cell: ({row}) => <p>{truncate(row?.values?.tag, {length: 33})}</p>,
			},
			{
				Header: "Created By",
				accessor: "created_by",
				maxWidth: 150,
			},
			{
				Header: "Updated By",
				accessor: "updated_by",
				maxWidth: 150,
			},
			{
				Header: "Aksi",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/tag-post/detail/${row.values?.id}`)}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/tag-post/edit/${row.values?.id}`)}>
							<span class="icon-ico-pencil ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => {
								setDeleteItemData(row?.values, () => setShowDeleteModal(true));
							}}>
							<span class="icon-ico-delete ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({
			...item,
			idSerial: (data?.data?.currentPage - 1) * dataLimit + index + 1,
		})) || [];

	const handleDeleteData = () => {
		mutate(
			{id: deleteItemData.id},
			{
				onSuccess: () => {
					showToast("success", `Data tag post ${deleteItemData.tag} berhasil dihapus`, 3000);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<ModalConfirmation
				approveLabel="Ya"
				description={`Apakah Anda yakin ingin menghapus data tag post "${deleteItemData.tag}"?`}
				loading={isDeletingData}
				rejectLabel="Batal"
				visible={showDeleteModal}
				onApprove={handleDeleteData}
				onClose={() => setShowDeleteModal(false)}
				onReject={() => setShowDeleteModal(false)}
			/>
			<div class="text-bold text-dark mb-4">Tag Post</div>
			<SearchBox
				rightElement={
					<ButtonAddTable text="Tambah Tag Post" onClick={() => history.push("/tag-post/tambah")} />
				}
				onSearch={keyword => {
					setSearchKeyword(keyword);
					setFilter({page: 1, limit: dataLimit, search: keyword});
				}}
			/>
			<BasicTable
				columns={columns}
				currentPage={data?.data?.currentPage}
				data={dataWithIDSerial}
				limit={dataLimit}
				loading={isLoading}
				striped={true}
				title="Data Tag Post"
				totalItems={data?.data?.totalItems}
				totalPage={data?.data?.totalPages}
				onLimitChange={limitValue => {
					setDataLimit(limitValue);
					setFilter({page: 1, limit: limitValue, search: searchKeyword});
				}}
				onPageChange={selectedPage => setFilter({page: selectedPage, limit: dataLimit, search: searchKeyword})}
			/>
		</DashboardLayout>
	);
};

export {TagPostListPage};
