import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

export function useAddActionCategory(options = {}) {
	return useMutation(values => axios.post("/action_category/create", values).then(res => res.data));
}
export function useEditActionCategory(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/action_category/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("actionCategory");
			queryClient.removeQueries("actionCategory");
		},
	});
}

export function useDeleteActionCategory(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/action_category/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("actionCategory");
			queryClient.removeQueries("actionCategory");
		},
	});
}
