import {useHistory, useParams} from "react-router-dom";
import {DashboardLayout} from "components/Layout";
import {SubmitButton} from "components/Button";
import {CircleLoader} from "components/Loader";
import {FormCard} from "components/Form";
import {useDetailDivision} from "hooks/SuperAdmin/Division";

export function MasterDivisiDetailPage() {
	const history = useHistory();
	const {id} = useParams();
	const {data: detailDivisi, isLoading: loadingFetcing} = useDetailDivision(id);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar list divisi" title="Detail Divisi">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar List Divisi"
				title={`Detail Data Divisi ${detailDivisi?.data?.name}`}
				onBackButtonClick={() => history.push("/master-divisi")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Divisi</label>
							<p className="text-bold text-grey">{detailDivisi?.data?.name}</p>
						</div>
					</div>
				</div>

				<div class="text-center my-3">
					<SubmitButton
						className="btn btn-orange d-inline-block mw-250"
						text="Ubah Data Ini"
						onClick={() => history.push(`/master-divisi/edit/${detailDivisi?.data?.id}`)}
					/>
				</div>
			</FormCard>
		</DashboardLayout>
	);
}
