import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY} from "./useQueryLayananPemeriksaanPenunjang";

export function useAddLayananPemeriksaanPenunjang() {
	return useMutation(values => axios.post("/checkup_support_hospital/create", values).then(res => res.data));
}
export function useUpdateLayananPemeriksaanPenunjang() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/checkup_support_hospital/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
		},
	});
}

export function useDeleteLayananPemeriksaanPenunjang() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/checkup_support_hospital/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
		},
	});
}

export function useApproveLayananPemeriksaanPenunjang() {
	const queryClient = useQueryClient();

	return useMutation(
		formData => axios.post("/checkup_support_hospital/action_requested", formData).then(res => res.data),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(QUERY);
			},
		},
	);
}
