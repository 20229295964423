import {isMobile} from "react-device-detect";
import {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {DashboardLayout} from "components/Layout";
import {BasicTable, SearchBox} from "components/Table";
import {ModalConfirmation} from "components/Modal";
import {ToggleSwitch} from "components/Button";
import {useListAvailableAction, useChangeDataStatus} from "hooks/AdminHospital/Action";
import {useToast} from "hooks/useToast";
import {useStateCallback} from "hooks/useStateCallback";
import {convertErrorMessageFormat} from "utils/converter";
import {handleSort} from "utils/sort";

export function ActionListPage() {
	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);
	const [searchKeyword, setSearchKeyword] = useState("");
	const {data, isLoading, filterActions, filter} = useListAvailableAction(selectedHospital.Hospital.id);
	const [dataLimit, setDataLimit] = useState(10);

	// Delete Data State
	const {mutate, isLoading: isUpdatingData} = useChangeDataStatus();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedItem, setSelectedItem] = useStateCallback({id: null, name: null});
	const {showToast} = useToast();

	const columns = useMemo(
		() => [
			{
				Header: "Nama Tindakan",
				accessor: "title",
				Cell: ({row}) => {
					return row.original?.title || "";
				},
			},
			{
				centered: true,
				Header: "Aksi",
				accessor: "isActive",
				width: "200px",
				Cell: ({row}) => {
					return (
						<>
							<ToggleSwitch
								isActive={row.original.isActive}
								onChange={() => {
									setSelectedItem({...row?.original, status: row.original.isActive}, () =>
										setShowDeleteModal(true),
									);
								}}
							/>
						</>
					);
				},
				sortType: handleSort,
			},
		],
		[setSelectedItem],
	);
	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Tindakan",
				accessor: "title",
				Cell: ({row}) => {
					return (
						<div class="col-6">
							<div class="text-bold">{row.original.title}</div>
						</div>
					);
				},
			},

			{
				Header: "Aksi",
				accessor: "isActive",
				Cell: ({row}) => {
					return (
						<div className="col-6">
							<>
								<ToggleSwitch
									isActive={row.original.isActive}
									onChange={() => {
										setSelectedItem({...row?.original}, () => setShowDeleteModal(true));
									}}
								/>
							</>
						</div>
					);
				},
				sortType: handleSort,
			},
		],
		[setSelectedItem],
	);

	const handeChangeStatusItem = () => {
		mutate(
			{actionId: selectedItem.id, hospitalId: selectedHospital.Hospital.id},
			{
				onSuccess: () => {
					showToast(
						"success",
						`Data Tindakan "${selectedItem.title}" berhasil di ${
							selectedItem.status ? "non-aktifkan" : "aktifkan"
						}`,
						3000,
					);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
					setSelectedItem({id: null, name: null});
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<>
				<ModalConfirmation
					approveLabel="Ya"
					description={`Apakah Anda yakin ingin  ${
						selectedItem.status ? "non-aktifkan" : "aktifkan"
					} data tindakan "${selectedItem.title}"?`}
					loading={isUpdatingData}
					rejectLabel="Batal"
					visible={showDeleteModal}
					onApprove={handeChangeStatusItem}
					onClose={() => setShowDeleteModal(false)}
					onReject={() => setShowDeleteModal(false)}
				/>
				<div class="text-bold text-dark mb-4">Data Tindakan - {selectedHospital.Hospital.name}</div>
				<SearchBox
					onSearch={keyword => {
						setSearchKeyword(keyword);
						filterActions({...filter, page: 1, limit: dataLimit, search: keyword});
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={data?.data?.currentPage}
					data={data?.data?.rows || []}
					disableSortBy={false}
					limit={dataLimit}
					loading={isLoading}
					striped={true}
					title="Data Tindakan"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setDataLimit(limitValue);
						filterActions({...filter, page: 1, limit: limitValue, search: searchKeyword});
					}}
					onPageChange={selectedPage =>
						filterActions({...filter, page: selectedPage, limit: dataLimit, search: searchKeyword})
					}
				/>
			</>
		</DashboardLayout>
	);
}
