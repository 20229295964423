import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY_HARGA_ADMINISTRASI} from "./useQueryHargaAdministrasi";

export function useAddHargaAdministrasi() {
	return useMutation(values => axios.post("/admin_price/create", values).then(res => res.data));
}
export function useUpdateHargaAdministrasi() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/admin_price/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_HARGA_ADMINISTRASI);
		},
	});
}

export function useDeleteHargaAdministrasi() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/admin_price/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY_HARGA_ADMINISTRASI);
		},
	});
}
