export const perawatMenu = {
	top: [
		{
			id: "admin-dashboard",
			title: "Dashboard",
			icon: "icon-ico-dashboard",
			path: "/",
			submenu: [],
		},
		{
			id: "perawat-appointment",
			title: "Appointment",
			icon: "icon-ico-appointment",
			path: "/perawat/appointment/list",
			submenu: [],
		},
	],
	bottom: [],
};
