import {SubmitButton} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {ModalConfirmation} from "components/Modal";
import {BasicTable, SearchBox} from "components/Table";
import {useDeleteVendor, useListVendor} from "hooks/SuperAdmin/Vendor";
import {useStateCallback} from "hooks/useStateCallback";
import {useToast} from "hooks/useToast";
import {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {convertErrorMessageFormat} from "utils/converter";

function ButtonAddPatient({onClick}) {
	return (
		<div class="w-20">
			<div class="row gx-2 align-items-center justify-content-end">
				<SubmitButton text="Tambah Vendor" onClick={onClick} />
			</div>
		</div>
	);
}

const MasterVendorListPage = () => {
	const [page, setPage] = useState(1);
	const [searchKeyword, setSearchKeyword] = useState("");
	const [dataLimit, setDataLimit] = useState(10);
	const {data, isLoading} = useListVendor({page, limit: dataLimit, search: searchKeyword});

	// Delete Data State
	const {mutate: deleteVendorById, isLoading: isDeletingData} = useDeleteVendor();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteItemData, setDeleteItemData] = useStateCallback({id: null, name: null});
	const {showToast} = useToast();

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Kode Vendor",
				accessor: "code",
			},
			{
				Header: "Nama Vendor",
				accessor: "name",
				maxWidth: 150,
			},
			{
				Header: "Email",
				accessor: "email",
				maxWidth: 150,
			},
			{
				Header: "No Telp",
				accessor: "phoneNumber",
			},
			{
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => (
					<>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/master-vendor/${row?.values?.id}/PIC`)}>
							<span class="icon-ico-user ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/master-vendor/show/${row?.values?.id}`)}>
							<span class="icon-ico-eye-open ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => history.push(`/master-vendor/edit/${row?.values?.id}`)}>
							<span class="icon-ico-pencil ft-18" />
						</a>
						<a
							class="text-orange wait-pay"
							style={{cursor: "pointer"}}
							onClick={() => setDeleteItemData(row?.values, () => setShowDeleteModal(true))}>
							<span class="icon-ico-delete ft-18" />
						</a>
					</>
				),
			},
		],
		[],
	);

	const dataWithIDSerial =
		data?.data?.rows?.map((item, index) => ({...item, idSerial: (page - 1) * dataLimit + index + 1})) || [];

	const handleDeleteData = () => {
		deleteVendorById(
			{id: deleteItemData.id},
			{
				onSuccess: () => {
					showToast("success", `Data vendor "${deleteItemData.name}" berhasil dihapus`, 3000);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<>
				<ModalConfirmation
					description={`Apakah anda yakin ingin menghapus data vendor "${deleteItemData.name}"?`}
					loading={isDeletingData}
					visible={showDeleteModal}
					onApprove={handleDeleteData}
					onClose={() => setShowDeleteModal(false)}
					onReject={() => setShowDeleteModal(false)}
				/>
				<div class="text-bold text-dark mb-4">Data Vendor</div>
				<SearchBox
					rightElement={<ButtonAddPatient onClick={() => history.push("/master-vendor/tambah")} />}
					onSearch={keyword => setSearchKeyword(keyword)}
				/>

				<BasicTable
					columns={columns}
					currentPage={data?.data?.currentPage}
					data={dataWithIDSerial}
					limit={dataLimit}
					loading={isLoading}
					striped={true}
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => setDataLimit(limitValue)}
					onPageChange={selectedPage => setPage(selectedPage)}
				/>
			</>
		</DashboardLayout>
	);
};

export {MasterVendorListPage};
