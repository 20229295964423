import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY} from "./useQueryDivision";

export function useAddDivision() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/division/add_division", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}

export function useChangeDataStatus() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/division/change_status_division", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}
