import clsx from "clsx";
import {useFormik} from "formik";
import {useEffect, useMemo, useState} from "react";
import * as Yup from "yup";

const REGEX_NUMBER = /^\d+$/;

const FormSchemaValidation = Yup.object().shape({
	diffFactor: Yup.string().required("Faktor pembeda wajib di isi"),
	gender: Yup.string().when("diffFactor", {
		is: diffFactor => diffFactor === "gender",
		then: Yup.string().required("Jenis kelamin wajib diisi"),
	}),
	limit: Yup.string().when("diffFactor", {
		is: diffFactor => diffFactor === "age",
		then: Yup.string().required("Batasan wajib diisi"),
	}),
	diffValueFirst: Yup.string().when(["diffFactor", "limit"], {
		is: (diffFactor, limit) => diffFactor === "age" && !!limit,
		then: Yup.string().required("Nilai Pembeda pertama wajib diisi"),
	}),
	diffUnitFirst: Yup.string().when(["diffFactor", "limit"], {
		is: (diffFactor, limit) => diffFactor === "age" && !!limit,
		then: Yup.string().required("Satuan Pembeda pertama wajib diisi"),
	}),
	diffValueLast: Yup.string().when(["diffFactor", "limit"], {
		is: (diffFactor, limit) => diffFactor === "age" && limit === "bw",
		then: Yup.string().required("Nilai Pembeda kedua wajib diisi"),
	}),
	diffUnitLast: Yup.string().when(["diffFactor", "limit"], {
		is: (diffFactor, limit) => diffFactor === "age" && limit === "bw",
		then: Yup.string().required("Satuan Pembeda kedua wajib diisi"),
	}),
	referenceLimit: Yup.string().required("Nilai rujukan wajib diisi"),
	referenceValueFirst: Yup.string().required("Nilai rujukan pertama wajib diisi"),
	referenceValueLast: Yup.string().when("referenceLimit", {
		is: referenceLimit => referenceLimit === "bw",
		then: Yup.string().required("Nilai rujukan kedua wajib diisi"),
	}),
	referenceUnit: Yup.string().required("Satuan rujukan wajib diisi"),
});

const FormInitialValues = {
	diffFactor: "",
	limit: "",
	gender: "",
	diffValueFirst: "",
	diffUnitFirst: "",
	referenceLimit: "",
	referenceValueFirst: "",
	referenceValueLast: "",
	referenceUnit: "",
};

const DIFF_FACTOR_OPTIONS = [
	{
		value: "age",
		label: "Usia",
	},
	{
		value: "gender",
		label: "Jenis Kelamin",
	},
	// {
	// 	value: "menstrual_cycle",
	// 	label: "Siklus Mens",
	// },
];

const LIMIT_OPTIONS = [
	{
		value: "lt",
		label: "Di Bawah",
	},
	{
		value: "gt",
		label: "Di Atas",
	},
	{
		value: "bw",
		label: "Di antara",
	},
];

const UNIT_OPTIONS = [
	{
		value: "year",
		label: "Tahun",
	},
	{
		value: "month",
		label: "Bulan",
	},
	{
		value: "week",
		label: "Minggu",
	},
	{
		value: "day",
		label: "Hari",
	},
];

const GENDER_OPTIONS = [
	{
		value: "men",
		label: "Pria",
	},
	{
		value: "women",
		label: "Wanita",
	},
];

const REFERENCE_LIMIT_OPTIONS = [
	{
		value: "eq",
		label: "Sama Dengan",
	},
	{
		value: "lt",
		label: "Kurang Dari",
	},
	{
		value: "gt",
		label: "Lebih Dari",
	},
	{
		value: "bw",
		label: "Di Antara",
	},
];

export default function FormFormulaPemeriksaanPenunjang({initialValues, isLoading, validBaseForm, onChange}) {
	const isEdit = !!initialValues;
	const [isPopulatingInitialValues, setIsPopulatingInitialValues] = useState(isEdit);

	const {values, errors, handleBlur, handleChange, setFieldValue, setFieldTouched} = useFormik({
		initialValues: FormInitialValues,
		validationSchema: FormSchemaValidation,
	});

	const handleTouchField = () => {
		setFieldTouched("diffFactor", true);
		setFieldTouched("limit", true);
		setFieldTouched("gender", true);
		setFieldTouched("diffValueFirst", true);
		setFieldTouched("diffUnitFirst", true);
		setFieldTouched("diffValueLast", true);
		setFieldTouched("diffUnitLast", true);
		setFieldTouched("referenceLimit", true);
		setFieldTouched("referenceValueFirst", true);
		setFieldTouched("referenceValueLast", true);
		setFieldTouched("referenceValueUnit", true);
	};

	useEffect(() => {
		const initializeFormValues = () => {
			if (initialValues) {
				const {
					diffFactor,
					limit,
					gender,
					diffValueFirst,
					diffUnitFirst,
					diffValueLast,
					diffUnitLast,
					referenceLimit,
					referenceValueFirst,
					referenceValueLast,
					referenceUnit,
				} = initialValues;

				setFieldValue("diffFactor", diffFactor);
				setFieldValue("limit", limit || "");
				setFieldValue("gender", gender || "");
				setFieldValue("diffValueFirst", diffValueFirst || "");
				setFieldValue("diffUnitFirst", diffUnitFirst || "");
				setFieldValue("diffValueLast", diffValueLast || "");
				setFieldValue("diffUnitLast", diffUnitLast || "");
				setFieldValue("referenceLimit", referenceLimit || "");
				setFieldValue("referenceValueFirst", referenceValueFirst || "");
				setFieldValue("referenceValueLast", referenceValueLast || "");
				setFieldValue("referenceUnit", referenceUnit || "");

				setTimeout(() => {
					handleTouchField();
					setIsPopulatingInitialValues(false);
				});
			}
		};

		initializeFormValues();
	}, [initialValues]);

	const {diffFactor, gender, limit, diffValueFirst, diffUnitFirst, diffValueLast, diffUnitLast} = values;

	const validAgeForm = Boolean(
		limit && diffValueFirst && diffUnitFirst && (limit === "bw" ? diffValueLast && diffUnitLast : true),
	);

	let showRujukanForm = useMemo(() => {
		if (diffFactor === "gender") return validBaseForm && !!gender;
		if (diffFactor === "age") return validBaseForm && validAgeForm;

		return false;
	}, [diffFactor, validBaseForm, gender, validAgeForm]);

	useEffect(() => {
		const noErrors = Object.keys(errors).length === 0;

		if (diffFactor === "gender") {
			onChange(noErrors && validBaseForm && !!gender, values);
		}

		if (diffFactor === "age") {
			onChange(noErrors && validBaseForm && validAgeForm, values);
		}
	}, [errors, diffFactor, values]);

	useEffect(() => {
		const handleResetOnChangeDiffFactor = () => {
			if (values.diffFactor) {
				setFieldValue("gender", "");
				setFieldValue("limit", "");
				setFieldValue("diffValueFirst", "");
				setFieldValue("diffUnitFirst", "");
				setFieldValue("diffValueLast", "");
				setFieldValue("diffUnitLast", "");
				setFieldValue("referenceLimit", "");
				setFieldValue("referenceValueFirst", "");
				setFieldValue("referenceValueLast", "");
				setFieldValue("referenceUnit", "");
			}
		};

		if (!isPopulatingInitialValues) {
			handleResetOnChangeDiffFactor();
		}
	}, [values.diffFactor]);

	return (
		<div className="row gx-2">
			<div className="col-sm-12 col-lg-6  col-xs-12">
				<div className="setting-item">
					<label className="setting-label required">Faktor Pembeda</label>
					<select
						className={clsx("form-control", errors?.diffFactor && "form-control-error")}
						disabled={isLoading}
						name="diffFactor"
						value={values?.diffFactor}
						onBlur={() => {
							handleBlur();
						}}
						onChange={event => {
							handleChange(event);
						}}>
						<option value="">Pilih Faktor Pembeda</option>
						{DIFF_FACTOR_OPTIONS.map(option => (
							<option value={option.value}>{option.label}</option>
						))}
					</select>
					{errors.diffFactor && <p className="form-error-item-message">{errors.diffFactor}</p>}
				</div>
			</div>

			{values?.diffFactor === "gender" && (
				<div className="col-sm-12 col-lg-6  col-xs-12">
					<div className="setting-item">
						<label className="setting-label required">Jenis Kelamin</label>
						<select
							className={clsx("form-control", errors?.gender && "form-control-error")}
							disabled={isLoading}
							name="gender"
							value={values?.gender}
							onBlur={() => {
								handleBlur();
							}}
							onChange={event => {
								handleChange(event);
							}}>
							<option value="">Pilih Jenis Kelamin</option>
							{GENDER_OPTIONS.map(option => (
								<option value={option.value}>{option.label}</option>
							))}
						</select>
						{errors.gender && <p className="form-error-item-message">{errors.gender}</p>}
					</div>
				</div>
			)}

			{values?.diffFactor === "age" && (
				<div className="col-sm-12 col-lg-6  col-xs-12">
					<div className="setting-item">
						<label className="setting-label required">Batasan</label>
						<select
							className={clsx("form-control", errors?.limit && "form-control-error")}
							disabled={isLoading}
							name="limit"
							value={values?.limit}
							onBlur={() => {
								handleBlur();
							}}
							onChange={event => {
								handleChange(event);
							}}>
							<option value="">Pilih Batasan</option>
							{LIMIT_OPTIONS.map(option => (
								<option value={option.value}>{option.label}</option>
							))}
						</select>
						{errors.limit && <p className="form-error-item-message">{errors.limit}</p>}
					</div>
				</div>
			)}

			{values?.diffFactor === "age" && values?.limit !== "" && (
				<>
					<div className="col-sm-12 col-lg-6  col-xs-12">
						<div className="setting-item">
							<label className="setting-label required">Masukkan Angka</label>
							<input
								className={clsx("form-control", errors?.diffValueFirst && "form-control-error")}
								disabled={isLoading}
								name="diffValueFirst"
								placeholder="Contoh: 50"
								type="text"
								value={values?.diffValueFirst}
								onBlur={() => {
									handleBlur();
								}}
								onChange={event => {
									const value = event.target.value;

									if (REGEX_NUMBER.test(value) || value === "") {
										setFieldValue("diffValueFirst", value);
									}
								}}
							/>
							{errors.diffValueFirst && (
								<p className="form-error-item-message">{errors.diffValueFirst}</p>
							)}
						</div>
					</div>

					<div className="col-sm-12 col-lg-6  col-xs-12">
						<div className="setting-item">
							<label className="setting-label required">Satuan</label>
							<select
								className={clsx("form-control", errors?.diffUnitFirst && "form-control-error")}
								disabled={isLoading}
								name="diffUnitFirst"
								value={values?.diffUnitFirst}
								onBlur={() => {
									handleBlur();
								}}
								onChange={event => {
									handleChange(event);
								}}>
								<option value="">Pilih Satuan</option>
								{UNIT_OPTIONS.map(option => (
									<option value={option.value}>{option.label}</option>
								))}
							</select>
							{errors.diffUnitFirst && <p className="form-error-item-message">{errors.diffUnitFirst}</p>}
						</div>
					</div>
				</>
			)}

			{values?.diffFactor === "age" && values?.limit === "bw" && (
				<>
					<div className="col-sm-12 col-lg-6  col-xs-12">
						<div className="setting-item">
							<label className="setting-label required">Masukkan Angka</label>
							<input
								className={clsx("form-control", errors?.diffValueLast && "form-control-error")}
								disabled={isLoading}
								name="diffValueLast"
								placeholder="Contoh: 50"
								type="text"
								value={values?.diffValueLast}
								onBlur={() => {
									handleBlur();
								}}
								onChange={event => {
									const value = event.target.value;

									if (REGEX_NUMBER.test(value) || value === "") {
										setFieldValue("diffValueLast", value);
									}
								}}
							/>
							{errors.diffValueLast && <p className="form-error-item-message">{errors.diffValueLast}</p>}
						</div>
					</div>

					<div className="col-sm-12 col-lg-6  col-xs-12">
						<div className="setting-item">
							<label className="setting-label required">Satuan</label>
							<select
								className={clsx("form-control", errors?.diffUnitLast && "form-control-error")}
								disabled={isLoading}
								name="diffUnitLast"
								value={values?.diffUnitLast}
								onBlur={() => {
									handleBlur();
								}}
								onChange={event => {
									handleChange(event);
								}}>
								<option value="">Pilih Satuan</option>
								{UNIT_OPTIONS.map(option => (
									<option value={option.value}>{option.label}</option>
								))}
							</select>
							{errors.diffUnitLast && <p className="form-error-item-message">{errors.diffUnitLast}</p>}
						</div>
					</div>
				</>
			)}

			{showRujukanForm && (
				<>
					<div className="col-sm-12 col-lg-4  col-xs-12">
						<div className="setting-item">
							<label className="setting-label required">Nilai Rujukan</label>
							<select
								className={clsx("form-control", errors?.referenceLimit && "form-control-error")}
								disabled={isLoading}
								name="referenceLimit"
								value={values?.referenceLimit}
								onBlur={() => {
									handleBlur();
								}}
								onChange={event => {
									handleChange(event);
								}}>
								<option value="">Pilih Nilai Rujukan</option>
								{REFERENCE_LIMIT_OPTIONS.map(option => (
									<option value={option.value}>{option.label}</option>
								))}
							</select>
							{errors.referenceLimit && (
								<p className="form-error-item-message">{errors.referenceLimit}</p>
							)}
						</div>
					</div>

					{values?.referenceLimit && (
						<>
							<div className="col-sm-12 col-lg-5  col-xs-12">
								<div className="d-flex gap-2">
									<div className="setting-item w-100">
										<label className="setting-label required">Masukkan Angka</label>
										<input
											className={clsx(
												"form-control",
												errors?.referenceValueFirst && "form-control-error",
											)}
											disabled={isLoading}
											name="referenceValueFirst"
											placeholder="Contoh: 50"
											type="text"
											value={values?.referenceValueFirst}
											onBlur={() => {
												handleBlur();
											}}
											onChange={event => {
												const value = event.target.value;

												if (REGEX_NUMBER.test(value) || value === "") {
													setFieldValue("referenceValueFirst", value);
												}
											}}
										/>
										{errors.referenceValueFirst && (
											<p className="form-error-item-message">{errors.referenceValueFirst}</p>
										)}
									</div>

									{values?.referenceLimit === "bw" && (
										<div className="setting-item w-100">
											<label className="setting-label required">Masukkan Angka</label>
											<input
												className={clsx(
													"form-control",
													errors?.referenceValueLast && "form-control-error",
												)}
												disabled={isLoading}
												name="referenceValueLast"
												placeholder="Contoh: 50"
												type="text"
												value={values?.referenceValueLast}
												onBlur={() => {
													handleBlur();
												}}
												onChange={event => {
													const value = event.target.value;

													if (REGEX_NUMBER.test(value) || value === "") {
														setFieldValue("referenceValueLast", value);
													}
												}}
											/>
											{errors.referenceValueLast && (
												<p className="form-error-item-message">{errors.referenceValueLast}</p>
											)}
										</div>
									)}
								</div>
							</div>

							<div className="col-sm-12 col-lg-3  col-xs-12">
								<div className="setting-item">
									<label className="setting-label required">Satuan</label>
									<input
										className={clsx("form-control", errors?.referenceUnit && "form-control-error")}
										disabled={isLoading}
										name="referenceUnit"
										placeholder="Contoh: pgmL"
										type="text"
										value={values?.referenceUnit}
										onBlur={() => {
											handleBlur();
										}}
										onChange={event => {
											handleChange(event);
										}}
									/>
									{errors.referenceUnit && (
										<p className="form-error-item-message">{errors.referenceUnit}</p>
									)}
								</div>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
}
