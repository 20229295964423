import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

export function useAddScheduleHoliday(options = {}) {
	return useMutation(values => axios.post("/schedule_holiday/create", values).then(res => res.data));
}
export function useEditScheduleHoliday(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/schedule_holiday/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("schedule_holidays");
			queryClient.removeQueries("schedule_holidays");
		},
	});
}

export function useDeleteScheduleHoliday(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/schedule_holiday/delete", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries("schedule_holidays");
			queryClient.removeQueries("schedule_holidays");
		},
	});
}
