import {isMobile} from "react-device-detect";
import {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {DashboardLayout} from "components/Layout";
import {BasicTable, SearchBox} from "components/Table";
import {ModalConfirmation} from "components/Modal";
import {ButtonAddTable} from "components/Button";
import {useListKonsultasi, useDeleteKonsultasi} from "hooks/SuperAdmin/Konsultasi";
import {useToast} from "hooks/useToast";
import {useStateCallback} from "hooks/useStateCallback";
import {convertErrorMessageFormat} from "utils/converter";

const initialDeleteState = {idSerial: null, id: null, name: null};

export function MasterKonsultasiListPage() {
	const [filter, setFilter] = useState({page: 1, search: "", limit: 10, sortBy: "id.desc"});
	const {data, isLoading} = useListKonsultasi(filter);

	// Delete Data State
	const {mutate, isLoading: isDeletingData} = useDeleteKonsultasi();
	const [showDeleteModal, setShowDeleteModal] = useStateCallback(false);
	const [deleteItemData, setDeleteItemData] = useStateCallback(initialDeleteState);
	const {showToast} = useToast();

	const history = useHistory();
	const columns = useMemo(
		() => [
			{
				Header: "No",
				accessor: "idSerial",
			},
			{
				Header: "Nama Konsultasi",
				accessor: "name",
				maxWidth: 300,
			},

			{
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<>
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/master-konsultasi/show/${row?.values?.id}`)}>
								<span class="icon-ico-eye-open ft-18" />
							</a>
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/master-konsultasi/edit/${row?.values?.id}`)}>
								<span class="icon-ico-pencil ft-18" />
							</a>
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => {
									setDeleteItemData(row?.values, () => {
										setShowDeleteModal(true);
									});
								}}>
								<span class="icon-ico-delete ft-18" />
							</a>
						</>
					);
				},
			},
		],
		[],
	);
	const columnsMobile = useMemo(
		() => [
			{
				Header: "Nama Konsultasi",
				accessor: "name",
				Cell: ({row}) => {
					return (
						<div class="col-9">
							<div class="text-bold">{row.values.name}</div>
						</div>
					);
				},
			},

			{
				Header: "Action",
				accessor: "id",
				Cell: ({row}) => {
					return (
						<div className="col-3">
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/master-konsultasi/show/${row?.values?.id}`)}>
								<span class="icon-ico-eye-open ft-18" />
							</a>
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => history.push(`/master-konsultasi/edit/${row?.values?.id}`)}>
								<span class="icon-ico-pencil ft-18" />
							</a>
							<a
								class="text-orange wait-pay"
								style={{cursor: "pointer"}}
								onClick={() => {
									setDeleteItemData(row?.values, () => setShowDeleteModal(true));
								}}>
								<span class="icon-ico-delete ft-18" />
							</a>
						</div>
					);
				},
			},
		],
		[],
	);

	const dataWithIDSerial = useMemo(() => {
		return (
			data?.data?.rows?.map((item, index) => ({
				...item,
				idSerial: (filter.page - 1) * filter.limit + index + 1,
			})) || []
		);
	}, [data, filter.limit, filter.page]);

	const handleDeleteData = () => {
		mutate(
			{id: deleteItemData.id},
			{
				onSuccess: () => {
					showToast("success", `Data konsultasi"${deleteItemData.name}" berhasil dihapus`, 3000);
					window.scrollTo(0, 0);
					setShowDeleteModal(false);
				},
				onError: res => {
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	return (
		<DashboardLayout>
			<>
				<ModalConfirmation
					description={`Apakah anda yakin ingin menghapus data konsultasi "${deleteItemData.name}"?`}
					loading={isDeletingData}
					visible={showDeleteModal}
					onApprove={handleDeleteData}
					onClose={() => {
						setDeleteItemData({...deleteItemData, id: null}, () => setShowDeleteModal(false));
					}}
					onReject={() => {
						setDeleteItemData({...deleteItemData, id: null}, () => setShowDeleteModal(false));
					}}
				/>
				<div class="text-bold text-dark mb-4">Data Konsultasi</div>
				<SearchBox
					rightElement={
						<ButtonAddTable
							text="Tambah Konsultasi"
							onClick={() => history.push("/master-konsultasi/tambah")}
						/>
					}
					onSearch={keyword => {
						setFilter({...filter, search: keyword});
					}}
				/>

				<BasicTable
					columns={isMobile ? columnsMobile : columns}
					currentPage={data?.data?.currentPage}
					data={dataWithIDSerial}
					limit={filter.limit}
					loading={isLoading}
					striped={true}
					title="Data Poliklinik"
					totalItems={data?.data?.totalItems}
					totalPage={data?.data?.totalPages}
					onLimitChange={limitValue => {
						setFilter({...filter, limit: limitValue});
					}}
					onPageChange={selectedPage => {
						setFilter({...filter, page: selectedPage});
					}}
				/>
			</>
		</DashboardLayout>
	);
}
