import {useMemo, useState} from "react";
import clsx from "clsx";
import Downshift from "downshift";
import {matchSorter} from "match-sorter";
import parseHTML from "html-react-parser";

export function PatientDropdown({
	name,
	value,
	onChange,
	loading,
	data = [],
	error,
	disabled,
	containerClassName = "setting-item selectric-wrapper selectric-select-top selectric-below",
	label,
	required,
	inputClassName = "",
	placeholder = "Write something",
	searchKeyValue = ["name"],
	inputText,
	inputStyle = {},
	onBlur = () => {},
	onInput = () => {},
}) {
	const [searchValue, setSearchValue] = useState("");
	const filteredData = useMemo(() => {
		if (!data.length) return [];

		return matchSorter(data, searchValue, {keys: searchKeyValue});
	}, [searchValue]);

	const initialValueObject = useMemo(() => {
		if (!value) return null;

		return data.find(item => item.value === value);
	}, [value, data]);

	return (
		<>
			{loading ? (
				<input class="form-control" placeholder="Loading.." value={value} />
			) : (
				<Downshift
					itemToString={item => (item ? item.label : inputText ? inputText : "")}
					selectedItem={initialValueObject}
					onChange={onChange}
					onInputValueChange={e => {
						onInput(e);
						setSearchValue(e);
					}}>
					{({getInputProps, getItemProps, getMenuProps, isOpen, inputValue, getRootProps}) => {
						return (
							<div
								class={clsx(containerClassName, isOpen && "selectric-open")}
								{...getRootProps({}, {suppressRefError: true})}>
								{label && <label class={clsx("setting-label", required && "required")}>{label}</label>}
								<input
									className={clsx("form-control", error && "form-control-error", inputClassName)}
									{...getInputProps()}
									disabled={disabled || loading}
									name={name}
									placeholder={loading ? "Loading..." : placeholder}
									style={inputStyle}
									value={value}
									onBlur={onBlur}
								/>
								{isOpen && (
									<div
										className="selectric-items"
										id="items-searchable-dropdown"
										style={{
											width: "100%",
											display: "block",
											height: "270px",
											zIndex: 99999,
											overflow: "auto",
											margin: 0,
										}}>
										<div className="selectric-scroll">
											<ul {...getMenuProps()}>
												<li />
												{}
												{!filteredData.length ? (
													<p style={{textAlign: "center", fontSize: "16px"}}>
														Data tidak ditemukan
													</p>
												) : (
													filteredData.map((item, index) => (
														<li
															{...getItemProps({
																key: item.value,
																index,
																item,
															})}>
															<div className="d-flex justify-content-flex-start align-items-center">
																{item.image && (
																	<div>
																		<img
																			alt=""
																			className="img-fluid"
																			src={item.image}
																			style={{width: "40px", marginRight: "10px"}}
																		/>
																	</div>
																)}
																<div className="searchable-dropdown-item-label-container">
																	<div className="text-bold text-dark">
																		{item.label}
																	</div>
																	{item.desc && (
																		<div className="selectric-item-desc ft-12 text-grey">
																			{parseHTML(item.desc)}
																		</div>
																	)}
																</div>
															</div>
														</li>
													))
												)}
											</ul>
										</div>
									</div>
								)}

								{error && <p className="form-error-item-message">{error}</p>}
							</div>
						);
					}}
				</Downshift>
			)}
		</>
	);
}
