import clsx from "clsx";
import {useState, useRef, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {loadLocalAssets} from "utils/loader";
import {Formik} from "formik";
import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {SubmitButton} from "components/Button";
import {convertErrorMessageFormat} from "utils/converter";
import {AddHostpitalFormSchemaValidation} from "config/form/FormValidation/Hospitals";
import {useToast} from "hooks/useToast";
import {useEditHospital, useDetailHospital} from "hooks/SuperAdmin/Hospital";
import {useUploadFile} from "hooks/Misc";

const FormInitialValues = {
	logo: undefined,
	name: "",
	address: "",
	shippingAddress: "",
	phone: "",
	email: "",
	fax: "",
	website: "",
	npw: "",
	bankProvider: "",
	bankAccountNumber: "",
	bankAccountHolder: "",
};

export function MasterRumahSakitEditPage() {
	const {showToast} = useToast();
	const formikRef = useRef();
	const inputNpwpRef = useRef();
	const [logoPreview, setLogoPreview] = useState(null);
	const history = useHistory();
	const params = useParams();
	const {id} = params;

	const {data: detailHospital, isLoading: loadingFetcing} = useDetailHospital(id);

	const {mutate: editHospitalByID, isLoading} = useEditHospital();
	const {mutate: uploadFile} = useUploadFile();

	useEffect(() => {
		if (detailHospital?.data?.id) {
			Object.keys(FormInitialValues).forEach(key => {
				formikRef?.current.setFieldValue(key, detailHospital?.data?.[key]);
				formikRef?.current.validateField(key).then(() => {
					formikRef?.current.setFieldTouched(key, true);
				});
			});
			setLogoPreview(detailHospital?.data?.logo);
		}
	}, [detailHospital]);

	const handleEdit = formValue => {
		editHospitalByID(
			{id, ...formValue},
			{
				onSuccess: () => {
					history.push("/master-rumah-sakit");
					showToast("success", "Data berhasil di perbarui", 2600);
				},
				onError: res => {
					window.scrollTo(0, 0);
					showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
				},
			},
		);
	};

	const handleSubmitForm = formValue => {
		if (typeof formValue.logo === "object") {
			const formData = new FormData();

			formData.append("file", formValue.logo);

			uploadFile(formData, {
				onSuccess: result => {
					handleEdit({
						...formValue,
						logo: result.data?.file,
					});
				},
			});
		} else {
			handleEdit(formValue);
		}
	};

	const handleUploadLogo = e => {
		const file = e.target.files[0];
		const reader = new FileReader();

		if (!file) return;
		if (!file.type.includes("image")) {
			formikRef?.current?.setFieldError(
				"logo",
				"Format logo tidak support, hanya menerima format .JPG .JPEG .PNG",
			);

			return;
		}
		if (file.size > 5242880) {
			formikRef?.current?.setFieldError("logo", "Ukuran file untuk logo maksimal 5 MB");

			return;
		}

		reader.onload = () => {
			setLogoPreview(reader.result);
		};
		reader.readAsDataURL(file);
		formikRef.current.setFieldValue("logo", file);
	};

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar List Rumah Sakit" title="Edit Data Rumah Sakit">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<Formik
				initialValues={FormInitialValues}
				innerRef={formikRef}
				validationSchema={AddHostpitalFormSchemaValidation}
				onSubmit={handleSubmitForm}>
				{({handleSubmit, handleBlur, handleChange, values, errors, touched, setFieldValue}) => (
					<FormCard
						backTitle="Daftar List Rumah Sakit"
						title="Edit Data Rumah Sakit"
						onBackButtonClick={() => history.push("/master-rumah-sakit")}>
						<div class="row gx-2 mb-5">
							<div class="col-sm-12 col-lg-6">
								<div class="setting-item">
									<label class="setting-label required">Logo</label>
									<div class="change-img mb-4">
										<div class="for-img-change">
											<div class="bx-img-change">
												<img
													alt="Logo Rumah Sakit"
													class="img-fluid"
													src={
														logoPreview
															? logoPreview
															: loadLocalAssets("img/user-default.jpg")
													}
												/>
											</div>
										</div>
										<div class="for-info-change">
											<div class="upload-img-patient">
												<input id="upload" type="file" onChange={handleUploadLogo} />
												<label class="btn-upload-img-patient" for="upload">
													<img
														alt="Upload logo"
														class="img-fluid me-2"
														src={loadLocalAssets("img/icn-cam.svg")}
													/>
													Upload logo
												</label>
											</div>
											<div class="ft-12 text-grey">*Ukuran file: maks 5 Mb (.JPG .JPEG .PNG)</div>
										</div>
									</div>
									{errors?.logo && touched?.logo && (
										<p className="form-error-item-message">{errors?.logo}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">Nama Rumah Sakit</label>
									<input
										class={clsx(
											"form-control",
											errors?.name && touched?.name && "form-control-error",
										)}
										disabled={isLoading}
										name="name"
										placeholder=""
										type="text"
										value={values?.name}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.name && touched?.name && (
										<p className="form-error-item-message">{errors.name}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">Alamat</label>
									<textarea
										class={clsx(
											"form-control",
											errors?.address && touched?.address && "form-control-error",
										)}
										name="address"
										rows="4"
										spellCheck={false}
										value={values?.address}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.address && touched?.address && (
										<p className="form-error-item-message">{errors.address}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">Alama Pengiriman</label>
									<textarea
										class={clsx(
											"form-control",
											errors?.shippingAddress && touched?.shippingAddress && "form-control-error",
										)}
										name="shippingAddress"
										rows="4"
										spellCheck={false}
										value={values?.shippingAddress}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.shippingAddress && touched?.shippingAddress && (
										<p className="form-error-item-message">{errors.shippingAddress}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">Nomor Telepon</label>
									<input
										class={clsx(
											"form-control",
											errors?.phone && touched?.phone && "form-control-error",
										)}
										disabled={isLoading}
										name="phone"
										placeholder=""
										type="text"
										value={values?.phone}
										onBlur={handleBlur}
										onChange={e => {
											const value = e.target.value.replace(/\D/g, "");

											setFieldValue("phone", value);
										}}
									/>
									{errors.phone && touched?.phone && (
										<p className="form-error-item-message">{errors.phone}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">Fax</label>
									<input
										class={clsx(
											"form-control",
											errors?.fax && touched?.fax && "form-control-error",
										)}
										disabled={isLoading}
										name="fax"
										placeholder=""
										type="text"
										value={values?.fax}
										onBlur={handleBlur}
										onChange={e => {
											const value = e.target.value.replace(/\D/g, "");

											setFieldValue("fax", value);
										}}
									/>
									{errors.fax && touched?.fax && (
										<p className="form-error-item-message">{errors.fax}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">Email</label>
									<input
										class={clsx(
											"form-control",
											errors?.email && touched?.email && "form-control-error",
										)}
										disabled={isLoading}
										name="email"
										placeholder=""
										type="text"
										value={values?.email}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.email && touched?.email && (
										<p className="form-error-item-message">{errors.email}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">NPWP</label>
									<input
										ref={inputNpwpRef}
										class={clsx(
											"form-control",
											errors?.npw && touched?.npw && "form-control-error",
										)}
										disabled={isLoading}
										name="npw"
										placeholder=""
										type="text"
										value={values?.npw}
										onBlur={handleBlur}
										onChange={handleChange}
										// onChange={() => {
										// 	const cardValue = inputNpwpRef.current.value
										// 		.replace(/\D/g, "")
										// 		.match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,1})(\d{0,3})(\d{0,3})/);

										// 	inputNpwpRef.current.value = !cardValue[2]
										// 		? cardValue[1]
										// 		: `${cardValue[1]}.${cardValue[2]}${`${
										// 				cardValue[3] ? `.${cardValue[3]}` : ""
										// 		  }`}${`${cardValue[4] ? `.${cardValue[4]}` : ""}`}${`${
										// 				cardValue[5] ? `-${cardValue[5]}` : ""
										// 		  }`}${`${cardValue[6] ? `.${cardValue[6]}` : ""}`}`;
										// 	const numbers = inputNpwpRef.current.value;

										// 	setFieldValue("npw", numbers);
										// }}
									/>
									{errors.npw && touched?.npw && (
										<p className="form-error-item-message">{errors.npw}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">Website</label>
									<input
										class={clsx(
											"form-control",
											errors?.website && touched?.website && "form-control-error",
										)}
										disabled={isLoading}
										name="website"
										placeholder=""
										type="text"
										value={values?.website}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.website && touched?.website && (
										<p className="form-error-item-message">{errors.website}</p>
									)}
								</div>
							</div>

							<div class="col-sm-12 col-lg-6">
								<div class="setting-item">
									<label class="setting-label required">Nama Bank</label>
									<input
										class={clsx(
											"form-control",
											errors?.bankProvider && touched?.bankProvider && "form-control-error",
										)}
										disabled={isLoading}
										name="bankProvider"
										placeholder=""
										type="text"
										value={values?.bankProvider}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.bankProvider && touched?.bankProvider && (
										<p className="form-error-item-message">{errors.bankProvider}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">No Rekening Bank</label>
									<input
										class={clsx(
											"form-control",
											errors?.bankAccountNumber &&
												touched?.bankAccountNumber &&
												"form-control-error",
										)}
										disabled={isLoading}
										name="bankAccountNumber"
										placeholder=""
										type="text"
										value={values?.bankAccountNumber}
										onBlur={handleBlur}
										onChange={e => {
											const value = e.target.value.replace(/\D/g, "");

											setFieldValue("bankAccountNumber", value);
										}}
									/>
									{errors.bankAccountNumber && touched?.bankAccountNumber && (
										<p className="form-error-item-message">{errors.bankAccountNumber}</p>
									)}
								</div>
								<div class="setting-item">
									<label class="setting-label required">A.N Bank</label>
									<input
										class={clsx(
											"form-control",
											errors?.bankAccountHolder &&
												touched?.bankAccountHolder &&
												"form-control-error",
										)}
										disabled={isLoading}
										name="bankAccountHolder"
										placeholder=""
										type="text"
										value={values?.bankAccountHolder}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
									{errors.bankAccountHolder && touched?.bankAccountHolder && (
										<p className="form-error-item-message">{errors.bankAccountHolder}</p>
									)}
								</div>
							</div>
						</div>

						<div class="text-center my-3">
							<SubmitButton
								className="btn btn-orange d-inline-block mw-250"
								disabled={Object.keys(errors)?.length > 0}
								// disabled={!AddHostpitalFormSchemaValidation.isValidSync(values)}
								loading={isLoading}
								text="Simpan Perubahan"
								onClick={handleSubmit}
							/>
						</div>
					</FormCard>
				)}
			</Formik>
		</DashboardLayout>
	);
}
