import {FcAbout} from "react-icons/fc";

export function DataNotFound({
	style = {height: "75vh"},
	text = "May be you are looking for something that doesn't exist",
}) {
	return (
		<div className="row" style={style}>
			<div className="d-flex flex-column justify-content-center align-items-center">
				<div style={{marginBottom: "10px"}}>
					<FcAbout className="ft-55" />
				</div>
				<h6>{text}</h6>
			</div>
		</div>
	);
}
