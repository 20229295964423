import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY} from "./useQueryMedicalRecord";

export function useAddMedicalRecord(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/medical_record/create", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
			queryClient.removeQueries(QUERY);
		},
	});
}
