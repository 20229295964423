import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getFormList(id, params) {
	if (!id) {
		const {data} = await axios.get(`/form`, {params});

		return data;
	}
	const {data} = await axios.get(`/form/${id}`);

	return data;
}

export function useFormList() {
	const [params, setParams] = useState({
		page: 1,
		search: null,
		type: 1,
		limit: 1,
	});
	const [formId, setFormId] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["formList", params, formId], async () => getFormList(formId, params));

	return {data, isLoading, isError, error, params, setParams, setFormId};
}
