import {useState, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {loadLocalAssets} from "utils/loader";
import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {SubmitButton} from "components/Button";
import {useDetailStaff} from "hooks/SuperAdmin/Staff";

export function StaffDetailPage() {
	const history = useHistory();
	const params = useParams();
	const {id} = params;
	const [detail, setDetail] = useState({});

	const {data: detailStaff, isLoading: loadingFetcing} = useDetailStaff(id);

	useEffect(() => {
		if (detailStaff?.success) {
			setDetail({...detailStaff?.data?.staffDetail, ...detailStaff?.data?.userDetail});
		}
	}, [detailStaff]);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar List Staff" title="Detail Data Staff">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar List Staff"
				title={`Detail Data ${detail?.firstName} ${detail?.lastName}`}
				onBackButtonClick={() => history.push("/hospital/staff")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label ">Foto Profil</label>
							<div class="change-img mb-4">
								<div class="for-img-change">
									<div class="bx-img-change">
										<img
											alt="Profile"
											class="img-fluid"
											src={
												detail?.profilePhoto
													? detail?.profilePhoto
													: loadLocalAssets("img/user-default.jpg")
											}
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Title</label>
							<p className="text-bold text-grey">{detail?.title}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Prefix</label>
							<p className="text-bold text-grey">{detail?.prefix}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Nama Panggilan</label>
							<p className="text-bold text-grey">{detail?.nickname}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Nama Depan & Belakang</label>
							<p className="text-bold text-grey">{detail?.fullName}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Suffix</label>
							<p className="text-bold text-grey">{detail?.suffix}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Email</label>
							<p className="text-bold text-grey">{detail?.email}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Nomor Telepon</label>
							<p className="text-bold text-grey">{detail?.phoneNumber}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">Usia</label>
							<p className="text-bold text-grey">{detail?.dobText}</p>
						</div>
					</div>

					<div class="col-sm-12 col-lg-6">
						<div class="setting-item">
							<label class="setting-label ">Status Karyawan</label>
							<p className="text-bold text-grey">{detail?.employeeStatusText}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">NIP</label>
							<p className="text-bold text-grey">{detail?.nip}</p>
						</div>
						<div class="setting-item">
							<label class="setting-label ">No STR</label>
							<p className="text-bold text-grey">{detail?.noStr}</p>
						</div>
					</div>
				</div>

				<div class="text-center my-3">
					<SubmitButton
						className="btn btn-orange d-inline-block mw-250"
						text="Ubah Data Ini"
						onClick={() => history.push(`/hospital/staff/edit/${id}`)}
					/>
				</div>
			</FormCard>
		</DashboardLayout>
	);
}
