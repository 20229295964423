import axios from "services/axios";
import {useMutation, useQueryClient} from "react-query";

import {QUERY} from "./../Medicine/useQueryMedicine";

export function useUpdateLayananObat() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/medicine/hospital/update", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
		},
	});
}

export function useCreateLayananObat() {
	const queryClient = useQueryClient();

	return useMutation(formData => axios.post("/medicine/hospital/create", formData).then(res => res.data), {
		onSuccess: () => {
			queryClient.invalidateQueries(QUERY);
		},
	});
}
