import Config from "config/env";

/**
 * Usage example:
 * loadLocalAssets('img/logo.svg'); -> "http://localhost:3000/img/logo.svg"
 */
export function loadLocalAssets(assetsName = null) {
	if (assetsName) return `${Config.endpoint.APP_ENDPOINT}/${assetsName}`;

	return null;
}
