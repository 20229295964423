import {useState, useMemo} from "react";
import {useQuery} from "react-query";
import {useSelector} from "react-redux";
import axios from "services/axios";

async function getHospitals(id, filter) {
	const {data} = await axios.get(`/hospital`, {params: {...filter, sortBy: "id.desc"}});

	return data;
}

export function useHospitals(enabled = true, limit = 10) {
	const [filter, filterHospitals] = useState({
		page: 1,
		search: null,
		limit,
		sortBy: "id.desc",
	});
	const [hospitalId, hospitalById] = useState(null);
	const fallback = [];

	const {
		data: hospitals = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["hospitals", hospitalId, filter], async () => getHospitals(hospitalId, filter), {enabled});

	return {hospitals, isLoading, isError, error, filter, filterHospitals, hospitalById};
}

export function useHospitalsOptions(enabled = true, limit = false) {
	const {hospitals, isLoading} = useHospitals(enabled);
	const userData = useSelector(state => state.HospitalsReducer.selectedHospital);

	const hospitalOptions = useMemo(() => {
		if (hospitals.success) {
			let list = hospitals?.data?.rows?.map(hospital => ({
				value: hospital?.id + "",
				label: hospital?.name,
				desc: hospital?.address,
				image: hospital?.logo,
			}));

			if (limit) {
				list = list.filter(x => x.value === userData?.hospitalId?.toString());
			}

			return list;
		}

		return [];
	}, [hospitals]);

	return {hospitalOptions, isLoading};
}

// Fetch Hospital By ID
export const fetchHospitalByID = hospitalId => {
	return axios.get(`/hospital/${hospitalId}`).then(res => res.data);
};

export function useDetailHospital(hospitalId) {
	return useQuery({
		queryKey: hospitalId && ["hospitals", hospitalId],
		queryFn: key => fetchHospitalByID(hospitalId),
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		enabled: !!hospitalId,
	});
}
