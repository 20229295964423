import axios from "services/axios";
import {useState} from "react";
import {useQuery} from "react-query";

async function getArticleReport(id, filter, type) {
	if (!id) {
		const {data} = await axios.get(`/report/${type}`, {params: {...filter, sortBy: "id.desc"}});

		return data;
	}

	const {data} = await axios.get(`/${type}/${id}`);

	return data;
}

export const QUERY_ARTICLE_REPORT = "article-report";

export const useArticleReport = type => {
	const [filter, setFilter] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [articleReportId, setArticleReportId] = useState(null);
	const {
		data = [],
		isLoading,
		isError,
		error,
	} = useQuery([QUERY_ARTICLE_REPORT, filter, articleReportId], async () =>
		getArticleReport(articleReportId, filter, type),
	);

	return {data, isLoading, isError, error, filter, setFilter, setArticleReportId};
};
