import axios from "services/axios";
import {useQuery} from "react-query";

const getBroadcast = async () => {
	const {data} = await axios.get("/patient/send_download_info");

	return data;
};

const useBroadcast = () => {
	const {isLoading, refetch} = useQuery("broadcast", async () => getBroadcast(), {
		enabled: false,
	});

	return {isLoading, refetch};
};

export {useBroadcast};
