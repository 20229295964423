import {useState} from "react";
import {useQuery} from "react-query";
import axios from "services/axios";

async function getScheduleHolidays(id, filter, userId) {
	const {data} = await axios.get(`/schedule_holiday`, {params: {...filter, userId}});

	return data;
}

export function useScheduleHolidays(userId) {
	const [filter, filterScheduleHolidays] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [scheduleHolidayId, scheduleHolidayById] = useState(null);
	const fallback = [];
	const {
		data = fallback,
		isLoading,
		isError,
		error,
	} = useQuery(["scheduleHolidays", scheduleHolidayId, filter], async () =>
		getScheduleHolidays(scheduleHolidayId, filter, userId),
	);

	return {data, isLoading, isError, error, filter, filterScheduleHolidays, scheduleHolidayById};
}

// Fetch Hospital By ID

export const fetchScheduleHolidayByID = scheduleHolidayId => {
	return axios.get(`/schedule_holiday/${scheduleHolidayId}`).then(res => res.data);
};

export function useDetailScheduleHoliday(scheduleHolidayId) {
	return useQuery({
		queryKey: scheduleHolidayId && ["scheduleHolidays", scheduleHolidayId],
		queryFn: key => fetchScheduleHolidayByID(scheduleHolidayId),
		refetchOnMount: true,
		refetchOnWindowFocus: true,
		enabled: !!scheduleHolidayId,
	});
}
