import clsx from "clsx";
import {SubmitButton} from "components/Button";
import {DashboardLayout} from "components/Layout";
import {useFormik} from "formik";
import {useCreateLayananObat, useUpdateLayananObat} from "hooks/AdminHospital/LayananObat";
import {useDetailHospitalMedicine, useListAvailableMedicine} from "hooks/AdminHospital/Medicine";
import {useListAvailableMedicineCategory} from "hooks/AdminHospital/MedicineCategory";
import {useListDivisi} from "hooks/SuperAdmin/Division";
import {useToast} from "hooks/useToast";
import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {convertErrorMessageFormat, convertToCurency} from "utils/converter";
import {REGEX_NUMBER} from "utils/regex";
import * as Yup from "yup";

const FormSchemaValidation = Yup.object().shape({
	divisionId: Yup.string().nullable(true),
	medicineCategoryId: Yup.string().required("Kategori Obat wajib di isi"),
	medicineId: Yup.string().required("Nama Obat wajib di isi"),
	type: Yup.string().required("Status pakai wajib di isi"),
	stock: Yup.string().required("Stok wajib di isi"),
	buyPrice: Yup.number()
		.required("Harga beli wajib diisi")
		.min(1, "Harga beli wajib diisi")
		.transform(v => (Number.isNaN(v) ? 0 : v)),
	sellPrice: Yup.number()
		.required("Harga jual wajib diisi")
		.min(1, "Harga jual wajib diisi")
		.transform(v => (Number.isNaN(v) ? 0 : v)),
});
const FormInitialValues = {
	divisionId: "",
	medicineCategoryId: "",
	medicineId: "",
	type: "",
	stock: "",
	buyPrice: 0,
	sellPrice: 0,
};

export function LayananObatAddPage() {
	const {showToast} = useToast();
	const history = useHistory();
	const {id} = useParams();

	const {data: medicineResponse} = useDetailHospitalMedicine(id);
	const medicineDetail = medicineResponse?.data;

	const selectedHospital = useSelector(state => state.HospitalsReducer.selectedHospital);

	const divisiQuery = useListDivisi({
		page: 1,
		limit: null,
		sortBy: "id.desc",
		hospitalId: selectedHospital?.Hospital?.id,
	});
	const divisiOptions = useMemo(() => {
		const arr = divisiQuery.data?.data?.rows || [];
		// eslint-disable-next-line padding-line-between-statements
		return arr.map(e => ({label: e.name, value: e.id}));
	}, [divisiQuery.data]);

	const medicineCategoryQuery = useListAvailableMedicineCategory(selectedHospital?.Hospital?.id, {limit: null});
	const medicineCategoryOptions = useMemo(() => {
		const arr = medicineCategoryQuery.data?.data?.rows || [];
		// eslint-disable-next-line padding-line-between-statements
		return arr.map(e => ({label: e.name, value: e.id}));
	}, [medicineCategoryQuery.data]);

	const [buyPrice, setBuyPrice] = useState(0);
	const [buyPriceString, setBuyPriceString] = useState("");

	const [sellPrice, setSellPrice] = useState(0);
	const [sellPriceString, setSellPriceString] = useState("");

	const createMutation = useCreateLayananObat();
	const updateMutation = useUpdateLayananObat();
	const isMutating = createMutation.isLoading || updateMutation.isLoading;

	const {values, errors, touched, handleBlur, handleChange, setFieldValue, handleSubmit} = useFormik({
		initialValues: FormInitialValues,
		validationSchema: FormSchemaValidation,
		onSubmit: values => {
			const payload = {
				id: id || null,
				hospitalId: selectedHospital?.hospitalId,
				divisionId: parseInt(values.divisionId) || null,
				medicineId: parseInt(values.medicineId),
				buyPrice,
				sellPrice,
				type: values.type,
				stock: parseInt(values.stock),
				statusApproval: "pending",
			};

			const onSuccess = () => {
				showToast("success", `Data berhasil ${id ? "diperbarui" : "ditambahkan"}`, 3000);
				history.push("/hospital/service-medicine/list");
			};

			const onError = res => {
				showToast("error", convertErrorMessageFormat(res.response.status, res.response.data.message), null);
			};

			if (id)
				return updateMutation.mutate(payload, {
					onSuccess,
					onError,
				});

			return createMutation.mutate(payload, {
				onSuccess,
				onError,
			});
		},
	});

	useEffect(
		() => {
			if (!medicineDetail) return;

			setFieldValue("divisionId", medicineDetail.divisionId);
			setFieldValue("medicineCategoryId", medicineDetail.medicine.medicineCategoryId);
			setFieldValue("medicineId", medicineDetail.medicine.id);
			setFieldValue("type", medicineDetail.type);
			setBuyPrice(medicineDetail.buyPrice);
			setBuyPriceString(convertToCurency(medicineDetail.buyPrice + ""));
			setSellPrice(medicineDetail.sellPrice);
			setSellPriceString(convertToCurency(medicineDetail.sellPrice + ""));
			setFieldValue("stock", medicineDetail.stock);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[medicineDetail],
	);

	const medicineQuery = useListAvailableMedicine(
		selectedHospital?.Hospital?.id,
		{
			limit: null,
			isActive: true,
			medicineCategoryId: values.medicineCategoryId,
		},
		{
			enabled: !!values.medicineCategoryId,
		},
	);
	const medicineCategoryId = values.medicineCategoryId;

	const medicineOptions = useMemo(() => {
		if (!medicineCategoryId) return [];

		const arr = medicineQuery.data?.data?.rows || [];

		// eslint-disable-next-line padding-line-between-statements
		return arr.filter(e => e.category.id === parseInt(medicineCategoryId)).map(e => ({label: e.name, value: e.id}));
	}, [medicineQuery.data, medicineCategoryId]);

	useEffect(
		() => {
			setFieldValue("buyPrice", buyPrice);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[buyPrice],
	);

	useEffect(
		() => {
			setFieldValue("sellPrice", sellPrice);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[sellPrice],
	);

	return (
		<DashboardLayout>
			<form onSubmit={handleSubmit}>
				<div class="box-white setting-content h-100 no-border p24">
					<div class="d-flex justify-content-between flex-wrap align-items-center mb-4">
						<div class="ttl-20">{id ? "Edit" : "Tambah"} Layanan Obat/Apotik Baru</div>
					</div>

					<hr />

					<div class="text-bold mb-4">
						<a
							class="text-dark"
							style={{cursor: "pointer"}}
							onClick={() => history.push("/hospital/service-medicine/list")}>
							<span class="icon-ico-back me-2" />
						</a>
						Daftar List Layanan Obat/Apotik
					</div>

					<div class="row gx-2 mb-5">
						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label">Divisi</label>
								<select
									className={clsx(
										"form-control",
										errors?.divisionId && touched?.divisionId && "form-control-error",
									)}
									disabled={isMutating}
									name="divisionId"
									value={values?.divisionId}
									onBlur={handleBlur}
									onChange={handleChange}>
									<option hidden>Pilih Divisi</option>
									<option value={null}>None</option>
									{divisiOptions.map(option => (
										<option value={option.value}>{option.label}</option>
									))}
								</select>
								{errors.divisionId && touched?.divisionId && (
									<p className="form-error-item-message">{errors.divisionId}</p>
								)}
							</div>
						</div>
						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Kategori Obat</label>
								<select
									className={clsx(
										"form-control",
										errors?.medicineCategoryId &&
											touched?.medicineCategoryId &&
											"form-control-error",
									)}
									disabled={isMutating}
									name="medicineCategoryId"
									value={values?.medicineCategoryId}
									onBlur={handleBlur}
									onChange={e => {
										handleChange(e);
										setFieldValue("medicineId", "");
									}}>
									<option value="">Pilih Kategori Obat</option>
									{medicineCategoryOptions.map(option => (
										<option value={option.value}>{option.label}</option>
									))}
								</select>
								{errors.medicineCategoryId && touched?.medicineCategoryId && (
									<p className="form-error-item-message">{errors.medicineCategoryId}</p>
								)}
							</div>
						</div>
						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Nama Obat</label>
								<select
									className={clsx(
										"form-control",
										errors?.medicineId && touched?.medicineId && "form-control-error",
									)}
									disabled={isMutating || !values.medicineCategoryId}
									name="medicineId"
									value={values?.medicineId}
									onBlur={handleBlur}
									onChange={handleChange}>
									<option value="">Pilih Nama Obat</option>
									{medicineOptions.map(option => (
										<option value={option.value}>{option.label}</option>
									))}
								</select>
								{errors.medicineId && touched?.medicineId && (
									<p className="form-error-item-message">{errors.medicineId}</p>
								)}
							</div>
						</div>

						<div class="col-sm-12 col-lg-6 col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Status Pakai</label>
								<select
									className={clsx(
										"form-control",
										errors?.type && touched?.type && "form-control-error",
									)}
									disabled={isMutating}
									name="type"
									value={values?.type}
									onBlur={handleBlur}
									onChange={handleChange}>
									<option value="">Pilih Satuan Obat</option>
									<option value="habis_pakai">Habis Pakai</option>
									<option value="tidak_habis_pakai">Tidak Habis Pakai</option>
								</select>
								{errors.type && touched?.type && (
									<p className="form-error-item-message">{errors.type}</p>
								)}
							</div>
						</div>

						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Harga Beli</label>
								<input
									className={clsx(
										"form-control",
										errors?.buyPrice && touched?.buyPrice && "form-control-error",
									)}
									name="buyPrice"
									placeholder="Rp"
									type="text"
									value={buyPriceString}
									onBlur={handleBlur}
									onChange={e => {
										let value = e.target.value.replace(/\D/g, "");

										setBuyPrice(parseInt(value));

										value = convertToCurency(value);

										setBuyPriceString(value);
									}}
								/>
								{errors.buyPrice && touched?.buyPrice && (
									<p className="form-error-item-message">{errors.buyPrice}</p>
								)}
							</div>
						</div>

						<div class="col-sm-12 col-lg-6  col-xs-12">
							<div class="setting-item">
								<label class="setting-label required">Harga Jual</label>
								<input
									className={clsx(
										"form-control",
										errors?.sellPrice && touched?.sellPrice && "form-control-error",
									)}
									name="sellPrice"
									placeholder="Rp"
									type="text"
									value={sellPriceString}
									onBlur={handleBlur}
									onChange={e => {
										let value = e.target.value.replace(/\D/g, "");

										setSellPrice(parseInt(value));

										value = convertToCurency(value);

										setSellPriceString(value);
									}}
								/>
								{errors.sellPrice && touched?.sellPrice && (
									<p className="form-error-item-message">{errors.sellPrice}</p>
								)}
							</div>
						</div>

						<div className="col-sm-12 col-lg-6  col-xs-12">
							<div className="setting-item">
								<label className="setting-label required">Stok</label>
								<input
									className={clsx(
										"form-control",
										errors?.stock && touched?.stock && "form-control-error",
									)}
									disabled={isMutating}
									name="stock"
									type="text"
									value={values?.stock}
									onBlur={handleBlur}
									onChange={event => {
										const value = event.target.value;

										if (REGEX_NUMBER.test(value) || value === "") {
											setFieldValue("stock", value);
										}
									}}
								/>
								{errors.stock && touched?.stock && (
									<p className="form-error-item-message">{errors.stock}</p>
								)}
							</div>
						</div>
					</div>

					<div class="text-center my-3">
						<SubmitButton
							className="btn btn-orange d-inline-block mw-250"
							disabled={!FormSchemaValidation.isValidSync(values)}
							loading={isMutating}
							text="Simpan"
						/>
					</div>
				</div>
			</form>
		</DashboardLayout>
	);
}
