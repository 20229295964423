import {FormCard} from "components/Form";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import statusColor from "config/patientStatus";
import {useDetailAppointment} from "hooks/Doctor/Appointment";
import {useHistory, useParams} from "react-router-dom";
import {convertToCurency} from "utils/converter";
import {converISOtoDate, getDayName} from "utils/date";
import {getTranslatedKeyword} from "utils/string";

export function AppointmentDetailPage() {
	const history = useHistory();
	const {id} = useParams();
	const {data, isLoading} = useDetailAppointment(id);
	const patient = data?.data?.patient;
	const doctor = data?.data?.doctor;

	if (isLoading) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar list appointment" title="Detail Appointment">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar List Appointment"
				title={`Detail Data Appointment ${patient?.fullName}`}
				onBackButtonClick={() => history.push("/appointment")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Pasien</label>
							<p className="text-bold text-grey">{patient?.fullName}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Dokter</label>
							<p className="text-bold text-grey">{doctor?.fullName}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Jadwal Appointment</label>
							<p className="text-bold text-grey">
								{`${getDayName(data?.data?.date, "ID-id")}, ${converISOtoDate(data?.data?.date)}`}{" "}
								<br />
								{`${data?.data?.startTime} - ${data?.data?.endTime}`} WIB
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Tipe Konsultasi</label>
							<p className="text-bold text-grey">{data?.data?.type}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Harga</label>
							<p className="text-bold text-grey">{convertToCurency(data?.data?.price + "")}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Status Transaksi</label>
							<p className="text-bold text-grey">
								{getTranslatedKeyword(data?.data?.transaction?.paymentStatus.toLowerCase())}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Status Pasien</label>
							<p className="text-bold text-grey">
								<div
									className="box-status me-2"
									style={{
										background: statusColor.bgColor[data?.data?.patientStatus],
										color: "#fff",
									}}
								/>
								{data?.data?.patientStatus}
							</p>
						</div>
					</div>
				</div>
			</FormCard>
		</DashboardLayout>
	);
}
