import axios from "services/axios";
import {useState} from "react";
import {useQuery} from "react-query";

async function getArticleUser(id, filter, type, roleId) {
	if (!id) {
		const {data} = await axios.get(`/${type}`, {
			params: {...filter, ...(type === "staff/full" && {roleId})},
		});

		return data;
	}
	const {data} = await axios.get(`/${type}/${id}`);

	return data;
}

export const QUERY_ARTICLE_USER = "article-user";

export const useArticleUser = (type, roleId) => {
	const [filter, setFilter] = useState({
		page: 1,
		search: null,
		limit: 10,
	});
	const [articleUserId, setArticleUserId] = useState(null);
	const {
		data = [],
		isLoading,
		isError,
		error,
	} = useQuery([QUERY_ARTICLE_USER, filter, articleUserId, type, roleId], async () =>
		getArticleUser(articleUserId, filter, type, roleId),
	);

	return {data, isLoading, isError, error, filter, setFilter, setArticleUserId};
};
