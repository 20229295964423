import {useQuery} from "react-query";
import axios from "services/axios";
import qs from "query-string";
import {useState} from "react";

export const QUERY = "patients";

export function useListPatient({page = 1, search = "", limit = 10, ...otherFilters}) {
	const [filter, setFilter] = useState({page, search, limit, ...otherFilters});
	const result = useQuery([QUERY, filter], () => axios.get(`/patient?${qs.stringify(filter)}`).then(res => res.data));

	return {...result, setFilter};
}
