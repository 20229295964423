/* eslint-disable no-unused-vars */
import {useHistory, useParams} from "react-router-dom";
import dayjs from "dayjs";
import {DashboardLayout} from "components/Layout";
import {CircleLoader} from "components/Loader";
import {useDetailPendaftaranPasien} from "hooks/PendaftaranPasien";
import {FormCard} from "components/Form";
import {converToLocalGender} from "utils/converter";
import {ProfileImageInitial} from "components/ProfileImage";

export function PendaftaranPasienDetailPage() {
	const history = useHistory();
	const params = useParams();
	const {id} = params;
	const {data: detailPendaftaranPasien, isLoading: loadingFetcing} = useDetailPendaftaranPasien(id);

	if (loadingFetcing) {
		return (
			<DashboardLayout>
				<FormCard backTitle="Daftar List Pendaftaran Pasien" title="Detail Data Pendafataran">
					<CircleLoader />
				</FormCard>
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<FormCard
				backTitle="Daftar List Pendaftaran Pasien"
				title={`Detail data ${detailPendaftaranPasien?.data?.firstName} ${detailPendaftaranPasien?.data?.lastName}`}
				onBackButtonClick={() => history.push("/pendaftaran-pasien")}>
				<div class="row gx-2 mb-5">
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<div class="change-img mb-4">
								<div class="for-img-change">
									<div class="bx-img-change">
										{!detailPendaftaranPasien?.data?.profilePhoto && (
											<ProfileImageInitial
												height={108}
												isMale={detailPendaftaranPasien?.data?.gender === "male"}
												text={`${detailPendaftaranPasien?.data?.firstName[0]}${detailPendaftaranPasien?.data?.lastName[0]}`}
												width={108}
											/>
										)}
										{detailPendaftaranPasien?.data?.profilePhoto && (
											<img
												alt=""
												className="img-fluid"
												src={detailPendaftaranPasien?.data?.profilePhoto}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<label class="setting-label ">Title</label>
							<p className="text-bold text-grey">{detailPendaftaranPasien?.data?.title || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Depan</label>
							<p className="text-bold text-grey">{detailPendaftaranPasien?.data?.firstName || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Belakang</label>
							<p className="text-bold text-grey">{detailPendaftaranPasien?.data?.lastName || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Panggilan</label>
							<p className="text-bold text-grey">{detailPendaftaranPasien?.data?.nickname || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Email</label>
							<p className="text-bold text-grey">{detailPendaftaranPasien?.data?.email || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nomor Telepon</label>
							<p className="text-bold text-grey">{detailPendaftaranPasien?.data?.phoneNumber || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Jenis Kelamin</label>
							<p className="text-bold text-grey">
								{converToLocalGender(detailPendaftaranPasien?.data?.gender) || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Status Pernikahan</label>
							<p className="text-bold text-grey">{detailPendaftaranPasien?.data?.maritalStatus || "-"}</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Tanggal Lahir</label>
							<p className="text-bold text-grey">
								{dayjs(detailPendaftaranPasien?.data?.dateOfBirth).format("DD-MMM-YYYY") || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">ID Number/KTP</label>
							<p className="text-bold text-grey">{detailPendaftaranPasien?.data?.patient?.nik || "-"}</p>
						</div>
					</div>
					<div class="col-sm-12 col-12">
						<div class="setting-item">
							<label class="setting-label ">Kontak Gawat Darurat</label>
							<ul className="list-group list-group-flush col-6">
								{detailPendaftaranPasien?.data?.patient?.emergencyContacts?.map(item => (
									<li className="list-group-item text-bold text-grey col-6">
										{item.relation}: {item.phoneNumber}
									</li>
								))}
							</ul>
							{!detailPendaftaranPasien?.data?.patient?.emergencyContacts && <p>-</p>}
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Remark</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.remark || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Medicare Number</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.medicareNumber || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nomor Rekam Medis</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.medicalRecordNumber || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Email Invoice</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.emailInvoice || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Comunication Preference</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.communicationPreferences?.map(
									(item, index) => {
										return index === 0 ? item : ` & ${item}`;
									},
								)}
								{!detailPendaftaranPasien?.data?.patient?.communicationPreferences && "-"}
							</p>
						</div>
					</div>
				</div>

				{/* addressPayment */}
				<p className="text-bold text-dark mt-4 mb-2">Alamat Pembayaran</p>
				<div class="row gx-2 mb-5">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Alamat</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.addressPayment?.address || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Alamat Detail</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.addressPayment?.addressDetail || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Koordinat</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.addressPayment?.longitude || "-"}
							</p>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.addressPayment?.latitude || "-"}
							</p>
						</div>
					</div>
				</div>

				{/* addresShipment */}
				<p className="text-bold text-dark mt-4 mb-2">Alamat Pengantaran</p>
				<div class="row gx-2 mb-5">
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Depan</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.addressShipment?.firstName || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Belakang</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.addressShipment?.lastName || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Email</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.addressShipment?.email || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nomor Telepon</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.addressShipment?.phoneNumber || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Nama Perusahaan</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.addressShipment?.companyName || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Negara</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.addressShipment?.country || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Provinsi</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.addressShipment?.province || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Kota</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.addressShipment?.city || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Kode Pos</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.addressShipment?.postalCode || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Alamat</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.addressShipment?.address || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Alamat Detail</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.addressShipment?.addressDetail || "-"}
							</p>
						</div>
					</div>
					<div class="col-sm-6 col-6">
						<div class="setting-item">
							<label class="setting-label ">Koordinat</label>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.addressShipment?.longitude || "-"}
							</p>
							<p className="text-bold text-grey">
								{detailPendaftaranPasien?.data?.patient?.addressShipment?.latitude}
							</p>
						</div>
					</div>
				</div>
			</FormCard>
		</DashboardLayout>
	);
}
