import {HospitalAddConsultationPage, HospitalListConsultationPage} from "pages/AdminHospital/Consultation";
import {HospitalAddDivisionPage, HospitalListDivisionPage} from "pages/AdminHospital/Division";
import {DiagnosaListPage} from "pages/AdminHospital/Diagnosa";
import {KategoriPemeriksaanListPage} from "pages/AdminHospital/KategoriPemeriksaan";
import {PemeriksaanPenunjangListPage, PemeriksaanPenunjangDetailPage} from "pages/AdminHospital/PemeriksaanPenunjang";
import {
	LayananPemeriksaanPenunjangListPage,
	LayananPemeriksaanPenunjangDetailPage,
	LayananPemeriksaanPenunjangAddPage,
	LayananPemeriksaanPenunjangEditPage,
} from "pages/AdminHospital/LayananPemeriksaanPenunjang";
import {LayananObatDetailPage, LayananObatListPage, LayananObatAddPage} from "pages/AdminHospital/LayananObat";
import {HospitalAddPoliklinikPage, HospitalListPoliklinikPage} from "pages/AdminHospital/Poliklinik";
import {HospitalAddPositionPage, HospitalListPositionPage} from "pages/AdminHospital/Position";
import {StaffAddPage, StaffDetailPage, StaffEditPage, StaffListPage} from "pages/AdminHospital/Staff";
import {HospitalAddUnitPage, HospitalListUnitPage} from "pages/AdminHospital/Unit";
import {HargaAdministrasiListPage, HargaAdministrasiEditPage} from "pages/AdminHospital/HargaAdministrasi";
import {MedicineCategoryListPage} from "pages/AdminHospital/MedicineCategory";
import {MedicineUnitListPage} from "pages/AdminHospital/MedicineUnit";
import {MedicineHospitalListPage} from "pages/AdminHospital/Medicine";
import {ActionCategoryListPage} from "pages/AdminHospital/ActionCategory";
import {ActionListPage} from "pages/AdminHospital/Action";
import {
	LayananPoliklinikTindakanListPage,
	LayananPoliklinikTindakanDetailPage,
	LayananPoliklinikTindakanAddPage,
	LayananPoliklinikTindakanEditPage,
} from "pages/AdminHospital/LayananPoliklinikTindakan";
import {DoctorListPage, DoctorDetailPage} from "pages/AdminHospital/Doctor";
import {
	PendaftaranPasienListPage,
	PendaftaranPasienAddPage,
	PendaftaranPasienDetailPage,
	PendaftaranPasienEditPage,
	PendaftaranPasienFileAddPage,
} from "pages/AdminAdmission/PendaftaranPasien";
import {HargaPendaftaranObatEdit, HargaPendaftaranObatList} from "pages/SuperAdmin/HargaPendaftaranObat";
import {MasterSediaanObatList} from "pages/SuperAdmin/MasterSediaanObat";
import {MasterLayananObatDetail, MasterLayananObatList} from "pages/SuperAdmin/LayananObat";

export const adminHospitalRoutes = [
	{
		name: "admin-hospital-position-add",
		path: "/hospital/jabatan/add",
		exact: true,
		isPrivate: true,
		component: HospitalAddPositionPage,
	},
	{
		name: "admin-hospital-position-list",
		path: "/hospital/jabatan/list",
		exact: true,
		isPrivate: true,
		component: HospitalListPositionPage,
	},
	{
		name: "admin-hospital-consultation-list",
		path: "/hospital/consultation/list",
		exact: true,
		isPrivate: true,
		component: HospitalListConsultationPage,
	},
	{
		name: "admin-hospital-consultation-add",
		path: "/hospital/consultation/add",
		exact: true,
		isPrivate: true,
		component: HospitalAddConsultationPage,
	},
	{
		name: "admin-hospital-division-list",
		path: "/hospital/divisi/list",
		exact: true,
		isPrivate: true,
		component: HospitalListDivisionPage,
	},
	{
		name: "admin-hospital-division-add",
		path: "/hospital/divisi/add",
		exact: true,
		isPrivate: true,
		component: HospitalAddDivisionPage,
	},
	{
		name: "admin-hospital-unit-list",
		path: "/hospital/unit/list",
		exact: true,
		isPrivate: true,
		component: HospitalListUnitPage,
	},
	{
		name: "admin-hospital-unit-add",
		path: "/hospital/unit/add",
		exact: true,
		isPrivate: true,
		component: HospitalAddUnitPage,
	},
	{
		name: "admin-hospital-poliklinik-list",
		path: "/hospital/poliklinik/list",
		exact: true,
		isPrivate: true,
		component: HospitalListPoliklinikPage,
	},
	{
		name: "admin-hospital-poliklinik-add",
		path: "/hospital/poliklinik/add",
		exact: true,
		isPrivate: true,
		component: HospitalAddPoliklinikPage,
	},
	{
		name: "staff",
		path: "/hospital/staff",
		exact: true,
		isPrivate: true,
		component: StaffListPage,
	},
	{
		name: "staff-tambah",
		path: "/hospital/staff/tambah",
		exact: true,
		isPrivate: true,
		component: StaffAddPage,
	},
	{
		name: "admin-staff-edit",
		path: "/hospital/staff/edit/:id",
		exact: true,
		isPrivate: true,
		component: StaffEditPage,
	},
	{
		name: "admin-staff-detail",
		path: "/hospital/staff/show/:id",
		exact: true,
		isPrivate: true,
		component: StaffDetailPage,
	},
	{
		name: "admin-hospital-diagnosa-list",
		path: "/hospital/diagnosa/list",
		exact: true,
		isPrivate: true,
		component: DiagnosaListPage,
	},
	{
		name: "admin-hospital-checkup-category-list",
		path: "/hospital/checkup-category/list",
		exact: true,
		isPrivate: true,
		component: KategoriPemeriksaanListPage,
	},
	{
		name: "admin-hospital-checkup-support-list",
		path: "/hospital/checkup-support/list",
		exact: true,
		isPrivate: true,
		component: PemeriksaanPenunjangListPage,
	},
	{
		name: "admin-hospital-checkup-support-detail",
		path: "/hospital/checkup-support/show/:id",
		exact: true,
		isPrivate: true,
		component: PemeriksaanPenunjangDetailPage,
	},
	{
		name: "admin-hospital-service-checkup-support-list",
		path: "/hospital/service-checkup-support/list",
		exact: true,
		isPrivate: true,
		component: LayananPemeriksaanPenunjangListPage,
	},
	{
		name: "admin-hospital-service-checkup-support-add",
		path: "/hospital/service-checkup-support/add",
		exact: true,
		isPrivate: true,
		component: LayananPemeriksaanPenunjangAddPage,
	},
	{
		name: "admin-hospital-service-checkup-support-detail",
		path: "/hospital/service-checkup-support/show/:id",
		exact: true,
		isPrivate: true,
		component: LayananPemeriksaanPenunjangDetailPage,
	},
	{
		name: "admin-hospital-service-checkup-support-edit",
		path: "/hospital/service-checkup-support/edit/:id",
		exact: true,
		isPrivate: true,
		component: LayananPemeriksaanPenunjangEditPage,
	},
	{
		name: "admin-hospital-medicine-list",
		path: "/hospital/service-medicine/list",
		exact: true,
		isPrivate: true,
		component: LayananObatListPage,
	},
	{
		name: "admin-hospital-medicine-add",
		path: "/hospital/service-medicine/tambah",
		exact: true,
		isPrivate: true,
		component: LayananObatAddPage,
	},
	{
		name: "admin-hospital-medicine-edit",
		path: "/hospital/service-medicine/edit/:id",
		exact: true,
		isPrivate: true,
		component: LayananObatAddPage,
	},
	{
		name: "admin-hospital-medicine-detail",
		path: "/hospital/service-medicine/show/:id",
		exact: true,
		isPrivate: true,
		component: LayananObatDetailPage,
	},
	{
		name: "layanan-obat",
		path: "/layanan-obat",
		exact: true,
		isPrivate: true,
		component: MasterLayananObatList,
	},
	{
		name: "layanan-obat-tambah",
		path: "/layanan-obat/:action",
		exact: true,
		isPrivate: true,
		component: MasterLayananObatDetail,
	},
	{
		name: "layanan-obat-detail",
		path: "/layanan-obat/:action/:id",
		exact: true,
		isPrivate: true,
		component: MasterLayananObatDetail,
	},
	{
		name: "admin-hospital-harga-administrasi-list",
		path: "/hospital/harga-administrasi/list",
		exact: true,
		isPrivate: true,
		component: HargaAdministrasiListPage,
	},
	{
		name: "admin-hospital-harga-administrasi-edit",
		path: "/hospital/harga-administrasi/edit/:id",
		exact: true,
		isPrivate: true,
		component: HargaAdministrasiEditPage,
	},
	{
		name: "admin-hospital-kategori-obat-list",
		path: "/hospital/kategori-obat/list",
		exact: true,
		isPrivate: true,
		component: MedicineCategoryListPage,
	},
	{
		name: "admin-hospital-unit-obat-list",
		path: "/hospital/unit-obat/list",
		exact: true,
		isPrivate: true,
		component: MedicineUnitListPage,
	},
	{
		name: "admin-hospital-obat-list",
		path: "/hospital/obat/list",
		exact: true,
		isPrivate: true,
		component: MedicineHospitalListPage,
	},
	{
		name: "admin-sediaan-obat",
		path: "/sediaan-obat",
		exact: true,
		isPrivate: true,
		component: MasterSediaanObatList,
	},
	{
		name: "admin-hospital-harga-pendaftaran-obat",
		path: "/harga-pendaftaran-obat",
		exact: true,
		isPrivate: true,
		component: HargaPendaftaranObatList,
	},
	{
		name: "admin-hospital-harga-pendaftaran-obat-edit",
		path: "/harga-pendaftaran-obat/edit/:id",
		exact: true,
		isPrivate: true,
		component: HargaPendaftaranObatEdit,
	},
	{
		name: "admin-hospital-kategori-tindakan-list",
		path: "/hospital/kategori-tindakan/list",
		exact: true,
		isPrivate: true,
		component: ActionCategoryListPage,
	},
	{
		name: "admin-hospital-tindakan-list",
		path: "/hospital/tindakan/list",
		exact: true,
		isPrivate: true,
		component: ActionListPage,
	},
	{
		name: "layanan-poliklinik-tindakan",
		path: "/hospital/layanan-poliklinik-tindakan/list",
		exact: true,
		isPrivate: true,
		component: LayananPoliklinikTindakanListPage,
	},
	{
		name: "layanan-poliklinik-tindakan-tambah",
		path: "/hospital/layanan-poliklinik-tindakan/tambah",
		exact: true,
		isPrivate: true,
		component: LayananPoliklinikTindakanAddPage,
	},
	{
		name: "admin-layanan-poliklinik-tindakan-edit",
		path: "/hospital/layanan-poliklinik-tindakan/edit/:id",
		exact: true,
		isPrivate: true,
		component: LayananPoliklinikTindakanEditPage,
	},
	{
		name: "admin-layanan-poliklinik-tindakan-detail",
		path: "/hospital/layanan-poliklinik-tindakan/show/:id",
		exact: true,
		isPrivate: true,
		component: LayananPoliklinikTindakanDetailPage,
	},
	{
		name: "admin-doctor-list",
		path: "/hospital/doctor",
		exact: true,
		isPrivate: true,
		component: DoctorListPage,
	},
	{
		name: "admin-doctor-detail",
		path: "/hospital/doctor/show/:id",
		exact: true,
		isPrivate: true,
		component: DoctorDetailPage,
	},
	{
		name: "admin-admission-master-pasien",
		path: "/pendaftaran-pasien",
		exact: true,
		isPrivate: true,
		component: PendaftaranPasienListPage,
	},
	{
		name: "admin-admission-tambah-pasien",
		path: "/pendaftaran-pasien/tambah",
		exact: true,
		isPrivate: true,
		component: PendaftaranPasienAddPage,
	},
	{
		name: "admin-admission-detail-pasien",
		path: "/pendaftaran-pasien/show/:id",
		exact: true,
		isPrivate: true,
		component: PendaftaranPasienDetailPage,
	},
	{
		name: "admin-admission-edit-pasien",
		path: "/pendaftaran-pasien/edit/:id",
		exact: true,
		isPrivate: true,
		component: PendaftaranPasienEditPage,
	},
	{
		name: "admin-laboratoriun-master-file-lab-add",
		path: "/file-labs/tambah/:idPatient",
		exact: true,
		isPrivate: true,
		component: PendaftaranPasienFileAddPage,
	},
];
